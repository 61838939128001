import dayjs from "dayjs";
import { PassengerType } from "../constants/settings";

import { ls } from "../locale";
import { stringFormatter } from "./formatter";

export const customerValidator = {
  validatePostalCode,
  validatePOBox,
  validateStreet,
  validateCityName,
  validateCountryCode,
  validateCountryName,
  validateState,
  validateMobile,
  validatePhone,
  validateConfirmEmail,
  validateEmail,
  validateBirthDate,
  validateName,
  validateLastName,
  validateDocumentNumber,
  validatePassportNumber,
  validateExpiryDate,
  validateNationalId,
  validatePassword,
  validateSigninPassword,
  invalidField,
  requiredField,
  validatePhoneTyping,
};

function validateStreet(street) {
  if (!street) return "Street name is required";
  const regex = /^[A-Za-z0-9 ]{1,80}$/;
  return regex.test(street.trim()) ? null : "Invalid street name";
}

function validateCityName(name) {
  if (!name) return "City name is required";
  const regex = /^[A-Za-z0-9 ]{1,32}$/;
  return regex.test(name.trim()) ? null : "Invalid city name";
}

function validatePostalCode(code, country) {
  if (!code) return "Postal code is required";

  const invalid = "Invalid postal code";

  code = code.trim();
  if (country === "US") {
    const regex = /^[0-9]{5}(?:-[0-9]{4})?$/;
    return regex.test(code) ? null : invalid;
  } else if (country === "CA") {
    const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    return regex.test(code) ? null : invalid;
  } else {
    if (code.length > 9) return "Postal code can't be longer than 9 digits";
    const regex = /^[A-Za-z0-9]{1,9}$/;
    return regex.test(code) ? null : invalid;
  }
}

function validatePOBox(code) {
  //if(!code) return "PO Box is required";
  const regex = /^[A-Za-z0-9 ]{0,32}$/;
  return regex.test(code.trim()) ? null : "Invalid PO Box number";
}

function validateCountryCode(code) {
  if (!code) return requiredField('CountryOfResidence'); //  "Country of residence is required";
  const regex = /^[A-Z]{2}$/;
  return regex.test(code.trim()) ? null : invalidField('CountryOfResidence') // "Invalid country code";
}

function validateState(state) {
  if (!state || state.trim().length === 0) return "State is required";

  return null;
}

function validateCountryName(country) {
  if (!country) return "Country name is required";
  const regex = /^[A-Za-z ]{2,32}$/;
  return regex.test(country.trim()) ? null : "Invalid country name";
}

function validateMobile(number) {
  if (!number) return requiredField('MobileNumber'); // "Moblie number is required";

  const regex = /^\d{5,20}$/;
  if (regex.test(number)) return null;
  return invalidField('MobileNumber');
}

function validatePhone(number, required) {
  if (!number) {
    if(!required) return null;
    else return requiredField('PhoneNumber')
  }
  const regex = /^\d{5,20}$/;
  if (regex.test(number)) return null;
  return invalidField('PhoneNumber');
}

function validateConfirmEmail(email, confirm) {
  if (!email) return null;

  const rError = ls.t('Error');

  const error = rError.ConfirmEmailNotMatch; // "Confirm email does not match";
  if (!confirm) return error;
  if (email.toLowerCase() !== confirm.toLowerCase()) return error;

  return null;
}

function validateEmail(email) {
  const regex = /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  if (!regex.test(email)) return invalidField('Email');

  return null;
}

function validateBirthDate(dob, passengerType, flightDate) {

  if(typeof(dob) === 'string') {
    const test = dayjs(dob, "YYYY-MM-DD", false);
    if(!test.isValid() || test.year() <= 1901) return {
      valid: false,
      value: '',
      message: invalidField('DateOfBirth')
    }
    dob = test.toDate();
  }

  const result = {
    valid: false,
    value: dayjs(dob).format("YYYY-MM-DD"),
    message: null
  };

  const y = flightDate.getFullYear();
  const m = flightDate.getMonth();
  const d = flightDate.getDate();

  const infantDate = new Date(y - 2, m, d);
  const childDate = new Date(y - 12, m, d);

  if (passengerType === PassengerType.Infant) {
    if (dob < infantDate) {
      result.message = invalidField('DateOfBirth');
      return result;
    }
  } else if (passengerType === PassengerType.Child) {
    if (dob < childDate || dob > infantDate) {
      result.message = invalidField('DateOfBirth');
      return result;
    }
  } else {
    if (dob > childDate) {
      result.message = invalidField('DateOfBirth');
      return result;
    }
  }

  //dob = dayjs(dob).format("YYYY-MM-DD");

  result.valid = true;
  //result.value = dob;
  return result;
}

function validateName(name) {

  const rError = ls.t('Error');

  if (!name || name.trim().length < 1) return requiredField('Name');
  if (name.length > 20) return rError.NameTooLong;

  const regex = /^[A-Za-z ]{1,20}$/;
  if (!regex.test(name)) return rError.NameInEnglish;

  return null;
}

function validateLastName(name) {
  const inValidName = validateName(name)
  if(inValidName) return inValidName

  if(name.trim().length < 2) {
    const rError = ls.t('Error'); 
    return rError.LastNameTooShort
  }

  return null;
}

function validateDocumentNumber(number) {
  if (!number || number.trim().length < 1) return requiredField('DocumentNumber');

  const regex = /^[A-Za-z0-9]{1,20}$/;
  if (!regex.test(number)) return invalidField('DocumentNumber');

  return null;
}

function validatePassportNumber(number) {
  if (!number || number.trim().length < 1) return requiredField('PassportNumber');

  number = number.trim();
  const regex = /^[A-Za-z0-9-]{5,20}$/;
  if (!regex.test(number)) return invalidField('PassportNumber');

  return null;
}

function validateExpiryDate(date, flightDate) {
  date = dayjs(date);
  if (!date.isValid()) return invalidField('ExpirationDate');

  date = date.toDate();
  if (flightDate > date) {
    return invalidField('ExpirationDate');
  }

  return null;
}

function validateNationalId(nationality, countries) {
  if(!nationality) return requiredField('Nationality');
  if(!Array.isArray(countries)) return null;

  const country = countries.find(x => x.countryCode === nationality);
  if (country && country.isGccCountry) return null;

  const rError = ls.t('Error');

  return rError.NationalIdGcc;
}

function invalidField(field) {
  const rError = ls.t('Error');
  const rInput = ls.t('Input');

  return stringFormatter.formatString(rError.InvalidField, rInput[field]);
}

function requiredField(field) {
  const rError = ls.t('Error');
  const rInput = ls.t('Input');

  return stringFormatter.formatString(rError.RequiredField, rInput[field]);
}

function validatePassword(value) {

  const rError = ls.t('Error');

  value = value ? value.trim() : '';
  if(!value) return requiredField('Password'); // "Password can't be empty";
  if(value.length < 6 || value.length > 20) return rError.PasswordLength; // "Password length is 6 to 20";
  let regex = /\d+/;
  if(!regex.test(value)) return rError.PasswordDigit; // "Password must contain at least one digit";
  regex = /[A-Z]+/;
  if(!regex.test(value)) return rError.PasswordCapitalLetter; // "Password must contain at least one capital letter";
  regex = /[!@#$%^&*]+/;
  if(!regex.test(value)) return rError.SpecialCharacter; // 'Password must contain at least one special character';

  return null;
}

function validateSigninPassword(value) {

  const rError = ls.t('Error');

  value = value ? value.trim() : '';
  if(!value) return requiredField('Password'); // "Password can't be empty";
  if(value.length < 6 || value.length > 20) return rError.PasswordLength; // "Password length is 6 to 20";

  return null;
}

// validate if the value will be valid phone number
// true if it is still valid, otherwise false
function validatePhoneTyping(number) {
  const regex = /^\d{0,20}$/;
  return regex.test(number);
}