import React, { Component } from 'react'
import { Row, Col, Modal, Button } from "react-bootstrap"
import { ls } from "../../../locale";
import { connect } from "react-redux";
import { history, withControlledComponent } from "../../../helpers";
import { UserType } from '../../../constants/settings';

import SidePanel from "../../../components/SidePanel"
import BasePage from "../../../components/BasePage";

import { agentActions } from "../../../state/ducks/agent";
import { sessionActions } from "../../../state/ducks/session";

import LoginForm from "./LoginForm";
import ForgotPasswordBox from './ForgotPasswordBox';
import ChangePasswordBox from '../shared/ChangePasswordBox';

import "./index.scss"


const LoginComponent = withControlledComponent(LoginForm);
const ForgotPasswordComponent = withControlledComponent(ForgotPasswordBox);
const ChangePasswordComponent = withControlledComponent(ChangePasswordBox);

class LoginPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isShowMessageBox: false,
      message: null,
      isShowForgotPasswordBox: false,
      initForgotPasswordDetails: {
        username: '',
        email: ''
      },
      isShowChangePasswordBox: false,
      initChangePasswordDetails: {
        username: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },

      initLoginDetails: {
        iata: process.env.REACT_APP_AGENT_IATANumber || '',
        username: process.env.REACT_APP_AGENT_UserName || '',
        password: process.env.REACT_APP_AGENT_Password || '',
      }
    }
  }

  componentDidMount() {
    this.props.getUserStatus().then(status => {
      //console.log('user status: ', status);
      if(status && status.userType === UserType.agent && status.loggedIn === true) 
        this.goNext();
    })
  }

  login = values => {
    this.props.agentLogin(values.iata, values.username, values.password)
      .then(response => {
        if (response.passwordReset) {
          this.setState({
            initChangePasswordDetails: {
              ...this.state.initChangePasswordDetails,
              username: values.username
            },
            isShowChangePasswordBox: true
          })
        }
        else if (!response.userName) {
          this.showMessageBox('Failed to login. Please try again.');
        }
        else {
          this.goNext();
        }
      })
  }

  onForgotPassword = values => {
    //this.showMessageBox('A temporary password will be sent to your contact email address. Do you want to process?');

    this.setState({
      isShowForgotPasswordBox: true,
      initForgotPasswordDetails: {
        ...this.state.initForgotPasswordDetails,
        username: values.username
      }
    })
  }

  forgotPassword = values => {
    this.hideForgotPasswordBox();

    if (values.username) {
      this.props.forgotPassword(values).then(() => {
        this.showMessageBox("A temporary password has been sent to your contact email address.");
      })
    }
  }

  changePassword = values => {
    this.hideChangePasswordBox();

    this.props.changePassword(values)
      .then(() => {
        this.showMessageBox('Your password was changed. Please sign in with your new password.');
      })
  }

  hideForgotPasswordBox = () => {
    this.setState({
      isShowForgotPasswordBox: false
    })
  }

  hideChangePasswordBox = () => {
    this.setState({
      isShowChangePasswordBox: false
    })
  }
  showMessageBox = message => {
    this.setState({
      isShowMessageBox: true,
      message
    })
  }

  closeMessageBox = () => {
    this.setState({
      isShowMessageBox: false
    })
  }


  goNext = () => {
    const nextPage = "/agent/portal";
    history.push("/" + ls.lang + nextPage);
  }

  render() {
    return (
      <BasePage >
        <Row className="loginCon min-height">
          <Col md={9} xs={12}>
            <LoginComponent onLogin={this.login} onForgotPassword={this.onForgotPassword} initValues={this.state.initLoginDetails} />
          </Col>
          <Col md={3} xs={12}>
            <SidePanel />
          </Col>
        </Row>

        <Modal className={ls.t("rootClass")} show={this.state.isShowMessageBox} onHide={this.closeMessageBox}>
          <Modal.Header closeButton>
            <Modal.Title>{'Warning'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>{this.state.message}</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeMessageBox}>OK</Button>
          </Modal.Footer>
        </Modal>
        {
          this.state.isShowForgotPasswordBox &&
          <ForgotPasswordComponent
            show={this.state.isShowForgotPasswordBox}
            onHide={this.hideForgotPasswordBox}
            onForgotPassword={this.forgotPassword}
            initValues={this.state.initForgotPasswordDetails} />
        }
        {
          this.state.isShowChangePasswordBox &&
          <ChangePasswordComponent
            show={this.state.isShowChangePasswordBox}
            onHide={this.hideChangePasswordBox}
            onChangePassword={this.changePassword}
            message={'Your are signing in with a temporay password. Please enter the details below to set your password.'}
            initValues={this.state.initChangePasswordDetails} />
        }
      </BasePage>
    );
  }
}

function mapStateToProps() {
  return {
  }
}

const mapDispatchToProps = {
  ...agentActions,
  getUserStatus: sessionActions.getUserStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
