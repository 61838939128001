import React from 'react';
import { Dialog, DialogPanel, DialogBackdrop, Button } from '@headlessui/react';
import { ls } from '../../locale';

import btnStyles from "../../styles/btn.module.scss"
import clsx from 'clsx';

export function TitlelessDialogBox({open, closeButtonText, onClose, panelClass, children}
  : {open: boolean, closeButtonText?: string, onClose: (confirmed: boolean) => void, hideCancelButton?: boolean, panelClass?: string, children?: React.ReactNode}
) {

  return (
    <Dialog open={open} onClose={() => onClose(false)} transition className="tw-relative tw-z-50 tw-opacity-100 tw-transition-opacity tw-duration-300 tw-ease-in-out data-[closed]:tw-opacity-0">
      <DialogBackdrop className="tw-fixed tw-inset-0 tw-bg-black/30" />

      <div className="tw-fixed tw-inset-0 tw-w-screen tw-flex tw-items-start tw-justify-center">
        <DialogPanel transition className={ clsx(
            "tw-absolute tw-top-10 tw-w-11/12 tw-max-w-screen-sm",
            "tw-p-5",
            "tw-bg-white tw-border tw-border-solid tw-border-gray-50 tw-rounded-lg tw-shadow-md tw-shadow-gray-500",
            "tw-transition tw-duration-300 data-[closed]:-tw-translate-y-5", panelClass)}>
          <div className="tw-my-4">
            { children}
          </div>
          <div className="tw-grid tw-grid-flow-col tw-justify-stretch tw-gap-2 tw-mt-8">
            <Button className={btnStyles.default} onClick={() => onClose(false)}>{ls.t('Button.Cancel')}</Button>
            <Button className={btnStyles.primary} onClick={() => onClose(true)}>{closeButtonText || ls.t('Button.Close')}</Button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  )
}
