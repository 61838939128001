import React from "react";
import { Row, Col, Checkbox } from "react-bootstrap";


import "./index.scss";
import PriorityLogo from "../../../images/priority-checkin.png"

import { numberFormatter } from "../../../helpers";
import { ls } from "../../../locale";

export default function PriorityCheckin({services, addService, removeService}) {

  if (!Array.isArray(services) || !services.length) return null;

  const rLounge = ls.t('ExtraPage.PriorityCheckin');
  if(!rLounge.Title) return null;

  const segCount = services.length;

  // const serviceAmount = numberFormatter.decimalSplitter(amount);
  // serviceAmount.decimal = numberFormatter.oneDecimalPoint(serviceAmount.decimal);


  return (
    <div className="priority-checkin-section">
      <h1>{rLounge.Label}</h1>
      <Row>
        <Col md={10 - 2 * (segCount - 1)} className="details">
          <img height="80" src={PriorityLogo} alt="Priority Check-in Access" />
          <div className="terms">
            <div className="title">{ rLounge.Title }</div>
            <div className="tips">{ rLounge.Terms}</div>
          </div>
        </Col>
        {services.map(segment => {
          if(!segment.service) return null;

          return segment.service.included
            ? <IncludedLounge key={segment.logicalFlightId} service={segment.service} />
            : <RemovableLounge key={segment.logicalFlightId} 
              service={segment.service} 
              logicalFlightId={segment.logicalFlightId} 
              currencyCode={segment.currencyCode} 
              removeService={removeService} addService={addService} />        
        })}
      </Row>
    </div>
  )
}

function IncludedLounge({service}) {
  const { description } = service;

  return (
    <Col md={2} className= "selection-button">
      <span className="title">&nbsp;</span>
      <p className="description">{description}</p>
      <span className="included">INCLUDED</span>
    </Col>
  )
}

function RemovableLounge({service, logicalFlightId, currencyCode, removeService, addService}) {
  const {selected, removable, amount, description } = service;
  const serviceAmount = numberFormatter.decimalSplitter(amount);
  serviceAmount.decimal = numberFormatter.oneDecimalPoint(serviceAmount.decimal, currencyCode);

  return (
    <Col md={2} className= { selected && removable ? "selection-button selected" : "selection-button" }
      onClick={() => { if(selected && removable) {removeService(logicalFlightId)} else if(!selected) {addService(logicalFlightId)} }}>
      <span className="title">{currencyCode} {serviceAmount.amount}<span className="decimal-point">{serviceAmount.decimal}</span></span>

      <p className="description">{description}</p>
      <Checkbox name="checkPriorityCheckin" checked={selected} disabled={!removable} onChange={() => {}}>
        <label></label>
      </Checkbox>

    </Col>
  )
}
