import React from 'react';
import { FlightDisruption } from '../../state/models';
import { ls } from '../../locale';

export function FlightStatus({disruption} : {disruption: FlightDisruption}) {

  const rDisruptionPage = ls.t('DisruptionPage');

  const className = disruption?.code ? "sa-text-blue sa-bg-yellow" : "tw-border tw-border-solid tw-border-2 tw-border-gray-200 sa-text-green"
  const message = disruption?.message ?? "Scheduled"
  return (
    <div className={`tw-inline-flex tw-justify-start tw-items-center tw-font-bold`}>
      <span className="tw-text-base">{rDisruptionPage.FlightStatus} : </span>
      <span className={`tw-px-2 tw-py-1 tw-rounded-xl tw-text-sm ${className}`}>{message}</span>
    </div>
  )
}