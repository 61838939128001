import React from "react";
import { Button } from "@headlessui/react";

import btnStyles from  "../../styles/btn.module.scss";
import dpStyles from  "./dp.module.scss";

import ArrowLeft from '../../images/arrow-left.png'
import ArrowRight from '../../images/arrow-right.png'
import { ls } from "../../locale";

export function ButtonInActions({text, hint, onClick}: {text: string, hint?: string, onClick: () => void}) {
  return (
    <div className="tw-w-full tw-grid tw-grid-cols-2 md:tw-w-auto md:tw-block tw-relative">
      <ActionHint hint={hint} />
      <Button className={`${btnStyles.primary} tw-w-full`} onClick={onClick}>{text}</Button>
    </div>
  )
}

export function ActionHint({hint}: {hint?: string}) {
  return (
    <div className={`${dpStyles.hint} ${ls.lang === 'ar' ? dpStyles.ar : ''}`}>
      { hint && <>
        <span>{hint}</span>
        <img src={ArrowRight} alt="arrow-right" />
      </>}
    </div>
  )
}
