import React, { useEffect, useState } from "react";
import { ls } from "../../../locale";
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";

import { jumpTo, commonHelpers, isInAppBrowser, webkitHelper, getAgentPath } from "../../../helpers";
import { wciActions } from "../../../state/ducks/wci";
import { bookingActions } from "../../../state/ducks/booking";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import StatusBar from "../../../components/StatusBar";

import FlightPanel from "./FlightPanel";


import "./index.scss";

function SelectPassengers({ booking, getBookingWithAllSegments, selectFlight, getBoardingPass }) {

  const [loading, setLoading] = useState(true);
  const [hasBoardingPass, setHasBoardingPass] = useState(false);
  const [canCheckin, setCanCheckin] = useState(false);

  useEffect(() => {
    getBookingWithAllSegments().then(booking => {
      setLoading(false);

      if (booking && Array.isArray(booking.segments)) {
        booking.segments.forEach(seg => {
          if (!Array.isArray(seg.legs)) return;
          seg.legs.forEach(leg => {
            if (!Array.isArray(leg.customers)) return;
            if (leg.customers.find(c => c.statusName === 'CheckedIn')) setHasBoardingPass(true);
            if (leg.customers.find(c => c.statusName === 'Active' || c.statusName === 'CheckingIn')) setCanCheckin(true);
          })
        });
      }
    });
  }, []);


  const checkinSegment = s => {
    if (!s || !s.logicalFlightId) return;
    selectFlight([s]).then(response => {
      if (response && response.pnr) goNext();
    })
  }

  const printBoardingPass = () => {
    // check if webkit is available; if yes, it's in app browser
    if (webkitHelper.sendPrintBoardingPass()) return;

    getBoardingPass().then((response) => {
      commonHelpers.createDownloadTag(response, 'boarding-pass.pdf');
    });
  }


  //  render() 
  const inAppBrowser = isInAppBrowser();

  const rConfirmationPage = ls.t('ConfirmationPage');
  if (!rConfirmationPage.BookingReference) return null;

  const rFlightDetails = ls.t('FlightDetails');
  const rButton = ls.t('Button');
  const rBookingStatus = ls.t('BookingStatus');

  let bookingStatus = booking.bookingStatus;
  if (rBookingStatus[bookingStatus]) bookingStatus = rBookingStatus[bookingStatus];

  return (
    <div className={ls.t("rootClass") + " main-container webcheck-in"} dir={ls.t("direction")}>
      <div className="header-con">
        <Header />
      </div>
      {!inAppBrowser && <StatusBar label="flights" />}
      <div className="container padt40 padb40">
        <Row className="min-height">
          <Col md={12} className="select-passenger-inner">
            <h1>{rFlightDetails.Title}</h1>
            <hr />
            <p className="booking-status"><b>{rConfirmationPage.BookingStatus} :</b> <span>{bookingStatus}</span></p>
            <div className={"booking-reference " + ls.t('rootClass')}>
              <p>{rConfirmationPage.BookingReference}</p>
              <h2>{booking.pnr}</h2>
            </div>

            {!loading && Array.isArray(booking.segments) && booking.segments.map((segment, index) => (
              <FlightPanel key={index}
                segment={segment}
                passengers={booking.passengers}
                canCheckin={canCheckin}
                onCheckin={s => checkinSegment(s)} />
            ))}
          </Col>
        </Row>
        <div className="clearfix btnCon">
          {hasBoardingPass && <Button className="btn btn-primary pull-left flip btn-cancel btn-effect"
            onClick={printBoardingPass}><span>{rButton.ViewBoardingPass}</span></Button>}
        </div>
      </div>
      {!inAppBrowser && <Footer />}
    </div>
  );
}

function goNext() {
  jumpTo(getAgentPath() + "/wci/passenger");
}

function mapStateToProps(state) {
  return {
    booking: state.booking.details,
  };
}

const mapDispatchToProps = {
  ...bookingActions,
  selectFlight: wciActions.selectFlight,
  getBoardingPass: wciActions.getBoardingPass,

}

export default connect(mapStateToProps, mapDispatchToProps)(SelectPassengers);
