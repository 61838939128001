import React from 'react';
import { ls } from "../../locale";
import { isInAppBrowser } from "../../helpers";

import Header from "../Header";
import Footer from "../Footer";
import StatusBar from "../StatusBar";

import Backdrop from "../Backdrop";

export default function BasePageWithStatusBar(props) {

  const inAppBrowser = isInAppBrowser();
  let className = ls.t("rootClass") + " main-container " + props.mainContainerClass;
  if(inAppBrowser) className += ' in-mobile-app'

  return (
    <div className={className} dir={ls.t("direction")}>
      <div className="header-con">
        <Header hasSummary={props.hasSummary} />
      </div>
      <Backdrop />
      { ! inAppBrowser && <StatusBar label={props.statusLabel} onItemClick={itemClicked} /> }
      <div className="container padt40 padb40">
        {props.children}
      </div>
      { ! inAppBrowser && <Footer /> }
    </div>
  );

  function itemClicked(item) {
    if (!item) return;

    if (typeof (props.onItemClick) === 'function') props.onItemClick(item);
  }
}