import types from "./types"

const INITIAL_STATE = {
  myBookings: null,
  profile: {},
  employeeInfo: null,
}

export default function (state = INITIAL_STATE, {type: actionType, payload }) {
  switch(actionType) {
    case types.INIT_STATE:
      return INITIAL_STATE;

    case types.GET_MY_BOOKINGS_RESPONSE:
      return {
        ...state,
        myBookings: payload,
      };
      
    case types.GET_PROFILE_RESPONSE:
      return {
        ...state,
        profile: payload
      }
    case types.GET_EMPLOYEE_INFO_RESPONSE: 
      return {
        ...state,
        employeeInfo: payload
      }

    default: return state;
  }
}