import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { usePrevious } from "../../helpers/hooks";

function ScrollToTop({location, children}) {

  const prevLocation = usePrevious(location);

  useEffect(() => {
    if(location !== prevLocation) {
      window.scrollTo(0,0);
    }
  }, [location, prevLocation]);

  return children;
}

export default withRouter(ScrollToTop);