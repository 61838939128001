import types from "./types";

const INITIAL_STATE = {
  isLoading: false,
  stations: [],
  countries: [],
};


export default function (state = INITIAL_STATE, { type: actionType, payload }) {
  switch (actionType) {
    case types.STATION_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.STATION_RESPONSE:
      return {
        ...state,
        isLoading: false,
        stations: payload
      };

    case types.COUNTRY_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.COUNTRY_RESPONSE:
      return {
        ...state,
        isLoading: false,
        countries: payload
      };

    default:
      return state;
  }
}