import { api } from "../api"

export const wciService = {
    retrieveBooking,
    confirm,
    getCheckInRequest,
    getSeatMap,
    updateCustomers,
    selectFlight,
    assignSeat,
    checkIn,
    getBoardingPass,
    getBoardingPassForEmail,
    checkExpressCheckin,
    expressCheckin,
}

function retrieveBooking(pnr, lastName) {
  const data = {
      pnr,
      lastName
    };

  return api.getFetch(`api/wci/retrieve`, data)
}

function confirm() {
  return api.postFetch(`api/wci/confirm`)
}

function getCheckInRequest() {
  return api.getFetch(`api/wci/request`)
}

function getSeatMap() {
  return api.getFetch(`api/wci/Seats`)
} 

function updateCustomers(data) {
  return api.postFetch(`api/wci/customers`, data)
}

function selectFlight(data) {
  return api.postFetch(`api/wci/flight`, data)
}

function assignSeat(data) {
  return api.postFetch(`api/wci/assignSeat`, data)
}

function checkIn() {
  return api.postFetch(`api/wci/checkin`)
}

function getBoardingPass() {
  return api.getFetch(`api/wci/boardingpass`)
}

function getBoardingPassForEmail(data) {
  return api.postFetch(`api/wci/pkPassFileForEmail`, data)
}

function checkExpressCheckin() {
  return api.getFetch(`api/wci/expressCheckin`)
}

function expressCheckin(data) {
  return api.postFetch(`api/wci/expressCheckin`, data)
}