import React from "react";
import { Row, Col, FormControl, Button } from "react-bootstrap";

import { customerValidator } from "../../../helpers";
import FormErrorBlock from "../../../components/FormErrorBlock";

export default function PasswordForm(props) {

  const { values, errors, setInputState, setErrors } = props;

  function save() {
    const ee = {};

    if(!values.currentPassword) {
      ee.currentPassword = 'Current Password is required'
    }
    
    if(!values.newPassword)
      ee.newPassword = 'New Password is required'
    else 
      ee.newPassword = customerValidator.validatePassword(values.newPassword);
    
    if(!ee.newPassword && values.confirmPassword !== values.newPassword)
      ee.confirmPassword = 'Confirm Password does not match'; 

    if (Object.values(ee).find(x => x)) {
      setErrors(ee);
      return;
    }

    if (typeof (props.onSave) === 'function') props.onSave(values);
  }

  return (
    <React.Fragment>
      <Row className="password-row">
        <Col md={4} xs={12}>
          <FormControl type="password" placeholder="Current Password" 
            value={values.currentPassword} 
            onChange={event => setInputState('currentPassword', event.target.value)} />
          <FormErrorBlock error={errors.currentPassword} />
        </Col>
        <Col md={4} xs={12}>
          <FormControl type="password" placeholder="New Password" 
            value={values.newPassword} 
            onChange={event => setInputState('newPassword', event.target.value)} />
          <FormErrorBlock error={errors.newPassword} />
        </Col>
        <Col md={4} xs={12}>
          <FormControl type="password" placeholder="Confirm Password" 
            value={values.confirmPassword} 
            onChange={event => setInputState('confirmPassword', event.target.value)} />
          <FormErrorBlock error={errors.confirmPassword} />
        </Col>
      </Row>
      <div className="clearfix btnCon padt20">
        <Button type="submit" className="btn btn-primary pull-right flip btn-submit btn-effect" onClick={save}>
          <span>Save</span>
        </Button>
      </div>
    </React.Fragment>
  );

}

