import React from 'react';
import { Row, Col, FormGroup, FormControl } from "react-bootstrap";

import CountrySelect from '../../../../components/Common/CountrySelect';
import { getSelectedOptionFromCountries } from "../../../../helpers";
import FormErrorBlock from '../../../../components/FormErrorBlock';
import { profileValidator as validator } from "../../../../helpers";
export default function AgencyDetails(props) {

  const { values, errors, setInputState, countries } = props;
  const country = getSelectedOptionFromCountries(values.countryCode, countries) || ' ';

  const setPhoneValue = (field, value) => {
    if(field === 'phone' || field === 'mobile') {
      if(validator.validatePhoneTyping(value)) setInputState(field, value)
    }
  }

  return (
    <>
      <Row className="padt20">
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Travel Agent Number *" 
              value={values.iataNumber} 
              onChange={e => setInputState('iataNumber', e.target.value.toUpperCase())} 
              data-toggle="tooltip" title="Travel Agent Number" />
            <FormErrorBlock error={errors.iataNumber} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="User Name *" 
              value={values.userName} 
              onChange={e => setInputState('userName', e.target.value)} 
              data-toggle="tooltip" title="User Name" />
            <FormErrorBlock error={errors.userName} />
          </FormGroup>
        </Col>
      </Row>
      <Row className="padt20">
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Agency Name *" 
              value={values.agencyName} 
              onChange={e => setInputState('agencyName', e.target.value)} 
              data-toggle="tooltip" title="Agency Name (required)" />
            <FormErrorBlock error={errors.agencyName} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Address *" 
              value={values.address} 
              onChange={e => setInputState('address', e.target.value)} 
              data-toggle="tooltip" title="Address (required)" />
            <FormErrorBlock error={errors.address} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="City *" 
              value={values.city} 
              onChange={e => setInputState('city', e.target.value)} 
              data-toggle="tooltip" title="City (required)" />
            <FormErrorBlock error={errors.city} />
          </FormGroup>
        </Col>

      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="State" 
              value={values.state} 
              onChange={e => setInputState('state', e.target.value)} 
              data-toggle="tooltip" title="State" />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Post Code *" 
              value={values.postalCode} 
              onChange={e => setInputState('postalCode', e.target.value)} 
              data-toggle="tooltip" title="Postal Code (required)" />
            <FormErrorBlock error={errors.postalCode} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <div className="country-select">
            <CountrySelect className="country-control"
              options={countries}
              selected={country}
              onSelected={selected => { setInputState('countryCode', selected.value); setInputState('country', selected.label); }}
              placeholder="Country"
            />
          </div>
        </Col>
      </Row>
      <Row className="padt20">
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Contact Name *" 
              value={values.contactName} 
              onChange={e => setInputState('contactName', e.target.value)} 
              data-toggle="tooltip" title="Contact Name (required)" />
            <FormErrorBlock error={errors.contactName} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Email Address *" 
              value={values.email} 
              onChange={e => setInputState('email', e.target.value)} 
              data-toggle="tooltip" title="Email Address (required)" />
            <FormErrorBlock error={errors.email} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Phone *" 
              value={values.phone} 
              onChange={e => setPhoneValue('phone', e.target.value)} 
              data-toggle="tooltip" title="Phone Number (required)" />
            <FormErrorBlock error={errors.phone} />
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}