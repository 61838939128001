import React, { useState, useEffect } from "react";
import { Dropdown, MenuItem } from "react-bootstrap/lib";
import { ls } from "../../../locale";

import "./currency-select.scss"

export function CurrencySelect({ options=[], selected, onSelected }) {

  const [currencies, setCurrencies] = useState([])

  useEffect(() => {
    const items = options.map(opt => {
      if (opt.includes('-')) {
        const values = opt.split('-');
        return {
          value: values[0].trim(),
          label: values[1].trim(),
        }
      }
      return {
        label: opt,
        value: opt,
      }
    });
    setCurrencies(items);


   }, [options, selected]);

  const handleChange = selectedOption => {
    if (typeof (onSelected) === 'function')
      onSelected(selectedOption);
  }

  return (
    <Dropdown id="currency-select-button" className="flights-currency-select">
      <Dropdown.Toggle bsStyle="default">{selected}</Dropdown.Toggle>
      <Dropdown.Menu className={"currency-options " + ls.t("rootClass") } >
        {Array.isArray(currencies) && currencies.map((item, index) => {
          return (
            <MenuItem key={index} eventKey={item.value} onSelect={handleChange} className="currency-item">
              <span className="currency-code">{item.value}</span>
              <span>&nbsp;-&nbsp;</span>
              <span className="currency-name">{item.label}</span>
            </MenuItem>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )

}
