import React from 'react'

export function PaymentDeniedMessage({message}) {
  if(!message) return null;

  message = message.split(/[\r\n]/);

  return (
    <div className="alert alert-danger" id="payment-denied-message">
      {message.map((m, index) => <p key={index}>{m}</p>)}
    </div>
  );
}