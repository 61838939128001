import React from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { ls } from '../../../locale'

import './index.scss'

import { PopupMessage, usePopupMessage } from "../../../components/PopupMessage"
import SidePanel from "../../../components/SidePanel";
import BasePage from '../../../components/BasePage'
import { RetrieveForm } from './RetrieveForm'

import { voucherActions } from '../../../state/ducks/voucher'

function RetrievePage({resendVoucher}) {

  const popup = usePopupMessage()

  const findVouchers = data => {
    resendVoucher(data)
    .then(() => {
      popup.showMessage('Voucher details have been sent to your email address.', 'Confirmation')
    })
  }

  const rTravelVoucher = ls.t('TravelVoucher')

  return (

    <BasePage>
      <div className="min-height retrieve-con">
        <Row>
          <Col md={9} sm={12}>
            <h1 className="title">{rTravelVoucher.RetreiveVouchers}</h1>
            <hr />
            <p className="description">{rTravelVoucher.RetrieveVoucherMessage}</p>
            <RetrieveForm onFindVouchers={data => findVouchers(data)} />
          </Col>
          <Col md={3} sm={12}>
            <SidePanel />
          </Col>
        </Row>
      </div>

      <PopupMessage show={popup.show} title={popup.title} message={popup.message} onHide={popup.hideMessage} hideCancelButton={true} />
    </BasePage>
  )
}


const mapStateToProps = () => ({})

const mapDispatchToProps = {
  ...voucherActions
}

export default connect(mapStateToProps, mapDispatchToProps)(RetrievePage)