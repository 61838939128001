import { loadingConstants } from "../constants";
import { webkitHelper } from "../helpers";

const initState = {
  loading: false,
  count: 0,
};

export function loadingStatus(state = initState, action) {
  let count = state.count;
  switch(action.type) {
    case loadingConstants.LOADING_STARTED:
      count ++;

      if(count === 1) {
        webkitHelper.sendLoadingStatus(true);
      }

      return {
        ...state,
        count: count,
        loading: true,
        message: action.message
      };

    case loadingConstants.LOADING_COMPLETED:
      count --;

      if(count === 0) {
        webkitHelper.sendLoadingStatus(false);
      }

      return {
        ...state,
        count: count,
        loading: count > 0,
        message: null
      };

    default: return state;
  }
}