import React, { useEffect, useState } from "react";
import { ls } from "../../../locale";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import { getAgentPath, isInAppBrowser } from "../../../helpers";
import { numberFormatter } from "../../../helpers";
import { PassengerType, PassengerStatus } from "../../../constants/settings";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import StatusBar from "../../../components/StatusBar";
import SeatSelection from "../../../components/SeatSelection";

import { bookingActions } from "../../../state/ducks/booking";
import { wciActions } from "../../../state/ducks/wci";

import "./index.scss";
import Backdrop from "../../../components/Backdrop";
import { PopupMessage, usePopupMessage } from "../../../components/PopupMessage";

import { jumpTo } from "../../../helpers";

function SeatsPage({ booking, bookingSummary, getBookingWithSummary, getSeatMap, assignSeat, confirm }) {

  const [seatMaps, setSeatMaps] = useState([]);

  const popup = usePopupMessage();

  useEffect(() => {
    getSeatMap().then(response => {
      setSeatMaps(response);
    });
    getBookingWithSummary();
  }, []);

  const onAssignSeat = (data) => {
    return assignSeat(data)
      .then(() => getBookingWithSummary());
  }

  const onContinue = () => {

    const selection = getSelection(booking);
    if (selection.totalSelected !== selection.totalCount || selection.extraSelected !== selection.extraCount) {
      popup.showMessage('You must select seat for all passengers to continue');
      return;
    }

    getBookingWithSummary().then(({ booking }) => {
      if (!booking.pnr) return;

      if (booking.reservationBalance === 0) {
        // check in
        confirm().then(response => {
          if (response.pnr) jumpTo(getAgentPath() + "/wci/confirmation");
        })
      }
      else {
        jumpTo(getAgentPath() + "/checkout");
      }
    })
  }


  // render()

  const currencyCode = booking.currencyCode;
  const formattedAmount = numberFormatter.decimalSplitter(booking.reservationBalance);

  const inAppBrowser = isInAppBrowser();

  const mobileClass = inAppBrowser ? "in-mobile min-height" : "min-height";

  const rSeatPage = ls.t('SeatPage');
  if (!rSeatPage.Title) return null;
  const rButton = ls.t('Button');
  const rCommon = ls.t('Common');

  return (
    <div className={ls.t("rootClass") + " main-container"} dir={ls.t("direction")}>
      <div className="header-con">
        <Header hasSummary={true} />
      </div>
      <Backdrop />
      {!inAppBrowser && <StatusBar label="seats" onItemClick={item => jumpTo(item)} />}
      <div className="container padt40 padb40 seat-page">
        <div className={mobileClass}>
          <h1>{rSeatPage.Title}</h1>
          <SeatSelection
            key={`${booking.pnr}-${seatMaps.length}`}
            currencyCode={currencyCode}
            segments={booking.segments}
            passengers={booking.passengers}
            seatMaps={seatMaps}
            onAssignSeat={onAssignSeat} />

          <div className="clearfix btnCon">
            {!inAppBrowser && <Button type="submit"
              className="btn btn-primary pull-left flip btn-cancel btn-effect"
              onClick={() => jumpTo(getAgentPath() + "/wci/passenger")}>
              <span><i className="picon picon-btn-arrow-left-w" />{rButton.Back}</span>
            </Button>}
            <Button type="submit"
              className="btn btn-primary pull-right flip btn-submit btn-effect"
              onClick={() => onContinue()}><span>{rButton.Continue}
                <i className="picon picon-btn-arrow-right-w" /></span>
            </Button>
            <div className="bottom-total pull-right">
              <label>{rCommon.BookingTotal}</label>
              <p>
                <span className="currency">
                  {bookingSummary.currencyCode}
                </span>
                {formattedAmount.amount}
                <span className={`${bookingSummary.currencyCode} decimal-point`}>
                  {
                    numberFormatter.oneDecimalPoint(formattedAmount.decimal, bookingSummary.currencyCode)
                  }
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {!inAppBrowser && <Footer />}
      <PopupMessage show={popup.show} title={popup.title} message={popup.message} onHide={popup.hideMessage} hideCancelButton={true} />
    </div>
  );
}

function getSelection(booking) {
  if (!booking) return false;

  const { passengers, segments } = booking;
  if (!Array.isArray(passengers) || !Array.isArray(segments)) return false;

  let extraCount = 0, extraSelected = 0, totalCount = 0, totalSelected = 0;

  segments.forEach(segment => {
    if (!Array.isArray(segment.legs)) return;
    segment.legs.forEach(leg => {
      if (!Array.isArray(leg.customers)) {
        return;
      }

      leg.customers.forEach(customer => {
        if (customer.status !== PassengerStatus.CheckingIn) return;

        const pax = passengers.find(x => x.id === customer.passengerId);
        if (!pax || pax.paxType === PassengerType.Infant) return;

        if (pax.paxType === PassengerType.ExtraSeat)
          extraCount++;
        else
          totalCount++;

        if (customer.seat) {
          if (pax.paxType === PassengerType.ExtraSeat)
            extraSelected++;
          else
            totalSelected++;
        }
      })
    })
  })

  return { extraCount, extraSelected, totalCount, totalSelected };
}

function mapStateToProps(state) {

  return {
    bookingSummary: state.booking.summary,
    booking: state.booking.details,
  };
}

const mapDispatchToProps = {
  ...wciActions,
  getBookingWithSummary: bookingActions.getBookingWithSummary,

};

export default connect(mapStateToProps, mapDispatchToProps)(SeatsPage);

