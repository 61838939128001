import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";

import "./index.scss";

import { jumpTo, buildSearchParams, getAgentPath } from "../../../helpers";
import { sessionActions } from  "../../../state/ducks/session";
import { agentActions } from "../../../state/ducks/agent";
import { mmbActions } from "../../../state/ducks/mmb";

import BasePage from "../../../components/BasePage";
import SideMenu from "../SideMenu";
import FindBooking from "./FindBooking";
import Activity from "./Activities";

import SearchFlight from "../../SearchPage/SearchFlight";
import { ls } from "../../../locale";

function AgentPortal({ getBookingHistory, bookingHistory, lastSearchedBooking, searchBooking, retrieveBooking, mmbRetrieveBooking, setBookingMode, setSearchParams, location }) {

  useEffect(() => {
    getBookingHistory();
  }, []);

  const handleViewBooking = useCallback(booking => {
    if(booking && booking.pnr) {
      retrieveBooking(booking.pnr)
      .then(response => {
        jumpTo("/agent/booking", response);
      })
    }
  }, [retrieveBooking]);

  const handleManageBooking = useCallback((booking) => {
    if(!booking.pnr || !booking.lastName) return; 

    mmbRetrieveBooking(booking.pnr, booking.lastName).then((response) => {
      if (response && response.booking) {
        setBookingMode({ mode: "MMB" });

        // build search request
        const params = buildSearchParams(response.searchRequest);
        if (params) {
          params.lng = ls.lang;
          setSearchParams(params);
        }
        jumpTo(getAgentPath() + '/mmb/summary');
      }
    });
  }, [mmbRetrieveBooking, setBookingMode, setSearchParams]);

  const handleSearchBooking = useCallback(pnr => {
    if(pnr && pnr.length === 6) {
      searchBooking(pnr);
    }
  }, [searchBooking]);

  // render()
    return (
      <BasePage>
        <Row className="min-height padt40">
          <Col md={9} xs={12}>
          <div>
            <h1>Agent Portal </h1>
            <hr/>

          </div>

          <FindBooking 
            lastSearchedBooking={lastSearchedBooking} 
            onSearchBooking={handleSearchBooking} 
            onViewBooking={handleViewBooking} 
            onManageBooking={handleManageBooking} />
          <SearchFlight />
          <Activity 
            bookings={bookingHistory} 
            onViewBooking={handleViewBooking} 
            onManageBooking={handleManageBooking} />
          </Col>
          <Col md={3} xs={12}>
            <SideMenu activeItem="MyBooking"  path={location.pathname} />
          </Col>
        </Row>
      </BasePage>
    )
}

function mapStateToProps(state) {
  return {
    ...state.agent,
  };
}

const mapDispatchToProps = {
  ...sessionActions,
  ...agentActions,
  mmbRetrieveBooking: mmbActions.retrieveBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentPortal);
