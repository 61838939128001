import React, { useCallback, useRef, useState, useEffect } from 'react'
import Icon from "react-icons-kit/Icon";
import {ic_arrow_forward as arrow_right} from "react-icons-kit/md/ic_arrow_forward";
import {ic_arrow_back as arrow_left} from "react-icons-kit/md/ic_arrow_back";

import { ls } from "../../../locale";
import { FareItem } from './FareItem'

export function FareList({flight, selectedFlight, isShowExtra, currencyCode, bookIndex, selectFlight}) {

  const targetRef = useRef()
  const lang = ls.t('rootClass')

  const [scrollState, setScrollState] = useState({left: false, right: false})
  const [hasLounge, setHasLounge] = useState(false);

  const adjustFareList = useCallback(() => {
    const el = targetRef.current
    if(!el) return;

    //  console.log(`offsetLeft: ${el.offsetLeft}, scrollLeft: ${el.scrollLeft}`)
    //  console.log(`offsetwidth: ${el.offsetWidth}, scrollWidth: ${el.scrollWidth}`)

    if(el.scrollWidth < 768) {
      setScrollState(prevState => {
        if(prevState.left || prevState.right)
          return {left: false, right: false}
        else
          return prevState
      })
    }

    // adjust item height
    matchItemHeight();

    if(el.scrollWidth <= el.offsetWidth) {
      setScrollState(prevState => {
        if(prevState.left || prevState.right)
          return {left: false, right: false}
        else
          return prevState
      })
      return
    }
    
    if(el.scrollLeft === 0) {
      setScrollState(prevState => {
        if(prevState.left || !prevState.right) 
          return {left: false, right: true}      
        else
          return prevState
      })
    }
    else {
      scrollRight(el, lang)
      setScrollState(prevState => {
        if(!prevState.left || prevState.right) 
          return {left: true, right: false}      
        else
          return prevState
      })
    }

  }, [targetRef, lang])


  const toggleScroll = useCallback(() => {
    const el = targetRef.current

    if(scrollState.left) {
      scrollLeft(el)
      setScrollState(prevState => {
        if(prevState.left || !prevState.right)
          return {left: false, right: true}
        else
          return prevState
      })
    }
    else if(scrollState.right) {
      scrollRight(el, lang)      
      setScrollState(prevState => {
        if(!prevState.left || prevState.right)
          return {left: true, right: false}
        else
          return prevState
      })
    }
  }, [targetRef, scrollState, lang])


  useEffect(() => {
    const lounge = Array.isArray(flight.segments) && flight.segments.some(s => s.hasLounge) ? true : false;
    setHasLounge(lounge)

    // align height
    setTimeout(() => adjustFareList(), 800)
  }, [flight, adjustFareList])

  return (
    <>
      <ScrollBar position="left" show={scrollState.left} onClick={toggleScroll}>
        <Icon icon={lang === 'ar' ? arrow_right : arrow_left} className="drop-icon"/>
      </ScrollBar>
      <div className="faresList" ref={targetRef}>

        { // fares list
          flight.fares.map(fare => <FareItem key={fare.fareTypeName} fare={fare} 
            logicalFlightId={flight.logicalFlightId} 
            departureDate={flight.departureDate}
            selectedFlight={selectedFlight}
            isShowExtra={isShowExtra}
            hasLounge={hasLounge}
            currencyCode={currencyCode}
            bookIndex={bookIndex}
            selectFlight={selectFlight}
          />)
        }
      </div>
      <ScrollBar position="right" show={scrollState.right} onClick={toggleScroll}>
        <Icon icon={lang === 'ar' ? arrow_left : arrow_right} className="drop-icon"/>
      </ScrollBar>
    </>
  )
}


function ScrollBar({position, show, onClick, children}) {

  if(!show) return null;

  const className = `scroll-bar ${position}`

  return (
    <div className={className}>
      <div className="arrow" onClick={onClick}>{children}</div>
    </div>
  )
}

// adjust the items on each row has the same height
function matchItemHeight() {

  for(let i=1; i<=5; i++) {
    let nodes = document.querySelectorAll(`.extraCon.extra-${i}`)
    if(nodes.length === 0) continue;

    nodes = Array.from(nodes)
    const heights = nodes.map(x => x.offsetHeight)
    const maxHeight = Math.max(...heights)

    nodes.forEach(node => {
      node.style.height = `${maxHeight}px`
    })
  }
}

function scrollLeft(el) {
  el.scrollLeft = 0
}

function scrollRight(el, lang) {
  // when the language is ar, the scrollLeft needs to be negative
  const flag = lang === 'ar' ? -1 : 1
  el.scrollLeft = el.offsetWidth * flag;
}