import React from 'react';
import { Dialog, DialogPanel, DialogTitle, DialogBackdrop, Button } from '@headlessui/react';
import { ls } from '../../locale';

import btnStyles from "../../styles/btn.module.scss"

export function DialogBox({open, title, notes, closeButtonText, onClose, hideCancelButton = false, children}
  : {open: boolean, title?: string, notes?: string, closeButtonText?: string, onClose: (confirmed: boolean) => void, hideCancelButton?: boolean, children?: React.ReactNode}
) {

  return (
    <Dialog open={open} onClose={() => {}} transition className="tw-relative tw-z-50 tw-opacity-100 tw-transition-opacity tw-duration-300 tw-ease-in-out data-[closed]:tw-opacity-0">
      <DialogBackdrop className="tw-fixed tw-inset-0 tw-bg-black/30" />

      <div className="tw-fixed tw-inset-0 tw-w-screen tw-flex tw-items-start tw-justify-center">
        <DialogPanel transition className="tw-absolute tw-top-5 tw-w-11/12 tw-max-w-screen-sm tw-bg-white tw-border tw-border-solid tw-border-gray-500 tw-rounded-b tw-shadow-md tw-shadow-gray-500 tw-transition tw-duration-300 data-[closed]:-tw-translate-y-5">
          <DialogTitle as="div" className="tw-flex tw-justify-between tw-items-center tw-px-2 tw-py-1 sa-bg-lightblue">
            <h4 className="tw-px-2 tw-text-xl tw-text-white">{title || ls.t('MessageBox.Title.Notification')}</h4>
            <Button className="tw-border-none tw-text-white tw-text-xl tw-font-semibold sa-bg-lightblue" onClick={() => onClose(false)}>×</Button>
          </DialogTitle>
          <div className="tw-p-4 tw-border-solid tw-border-0 tw-border-b tw-border-gray-200">
            { children}
          </div>
          <div className="tw-flex tw-justify-between tw-p-4">
            <div className="tw-text-xs">{notes}</div>
            <div className="tw-flex tw-justify-end tw-gap-4">
              {!hideCancelButton && <Button className={btnStyles.default} onClick={() => onClose(false)}>{ls.t('Button.Cancel')}</Button>}
              <Button className={btnStyles.secondary} onClick={() => onClose(true)}>{closeButtonText || ls.t('Button.Close')}</Button>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  )
}
