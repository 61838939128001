import React from 'react'
import Dropdown from "react-dropdown";

export default function TitleSelect({options, value, rTitle, onChange, disabled}) {

  const change = selected => {
    if( typeof(onChange) === 'function') {
      onChange(selected.value);
    }
  }

  if(typeof(value) === 'string') {
    if(!rTitle || !rTitle[value]) {
      value = { label: value, value: value }
    }
    else {
      value = { label: rTitle[value], value: value }
    }
  }

    if(!Array.isArray(options)) options = [];
    options = options.map(option => {
      if(typeof(option) === 'string') {
        if(!rTitle || !rTitle[option])
          return { label: option, value: option };
        else 
          return { label: rTitle[option], value: option }
      } 
      else {
        return option;
      }
    });

    return (
      <Dropdown options={options} placeholder={value.label} value={value} disabled={disabled}
        onChange={change} />
    )
  
}

