import { loadingConstants } from "../constants";

export const loadingActions = {
  startloading,
  completeLoading,
} 

function startloading(message) {
  return { type: loadingConstants.LOADING_STARTED, message };
}

function completeLoading() {
  return { type: loadingConstants.LOADING_COMPLETED };
}