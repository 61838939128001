import React from 'react'
import { ls } from "../../../locale";

export function FareLabel({isShowExtra}) {

  const rFareRule = ls.t('FareRule')

  return (
    <div className="faresLabel">
      <div className="labelCon">
        <div className="tagsCon" />
        <div className="extraCon extra-1">
          <i className="picon picon-handluggage">
          </i> {rFareRule.HandLuggage.Label}
        </div>
        <div className="extraCon extra-2">
          <i className="picon picon-luggage">
          </i> {rFareRule.CheckInLuggage.Label}
        </div>
        {isShowExtra && <div className="extraCon extra-3">
          <i className="picon picon-seat">
          </i> {rFareRule.Extra.Label}
        </div>}
        <div className="extraCon extra-4">
          <i className="picon picon-change">
          </i> {rFareRule.ChangeFlight.Label}
        </div>
        <div className="extraCon extra-5">
          <i className="picon picon-cancellation">
          </i> {rFareRule.CancelFlight.Label}
        </div>
      </div>
    </div>
  )
}