import React from 'react'
import { Row, Col, FormControl, FormGroup } from 'react-bootstrap'
import { ls } from '../../../locale'

import FormErrorBlock from '../../../components/FormErrorBlock'
import DialingCodeSelect from "../../../components/Common/DialingCodeSelect";
import { getDialingOptionByDialingCode } from '../../../helpers'


export function Contact({values, errors, setInputState, countries}) {

  const rInput = ls.t('Input');
  const rPax = ls.t('Pax')

  const mobileCountry = getDialingOptionByDialingCode(values.mobileCountry, countries) || ' ';

  return (
    <div className="padding-top-0">
      <div className="single-passenger">
        <Row>
          <Col sm={4} xs={12}>
            <FormGroup>
              <FormControl type="email" name="email" 
                value={values.email || ''} 
                onChange={e => {setInputState('email', e.target.value);setInputState('confirmEmail', '') }} 
                placeholder={rPax.Email + ' *'} 
              />
              <FormErrorBlock error={errors.email} />
            </FormGroup>
          </Col>
          <Col sm={4} xs={12}>
            <FormGroup>
              <FormControl type="email" name="confirmEmail"
                value={values.confirmEmail || ''} 
                onChange={e => setInputState('confirmEmail', e.target.value)} 
                placeholder={rPax.ConfirmEmail + ' *'} 
                onPaste={event => {
                  event.preventDefault();
                }}
              />
              <FormErrorBlock error={errors.confirmEmail} />
            </FormGroup>
          </Col>
          <Col sm={4} xs={12}>
            <Row className="phone-dropdown">
              <Col md={4} xs={4}>
                <DialingCodeSelect
                    className="dialingcode-select"
                    selected={mobileCountry}
                    options={countries}
                    onSelected={selected => setInputState("mobileCountry", selected.label)} />
              </Col>
              <Col md={8} xs={8}>
                <FormGroup>
                  <FormControl
                    type="telephone"
                    value={values.mobile || ''}
                    onChange={event => setInputState('mobile', event.target.value)}
                    placeholder={rInput.MobileNumber}
                  />
                  <FormErrorBlock error={errors.mobile}/>
                </FormGroup>
              </Col>
            </Row>    
          </Col>
        </Row>
      </div>
    </div>
  )
}