import React from 'react'
import { Row, Col } from "react-bootstrap";
import { ls } from "../../locale";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import StatusBar from "../../components/StatusBar";
import SidePanel from "../../components/SidePanel";

import SearchFlight from "./SearchFlight";

export default function SearchPageLayout({ tripType = 'round' }) {
  return (
    <div className={ls.t("rootClass") + " main-container search-page"} dir={ls.t("direction")}>
      <div className="header-con">
        <Header />
      </div>
      <StatusBar label="flights" />
      <div className="container padt40 padb20">
        <Row className="min-height">
          <Col md={9} xs={12}>
            <SearchFlight tripType={tripType} />
          </Col>
          <Col md={3} xs={12}>
            <SidePanel />
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  )
}