import React from 'react'
import dayjs from 'dayjs';
import AlertBox from "../../components/Common/AlertBox";

export function TransactionDetails({booking}) {
  if ( !booking || booking.bookingStatus !== 'Confirmed' 
      || !booking.paymentState 
      || booking.paymentState.paymentMethod !== 'KNET' 
      || !booking.paymentState.referenceNumber
  ) return null


  const state = booking.paymentState

  return (
    <AlertBox show={true} closeButton>
      <table cellspacing="0" cellpadding="0" className='transaction-details'>
        <tr>
          <td>Reference Number</td>
          <td className='value'>{state.referenceNumber}</td>
        </tr>
        <tr>
          <td>Third Party Transaction Number</td>
          <td className='value'>{state.thirdPartyTransactionNumber}</td>
        </tr>
        <tr>
          <td>Payment Amount</td>
          <td className='value'>{state.currencyCode} {state.paymentAmount}</td>
        </tr>
        <tr>
          <td>Transaction Date</td>
          <td className='value'>{dayjs(state.transactionDate).format('D MMM YYYY')}</td>
        </tr>
      </table>
    </AlertBox>
  )
}