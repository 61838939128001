import React from 'react'
import { ls } from "../../../locale";

import Icon from "react-icons-kit/Icon";
import {userCircleO} from "react-icons-kit/fa/userCircleO";
import {angleRight} from "react-icons-kit/fa/angleRight";


export function Passenger({flight, leg, passenger, currencyCode, hasFreeMeal, onClick}) {

  const rExtraPage = ls.t('ExtraPage')
  const rPaxType = ls.t('PaxType')

  if(!Array.isArray(passenger.meals)) return null;

  let mealData, mealBoxes = null;

  // const selectedMeal = passenger.selectedMeals || [];

  const selectedMeal = passenger.meals.find(x => x.selected && x.physicalFlightId === leg.physicalFlightId);
  const legMeal = passenger.meals.filter(x => x.physicalFlightId === leg.physicalFlightId)

  if (selectedMeal) {
    const {label, amount, ssrCode} = selectedMeal;
    if(ssrCode === 'MLIN' || ssrCode === 'HNML') {
      mealData = <p>
        <span>{label}</span>
        <span className="included">Included</span>
      </p>;
    }
    else {
      mealData = <p>
        <span>{label}</span>
        <span className="price">
          {currencyCode}
          <span>{amount}</span>
        </span>
      </p>;

      if(legMeal.length)
        mealBoxes = <div className="meal-action" onClick={() => onClick(flight.logicalFlightId, leg.physicalFlightId, passenger.id)}>
                        {rExtraPage.Meal.EditMeal}<Icon icon={angleRight}/>
                    </div>;
    }

  } 
  else {
    if(legMeal.length) {
      mealData = hasFreeMeal 
        ? <p><span className="free-meal-selection">{rExtraPage.Meal.FreeMeal}</span></p> 
        : <p>{rExtraPage.Meal.NoSelected}</p>
       
      mealBoxes = <div className="meal-action" onClick={() => onClick(flight.logicalFlightId, leg.physicalFlightId, passenger.id)}>
                    {rExtraPage.Meal.ChooseMeal}<Icon icon={angleRight}/>
                  </div>;
    }
    else {
      mealData = <p>{rExtraPage.Meal.NoMeal}</p>
    }
  }

  const passengerName = passenger.lastName 
    ? `${passenger.firstName} ${passenger.lastName}` 
    : `${rPaxType[passenger.paxTypeName].Passenger} ${passenger.paxIndex}`;

  return (
    <div className="passenger-box">
      <Icon icon={userCircleO} className="passenger-icon"/>
      <p className="passage-data">{passengerName}</p>
      {mealData}
      {mealBoxes}
    </div>
  );
}