import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { createLogger } from "redux-logger";
import reduxReducer from "../reducers";
//import locale from "../locale";
import * as reducers from "../state/ducks"


let middleware = [thunk];
if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_DISABLE_REDUX_LOG !== 'true') {
  const loggerMiddleware = createLogger();
  middleware = [...middleware, loggerMiddleware]
}

export const store = createStore(
  combineReducers({
    redux: reduxReducer,
    ...reducers
  }),
  applyMiddleware(...middleware)
);



