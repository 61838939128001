import React from "react";
import queryString from "query-string";

export default function PaymentPage(props) {

  if(window.parent) {
    const params = queryString.parse(props.location.search);

    const event = new CustomEvent('TDS_PAYMENT_RESULT', { detail: {
      code: params.code,
      reference: params.reference,
      paytype: params.paytype
    }})

    window.parent.document.dispatchEvent(event)
  }

  return <div>Process Your Payment...</div>
}
