export class DateFormatter {

  private date: Date

  private monthNames = ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  private weekDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednsday', 'Thursday', 'Friday', 'Saturday']

  constructor(dateValue: string | Date ) {
    if(dateValue instanceof Date) this.date = dateValue;
    else this.date = new Date(dateValue);
  }

  // YYYY-MM-DD
  toISODateString(): string {

    if(!this.isValid()) return '';
    
    const date = this.date;
    const d = date.getDate()
    const m = date.getMonth() + 1

    const year = date.getFullYear();
    const month = (m < 10 ? '0' : '') + m
    const day = (d < 10 ? '0' : '')  + d

    return `${year}-${month}-${day}`
  }

  // YYYYMMDD
  toEightDigits(): string {
    if(!this.isValid()) return '';

    return this.toISODateString().replaceAll('-', '')
  }

  // MMMM D eg January 2
  toMonthDate(): string {
    if(!this.isValid()) return '';

    const d = this.date;
    const month = this.monthNames[d.getMonth()];

    return `${month} ${d.getDate()}`
  }
  // MMM D eg Jan 2
  toMonthDateShort(): string {
    if(!this.isValid()) return '';

    const d = this.date;
    const month = this.monthNames[d.getMonth()].substr(0, 3);

    return `${month} ${d.getDate()}`
  }

  // MMM D, YYYY
  toMonthDateYearShort(): string {
    if(!this.isValid()) return '';

    const d = this.date;
    return `${this.toMonthDateShort()}, ${d.getFullYear()}`
  }

  // D MMM
  toDateMonthShort(): string {
    if(!this.isValid()) return '';

    const d = this.date;
    const month = this.monthNames[d.getMonth()].substr(0, 3);

    return `${d.getDate()} ${month}`
  }

  // DDD D MMM
  toWeekDayMonthShort(): string {
    if(!this.isValid()) return '';
    return `${this.getWeekDayNameShort()} ${this.toDateMonthShort()}`
  }

  toDateMonthYearShort(): string {
    if(!this.isValid()) return '';

    const d = this.date;
    return `${this.toDateMonthShort()}, ${d.getFullYear()}`
  }

  toLocaleDate(): string {
    if(!this.isValid()) return '';

    const d = this.date
    const year = d.getFullYear().toString().substr(-2)
    const month = d.getMonth() + 1
    const date = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()

    return `${month}.${date}.${year}`
  }


  // hh:mm
  toTime24(): string {
    if(!this.isValid()) return '';

    const d = this.date;
    const timeString = d.toTimeString();
    return timeString.substr(0, 5)
  }

  // full name for week day
  getWeekDayName(): string {
    if(!this.isValid()) return '';

    return this.weekDayNames[this.date.getDay()]
  }

  // short name for week day
  getWeekDayNameShort(): string {
    if(!this.isValid()) return '';
    
    const name = this.getWeekDayName();
    return name.substr(0, 3)
  }

  isValid(): boolean {
    return !isNaN(this.date.getTime())
  }
}