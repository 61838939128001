import React from "react";
import dayjs from "dayjs";

import { numberFormatter, stringFormatter } from "../../helpers";
import { ls } from "../../locale";

export function FlightDetails({ flight }) {
  return (
    <div className="grid-container">
      {flight.legs.map((leg, index) => <FlightLeg leg={leg} index={index} total={flight.legs.length} key={index} />)}
    </div>
  )
}

function FlightLeg({ leg, index, total }) {

  const rFlight = ls.t('Flight')
  const rFlightDetails = ls.t('FlightDetails')

  const flightTime = numberFormatter.formatFlightTime(leg.flightTime, rFlight.DurationShort)
  const transitionTime = numberFormatter.formatFlightTime(leg.transitionTime, rFlight.DurationShort)

  const description = stringFormatter.formatString(rFlightDetails.Transit, leg.originCity, transitionTime)

  return (
    <>
      { index > 0 && <>
        <Separator />
        <TransitionTime transitionTime={transitionTime} description={description} />
        <Separator />
      </>}
      {/* leg.originCity */}
      <div className="grid-item time">{dayjs(leg.departureDate).format("HH:mm")}</div>
      <div className="grid-item"><i className="picon16 picon-plane" /></div>
      <div className="grid-item city">{leg.originCity}</div>
      {/* origin airport */}
      <div className="grid-item date">{dayjs(leg.departureDate).format("DD MMM")}</div>
      <div className="grid-item"><span className="dotted-line-vertical"/></div>
      <div className="grid-item airport">
        {leg.originName} &nbsp;({leg.origin})
        {leg.fromTerminal && <span className="terminal">, {leg.fromTerminal}</span>}
      </div>
      {/* separator */}
      <Separator />
      
      {/* leg.destinationCity */}
      <div className="grid-item flight-time">{flightTime}</div>
      <div className="grid-item"><span className="dotted-line-vertical"/></div>
      <div className="grid-item aircraft">
        {leg.carrierCode}{leg.flightNumber}, {leg.aircraftDescription}, Operated by {rFlight.Carrier[leg.operatingCarrierCode]}
      </div>

      <Separator />
      {/* leg.destinationCity */}
      <div className="grid-item time">{dayjs(leg.arrivalDate).format("HH:mm")}</div>
      <div className="grid-item"><span className="circle-filled"/></div>
      <div className="grid-item city">{leg.destinationCity}</div>
      {/* origin airport */}
      <div className="grid-item date">{dayjs(leg.arrivalDate).format("DD MMM")}</div>
      <div className="grid-item">{ (index + 1 < total) && <span className="dotted-line-vertical"/>} </div>
      <div className="grid-item airport">
        {leg.destinationName} &nbsp;({leg.destination})
        {leg.toTerminal && <span className="terminal">, {leg.toTerminal}</span>}
      </div>
    </>
  )
}

function TransitionTime({ transitionTime, description }) {
  return (
    <>
      <div className="grid-item flight-time">{transitionTime}</div>
      <div className="grid-item"><span className="dotted-line-vertical"/></div>
      <div className="grid-item airport">{description}</div>
    </>
  )
}

function Separator() {
  return (
    <>
      <div className="grid-item separator"></div>
      <div className="grid-item separator"><span className="dotted-line-vertical"/></div>
      <div className="grid-item separator"></div>
    </>
  )
}