import React from "react";
import { connect } from "react-redux";
import { backdropActions } from "../../state/ducks/backdrop";

function Backdrop({show, showBackdrop}) {
  return show ? <div className="backdrop" onClick={() => showBackdrop(false)} /> : null;
}

const mapStateToProps = state => ({
  show: state.backdrop.show
});

const mapDispatchToProps = {
  showBackdrop: backdropActions.showBackdrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(Backdrop);

