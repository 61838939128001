import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ls } from '../../../locale'

import './index.scss'

// shared components
import { withControlledComponent, history, commonHelpers } from '../../../helpers'
import { createTdsHandler } from '../../../helpers/tdsHelper';

import BasePage from "../../../components/BasePage"
import { PopupMessage } from "../../../components/PopupMessage"
import TermsCheckbox from "../../../components/Common/TermsCheckbox"
import PaymentOptions from "../../CheckoutPage/PaymentOptions"
import { PaymentDeniedMessage } from './PaymentDeniedMessage'

// local components
import { SectionHeader } from './SectionHeader'
import { Passenger } from './Passenger'
import { Contact } from './Contact'
import { Terms } from './Terms'
import { Actions } from './Actions'
import { PaymentTotal } from './PaymentTotal'
import { VoucherBanner } from './VoucherBanner'

import { validateContact, validatePassenger } from './validators'

// actions
import { resourceActions } from "../../../state/ducks/resources";
import { sessionActions } from "../../../state/ducks/session";
import { voucherActions } from "../../../state/ducks/voucher"
import scrollToElement from 'scroll-to-element'

const PassengerForm = withControlledComponent(Passenger)
const ContactForm = withControlledComponent(Contact)

class PurchasePage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      passenger: {
        title: 'MR',
        firstName: '',
        lastName: '',
        dateOfBirth: ''
      },
      contact: {
        email: '',
        confirmEmail: '',
        mobile: '',
        mobileCountry: '976',
      },
      termsAgreed: false,
      payment: {
        paymentMethods: ['CS'],
        paymentMethodCode: 'CS',
        total: null
      },
      message: {
        show: false,
        content: ''
      },
      paymentState: props.location.state && props.location.state.payment
    }
  }



  componentDidMount() {
    this.tdsHandler = createTdsHandler(this.processedCallback, this.validatedCallback);
    this.props.getCountries();
    this.props.getVoucher()
    .then(({voucher}) => {

      // update the state
      if(voucher && voucher.passenger && voucher.contact) {
        voucher.contact.confirmEmail = voucher.contact.email

        this.setState({
          passenger: {...this.state.passenger, ...voucher.passenger },
          contact: { ...this.state.contact, ...voucher.contact }
        })
        this.passengerForm.reset()
        this.contactForm.reset()
      }

      this.props.getPaymentMethods()
      .then(response => {      
        this.setState({
          payment: {
            ...this.state.payment,
            paymentMethods: response.paymentMethods
          }
        })
        
        const method = response.paymentMethods[0]     
        this.setPaymentMethodState(method)
      });
    })
    
  }


  processedCallback = results => {
    this.props.hideLoading();

    if (!results.Status) {
      this.showMessage('unable to validate the card number.');
      return;
    }

    const paymentDetails = this.paymentDetails;
    paymentDetails.TDSReferenceId = this.paymentReference;

    return this.props.tdsEnrol(paymentDetails, results)
      .then(tdsResponse => {
        if (!tdsResponse.success) {
          // show error
          this.showMessage('TDS failed.');
        }
        else if (tdsResponse.paymentComplete) {
          // show success
          //console.log('payment is processed successfully.');
          this.goNext();
        }
        else if (tdsResponse.require3DS) {
          this.tdsHandler.require3DS(tdsResponse);
        }
        else {
          // console.log('payment failed: ', tdsResponse);
          // this.showMessage('Failed to process the payment. Please try again.');
          this.setState({
            paymentState: {
              code: "1000",
              reference: ''
            }
          })
          scrollToElement('#voucher-banner')
        }
      })
      .catch(errors => {
        this.handlePaymentError(errors);
      })

  }

  validatedCallback = jwt => {
    if(!jwt) return

    const paymentDetails = this.paymentDetails;
    paymentDetails.TDSReferenceId = this.paymentReference;
    paymentDetails.tDSToken = jwt;

    this.props.tdsValidate(paymentDetails)
      .then(response => {
        if (response.success && response.paymentComplete) {
          // show success
          this.goNext()
        }
        else {
          // show error
          //console.log('payment failed. ', response);
          this.showMessageBox('Failed to process the payment. Please try again.');
        }
      })
      .catch(errors => {
        this.handlePaymentError(errors);
      })
  }

  toggleTermsAgreed = () => {
    this.setState({
      termsAgreed: !this.state.termsAgreed
    })
  }


  validateForm = () => {
    const contactValid = this.contactForm.validateForm()
    const passengerValid = this.passengerForm.validateForm()
    const rError = ls.t('Error')

    if(!contactValid || !passengerValid) {
      this.showMessage(rError.InputError.Desc)
      return false
    }

    if(!this.state.termsAgreed) {
      this.showMessage(rError.CheckoutTerms)
      return false
    }

    const paymentValid = this.paymentOptionsComponent.validateForm();
    if(!paymentValid) {
      this.showMessage(rError.InputError.Desc)
      return false
    }

    return true
  }


  buy = () => {

    if(!this.validateForm()) return;

    const contact = this.contactForm.getValues()
    const passenger = this.passengerForm.getValues()

    const request = {
      passenger,
      contact,
    }
    
    this.props.saveVoucher(request)
    .then(() => {
      // console.log('voucher created: ', response)
      
      const paymentOption = this.paymentOptionsComponent.getFormValues();
      this.makePayment(paymentOption)  
    })
  }

  makePayment = paymentOption => {
    if (paymentOption.paymentMethodCode === "CS") {
      const expiryFields = paymentOption.expiration.split("/");
      const details = {
        methodCode: "CS",
        cardNumber: paymentOption.cardNumber,
        nameOnCard: paymentOption.nameOnCard,
        expiryYear: expiryFields[1],
        expiryMonth: expiryFields[0],
        cvNumber: paymentOption.cvc
      };
      this.start3DS(details);
      return;
    }
    else if (paymentOption.paymentMethodCode === "ON") {
      this.props.makeOmanDebitPayment()
      .then(response => {
        // we show the loading mask to prevent the 'Pay Now' button is hit again
        this.props.showLoading(); 
        window.location.href = response.redirectUrl;
      })
    }
    else if(paymentOption.paymentMethodCode === 'INVC') {
      this.props.makeInvoicePay()
      .then(() => {
        this.goNext();
      })
    }
  }

  start3DS = paymentDetails => {
    this.paymentDetails = paymentDetails;

    this.props.authorizeCS(paymentDetails)
      .then(tokenResponse => {
        const fingerprintDetail = tokenResponse.fingerprintDetail;
        this.paymentReference = fingerprintDetail.reference;

        this.props.showLoading();

        const tag = commonHelpers.createFinderprintScriptTag(fingerprintDetail.fingerprintUrl);
        tag.onload = () => this.setupTDS(paymentDetails.cardNumber, fingerprintDetail.token) 
      })
      .catch(errors => {
        this.handlePaymentError(errors);
      })
  }

  setupTDS = (cardNumber, tdsToken) => {
    this.tdsHandler.setup(cardNumber).then(() => {
      this.tdsHandler.init3DS(tdsToken);
    })
  }

  handlePaymentError = errors => {
    // console.log('payment errors: ', errors);
    const rejected = errors.find(x => x.errorCode === 700);
    if(rejected) {
      this.setState({
        paymentStatus: {
          code: "1000",
          reference: ''
        }
      })
      scrollToElement('#voucher-banner');
      return;
    }
  }

  setPaymentMethodState = (code) => {
    this.setState({
      payment: {
        ...this.state.payment,
        paymentMethodCode: code
      }
    });
    // get total
    this.props.getPaymentTotal(code)
    .then(response => {
      // update state
      this.setState({
        payment: { 
          ...this.state.payment, 
          total: response
        }
      })
    })
  }

  goNext = () => {
    const nextPage = '/voucher/confirmation'
    const url = "/" + ls.lang + nextPage;

    history.push(url);
  }

  goBack = () => {
    history.go(-1)
  }

  showMessage = (message) => {
    this.setState({
      message: {
        show: true,
        content: message
      }
    })
  }

  hideMessage = () => {
    this.setState({
      message: {
        show: false,
        content: ''
      }
    })
  }
 
  render() {

    const userData = this.props.getUserData()

    const rTravelVoucher = ls.t('TravelVoucher')
    const rError = ls.t('Error')
    const rContact = ls.t('CheckoutPage.BookingContact')
    const rTermsAndConditions = ls.t('ExtraPage.Insurance.Terms')

    const isShowPaymentDenied = this.state.paymentState && (this.state.paymentState.code === "1000" || this.state.paymentState.code === "1001")

    return (
      <BasePage mainContainerClass="checkout-page">
        <div className="min-height purchase-con passenger padding-top-0">
          <VoucherBanner />

          {isShowPaymentDenied && <PaymentDeniedMessage message={rError.PaymentDeclined} />}
          
          <SectionHeader title={rTravelVoucher.IssueTo} />
          <PassengerForm ref={el => this.passengerForm = el}
            initValues={this.state.passenger}
            onValidate={validatePassenger}
          />

          <SectionHeader title={rContact} />
          <ContactForm ref={el => this.contactForm = el} 
            initValues={this.state.contact} 
            onValidate={validateContact}
            countries={this.props.countries} 
          />

          <SectionHeader title={rTermsAndConditions} />
          <Terms />
          
          <TermsCheckbox terms={rTravelVoucher.Statement} 
            checked={this.state.termsAgreed} 
            onClick={this.toggleTermsAgreed}  
          />

          <PaymentOptions
            ref={el => this.paymentOptionsComponent = el}
            paymentMethods={this.state.payment.paymentMethods}
            paymentMethodCode={this.state.payment.paymentMethodCode}
            onPaymentMethodChanged={method => this.setPaymentMethodState(method)}
            userData={userData}
          />
          <PaymentTotal total={this.state.payment.total} />

          <Actions onPayNow={() => this.buy()} onBack={() => this.goBack()} />

          <PopupMessage show={this.state.message.show} onHide={this.hideMessage} message={this.state.message.content} hideCancelButton={true} />

        </div>
      </BasePage>
    )
  }
}

const mapStateToProps = (state) => ({
  countries: state.resources.countries
})

const mapDispatchToProps = {
  ...resourceActions,
  ...voucherActions,
  getUserData: sessionActions.getUserData,
  showLoading: sessionActions.showLoading,
  hideLoading: sessionActions.hideLoading,
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasePage)