import React from 'react'
import { useEffect } from 'react'
import { ls } from "../../locale";

import './PaymentStepupWindow.scss'

export function PaymentStepupWindow({jwt, md, stepUpUrl, windowHeight, windowWidth, onSubmit, onError}) {

  useEffect(() => {
    // invoke the frame
    const stepUpForm = document.querySelector('#step-up-form');
    // Step-Up form exists
    if(stepUpForm) {
      // log event
      if(typeof onSubmit === 'function') onSubmit({jwt, md, stepUpUrl})
      // submit form
      stepUpForm.submit();
    }
    else {
      if(typeof onError === 'function') onError("couldn't find step-up-form", {jwt, md, stepUpUrl})
    }
  }, [jwt, md, onError, onSubmit, stepUpUrl])

  if(!windowWidth) windowWidth = 400
  if(!windowHeight) windowHeight = 400

  const contWidth = (windowWidth + 16) + "px";
  const contHeight = (windowHeight + 16) + "px";

  return (
    <div className={ls.t("rootClass") + " tds-popup"} style={{width: contWidth, height: contHeight}}>
      <iframe title="stepupIframe" name="step-up-iframe" height={windowHeight} width={windowWidth}></iframe>
      <form id="step-up-form" target="step-up-iframe" method="post" action={stepUpUrl}> 
        <input type="hidden" name="JWT" value={jwt} />
        <input type="hidden" name="MD" value={md} /> 
      </form>

    </div>

  )
}
