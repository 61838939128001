import { Promise } from "core-js";
import types from "./types";
import { bookingService } from "./bookingService";

export default {
  getBooking,
  getBookingSummary,
  getBookingWithSummary,
  getBookingWithAllSegments,
  getAncillaries,
  getSeatMap,
  addBaggage,
  removeBaggage,
  addInsurance,
  removeInsurance,
  addLounge,
  removeLounge,
  addPriorityCheckin,
  removePriorityCheckin,
  changeMeals,
  assignSeat,
  clearSeat,
  clearAllSeat,
  updatePassengers,
  populateBooking,
  resetSegment,
  summaryPnr,
  printItinerary,
  printItineraryWithoutPrice,
  getPKPassFile,
  feedback,
}

export const actions = {
  bookingResponse,
  summaryResponse,
  ancillaryResponse,
}

function getBooking() {
  return (dispatch) => {
    dispatch(bookingRequest());
    return bookingService.getBooking().then(response => {
      dispatch(bookingResponse(response));
      return response;
    })
  }
}

function getBookingWithSummary() {
  return (dispatch) => {
    dispatch(bookingRequest());
    return bookingService.getBookingWithSummary().then(response => {
      dispatch(bookingResponse(response.booking));
      dispatch(summaryResponse(response.summary));
      return response;
    })
  }
}

function getBookingWithAllSegments(fromCache) {
  return (dispatch, getState) => {

    if(fromCache) {
      const state = getState();
      if(state.booking.details && state.booking.details.currencyCode && state.booking.details.isShowAll) {
        return Promise.resolve(state.booking.details);
      }
    }

    dispatch(bookingRequest());
    return bookingService.getBooking(true).then(response => {
      dispatch(bookingWithAllSegmentsResponse(response));
      return response;
    })
  }
}


function getBookingSummary() {
  return (dispatch) => {
    dispatch(summaryRequest());

    return bookingService.getBookingSummary().then( response => {
      dispatch( summaryResponse( response ));
      return response;
    })
  }
}

function getAncillaries(request) {
  return (dispatch) => {

    dispatch(ancillaryRequest());
    return bookingService.getAncillaries(request).then( response => {
      dispatch( ancillaryResponse(response));
      return response;
    })
  }
}

function getSeatMap() {
  return () => {
    return bookingService.getSeatMap().then( response => {
      return response;
    });
  }
}

function addBaggage(flightId, passengerId, ssrCode, prevSsrCode) {
  return dispatch => {
    return bookingService.addBaggage(flightId, passengerId, ssrCode, prevSsrCode)
    .then(response => {
      dispatch( ancillaryResponse(response));
      return response;
     });
  }
}

function removeBaggage(flightId, passengerId, ssrCode) {
  return dispatch => {
    return bookingService.removeBaggage(flightId, passengerId, ssrCode)
    .then(response => {
      dispatch( ancillaryResponse(response));
      return response;
     });
  }
}

function addInsurance() {
  return dispatch => {
    return bookingService.addInsurance()
    .then(response => {
      dispatch( ancillaryResponse(response));
      return response;
     });
  }
}

function removeInsurance() {
  return dispatch => {
    return bookingService.removeInsurance()
    .then(response => {
      dispatch( ancillaryResponse(response));
      return response;
     });
  }
}

function addLounge(logicalFlightId) {
  return dispatch => {
    return bookingService.addLounge(logicalFlightId)
    .then(response => {
      dispatch( ancillaryResponse(response));
      return response;
     });
  }
}

function removeLounge(logicalFlightId) {
  return dispatch => {
    return bookingService.removeLounge(logicalFlightId)
    .then(response => {
      dispatch( ancillaryResponse(response));
      return response;
     });
  }
}

function addPriorityCheckin(logicalFlightId) {
  return dispatch => {
    return bookingService.addPriorityCheckin(logicalFlightId)
    .then(response => {
      dispatch( ancillaryResponse(response));
      return response;
     });
  }
}

function removePriorityCheckin(logicalFlightId) {
  return dispatch => {
    return bookingService.removePriorityCheckin(logicalFlightId)
    .then(response => {
      dispatch( ancillaryResponse(response));
      return response;
     });
  }
}

function changeMeals(selection) {
  return dispatch => {
    return bookingService.changeMeals(selection)
    .then(response => {
      dispatch( ancillaryResponse(response));
      return response;
    })
  }
}

function assignSeat(data) {
  return () => bookingService.assignSeat(data);
}

function clearSeat(data) {
  return () => bookingService.clearSeat(data);
}

function clearAllSeat() {
  return () => bookingService.clearAllSeat();
}

function updatePassengers(passengers, contact) {
  return () => bookingService.updatePassengers(passengers, contact);
}

function populateBooking() {
  return dispatch => {
    return bookingService.populateBooking().then(response => {
      dispatch(bookingResponse(response));
      return response;
    });
  }
}

function resetSegment(segmentIndex) {
  return dispatch => {
    return bookingService.resetSegment(segmentIndex).then(response => {
      dispatch(bookingResponse(response.booking));
      dispatch(summaryResponse(response.summary));
      return response;
    });
  }
}

function summaryPnr() {
  return dispatch => {
    return bookingService.summaryPnr().then(response => {
      dispatch(bookingResponse(response.booking));
      dispatch(summaryResponse(response.summary));
      return response;
    });
  }
}

function printItinerary() {
  return () => bookingService.printItinerary();
}

function printItineraryWithoutPrice() {
  return () => bookingService.printItineraryWithoutPrice();
}
function getPKPassFile() {
  return () => bookingService.getPKPassFile();
}

function feedback(data) {
  return () => bookingService.feedback(data);
}

function bookingRequest() {
  return { type: types.BOOKING_REQUEST };
}

function bookingResponse(payload) {
  return { type: types.BOOKING_RESPONSE, payload };
}

function bookingWithAllSegmentsResponse(payload) {
  return { type: types.BOOKING_WITH_ALLSEGMENTS_RESPONSE, payload};
}

function summaryRequest() {
  return { type: types.SUMMARY_REQUEST }
}

function summaryResponse( payload ) {
  return{
    type: types.SUMMARY_RESPONSE , payload:payload
  }
}

function ancillaryRequest() {
  return { type: types.ANCILLARY_REQUEST };
}

function ancillaryResponse(payload) {
  return { type: types.ANCILLARY_RESPONSE, payload };
}