import React from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { alertActions } from "../../actions";
import { sessionActions } from "../../state/ducks/session";
import { UserType } from "../../constants/settings";
import { webkitHelper } from "../../helpers";
import { ls } from "../../locale";

function Alert(props) {

  const handleClose = () => {

    const errors = props.errors;
    props.clear();

    const underMaintenance = Array.isArray(errors) && errors.find(x => x.errorCode === 503 || x.errorCode === "503");
    if (underMaintenance) {
      window.location.href = "/maintain.html";
      return;
    }

    const sessionExpired = Array.isArray(errors) && errors.find(x => x.errorCode === 403 || x.errorCode === "403");
    if (sessionExpired) {
      if (webkitHelper.sendSessionExpired()) return;

      const userData = props.getUserData();
      const bookingMode = props.getBookingMode().mode;
      redirect(userData, bookingMode);
    }
  }

  const rButton = ls.t('Button');
  const rTitle = ls.t('MessageBox.Title');

  const close = rButton.Close || 'Close';
  const error = rTitle.Error || 'Error';


  return (
    <Modal className={ls.t("rootClass")} show={props.show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{error}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"salam-air-popup " + ls.t("rootClass")}>
          <h3>{props.getUserDataessage}</h3>
          { Array.isArray(props.errors) && props.errors.map((error, index) => (
              <p key={index}>[{error.errorCode}] {error.message}</p>
            ))
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleClose()}>{close}</Button>
      </Modal.Footer>
    </Modal>
  )
}

function redirect(userData, bookingMode) {

  const history = createBrowserHistory({ forceRefresh: true });
  const lng = ls.lang;

  let dest = 'search';
  if (userData && userData.userType === UserType.agent) {
    dest = 'agent';
  }
  else if (userData && userData.userType === UserType.member) {
    dest = 'member';
  }
  else {
    if (bookingMode === 'WCI')
      dest = 'wci';
    else if (bookingMode === 'MMB')
      dest = 'mmb';
  }
  history.push(`/${lng}/${dest}`);
}

function mapStateToProps(state) {
  return {
    ...state.redux.alert
  }
}

const mapDispatchToProps = {
  ...alertActions,
  getBookingMode: sessionActions.getBookingMode,
  getUserData: sessionActions.getUserData,
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
