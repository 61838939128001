import { sessionService } from "../session/sessionService"
import { api } from "../api"

import { UserType } from "../../../constants/settings"

export const agentService = {
  agentLogin,
  getBookingHistory,
  retrieveBooking,
  retrieveDashboardReport,
  forgotPassword,
  changePassword,
  createAgency,
  modifyAgency,
  getChildAgencies,
  getAgencyDetails,
  createAgent,
  modifyAgent,
  modifyAgentStatus,
  getChildAgents,
  transferFunds,
  retrieveReportList,
  generateReport
}

function agentLogin(iataNumber, userName, password) {
    const data = {
      iataNumber,
      userName,
      password
    };

    return api.postFetch(`api/travelagent/login`, data)
    .then(response => {
      const userData = {
        userName: response.userName,
        displayName: response.companyName, 
        userType: UserType.agent, 
        loggedIn: true,
        currencyCode: response.currencyCode,
        availableCredit: response.availableCredit,
        permissions: response.permissions,
        isChildAgency: response.isChildAgency,
        iataNumber: response.iataNumber
      }
      sessionService.setUserData(userData);
      sessionService.setCurrency()
      return response;
    })
}

function getBookingHistory() {
  return api.getFetch(`api/travelagent/bookinghistory`)
}

function retrieveBooking(pnr) {
  return api.getFetch(`api/travelagent/retrievebooking`, {pnr})
}

function retrieveDashboardReport(data) {
  return api.postFetch(`api/travelagent/report`, data)
}

function forgotPassword(data) {
  return api.postFetch(`api/travelagent/forgotPassword`, data)
}

function changePassword(data) {
  return api.postFetch(`api/travelagent/changePassword`, data)
}

function createAgency(data) {
  return api.postFetch(`api/travelagent/createAgency`, data)
}

function modifyAgency(data) {
  return api.postFetch(`api/travelagent/modifyAgency`, data)
}

function createAgent(data) {
  return api.postFetch(`api/travelagent/createAgent`, data)
}

function modifyAgent(data) {
  return api.postFetch(`api/travelagent/modifyAgent`, data)
}

function modifyAgentStatus(data) {
  return api.postFetch(`api/travelagent/modifyAgentstatus`, data)
}

function getChildAgencies() {
  return api.getFetch(`api/travelagent/childagencies`)
}

function getAgencyDetails(iataNumber) {
  const data = { iataNumber };

  return api.getFetch(`api/travelagent/agencydetails`, data)
}

function getChildAgents(iataNumber) {
  const data = { iataNumber };

  return api.getFetch(`api/travelagent/childagents`, data)
}

function transferFunds(data) {
  return api.postFetch(`api/travelagent/transferFunds`, data)
}

function retrieveReportList() {
  return api.getFetch(`api/travelagent/reportlist`)
}


function generateReport(data) {
  return api.postFetch(`api/travelagent/generatereport`, data)
}