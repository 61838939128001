import React, { useState } from "react";
import dayjs from "dayjs";
import Icon from "react-icons-kit/Icon";
import { ReactSVG } from "react-svg";
import { user } from "react-icons-kit/fa/user";
import { chevronRight } from "react-icons-kit/fa/chevronRight";

import { ListGroupItem, Row, Col, Checkbox } from "react-bootstrap";

import { numberFormatter, stringFormatter } from "../../../helpers";
import { FlightDetailsWindow } from "../../../components/FlightDetailsWindow";

import { PassengerType } from "../../../constants/settings";

import SeatSVG from "../../../images/seat.svg";

import "./index.scss";
import { ls } from "../../../locale";

export default function FlightDetail({ segment, passengers }) {

  const [showDetail, setShowDetail] = useState(false);

  if (!segment || !Array.isArray(passengers)) return null;

  const checkedInPassengers = getCheckedInPassengers(segment, passengers);

  const fare = segment.fares[0];
  const stops = segment.legs.length ? segment.legs.length - 1 : 0;

  const rFareType = ls.t('FareType');
  const rFlight = ls.t('Flight');
  const rFlightDetails = ls.t('FlightDetails');

  const durationText = segment.flightTime > 0 ? numberFormatter.formatFlightTime(segment.flightTime, rFlight.Duration) : '';

  return (
    <React.Fragment>
      <ListGroupItem>
        <Row className="flight-info">
          <Col md={3}>
            <i className="picon picon-plane" />
            <span>{segment.originCity} {rFlight.To} {segment.destinationCity}</span>
          </Col>
          <Col md={5}>
            <p className="flight-date">{dayjs(segment.departureDate).format("ddd D MMM YYYY")}</p>
            <div className="flight-times row">
              <Col md={4} xs={4}>
                <p className="time">{dayjs(segment.departureDate).format("HH:mm")}</p>
                <p>{segment.originCode} - {rFlight.Departure}</p>
              </Col>
              <Col md={4} xs={4}>
                <i className={stops > 0 ? "picon picon-flights" : "picon picon-flight"} />
              </Col>
              <Col md={4} xs={4}>
                <p className="time">{dayjs(segment.arrivalDate).format("HH:mm")}</p>
                <p>{segment.destinationCode} - {rFlight.Arrival}</p>
              </Col>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="fare-name">{fare.fareTypeName ? rFareType[stringFormatter.removeSpace(fare.fareTypeName)].FullName : null}</h4>
            <div className="fare-info">
              <span>{segment.carrierCode}{segment.flightNumber}</span>
              <span>{stops > 0 ? (stops + " " + rFlight.Stop) : rFlight.Direct}</span><span>{durationText}</span>
              <span className="details-span"><button className="link-button" onClick={() => setShowDetail(true)}>
                {rFlightDetails.Label}</button> <Icon icon={chevronRight} />
              </span>
            </div>
          </Col>
        </Row>
        <div className="passenger-heading">
          <Icon icon={user} className="passenger-icon" />
          <span>Checked in Passengers</span>
        </div>
        {
          checkedInPassengers.map((passenger, index) => {
            const title = passenger.title && passenger.title.value ? passenger.title.value : passenger.title;
            let paxname = `${title} ${passenger.firstName} ${passenger.lastName}`;

            if (passenger.paxType === PassengerType.ExtraSeat) paxname = null;

            return (
              <div key={index} className="single-check-in-passenger">
                <Checkbox className="check-box" checked={true} onChange={() => { }}>
                  <label></label>
                </Checkbox>
                <div className="pax-details">
                  <span className="passenger-name">{paxname}</span>
                  <span className="passenger-seat">
                    {passenger.seats.map(seat => (
                      <React.Fragment key={seat}>
                        <ReactSVG src={SeatSVG} className="inline-icon" />
                        <span className="seat-number">&nbsp;SEAT&nbsp; {seat}</span>
                      </React.Fragment>
                    ))}
                  </span>
                </div>
              </div>
            )
          })
        }

      </ListGroupItem>
      {showDetail && <FlightDetailsWindow flight={segment} onClose={() => setShowDetail(false)} />}
    </React.Fragment>
  );

}

function getCheckedInPassengers(segment, passengers) {
  const result = [];
  passengers.forEach(passenger => {
    const seats = [];
    let checkedIn = false;
    if (Array.isArray(segment.legs)) {
      segment.legs.forEach(leg => {
        if (Array.isArray(leg.customers)) {
          leg.customers.forEach(customer => {
            if (customer.passengerId === passenger.id) {
              if (customer.seat) seats.push(customer.seat.rowNumber + customer.seat.seatNumber);
              if (customer.status === 4) checkedIn = true;
            }
          })
        }
      })
    }

    if (checkedIn) result.push({ ...passenger, seats });
  })

  return result;
}
