import React from 'react';

import { Airport } from "./Airport";
import { FlightDate } from "./FlightDate";
import { FareType, Leg, Segment } from '../../state/models/booking';
import { FlightStatus } from './FlightStatus';
import { FareDetail } from './FareDetail';
import { Connector } from './Connector';

import styles from './dp.module.scss'
import { numberFormatter } from '../../helpers';
import { ls } from '../../locale';
import ClockIcon from '../../images/clock.svg';
import { ReactSVG } from 'react-svg';

export function FlightDetail({segment}: {segment: Segment}) {

  const fare = segment.fares[0];
  // const stops = segment.legs.length ? segment.legs.length - 1 : 0;

  return (
    <div className="tw-mt-5 tw-border tw-border-solid tw-border-gray-200 tw-bg-white">
      {segment.legs.map(leg => {
        if(leg.transitionTime > 0 || leg.transitionTime < 0) {
          return <>
            <TransitionTime transitionTime={leg.transitionTime} />
            <LegDetails key={leg.physicalFlightId} leg={leg} fare={fare} />
          </>
        }
        return  <LegDetails key={leg.physicalFlightId} leg={leg} fare={fare} />
      })}
    </div>
  )
}

function LegDetails({leg, fare}: {leg: Leg, fare: FareType}) {

  const disruption = leg.flightDisruption;
  const code = disruption?.code?.toLocaleLowerCase();
  const flightStyles = code ? (styles[code] ?? '') : '';
  const dateStyle = code === 'tk' ? 'tw-px-3 tw-py-1 tw-rounded-xl sa-bg-yellow sa-text-blue' : '';

  return (
    <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-5 tw-gap-5 tw-px-5 tw-py-5 lg:tw-py-2">
      <div className="tw-block lg:tw-hidden">
        <FlightStatus disruption={disruption} />
      </div>
      <div className={`tw-col-span-3 tw-flex tw-justify-between tw-items-end tw-gap-2 tw-my-2 lg:tw-my-5 tw-relative ${flightStyles}`}>
        <div className='tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-gap-2'>
          <Airport code={leg.origin} name={leg.originCity} />
          <FlightDate date={leg.departureDate} prevDate={disruption?.previousDepartureDate} className={dateStyle} />
        </div>
        <div className="tw-grow tw-pb-10">
          <Connector />
        </div>
        <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-gap-2">
          <Airport code={leg.destination} name={leg.destinationCity} />
          <FlightDate date={leg.arrivalDate} prevDate={disruption?.previousArrivalDate} className={dateStyle} />
        </div>
      </div>
      <div className="tw-col-span-2 tw-flex tw-flex-col tw-justify-between tw-min-w-72">
        <div className="tw-hidden lg:tw-block tw-text-center">
          <FlightStatus disruption={disruption} />
        </div>
        <div className="tw-flex lg:tw-justify-end">
          <FareDetail 
            fareName={fare?.fareTypeName ?? ""} 
            flightNumber={leg.carrierCode + leg.flightNumber} 
            stops={0}
            flightTime={leg.flightTime} />
        </div>
      </div>
    </div>
  )
}

function TransitionTime({transitionTime} : {transitionTime: number}) {

  const rFlight = ls.t('Flight')
  const time = numberFormatter.formatFlightTime(transitionTime, rFlight.DurationShort)
  const text = `Transit Time ${time}`

  if(transitionTime >= 60) return (
    <div className='tw-grid tw-grid-cols-1 lg:tw-grid-cols-5 tw-my-5'>
      <div className='tw-col-span-3 tw-text-center'>
        <span className={`tw-px-2 tw-py-1 tw-rounded-xl tw-border-solid tw-border-2 tw-border-gray-200 sa-text-green`}>{text}</span>
      </div>
    </div>
  )


  return (
    <div className='tw-grid tw-grid-cols-1 lg:tw-grid-cols-5 tw-my-5'>
      <div className='tw-col-span-3 tw-flex tw-justify-center tw-items-center tw-gap-0'>
        <span className={`tw-px-2 tw-py-1 tw-mx-2 tw-rounded-xl sa-bg-yellow sa-text-blue tw-font-bold`}>Connection time insufficient</span>
        <ReactSVG src={ClockIcon} className='tw-inline-block tw-size-6' />
        <span>{text}</span>
      </div>
    </div>
  )
}