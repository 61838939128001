import { customerValidator } from "../../helpers";

export const initialState = {
  isShowLoginForm: false,
  isShowPasswordBox: false,
  username: '',
  password: '',
  errors: {}
}

export function reducer(state, action) {
  switch (action.type) {
    case 'SHOW_LOGIN_FORM':
      return {
        ...state,
        isShowLoginForm: true,
        isShowPasswordBox: false,
      }
    case 'HIDE_LOGIN_FORM':
      return {
        ...state,
        isShowLoginForm: false,
        isShowPasswordBox: false,
      }
    case 'SET_INPUT_STATE':
      return {
        ...state,
        [action.key]: action.value,
        errors: {...state.errors, [action.key]: null}
      }
    case 'SET_ERROR_STATE':
      return {
        ...state,
        errors: { ...state.errors, [action.key]: action.error }
      }
    case 'SHOW_PASSWORD_BOX':
      return {
        ...state,
        isShowLoginForm: false,
        isShowPasswordBox: true,
      }
    case 'HIDE_PASSWORD_BOX':
      return {
        ...state,
        isShowPasswordBox: false,
      }
    default:
      return state
  }
}

export function isValidUsername(username) {
  if(!username || !username.trim()) {
    return 'User ID is empty';
  }
  
  const invalidEmail = customerValidator.validateEmail(username);
  if(invalidEmail) {
    return "User ID must be a valid email address";
  }

  return null
  
}