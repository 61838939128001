import dayjs from 'dayjs';
import { ls } from '../locale';

const tripTypes = [
  { code: "0", value: "default" },
  { code: "1", value: "oneway" },
  { code: "2", value: "round" },
  { code: "3", value: "multi" }
]


export const tripTypeHelper = {
  defaut: 'round',
  
  isValidValue: function(value) {
    if(!value) return false;
    return tripTypes.find(x => x.value === value) ? true : false;
  },

  findValueByCode: function(code) {
    code += "";
    if(!code) return null;

    const r = tripTypes.find(x => x.code === code);
    if(r) return r.value;

    return null;
  }
}

export function buildSearchParams(searchRequest) {
  if (!searchRequest) return null;

  const tripType = tripTypeHelper.findValueByCode(searchRequest.tripType);

  let route = `${searchRequest.originStationCode}-${
    searchRequest.destinationStationCode
  }`;
  let dates = `${getDateString(searchRequest.departureDate)}`;
  const counts = `${searchRequest.adultCount}-${searchRequest.childCount}-${searchRequest.infantCount}-${searchRequest.extraCount}`;

  if (tripType === "round") {
    dates += `|${getDateString(searchRequest.returnDate)}`;
  } else if (tripType === "multi") {
    dates += `|${getDateString(searchRequest.departureDate2)}`;
    route += `|${searchRequest.originStationCode2}-${
      searchRequest.destinationStationCode2
    }`;
  }

  return {
    flightType: tripType,
    route,
    dates,
    counts,
    promoCode: searchRequest.promoCode,
    lng: ls.lang
  };

  function getDateString(date) {
    if (!date) return date;
    const index = date.indexOf("T");
    if (index < 0) return date;

    return date.substring(0, index);
  }
}


export function buildSearchQuery(search, tripDates = []) {
  let query = '';

  if(!search || !search.route || !search.flightType) return query;

  // routes
  const routes = search.route.split('-');
  if(routes.length !== 2) return query;
  // dates
  const dates = search.dates.split('|');
  if(dates.length < 1) return query;

  // adjust the date based on selected flight
  for(let i=0; i<dates.length; i++) {
    const td = tripDates[i];
    if(!td) continue;
    dates[i] = dayjs(td).format('YYYY-MM-DD')
  }
  query += `tripType=${search.flightType}&origin=${routes[0]}&destination=${routes[1]}&departureDate=${dates[0].replace(/-/g, '')}`;
  if(dates.length > 1) query += `&returnDate=${dates[1].replace(/-/g, '')}`;

  // pax counts
  const counts = search.counts.split('-');
  if(counts < 1) return query;

  query += `&adult=${counts[0]}`;
  if(counts.length > 1) query += `&child=${counts[1]}`;
  if(counts.length > 2) query += `&infant=${counts[2]}`;
  if(counts.length > 3) query += `&extra=${counts[3]}`;

  return query;
}

// return the disclousure message as a string
export function getOperatingDisclosureMessage(segment) {

  if(!segment || !Array.isArray(segment.legs)) return null;

  const rCarriers = ls.t('Flight.Carrier');
  const rOperatingMessage = ls.t('Flight.OperatingMessage');   // The message is 'Flight _A_ is operated by _C_ as _B_'
  
  if(!rCarriers | !rOperatingMessage) return null;
  
  let result = '';

  segment.legs.forEach(leg => {
    if(leg.carrierCode === leg.operatingCarrierCode) return;

    const carrier = rCarriers[leg.operatingCarrierCode];
    const flightNumberA = leg.carrierCode + leg.flightNumber;
    const flightNumberB = leg.operatingCarrierCode + leg.operatingFlightNumber;
    
    const message = rOperatingMessage.replace('_C_', carrier).replace('_A_', flightNumberA).replace('_B_', flightNumberB);
    
    if(result) result += ', ';
    result += message;
  })

  return result;
        
}





