import React, { useState, useEffect, useRef } from "react";
import { Row, Col, FormControl, FormGroup, Button } from "react-bootstrap";

import { ReactSVG } from "react-svg";
import CheckinIcon from "../../images/web-checkin.svg";
import { ls } from "../../locale";
import FormErrorBlock from "../FormErrorBlock";

import { customerValidator } from "../../helpers";

export default function RetrieveBooking(props) {

  const nameEl = useRef(null);

  const [pnr, setPnr] = useState(props.pnr || '')
  const [lastName, setLastName] = useState(props.lastName || '')
  const [errors, setErrors] = useState({});


  useEffect(() => {
    if(!pnr) setPnr(props.pnr);
  }, [props.pnr, pnr]);

  useEffect(() => {
    if(!lastName) setLastName(props.lastName);
  }, [props.lastName, lastName])

  function setPnrState(pnr) {
    pnr = pnr.trim();
    if (pnr.length > 6) pnr = pnr.substring(0, 6);

    pnr = pnr.toUpperCase();
    setPnr(pnr);
    setErrors({
      ...errors,
      pnr: null
    })
  }

  function setLastNameState(lastName) {
    lastName = lastName.trimStart().replace(/\s{2,}/, ' ');
    if (lastName.length > 20) lastName = lastName.substring(0, 20);

    lastName = lastName.toUpperCase();
    setLastName(lastName);

    setErrors({
      ...errors,
      lastName: null
    })
  }

  function findBooking() {
    const errors = {};

    if(!pnr) {
      errors.pnr = "Please enter Booking Reference";
    } 
    else {
      const pnrRegex = /^[A-Z0-9]{6}$/;
      if(!pnrRegex.test(pnr)) {
        errors.pnr = "Booking Reference must be 6 characters.";
      }
    }
    
    errors.lastName = customerValidator.validateLastName(lastName);

    if(errors.pnr || errors.lastName) {
      setErrors(errors);
      return;
    }

    if (typeof (props.onFindBooking) === 'function')
      props.onFindBooking(pnr, lastName)
  }

  function handlePnrKeyPress(e) {
    if(e && e.key === 'Enter') {
      nameEl.current.focus();
    }
  }

  function handleNameKeyPress(e) {
    if(e && e.key === 'Enter') {
      findBooking();
    }
  }

  const rRetrievePage = ls.t('RetrievePage');
  if (!rRetrievePage.Title) return null;

  const rButton = ls.t('Button');

  return (
    <React.Fragment>
      <h1>{rRetrievePage.Title}</h1>
      <div className={"web-checkin-wrap " + ls.t('rootClass')}>
        <ReactSVG src={CheckinIcon} className="web-checkin-icon" />
        <span>{props.title}</span>
      </div>
      <hr />
      {props.children}
      <Row className="webcheck-in-row">
        <Col md={6} xs={12}>
          <FormGroup className={errors.pnr ? "has-error" : null}>
            <FormControl 
              type="text" 
              placeholder={rRetrievePage.BookingReference} 
              value={pnr} 
              onChange={event => setPnrState(event.target.value)} 
              onKeyPress={handlePnrKeyPress}
            />
            <FormErrorBlock error={errors.pnr} />
          </FormGroup>
        </Col>
        <Col md={6} xs={12}>
          <FormGroup className={errors.lastName ? "has-error" : null}>
            <FormControl 
              inputRef={ref => nameEl.current=ref} 
              type="text" 
              placeholder={rRetrievePage.LastName} 
              value={lastName} 
              onChange={event => setLastNameState(event.target.value)}
              onKeyPress={handleNameKeyPress}
            />
            <FormErrorBlock error={errors.lastName} />
          </FormGroup>
        </Col>
      </Row>
      <div className="clearfix btnCon">
        <Button type="submit" className="btn btn-primary pull-left flip btn-submit btn-effect" onClick={() => findBooking()}>
          <span>{rButton.FindBooking}
            <i className="picon picon-btn-arrow-right-w">
            </i>
          </span>
        </Button>
      </div>
    </React.Fragment>
  );

}
