import React from "react";
import { Row, Col } from "react-bootstrap";
import { Redirect } from 'react-router-dom';

import "./index.scss";

import { sessionService } from "../../state/ducks/session/sessionService";

import SelectFlights from "./SelectFlights";
import BasePageWithStatusBar from "../../components/BasePage/BasePageWithStatusBar";
import { getAgentPath } from "../../helpers";

export default function FlightsPage(props) {

  const params = sessionService.getSearchParams();

  if (!params || !params.flightType || !params.route || !params.dates || !params.counts) {
    const lng = props.match.params.lng;
    const url = `/${lng}/${getAgentPath()}/search`;

    return <Redirect to={url} />
  }

  return (
    <BasePageWithStatusBar hasSummary statusLabel="flights" mainContainerClass="flight-selection">
      <Row className="min-height">
        <Col md={12} xs={12}>
          <SelectFlights />
        </Col>
      </Row>
    </BasePageWithStatusBar>
  );
}
