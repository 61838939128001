import React from "react";
import './expresscheckin.scss'
import { ls } from "../../locale";

export function ExpressCheckinBar({onClick}) {

  const handleClick = () => {
    if(typeof onClick === 'function') onClick();
  }

  const expresscheckinImage = `/images/express-checkin-${ls.t('rootClass')}.png`
  const rButton = ls.t('Button');

  return (
    <div className="express-checkin-bar">
      <div className="desc"><img src={expresscheckinImage} alt="Express Check-in"/></div>
      <button className="btn btn-primary" onClick={handleClick}>{rButton.Checkin}</button>
    </div>
  )
}