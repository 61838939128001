import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { ls } from "../../locale";
import { connect } from "react-redux";
import { jumpTo, isInAppBrowser, getAgentPath } from "../../helpers";

import queryString from "query-string";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SidePanel from "../../components/SidePanel";
import RetrieveBooking from "../../components/Common/RetrieveBooking";

import { wciActions } from "../../state/ducks/wci";
import { sessionActions } from "../../state/ducks/session";

import "./index.scss";


function CheckInPage({ retrieveBooking, setBookingMode, location }) {

  const [pnr, setPnr] = useState('');
  const [lastName, setLastName] = useState('');

  const findBooking = (pnr, lastName, ref) => {
    if (!pnr || !lastName) return;

    retrieveBooking(pnr, lastName).then(response => {
      if (response && response.pnr) {
        setBookingMode({ mode: "WCI", ref });
        jumpTo(getAgentPath() + "/wci/flight");
      }
    });
  }

  useEffect(() => {
    const params = queryString.parse(location.search);

    let pnr = params.pnr || '';
    let lastName = params.lastName || '';
    const ref = params.ref

    if (pnr.length > 6) pnr = "";
    if (lastName.length > 20) lastName = "";

    setPnr(pnr);
    setLastName(lastName);

    if (pnr && lastName) findBooking(pnr, lastName, ref);

    if (ref === 'wcinotifier') {
      window.dataLayer.push({ event: 'online-checkin-clicked', booking_reference: pnr })
    }
  }, []);

  // render() 

  const inAppBrowser = isInAppBrowser();

  const rRetrievePage = ls.t('RetrievePage');

  return (
    <div className={ls.t("rootClass") + " main-container webcheck-in"} dir={ls.t("direction")}>
      <div className="header-con">
        <Header />
      </div>
      {!inAppBrowser && <div className="header-bar" />}
      <div className="container padt40 padb40">
        <Row className="min-height web-checkin-wrap">
          <Col md={9} xs={12}>
            {rRetrievePage.WCI && <RetrieveBooking title={rRetrievePage.WCI.Label}
              pnr={pnr}
              lastName={lastName}
              onFindBooking={(pnr, lastName) => findBooking(pnr, lastName)} />}
          </Col>
          <Col md={3} xs={12}>
            <SidePanel />
          </Col>
        </Row>
      </div>
      {!inAppBrowser && <Footer />}
    </div>
  );
}


function mapStateToProps() {
  return {
  }
}

const mapDispatchToProps = {
  retrieveBooking: wciActions.retrieveBooking,
  setBookingMode: sessionActions.setBookingMode,
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckInPage);
