import React, { useState } from "react";
import dayjs from "dayjs";
import PerfectScrollbar from "react-perfect-scrollbar";

import "./index.scss";
import "react-perfect-scrollbar/dist/css/styles.css";

import Icon from "react-icons-kit/Icon";
import { minus } from "react-icons-kit/fa/minus";
import { plus } from "react-icons-kit/fa/plus";
import { close } from "react-icons-kit/fa/close";


import {numberFormatter} from "../../helpers";
import ServiceSection from "./ServiceSection";
import { ls } from "../../locale";

export default function SummaryDropdown({ bookingSummary, total, actClass, setBackdrop }) {

  const [showFares, setShowFares] = useState([]);

  const toggleFares = index => {
    setShowFares(prevValue => {
      const fares = prevValue.slice();
      fares[index] = !prevValue[index];
      return fares;
    });
  };

  // render()

    const rSummary = ls.t('Summary');
    if(!rSummary.ViewSummary) return null;

    const rFlight = ls.t('Flight');
    const rFareType = ls.t('FareType');
    const rPaxType = ls.t('PaxType');    

    const segments = (bookingSummary && bookingSummary.segments) || [];
    const currency = (bookingSummary && bookingSummary.currencyCode) || "OMR";

    const totalWithCurrency = numberFormatter.formatCurrency(total);

    return (
      <div className={"summaryCon" + (actClass ? " active" : "")}>
        <PerfectScrollbar>
          <Icon
            onClick={setBackdrop}
            className="close-icon"
            icon={close}/>
          {segments && segments.map((segment, index) => {

            let totalPassenger = 0;
            if (Array.isArray(segment.fares)) {
              segment.fares.forEach(fare => {
                totalPassenger += fare.paxCount;
              });
            }
            const seatsAsService = Array.isArray(segment.seats) 
              ? segment.seats.map(s => ({
                amountWithoutTaxes: s.amountWithoutTaxes,
                taxes: s.taxes,
                label: s.seat,
                serviceCount: 1
              })) 
              : [];

            const direction = segment.direction === "Inbound" ? rSummary.ReturnFlight : rSummary.DepartureFlight;
            const fareTypeName = segment.fareTypeName ? segment.fareTypeName.replace(/\s/, '') : null;
            return (

              <div key={index}>
                {index > 0 && <hr/>}
                <div className="title">
                  <i className="picon picon-plane"></i> {direction}
                </div>
                <div className="data-box">
                  <div className="flight-info">
                    <span>{dayjs(segment.departureDate).format("ddd D MMM YYYY")}</span>
                    <span className="value">{rFlight.Flight} {segment.carrierCode}{segment.flightNumber}</span>
                  </div>
                  <div className="flight-time">
                    <span>{dayjs(segment.departureDate).format("HH:mm")} {segment.originCode}</span>
                    <span
                      className="value">{segment.destinationCode} {dayjs(segment.arrivalDate).format("HH:mm")}</span>
                  </div>
                  <div className="collapsible" onClick={() => toggleFares(index)}>
                    <span>{fareTypeName ? rFareType[fareTypeName].Label : ''} x {totalPassenger}</span>
                    <span className="value"><span className="currency">{currency}</span>&nbsp;
                        {numberFormatter.decimalSplitter(numberFormatter.formatCurrency(segment.fareTotal)).amount}
                      <span className={currency + " decimal-point"}>
                      {numberFormatter.oneDecimalPoint(numberFormatter.decimalSplitter(segment.fareTotal).decimal, currency)}
                      </span>
                      <Icon icon={showFares[index] ? minus : plus}/></span>
                  </div>
                  <div className={showFares[index] ? "tax-info" : "tax-info hidden"}>
                    {
                      segment.fares.map((fare, findex) => (
                        <div key={findex}>
                          <span>{fare.paxCount} {rPaxType[fare.paxType].Label}</span>
                          <span className="value"><span className="currency">{currency}</span>&nbsp;
                            {
                              numberFormatter.decimalSplitter(numberFormatter.formatCurrency(fare.amountWithoutTaxes)).amount
                            }
                            <span className={currency + " decimal-point"}>
                              {numberFormatter.oneDecimalPoint(numberFormatter.decimalSplitter(fare.amountWithoutTaxes).decimal, currency)}
                            </span>
                          </span>
                        </div>
                      ))
                    }
                    {
                      segment.taxes.map((tax, tindex) => (
                        <div key={tindex}>
                          <span>{tax.taxDecription}</span>
                          <span className="value"><span className="currency">{currency}</span>&nbsp;
                            {
                              numberFormatter.decimalSplitter(numberFormatter.formatCurrency(tax.amount)).amount
                            }
                            <span className={currency + " decimal-point"}>
                                {numberFormatter.oneDecimalPoint(numberFormatter.decimalSplitter(tax.amount).decimal, currency)}
                              </span>
                      </span>
                        </div>
                      ))
                    }
                  </div>
                  <ServiceSection title={rSummary.Baggage} currency={currency} services={segment.baggages}/>
                  <ServiceSection title={rSummary.Meal} currency={currency} services={segment.meals}/>
                  <ServiceSection title={rSummary.Lounge} currency={currency} services={segment.lounges} />
                  <ServiceSection title={rSummary.PriorityCheckin} currency={currency} services={segment.priorities} />
                  <ServiceSection title={rSummary.Seat} currency={currency} services={seatsAsService} hideCount={true} />
                </div>
              </div>
            );
          })}

          {bookingSummary.othersTotal > 0 &&
            <div className="data-box">
              <ServiceSection title={rSummary.Other} currency={currency} services={bookingSummary.others} hideCount={true}/>
            </div>}

          <hr/>
          <div className="summary-total">
            <div className="label">
              {rSummary.TotalToPay}
            </div>
            <div className="amount">
              <div>
                {currency}&nbsp;
                {numberFormatter.formatCurrency(totalWithCurrency)}
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    );
}




