import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { ls } from "../../locale";
import { connect } from "react-redux";
import { jumpTo, isInAppBrowser, getAgentPath } from "../../helpers";

import queryString from "query-string";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SidePanel from "../../components/SidePanel";
import RetrieveBooking from "../../components/Common/RetrieveBooking";

import { mmbActions } from "../../state/ducks/mmb";
import { sessionActions } from "../../state/ducks/session";

import { isFlightDisrupted } from "../DisruptionPage/helpers";

import "./index.scss";

function ManagePage({ retrieveBooking, setBookingMode, location }) {

  const [pnr, setPnr] = useState('');
  const [lastName, setLastName] = useState('');

  const findBooking = (pnr, lastName) => {
    if (!pnr || !lastName) return;

    retrieveBooking(pnr, lastName).then((response) => {
      if (response && response.booking) {
        setBookingMode({
          mode: response.booking.bookingMode
        });

        if(isFlightDisrupted(response.booking)) {
          jumpTo(getAgentPath() + "/disruption");
          return
        }
        jumpTo(getAgentPath() + "/mmb/summary");
      }
    });
  };

  useEffect(() => {
    const params = queryString.parse(location.search);

    let pnr = params.pnr || '';
    let lastName = params.lastName || '';
    const ref = params.ref

    if (pnr.length > 6) pnr = "";
    if (lastName.length > 20) lastName = "";

    setPnr(pnr);
    setLastName(lastName);

    if (pnr && lastName) findBooking(pnr, lastName);

    if (ref === 'wcinotifier') {
      window.dataLayer.push({ event: 'manage-my-booking-clicked', booking_reference: pnr, ref })
    }
  }, []);


  // render() 

  const inAppBrowser = isInAppBrowser();

  const rRetrievePage = ls.t('RetrievePage');
  if (!rRetrievePage.MMB) return null;

  return (
    <div
      className={ls.t("rootClass") + " main-container webcheck-in"}
      dir={ls.t("direction")}
    >
      <div className="header-con">
        <Header />
      </div>
      {!inAppBrowser && <div className="header-bar" />}
      <div className="container padt40 padb40">
        <Row className="min-height web-checkin-wrap">
          <Col md={9} xs={12}>
            <RetrieveBooking
              title={rRetrievePage.MMB.Label}
              pnr={pnr}
              lastName={lastName}
              onFindBooking={(pnr, lastName) => findBooking(pnr, lastName)}
            >
              <p>{rRetrievePage.MMB.Desc}</p>
            </RetrieveBooking>
          </Col>
          <Col md={3} xs={12}>
            <SidePanel
              memberLoginUrl="https://salamair.booksecure.net/login.aspx?lang=en-us"
              agentRegisterUrl="https://www.salamair.com/en/contact-us/call-centre"
            />
          </Col>
        </Row>
      </div>
      {!inAppBrowser && <Footer />}
    </div>
  );
}

function mapStateToProps() {
  return {
  };
}

const mapDispatchToProps = {
  retrieveBooking: mmbActions.retrieveBooking,
  setBookingMode: sessionActions.setBookingMode,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagePage);


