import React, { useRef } from 'react';
import { Button } from "react-bootstrap";
import { withControlledComponent, customerValidator } from '../../../../helpers';
import { agentValidator } from '../agenctValidator';

import AgentDetails from './CreateAgentForm';
const AgentForm = withControlledComponent(AgentDetails);

export default function CreateAgent(props) {

  const agentForm = useRef(null);

  function createAgent() {

    const isFormValid = agentForm.current.validateForm();
    if (!isFormValid) return;

    const details = agentForm.current.getValues();
    //console.log('agent details: ', details);
    details.iataNumber = props.agency.iataNumber;
    if (typeof (props.onCreateAgent) === 'function') {
      props.onCreateAgent(details);
    }
  }

  function validateAgentDetails(data) {
    const errors = {};
    errors.userName = agentValidator.validateUserName(data.userName);
    errors.firstName = customerValidator.validateName(data.firstName);
    errors.lastName = customerValidator.validateName(data.lastName);
    errors.address = agentValidator.validateAddress(data.address);
    errors.city = agentValidator.validateCity(data.city);
    errors.postalCode = agentValidator.validatePostalCode(data.postalCode);
    errors.email = customerValidator.validateEmail(data.email);
    errors.phone = customerValidator.validatePhone(data.phone, true);

    return errors;
  }

  const agentDetails = {
    userName: '',
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    countryCode: 'OM',
    country: 'Oman',
    email: '',
    phone: '',
    isActive: true,
  }

  return (
    <>
      <h1>Create Agent</h1>
      <hr />
      {props.agency && <p className="agency-name">Agency: {props.agency.iataNumber} - {props.agency.agencyName}</p>}
      <AgentForm ref={agentForm} countries={props.countries} initValues={agentDetails} onValidate={validateAgentDetails} />

      <div className="clearfix btnCon col-xs-12">
        <Button className="btn btn-default btn-cancel pull-left" onClick={props.onCancel}>Back</Button>
        <Button className="btn btn-primary btn-submit pull-right" onClick={createAgent}>Create</Button>
      </div>
    </>
  )
}

