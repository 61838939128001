import types from "./types";

export default {
  showBackdrop
}

function showBackdrop(show) {
  return dispatch => {
     dispatch({type: show ? types.SHOW_BACKDROP : types.HIDE_BACKDROP});
  };
}