import React, { useRef } from 'react';
import { Button } from "react-bootstrap";
import { withControlledComponent, customerValidator } from '../../../../helpers';
import { agentValidator } from '../agenctValidator';

import AgencyDetails from './CreateAgencyForm';
const AgencyForm = withControlledComponent(AgencyDetails);


export default function CreateAgency(props) {

  const agencyForm = useRef(null);

  function createAgency() {

    const isFormValid = agencyForm.current.validateForm();
    if (!isFormValid) return;

    const details = agencyForm.current.getValues();
    //console.log('agency details: ', details);

    if (typeof (props.onCreateAgency) === 'function') {
      props.onCreateAgency(details);
    }
  }

  function validateAgencyDetails(data) {
    const errors = {};
    errors.iataNumber = agentValidator.validateIataNumber(data.iataNumber);
    errors.userName = agentValidator.validateUserName(data.userName);
    errors.agencyName = agentValidator.validateAgencyName(data.agencyName);
    errors.address = agentValidator.validateAddress(data.address);
    errors.city = agentValidator.validateCity(data.city);
    errors.postalCode = agentValidator.validatePostalCode(data.postalCode);
    errors.contactName = agentValidator.validateContactName(data.contactName);
    errors.email = customerValidator.validateEmail(data.email);
    errors.phone = customerValidator.validatePhone(data.phone, true);

    return errors;
  }

  const agencyDetails = {
    iataNumber: '',
    userName: '',
    password: '',
    agencyName: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    countryCode: 'OM',
    country: 'Oman',
    contactName: '',
    email: '',
    phone: '',
    isActive: true,
  }

  return (
    <>
      <h1>Create Agency</h1>
      <hr />
      <AgencyForm ref={agencyForm} countries={props.countries} initValues={agencyDetails} onValidate={validateAgencyDetails} />

      <div className="clearfix btnCon col-xs-12">
        <Button className="btn btn-default btn-cancel pull-left" onClick={props.onCancel}>Back</Button>
        <Button className="btn btn-primary btn-submit pull-right" onClick={createAgency}>Create</Button>
      </div>
    </>
  )
}

