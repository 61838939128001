import types from "./types"

const INITIAL_STATE = {
  bookingHistory: [],
  searchHistory: [],
  lastSearchedBooking: null,
  childAgencies: [],
  reportList: [],
}

export default function (state = INITIAL_STATE, {type: actionType, payload }) {
  switch(actionType) {
    case types.INIT_STATE:
      return INITIAL_STATE;

    case types.BOOKING_HISTORY_RESPONSE:
      return {
        ...state,
        bookingHistory: payload,
      };
    case types.RETRIEVE_BOOKING_RESPONSE:
      return {
        ...state,
        searchHistory: [payload, ...state.searchHistory]
      };
    case types.LAST_SEARCHED_BOOKING:
      return {
        ...state,
        lastSearchedBooking: payload
      }
    case types.CHILD_AGENCIES_RESPONSE:
      return {
        ...state,
        childAgencies: payload
      }
    case types.REPORT_LIST_RESPONSE:
      return {
        ...state,
        reportList: payload
      }

    default: return state;
  }
}