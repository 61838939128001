import { customerValidator, profileValidator } from '../../../helpers'

export const validateContact = values => {
  const errors = {}

  errors.email = profileValidator.validateEmail(values.email)
  if(values.email) {
    errors.confirmEmail = customerValidator.validateConfirmEmail(values.email, values.confirmEmail)
  }
  errors.mobile = profileValidator.validateMobile(values.mobile)

  return errors
}

export const validatePassenger = values => {
  const errors = {}

  errors.firstName = customerValidator.validateName(values.firstName)
  errors.lastName = customerValidator.validateName(values.lastName)
  errors.dateOfBirth = profileValidator.validateDateOfBirth(values.dateOfBirth)

  return errors
}