import React from "react";
import { Panel } from "react-bootstrap";
import { ReactSVG } from "react-svg";

import Unavailable from "../../../images/unavialble.svg";
import ExpressSeat from "../../../images/expressseat.svg";
import ExtraSeat from "../../../images/extraseat.svg";
import StandardSeat from "../../../images/standerd.svg";
import FrontSeat from "../../../images/frontseat.svg"
import BackSeat from "../../../images/backseat.svg";
import LubanSeat from '../../../images/lubanseat.svg'
import Selected from "../../../images/selected.svg";
import { ls } from "../../../locale";


const SeatGuide = ( props ) => {

  const { categories, currency } = props;
  const rSeatType = ls.t('SeatType');

  return(
    <Panel className="seats-guide-panel">
      <div className="seats-guide">
        <div className="details">
          <ReactSVG className="icon" src={Selected}/>
          <span className="seat-label">{rSeatType.Selected.Label}</span>
        </div>
        <div className="details">
          <ReactSVG className="icon" src={Unavailable}/>
          <span className="seat-label">{rSeatType.Unavailable.Label}</span>
        </div>
        {/* {freeSeat && <Col md={4} xs={6} className="details">
          <ReactSVG className="icon" src={BackSeat}/>
          <span>{rSeatType.Free.Label}</span>
          <span className="price"> {rSeatType.Free.Price}</span>
        </Col>} */}
        {
          Array.isArray(categories) ? <LiveGuide categories={categories.filter(x => x.code !== 'Free')} currency={currency} /> : <DefaultGuide />
        }
      </div>
    </Panel>
  )
}

const DefaultGuide = () => {

  const rSeatType = ls.t('SeatType');

  return (
    <>
      <div className="details">
        <ReactSVG className="icon" src={ExtraSeat}/>
        <span className="seat-label">{rSeatType.Extra.Label}</span>
      </div>
      <div className="details">
        <ReactSVG className="icon" src={ExpressSeat}/>
        <span className="seat-label">{rSeatType.Express.Label}</span>
      </div>
      <div md={4} xs={6} className="details">
        <ReactSVG className="icon" src={StandardSeat}/>
        <span className="seat-label">{rSeatType.Standard.Label}</span>
      </div>
  </>
  )
}

const LiveGuide = ({categories, currency}) => {

  const rSeatType = ls.t('SeatType');

  return (
    <>
      {
        categories.map((cate, index) => (
          <div key={index} className="details">
            <ReactSVG className={"icon " + cate.code.toLowerCase()} src={getCategoryIcon(cate.code)}/>
            <span className="seat-label">{rSeatType[cate.code].Label}</span>
            { cate.amount > 0 
              ? <span className="price"> <span className="currency">{currency}</span> {cate.amount}</span>
              : <span className="price"> {rSeatType.Free.Price}</span>
            }
          </div>
        ))
      }
    </>
  )
}

const getCategoryIcon = code => {
  switch(code) {
    case "Business": return ExtraSeat;
    case "Luban": return LubanSeat;
    case "Extra": return ExtraSeat;
    case "Express": return ExpressSeat;
    case "Front": return FrontSeat;
    case "Back": return BackSeat;
    default: return StandardSeat;
  }
}

export default SeatGuide;
