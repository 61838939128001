import React, {forwardRef, useImperativeHandle, useReducer, useCallback } from "react";
import { jsonHelper } from "../jsonHelper";


function reducer(state, action) {
  switch (action.type) {
    case 'SET_INPUT_STATE':
      if(state[action.key] === action.value) return state;

      return {
        values: {...state.values, [action.key]: action.value},
        errors: {...state.errors, [action.key]: null}
      }
    case 'SET_ERROR_STATE':
      if(state.errors[action.key] === action.error) return state;

      return {
        values: state.values,
        errors: { ...state.errors, [action.key]: action.error }
      }
    case 'SET_VALUES':
      return {
        values: jsonHelper.cloneDeep(action.values),
        errors: {}
      }
    case 'SET_ERRORS':
      return {
        values: state.values,
        errors: action.errors
      }
    default:
      return state
  }
}


export function withControlledComponent(WrappedForm) {
  return forwardRef(function ({initValues = {}, onValidate, onStateChanged, ...props}, ref) {

    const [state, dispatch] = useReducer(reducer, {values: jsonHelper.cloneDeep(initValues), errors: {}});

    const setValues = useCallback(values => dispatch({'type': 'SET_VALUES', values}), [])
    const reset = useCallback(() => setValues(initValues), [initValues])
    
    const validateForm = useCallback(() => {
      if (typeof (onValidate) !== 'function') return true;

      const errors = onValidate(state.values) || {};
      dispatch({type: 'SET_ERRORS', errors})

      return !(Object.values(errors).some(x => !!x));
    }, [state, onValidate])

    const getValues = useCallback(() => ({...state.values}), [state])

    useImperativeHandle(ref, () => ({
      setValues,
      reset,
      validateForm,
      getValues,
    }), [setValues, reset, validateForm, getValues]);

    const setInputState = useCallback((key, value) => {
      dispatch({type: 'SET_INPUT_STATE', key, value})

      if(typeof(onStateChanged) === 'function') {
        onStateChanged({ key, value });
      }
    }, [onStateChanged])

    return <WrappedForm
      setInputState={setInputState}
      setErrorState={(key, error) => dispatch({type: 'SET_ERROR_STATE', key, error})}
      setErrors={errors => dispatch({type: 'SET_ERRORS', errors})}
      values={state.values}
      errors={state.errors}
      {...props} />
  })
}