import React from 'react';
import { sessionService } from '../../state/ducks/session/sessionService';
import { Redirect } from 'react-router-dom';

import { getLanguageFromCulture } from '../../helpers';

// check the log-in status and redirect to log-in page according to the role specified

export function withAuthentication(WrappedComponent, role) {

  return props => {
    const userData = sessionService.getUserData();

    if(userData && userData.loggedIn) {
      return <WrappedComponent {...props } />;
    }

    const culture = sessionService.getCulture();
    const lang = getLanguageFromCulture(culture);
    
    if(!role) role = 'search';

    const redirectTo = `/${lang}/${role}`;
    return <Redirect to={redirectTo} />;
  }
}