import React from  'react'

export function DataCollectionIframe() {
  return (
    <>
      <iframe id="cardinal_collection_iframe" name="collectionIframe" 
        title="collectionIframe" height="10" width="10" style={{display:"none"}}></iframe>
      <form id="cardinal_collection_form" method="POST" target="collectionIframe" action="">
        <input id="cardinal_collection_form_input" type="hidden" name="JWT"value="" />
      </form>
    </>
  )
}