import types from "./types";
import { wciService } from "./wciServices";

import { actions as bookingActions } from "../booking/actions";

export default {
  retrieveBooking,
  confirm,
  getCheckInRequest,
  getSeatMap,
  selectFlight,
  updateCustomers,
  assignSeat,
  checkIn,
  getBoardingPass,
  checkExpressCheckin,
  expressCheckin,
}

function retrieveBooking(pnr, lastName) {
  return dispatch => {    

    dispatch(bookingActions.ancillaryResponse([]))

    return wciService.retrieveBooking(pnr, lastName)
  }
}

function confirm() {
  return () => {
    return wciService.confirm()
  }
}

function getCheckInRequest() {
  return dispatch => {
    dispatch(checkInRequest());

    return wciService.getCheckInRequest()
    .then(response => {
      dispatch(checkInResponse(response));
      return response;
    });
  }
}

function getSeatMap() {
  return () => {
    return wciService.getSeatMap()
  }
}

function updateCustomers(data) {
  return () => {
    return wciService.updateCustomers(data)
  }
}

function selectFlight(data) {
  return dispatch => {
    return wciService.selectFlight(data)
    .then(response => {
      dispatch(checkInResponse(response));
      return response;
    })
  }
}

function expressCheckin(data) {
  return dispatch => {
    return wciService.expressCheckin(data)
    .then(response => {
      dispatch(checkInResponse(response));
      return response;
    })
  }
}

function assignSeat(data) {
  return () => wciService.assignSeat(data)
}

function checkIn() {
  return () => wciService.checkIn()
}

function getBoardingPass() {
  return () => wciService.getBoardingPass()
}

function checkExpressCheckin() {
  return () => wciService.checkExpressCheckin()
}

function checkInRequest() {
  return { type: types.CHECKIN_REQUEST };
}

function checkInResponse(payload) {
  return { type: types.CHECKIN_RESPONSE, payload };
}
 