import React from 'react'


export default function Vouchers(props) {
  if(!Array.isArray(props.vouchers) || !props.vouchers.length) return null;

  return (
    <div className="vouchers">
      <p>Vouchers below have been sent to your contact email.</p>
      {props.vouchers.map(voucher => (
        <div className="voucher">
          <div>Voucher Issued to: {voucher.firstName} {voucher.lastName}</div>
          <div>Voucher Number: {voucher.voucherFullNumber}</div>
          <div>Voucher Amount: {voucher.currencyCode} {voucher.balanceAmount}</div>

        </div>
      ))}
    </div>
  )
}