import React from 'react'
import { useForm } from 'react-hook-form'
import { Row, Col, Button, Clearfix, FormControl, FormGroup } from 'react-bootstrap'

import FormErrorBlock from '../../../components/FormErrorBlock'
import { ls } from '../../../locale'
import { customerValidator } from '../../../helpers'





export function RetrieveForm({onFindVouchers}) {

  const { register, handleSubmit, formState: { errors }} = useForm()

  const onSubmit = data => {
    if(typeof onFindVouchers === 'function') onFindVouchers(data)
  }

  const rPax = ls.t('Pax')
  const rTravelVoucher = ls.t('TravelVoucher')

  const validator = {
    firstName: { 
      required: customerValidator.requiredField('Name') // 'First name is required'
    },
    lastName: { 
      required: customerValidator.requiredField('Name') // 'Last name is required'
    },
    email: { 
      required: customerValidator.requiredField('Email'), // 'Email is required', 
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: customerValidator.invalidField('Email') // "Invalid email address"
      }
    }
  }


  return (
    <div className="passenger">
      <div className="single-passenger">
        <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={4} xs={12}>
            <FormGroup>
              <FormControl type="text" 
                placeholder={rPax.FirstName + ' *'}
                {...register('firstName', validator.firstName)} />
              <FormErrorBlock error={errors.firstName && errors.firstName.message} />
            </FormGroup>
          </Col>
          <Col sm={4} xs={12}>
            <FormGroup>
              <FormControl type="text"
                placeholder={rPax.LastName + ' *'} 
                {...register('lastName', validator.lastName)} />
              <FormErrorBlock error={errors.lastName && errors.lastName.message } />
            </FormGroup>
          </Col>
          <Col sm={4} xs={12}>
            <FormGroup>
              <FormControl type="text" 
                placeholder={rPax.Email + ' *'}
                {...register('email', validator.email)} />
              <FormErrorBlock error={ errors.email && errors.email.message} />
            </FormGroup>
          </Col>
        </Row>

        <Clearfix />

        <div className="btnCon form-actions padt40">
          <div></div>
          <Button type="submit" className="btn-submit">{rTravelVoucher.FindVouchers}</Button>
        </div>
        </form>
      </div>
    </div>
  )
}