import { sessionService } from "../session/sessionService"
import { api } from "../api"

import { UserType } from "../../../constants/settings"

export const memberService = {
  login,
  register,
  getMyBookings,
  forgotPassword,
  getProfile,
  updateProfile,
  getEmployeeInfo,
  retrieveBooking,
  checkStaffTicketAllowance,
  activate,
}

function login(userName, password) {
  const data = {
      email: userName,
      password
    };

  return api.postFetch(`api/member/login`, data)
    .then(response => {
      saveUserData(response)
      sessionService.setCurrency();
      return response;
    })
}

function register(data) {
  return api.postFetch(`api/member/register`, data)
    .then(response => {
      saveUserData(response)
      return response;
    })
}

function getMyBookings() {
  return api.getFetch(`api/member/myBookings`)
}

function getEmployeeInfo() {
  return api.getFetch(`api/member/employeeInfo`)
}

function forgotPassword(email) {
  if(!email) {
      return Promise.reject("Invalid email!")
    }

    const data = { email }

    return api.postFetch(`api/member/forgotPassword`, data)
}

function getProfile() {
  return api.getFetch(`api/member/profile`)
}

function updateProfile(data) {
  return api.postFetch(`api/member/profile`, data)
}

function retrieveBooking(pnr) {
  const url = `api/member/retrieveBooking?pnr=${pnr}`

  return api.getFetch(url)
}

function checkStaffTicketAllowance() {
  const url = `api/member/StaffTicketAllowance`
  return api.getFetch(url)
}

function activate(token, errorHandler) {
  const url = `api/member/activate?token=${token}`
  return api.postFetch(url, undefined, errorHandler).then(response => {
    // error happened
    if(!response) return 

    saveUserData(response)
    return response
  })
}

// save the member status to session
function saveUserData(member) {
  const userData = {
    userName: member.userName,
    displayName: `${member.firstName} ${member.lastName}`,
    //profileId: response.profileId,
    userType: UserType.member, 
    loggedIn: true,
    isCorpCustomer: member.isCorpCustomer,
    isSAEmployee: member.isSAEmployee
  }
  sessionService.setUserData(userData);
}