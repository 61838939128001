import dayjs from "dayjs";
import { customerValidator } from ".";
import { ls } from "../locale";

export const profileValidator = {
  validateUserName,
  validateMobile,
  validatePhone,
  validateEmail,
  validateNames,
  validateDocumentType,
  validateDocumentNumber,
  validateDateOfBirth,
  validateExpiryDate,
  validatePhoneTyping
}

function validateUserName(value) {
  value = value ? value.trim() : '';
  if(!value) return customerValidator.requiredField('UserId');

  const regex = /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  if (!regex.test(value)) {
    const rError = ls.t('Error');
    return rError.UserIdEmail; //"User ID must be a valid email address";
  }
  return null;
}

function validateMobile(value) {
  value = value ? value.trim() : '';
  if(!value) return customerValidator.requiredField('MobileNumber');

  const regex = /^\d{5,20}$/;
  if(!regex.test(value)) return customerValidator.invalidField('MobileNumber');
  
  return null;
}

function validatePhone(value) {
  value = value ? value.trim() : '';
  if(!value) return null;

  const regex = /^\d{5,20}$/;
  if(!regex.test(value)) return customerValidator.invalidField('PhoneNumber');
  
  return null;
}

function validateEmail(value) {
  value = value ? value.trim() : '';
  if(!value) return customerValidator.requiredField('Email');

  const regex = /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  if (!regex.test(value)) return customerValidator.invalidField('Email');

  return null;
}

function validateNames(value) {
  value = value ? value.trim() : '';
  if(!value) return customerValidator.requiredField('Name');

  const regex = /^[A-Za-z ]+$/
  if(!regex.test(value)) return ls.t('Error.NameInEnglish');
}

function validateDocumentType(value) {
  if(!value) return customerValidator.requiredField('DocumentType');

  return null;
}

function validateDocumentNumber(value) {
  value = value ? value.trim() : '';
  if(!value) return customerValidator.requiredField('IDNumber');

  const regex = /^[A-Za-z0-9]+$/
  if(!regex.test(value)) return customerValidator.invalidField('IDNumber');
}

function validateDateOfBirth(value) {
  const dob = dayjs(value);
  const today = dayjs();

  if(today.diff(dob, 'year') < 13) return customerValidator.invalidField('DateOfBirth');

  return null;
}

function validateExpiryDate(value) {
  const exp = dayjs(value);
  const today = dayjs();

  if(exp.diff(today, 'day') <= 1) return customerValidator.invalidField('ExpirationDate');

  return null;
}

// validate if the value will be valid phone number
// true if it is still valid, otherwise false
function validatePhoneTyping(number) {
  const regex = /^\d{0,20}$/;
  return regex.test(number);
}