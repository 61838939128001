import React from 'react'

export function Section({title, expanded, onTitleClick, children}) {
  return (
    <div className={"help-section"}>
      <div className={"title"} onClick={onTitleClick}>{title}</div>
      <div className={`content ${expanded ? "expanded" : ""}`}>
        {children}
      </div>
    </div>
  )
}