import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import dayjs from "dayjs";
import { ls } from "../locale";

// localize the date picker
import { registerLocale, setDefaultLocale  } from "react-datepicker";
//import en from "date-fns/locale/en-US";
import ar from "date-fns/locale/ar-SA";

import { setSource } from "../helpers";

import { sessionActions } from "../state/ducks/session";
import { resourceActions } from "../state/ducks/resources";

function HtmlLocale({location, getCulture, setCulture, getSession, setSessionToken, getLocale, setUserData, hasToken, children }) {

  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const path = location.pathname;

    // redirect payment page to the correct language
    if(path === '/en/payment') {
      const culture = getCulture();
      if(culture && culture.indexOf('ar') === 0) {
        window.location.href = '/ar/payment' + location.search;
        return;
      }
    }
  }, [location.pathname])

  useEffect(() => {
    const path = location.pathname;

    // set the locale for dayjs and the date picker
    const lng = getLocaleFromPath(path);
    
    if (lng === "ar") {
      const css = import("../bootstrap-rtl.min.css");

      const updateLocale = require('dayjs/plugin/updateLocale')
      dayjs.extend(updateLocale)

      dayjs.updateLocale('en', {
        months: ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'],
        monthsShort: ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'],
        weekdays: ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],  // weeks start from Sunday
        weekdaysShort: ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
      })

      registerLocale('ar', ar);
      setDefaultLocale(lng); 
    }    
  }, [location.pathname])

  useEffect(() => {

    const search = location.search;
    const params = queryString.parse(search);

    if(params.source) setSource(params.source);

    if(params.token && params.token.length > 100) {

      // this is a session initilized by container/mobile apps
      setSessionToken(params.token);
      setUserData(null);
    }
    else {
      params.token = undefined;
    }

    const path = location.pathname;
    const lng = getLocaleFromPath(path);

    const locale = lng === 'ar' ? 'ar-OM' : 'en-US';
    setCulture(locale);
    
    getSession().then(result => {
      if(!result) return;

      getLocale().then(data => {
        //console.log('locale: ', data[locale]);
        ls.setLocale(data[locale], lng);
        setDocumentlocale();

        setLoading(false);
      });      
    });
  }, [])

  return (
    <>
    { loading 
        ? <div>Loading...</div> 
        : (!hasToken ? <div>Not authenticated</div> : children)
    }
    </>
  )
}

function setDocumentlocale() {
  if (!window || !window.document || !window.document.documentElement) {
    return;
  }
  const html = window.document.documentElement;
  html.lang = ls.t("rootClass");
  window.document.title = ls.t("Common.SiteName"); //"SalamAir | Book A Flight"; 
  document.getElementById("root").classList.add("lng-" + ls.t("rootClass"));
}

function getLocaleFromPath(path) {
  if (path === "/") {
    return "en";
  }
  let lng = path.split("/")[1];
  if(lng !== "ar" && lng !== "en") lng = "en";
  return lng;
}

function mapStateToProps(state) {
  return {
    hasToken: state.session.hasToken,
  };
}

const mapDispatchToProps = {
  getLocale: resourceActions.getLocale,
  ...sessionActions,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HtmlLocale));
