import React, { useState } from 'react';
import { TitlelessDialogBox } from '../../comps/dialog';
import { Segment } from '../../state/models';
import { Checkbox } from '../../comps/checkbox';

export function useFlightSelect() {
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState<string>("Change Flight")
  const [message, setMessage] = useState<string>("Choose the flight you want to change")
  const [notes, setNotes] = useState<string|undefined>()
  const [segments, setSegments] = useState<Segment[]>([])
  const [selected, setSelected] = useState<number[]>([])
  const [preSelected, setPreSelected] = useState<number[]>([])

  const [trigger, setTrigger] = useState<{
    reject: (v: any) => void;
    resolve: (v: any) => void;
  }>();

  function show(segments: Segment[], selected: number[] = [], title?: string, message?: string, notes?: string): Promise<number[]> {
    setSegments(segments)
    setSelected(selected)
    setPreSelected(selected)

    if(title) setTitle(title)
    if(message) setMessage(message)
    setNotes(notes);
    setOpen(true)

    return new Promise<number[]>((resolve, reject) => {
      setTrigger({ resolve, reject })
    })
  }

  // confirmed: a boolean to indicate if the user has confirmed the message
  function close(confirmed: boolean = false) {
    setOpen(false)
    if(trigger) {
      confirmed ? trigger.resolve(selected) : trigger.reject(false)
      setTrigger(undefined)
    }
  }

  function select(segment: Segment) {
    const flightId = segment.logicalFlightId

    if(!selected.includes(flightId)) {
      setSelected([...selected, flightId])
      return
    }
    
    // we don't remove pre selected flights
    if(preSelected.includes(flightId)) return;
    
    setSelected(selected.filter(id => id !== flightId))    
  }

  function isDisabled(segment: Segment) {
    return preSelected.length > 0 && preSelected.includes(segment.logicalFlightId)
  }

  return {
    open,
    show,
    segments,
    selected,
    title,
    message,
    notes,
    isDisabled,
    onSelect: select,
    onClose: close
  }
}


export function FlightSelect({open, onClose, segments, selected, title, message, notes, onSelect, isDisabled} 
  : {open: boolean, onClose: (confirmed?: boolean) => void, segments: Segment[], selected: number[], title: string, message: string, notes?: string, onSelect: (segment: Segment) => void , isDisabled: (segment: Segment) => boolean}) {

  return (
    <TitlelessDialogBox open={open} closeButtonText="Select" onClose={onClose} panelClass="tw-max-w-sm">
      <div className="tw-flex tw-flex-col tw-items-center tw-mb-6">
        <div><i className="picon picon-action" /></div>
        <div className="tw-px-2 sa-text-blue tw-text-lg">{title}</div>
      </div>
      <p className="sa-text-blue">{message}</p>
      <div className="tw-flex tw-flex-col tw-gap-2 tw-px-2">
        {segments.map(segment => (
          <Checkbox key={segment.logicalFlightId}
            checked={selected.includes(segment.logicalFlightId)}
            disabled={typeof isDisabled === 'function' ? isDisabled(segment) : false}
            onChange={() => onSelect(segment)}
          >
            <span className='tw-font-bold sa-text-green'>{`${segment.originCity} - ${segment.destinationCity}`}</span>
          </Checkbox>
        ))} 
      </div>
      {notes && <p className="tw-mt-4 tw-text-xs">{ notes }</p> }
    </TitlelessDialogBox>
  )
}