import { api } from "../api";
import { getClientWindowSize } from "../helper";

export const paymentService = {
  getFingerprint,
  authorizeCS,
  check3DSEntrollment,
  check3DSValidation,
  makeVoucherPayment,
  makeOmanDebitPayment,
  makeAGPayment,
  makePLPayment,
  makeFTPayment,
  makeCashPayment,
  checkOmanDebitPayment,
  getPaymentTotal,
  convertCurrency,
  getPaymentMethods,
  getMadaPaymentParams,
  getKnetPaymentParams,
  getSmartPayParams
};

function getFingerprint(eppSelected) {
  let url = `api/payment/getFingerprint`
  if(eppSelected)  url += `?eppSelected=true`
  
  return api.getFetch(url)
}

function authorizeCS(details) {

  const data = {
      clientWindowSize: getClientWindowSize(),
      paymentDetail: details
    };

   return api.postFetch(`api/payment/authorizeCS`, data)
}

function check3DSEntrollment(details, cardinalEventData) {
  const data = {
      clientWindowSize: getClientWindowSize(),
      paymentDetail: details,
      cardinalEventData
    };

  return api.postFetch(`api/payment/EnrollmentCheck`, data)
}

function check3DSValidation(details) {
  const data = {
      clientWindowSize: getClientWindowSize(),
      paymentDetail: details
    };

  return api.postFetch(`api/payment/TDSValidation`, data)
}
function makeVoucherPayment(details) {
  return api.postFetch(`api/payment/voucherpay`, details)
}

function makeOmanDebitPayment() {
  return api.getFetch(`api/payment/onpay`)
}


function makeAGPayment() {
  return api.postFetch(`api/payment/agpay`)
}

function makePLPayment() {
  return api.postFetch(`api/payment/paylater`)
}

function makeFTPayment() {
  return api.postFetch(`api/payment/freeticket`)
}

function makeCashPayment(request) {
  return api.postFetch(`api/payment/cash`, request)
}

function checkOmanDebitPayment(reference) {
  return api.postFetch(`api/payment/onpay?request=${reference}`)
}

function getPaymentTotal(methodCode) {
  const data = {
      methodCode
    };

   return api.getFetch(`api/payment/paymenttotal`, data)
}

function convertCurrency(data) {
  return api.getFetch(`api/payment/convertCurrency`, data)
}

function getPaymentMethods() {
  return api.getFetch(`api/payment/paymentMethods`)
}

function getMadaPaymentParams() {
  return api.getFetch(`api/payment/madapay`)
}

function getKnetPaymentParams() {
  return api.getFetch(`api/payment/knetpay`)
}

function getSmartPayParams() {
  return api.getFetch(`api/payment/SmartPay`)
}