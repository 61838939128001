import React from 'react'
import { Modal } from 'react-bootstrap'

import { 
  FacebookShareButton, FacebookIcon,
  WhatsappShareButton, WhatsappIcon,
  TwitterShareButton, TwitterIcon,
  LinkedinShareButton, LinkedinIcon,
  RedditShareButton, RedditIcon,
  TelegramShareButton, TelegramIcon
} from 'react-share'

import './index.scss'

export default function SocialShare({url, title, show, onHide, className}) {

  if(!show) return null;

  if(!url) url = "https://www.salamair.com"
  const size = 32
  const round = true
  
  return (
    <Modal className={`social-share ${className}`} show={true} onHide={onHide}>
      <Modal.Body>
        <FacebookShareButton url={url} quote={title}>
          <FacebookIcon size={size} round={round} />
        </FacebookShareButton>
        <WhatsappShareButton url={url} title={title}>
          <WhatsappIcon size={size} round={round} />
        </WhatsappShareButton>
        <TwitterShareButton url={url} title={title}>
          <TwitterIcon size={size} round={round} />
        </TwitterShareButton>
        <LinkedinShareButton url={url} title={title}>
          <LinkedinIcon size={size} round={round} />
        </LinkedinShareButton>
        <RedditShareButton url={url} title={title}>
          <RedditIcon size={size} round={round} />
        </RedditShareButton>
        <TelegramShareButton url={url} title={title}>
          <TelegramIcon size={size} round={round} />
        </TelegramShareButton>
      </Modal.Body>
    </Modal>
  )
}