import dayjs from "dayjs"
import { profileValidator as validator, customerValidator } from "../../../helpers";

export const initialState = {
  values: {
    userName: '',
    password: '',
    confirmPassword: '',
    title: 'MR',
    firstName: '',
    lastName: '',
    dateOfBirth: dayjs().format('YYYY-MM-DD'),
    country: { label: "Country of Residence *", value: "" },
    mobile: '',
    mobileCountry: { label: "968", value: "Oman" }
  },
  errors: {}
}

export function validateValues(values) {
  
  const errors = {}
  
  errors.userName = validator.validateEmail(values.userName)
  errors.password = customerValidator.validatePassword(values.password)
  
  if(!errors.password) {
    if(values.password && values.password !== values.confirmPassword){
      errors.confirmPassword = "Confirm password doesn't match"
    }
  }
  errors.firstName = validator.validateNames(values.firstName)
  errors.lastName = validator.validateNames(values.lastName)

  errors.mobile = validator.validateMobile(values.mobile)

  if(!values.country.value) {
    errors.country = "Country of Residence is required"
  }

  return errors
}
