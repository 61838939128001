import React from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip";
import {infoCircle} from "react-icons-kit/fa/infoCircle";
import Icon from "react-icons-kit/Icon";
import { ls } from "../../../locale";

export function FareDetails({fareTypeName, isShowExtra, hasFreeLounge, logicalFlightId, promotions}) {
  if(!fareTypeName) return null;
  
  fareTypeName = fareTypeName.replace(/\s/, '');
  // if(!data || !data.fareTypeName) return null;

  const rFareType = ls.t('FareType');
  const fare = rFareType[fareTypeName];
  if(!fare) return null;

  const rFareRule = ls.t('FareRule');

  if(typeof fare.ChangeFlight.Desc === 'string') fare.ChangeFlight.Desc = fare.ChangeFlight.Desc.split(/[\r\n]/);
  if(typeof fare.CancelFlight.Desc === 'string') fare.CancelFlight.Desc = fare.CancelFlight.Desc.split(/[\r\n]/);

  return (
    <React.Fragment>
      <div className="titleCon clearfix">
        { fare.Label}
      </div>
      <div className="tagsCon"> 
        { Array.isArray(promotions) && promotions.map((promotion, index) => <span key={index}>{promotion}</span>)}
      </div>
      <div className="extraCon extra-1">
        <span className="hidden-md hidden-lg hidden-sm pull-left">
          <i className="picon picon-handluggage"/>
           {rFareRule.HandLuggage.Label}
        </span>
        {fare.HandLuggage.Label}
      </div>
      <div className="extraCon extra-2">
        <span className="hidden-md hidden-lg hidden-sm pull-left">
          <i className="picon picon-luggage"/>
          {rFareRule.CheckInLuggage.Label}
        </span>
        { (!fare.CheckInLuggage.Label || fare.CheckInLuggage.Label === 'X') ? <i className="picon picon-cross"/> : fare.CheckInLuggage.Label}
      </div>
      {isShowExtra && <div className="extraCon extra-3">
        <span className="hidden-md hidden-lg hidden-sm pull-left">
          <i className="picon picon-seat"/>
          {rFareRule.Extra.Label}
        </span>
        { (!fare.Extra.Label || fare.Extra.Label === 'X') ? <i className="picon picon-cross"/> : fare.Extra.Label + ' '}
        { fare.Extra.Desc && <Icon icon={infoCircle} data-tip data-tooltip-id={"extra" + logicalFlightId + fareTypeName} data-type="error"/> }
      </div>}
      <div className="extraCon extra-4">
        <span className="hidden-md hidden-lg hidden-sm pull-left">
          <i className="picon picon-change"/>
          {rFareRule.ChangeFlight.Label}
        </span>
        {fare.ChangeFlight.Policy} <Icon icon={infoCircle} data-tip data-tooltip-id={"changePolicy" + logicalFlightId + fareTypeName} data-type="error"/>
      </div>
      <div className="extraCon extra-5">
        <span className="hidden-md hidden-lg hidden-sm pull-left">
          <i className="picon picon-cancellation"/>
          {rFareRule.CancelFlight.Label}
        </span>
        {fare.CancelFlight.Policy} <Icon icon={infoCircle} data-tip 
          data-tooltip-id={"cancellationPolicy" + logicalFlightId + fareTypeName} 
          data-type="error"/>
      </div>

      <ReactTooltip id={"changePolicy" + logicalFlightId + fareTypeName} className={"fare-tooltip " + ls.t('rootClass')}>
        { Array.isArray(fare.ChangeFlight.Desc) && fare.ChangeFlight.Desc.map((detail, index) => <p key={index}>{detail}</p>)}
      </ReactTooltip>
      <ReactTooltip id={"cancellationPolicy" + logicalFlightId + fareTypeName} className={"fare-tooltip " + ls.t('rootClass')}>
        {Array.isArray(fare.CancelFlight.Desc) && fare.CancelFlight.Desc.map((detail, index) => <p key={index}>{detail}</p>)}
      </ReactTooltip>
      <ReactTooltip id={"extra" + logicalFlightId + fareTypeName} className={"fare-tooltip " + ls.t('rootClass')}>
        {/* <p>{fare.Extra.Desc}</p> */}
        {hasFreeLounge ? rFareRule.Lounge.Desc : fare.Extra.Desc}
      </ReactTooltip>
    </React.Fragment>
  )
}