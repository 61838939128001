import React, { Component } from "react";
import { connect } from "react-redux";
import { ls } from "../../locale";
import { ListGroup, Button } from "react-bootstrap";

import "./index.scss";

import { bookingActions } from "../../state/ducks/booking";
import { sessionActions } from "../../state/ducks/session";
import { wciActions } from "../../state/ducks/wci";
import { agentActions } from "../../state/ducks/agent";
import { UserType } from "../../constants/settings";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import StatusBar from "../../components/StatusBar";
import FlightDetail from "./FlightDetail";
import AlertBox from "../../components/Common/AlertBox";
import RatingBar from '../../components/RatingBar';
import { ExpressCheckinBar } from "../../components/ExpressCheckin";
import { TransactionDetails } from "./TransactionDetails";
import {history, isInAppBrowser, webkitHelper, commonHelpers, stringFormatter } from "../../helpers";
import { mmbActions } from "../../state/ducks/mmb";
import Backdrop from "../../components/Backdrop";
import { isAllowPrintItinerary } from "../../helpers/userHelper";

const ratingKey = 'Feedback.Booking.Items.Q1'
const feedbackKey = 'Feedback.Booking.Thanks'
// const agent = commonHelpers.checkBrowser();

class Confirmation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rated: 0,
      expressCheckinSegments: [],
    };
  }

  componentDidMount() {

    // const lng = this.props.match.params.lng;

    // if (lng === "ar") {
    //   const css = import("../../bootstrap-rtl.min.css");
    // }

    this.props.getBookingWithAllSegments().then(booking => {

      // check if the page is loaded unintentionally by the user
      if (!booking?.pnr) {
        this.jumpTo('notfound');
        return;
      }

      if(booking.bookingMode === 'Booking') {
        webkitHelper.sendBookingConfirmation(booking);
      }
      // disable express check-in
      this.props.checkExpressCheckin().then(response => {
        if(response && Array.isArray(response.segments) && response.segments.length > 0) {
          this.setState({
            expressCheckinSegments: response.segments
          })
        }
      })

      this.props.getBookingSummary();
    });
    
    this.resetAgentBookingHistory();
    this.props.setCurrency();
  }

  printItinerary = () => {
    this.props.printItinerary().then(response => {
      //console.log('ticket loaded.');
      commonHelpers.createDownloadTag(response, 'ticket.pdf');
    })

  }

  printItineraryWithoutPrice = () => {
    this.props.printItineraryWithoutPrice().then(response => {
      //console.log('ticket loaded.');
      commonHelpers.createDownloadTag(response, 'ticket-without-price.pdf');
    })

  }

  getPKPassFile = () => {
    this.props.getPKPassFile()
    .then(blob => {
      //console.log('ticket loaded.');
      commonHelpers.createDownloadTag(blob, 'salamair.pkpass', 'application/vnd.apple.pkpass');
    })
  }
  

  resetAgentBookingHistory = () => {
    const userData = this.props.getUserData();
    if(userData && userData.userType === UserType.agent) {
      this.props.resetAgentBookingHistory();
    }
  }

  feedback = rating => {
    if(!rating) return;

    const data = {
      items: [{
        key: ratingKey,
        rating
      }]
    }

    this.props.feedback(data).then(() => {
      //console.log('rated');
      this.setState({
        rated: rating,
      })
    })
  }
  

  expressCheckin = logicalFlightId => {
    const booking = this.props.booking
    if(!booking || !Array.isArray(booking.segments) || !booking.segments.some(x => x.logicalFlightId === logicalFlightId)) return;
    if(!Array.isArray(booking.passengers) || !booking.passengers.length) return;

    const segment = {
      logicalFlightId,
    }

    this.props.expressCheckin([segment]).then(() => {
      this.props.setBookingMode({mode: "WCI", expressCheckin: 'Booking', segments: [segment]})
      this.jumpTo('wci/passenger')
    })

  }

  manageBooking = () => {
    const {booking} = this.props
    if(!booking || booking.bookingStatus !== 'Confirmed') return;

    if(!Array.isArray(booking.passengers) || !booking.passengers.length) return;
    const pax = booking.passengers[0]

    this.props.mmbRetrieveBooking(booking.pnr, pax.lastName)
    .then(() => {
      this.props.setBookingMode({
        mode: 'MMB'
      });

      this.jumpTo('mmb/summary')
    })
  }

  jumpTo = page => {
    if (!page) return;

    const nextPage = "/" + page;
    history.push("/" + ls.lang + nextPage);
  };

  render() {

    const rConfirmationPage = ls.t('ConfirmationPage');
    if(!rConfirmationPage.BookingStatus) return null;

    const ratingTitle = ls.t(ratingKey) || 'RATE YOUR BOOKING EXPERIENCE?';
    const ratingMessage = ls.t(feedbackKey) || 'THANK YOU FOR YOUR FEEDBACK';

    const booking = this.props.booking || {};
    const passengers = booking.passengers || [];

    const rBookingStatus = ls.t('BookingStatus');
    const rButton = ls.t('Button');
    let bookingStatus = booking.bookingStatus; 
    if(bookingStatus && rBookingStatus[bookingStatus]) bookingStatus = rBookingStatus[bookingStatus];

    const holdBooking = stringFormatter.formatHoldTime(booking, rConfirmationPage.HoldTime);
    const pendingBooking = booking.pendinglMessages || [];

    const inAppBrowser = isInAppBrowser();
    const agent = commonHelpers.checkBrowser();

    const userData = this.props.getUserData();
    const isAllowItinerary = isAllowPrintItinerary(userData);

    return (
      <div className={ ls.t("rootClass") + " main-container" + (inAppBrowser ? " in-mobile-app" : "")} dir={ls.t("direction")}>
        <div className="header-con">
          <Header hasSummary={true} />
        </div>
        <Backdrop />
        { !inAppBrowser && <StatusBar path={this.props.match} label="confirmation" /> }
        <div className="container confirmation padt20 padb40">
          <div className="min-height">
            <div className="booking-reference">
              <p>{rConfirmationPage.BookingReference}</p>
              <h2>{this.props.booking.pnr}</h2>
            </div>
            <h1>{rConfirmationPage.FlightDetails}</h1>
            <p className="booking-status">{rConfirmationPage.BookingStatus} <span>{bookingStatus}</span></p>
            <AlertBox key={booking.pnr + '-pending'} show={pendingBooking.length > 0} closeButton>
                {pendingBooking.map((line, index) => <p key={index}>{line}</p>)}
            </AlertBox>
            <AlertBox key={booking.pnr + '-hold'} show={holdBooking} closeButton>
                {holdBooking}
            </AlertBox>
            <AlertBox key={booking.pnr + '-confirm'} show={booking.bookingStatus === 'Confirmed'} closeButton>
                <p>{rConfirmationPage.Statement.Title}</p>
                <p>{rConfirmationPage.Statement.Desc}</p>
                {Array.isArray(booking.additionalMessages) && booking.additionalMessages.map((line, index) => <p key={index}>{line}</p>)}
            </AlertBox>
            <TransactionDetails booking={booking} />
            <ListGroup>
              {
                this.props.booking.segments && this.props.booking.segments.map((segment, index) => (
                  <React.Fragment key={segment.logicalFlightId}>
                    <FlightDetail key={index} passengers={passengers} segment={segment} />
                    {this.state.expressCheckinSegments.some(x => x.logicalFlightId === segment.logicalFlightId) && 
                      <ExpressCheckinBar onClick={() => this.expressCheckin(segment.logicalFlightId)} />
                    }
                  </React.Fragment>
                ))
              }
            </ListGroup>
            <div className={"clearfix btnCon confirmation " + ls.t("rootClass")}>
              { agent.safariAgent && <div className="clickable btn-apple-wallet" onClick={this.getPKPassFile}>
                <img width="160" src="/images/email/apple-wallet-2x.png" alt="Add to Apple Wallet" />
              </div> }
              <Button type="submit" className="btn btn-primary pull-right flip btn-submit" 
                onClick={this.printItinerary}>{rButton.PrintItinerary}</Button>
              { isAllowItinerary && <Button type="submit" 
                className="btn btn-primary pull-right flip btn-submit" 
                onClick={this.printItineraryWithoutPrice}>{rButton.PrintItineraryWithoutPrice}</Button> }
              { !inAppBrowser && booking && booking.bookingStatus === 'Confirmed' && !isShareCodeBooking(booking) && 
                <Button type="submit" className="btn btn-primary pull-right flip btn-submit" onClick={this.manageBooking}>
                  {rButton.ManageBooking || "Manage Booking"}
                </Button> }
            </div>
            <div className={"socialCon" + (inAppBrowser ? ' web-view' : '' )}>
              <RatingBar size={24} title={ratingTitle} message={ratingMessage} rated={this.state.rated}  onRate={this.feedback} />
            </div>
          </div>
        </div>
        { !inAppBrowser && <Footer /> }
      </div>
      
    );
  }
}


function mapStateToProps(state) {
  return {
    booking: state.booking.details,
  }
}

const mapDispatchToProps = {
  ...bookingActions,
  resetAgentBookingHistory: agentActions.resetBookingHistory,
  getUserData: sessionActions.getUserData,
  setCurrency: sessionActions.setCurrency,
  setBookingMode: sessionActions.setBookingMode,
  checkExpressCheckin: wciActions.checkExpressCheckin,
  expressCheckin: wciActions.expressCheckin,
  mmbRetrieveBooking: mmbActions.retrieveBooking,
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);


function isShareCodeBooking(booking) {
  return Array.isArray(booking?.segments) && booking.segments.some(x => x.isShareCode);
}