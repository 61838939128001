import types from "./types";
import {actions as bookingActions } from "../booking/actions";
import { flightService } from "./flightService";
import { sessionService } from "../session/sessionService";
import { buildSearchParams } from "../../../helpers";

export default {
  getFlights,
  setBookingDate,
  setFareSellKey,
  getSearchRequest,
  setPriceAlert,
  checkSpecialFares,
};

function getFlights(search) {
  return (dispatch) => {
    dispatch(flightRequest());
    return flightService
      .getFlights(search)
      .then(response => {
        dispatch(flightSearchResponse(JSON.stringify(search)));
        dispatch(flightResponse(response));
        dispatch(bookingActions.ancillaryResponse([]));
        dispatch(bookingActions.bookingResponse({}));
        dispatch(bookingActions.summaryResponse({}));

        if(response.searchRequest) {
          const prevParams = sessionService.getSearchParams()
          const searchParams = buildSearchParams(response.searchRequest)
          sessionService.setSearchParams({ ...prevParams, ...searchParams })
        }

        return response;
      });
  };
}

function setBookingDate(data){
  return dispatch => {
    dispatch(setDateRequest(data));
  };
}

function setFareSellKey( key ){
  return dispatch => {
    return flightService.setFareSellKey( key ).then( response => {
      dispatch(bookingActions.ancillaryResponse([]));
      if(response.booking)
        dispatch(bookingActions.bookingResponse(response.booking));
      if(response.summary)
        dispatch(bookingActions.summaryResponse(response.summary));
      return response;
    })
  }
}

function getSearchRequest() {
  return () => flightService.getSearchRequest();
}

function setPriceAlert(request) {
  return () => flightService.setPriceAlert(request);
}

function checkSpecialFares() {
  return () => flightService.checkSpecialFares();
}


function setDateRequest(payload) {
  return { type: types.SET_BOOKINGDATE,payload:payload};
}

function flightRequest() {
  return { type: types.FLIGHT_REQUEST};
}

function flightResponse(payload) {
  return { type: types.FLIGHT_RESPONSE,payload:payload };
}

function flightSearchResponse(payload) {
  return { type: types.FLIGHT_SEARCH_RESPONSE, payload };
}




