import { api } from "../api";

export const flightService = {
  getFlights,
  setFareSellKey,
  getSearchRequest,
  setPriceAlert,
  checkSpecialFares,
};

function createSearchUrl(data) {

  const routes = data.route.split("|");
  const counts = data.counts.split("-");
  const dates = data.dates.split("|");
  const days = typeof (data.days) === 'number' ? data.days : 7;

  let url = `TripType=${getFlightType(data.flightType)}` +
    `&${getRoutes(routes)}` +
    `&${getDates(dates, data.flightType)}` +
    `&AdultCount=${counts[0]}` +
    `&ChildCount=${counts[1]}` +
    `&InfantCount=${counts[2]}` +
    `&extraCount=${counts[3] || '0'}` +
    `&days=${days}`;

  if(data.promoCode) url += `&PromoCode=${data.promoCode || ''}`
  if(data.source) url += `&AgentId=${data.source || ''}`
  if(data.currencyCode) url += `&currencyCode=${data.currencyCode}`
  if(data.deviceId) url += `&deviceId=${data.deviceId}`;
  if(data.fare) url += `&fare=${data.fare}`;

  return url;
}

function getRoutes(routes) {
  if (!routes.length) return null;

  let route = routes[0].split("-");
  let str = `OriginStationCode=${route[0]}&DestinationStationCode=${route[1]}`;
  if (routes.length > 1) {
    route = routes[1].split("-");
    str += `&OriginStationCode2=${route[0]}&DestinationStationCode2=${route[1]}`;
  }

  return str;
}

function getDates(dates, flightType) {
  let str = `DepartureDate=${dates[0]}`;
  if (dates.length > 1) {
    if (flightType === "multi") {
      str += `&DepartureDate2=${dates[1]}`;
    }
    else {
      str += `&ReturnDate=${dates[1]}`;
    }
  }

  return str;
}

function getFlightType(str) {
  switch (str) {
    case "multi": return "3";
    case "round": return "2";
    case "oneway": return "1";
    default: return "2";
  }
}

function getFlights(search) {
  const searchUrl = createSearchUrl(search);
  return api.getFetch(`api/flights?${searchUrl}`)
}

function setFareSellKey(sellKey) {
  const requestData = {
      sellKey
    }
    // var requestData = key;
    return api.postFetch(`api/flights`, requestData)
}

function getSearchRequest() {
  return api.getFetch(`api/flights/searchRequest`)
}

function setPriceAlert(request) {
  return api.postFetch(`api/flights/priceAlert`, request)
}

function checkSpecialFares() {
  const url = `api/flights/specialFares`
  return api.getFetch(url)
}