import React from 'react'
import { ls } from "../../locale";

import Header from "../Header";
import Footer from "../Footer";
import Backdrop from '../Backdrop';

export default function BasePage({mainContainerClass = '', containerClass= '', children}) {


  return (
    <div id="main-container" className={ls.t("rootClass") + " main-container " + mainContainerClass} dir={ls.t("direction")}>
      <div className="header-con">
        <Header />
      </div>
      <Backdrop />
      <div className={"container padt40 padb20 " + containerClass}>
        {children}
      </div>
      <Footer />
    </div>
  )
}
