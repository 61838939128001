import { numberFormatter } from "../../../helpers";

export const initialState = {
  isShowMealMenuBox: false,
  currentPassenger: null,
  currentPhysicalFlightId: 0
}

export function reducer(state, action) {
  switch (action.type) {
    case 'SHOW_MEALS_MENU':
      return {
        isShowMealMenuBox: true,
        currentPassenger: action.passenger,
        currentPhysicalFlightId: action.physicalFlightId
      }
    case 'HIDE_MEALS_MENU':
      return {
        isShowMealMenuBox: false,
        currentPassenger: null,
        currentPhysicalFlightId: 0
      }
    default:
      return state
  }
}


export function calculateTotalAmount(selection) {
  let total = 0;
  selection && selection.forEach(flight => {
    flight.passengers.forEach(pax => {
      if(!Array.isArray(pax.meals)) return;
      pax.meals.forEach(meal => {
        if(meal.selected) total += meal.amount;
      });
    });
  });

  return numberFormatter.formatCurrency(total);
}

export function clearMealSelection(selection) {
  if(!Array.isArray(selection)) return 0;

  let cleared = 0;
  selection.forEach(flight => {
    flight.passengers.forEach(pax => {
      if (!Array.isArray(pax.meals)) return;
      pax.meals.forEach(meal => {
        if (meal.removable) {
          meal.selected = false;
          cleared++;
        }
      });
    });
  });
  return cleared;
}

export function getChangedMeals(booking, selection) {
  const changed = [];

  if (!booking || !Array.isArray(selection)) return;

  selection.forEach(flight => {

    const segment = booking.segments.find(s => s.logicalFlightId === flight.logicalFlightId)
    if (!segment) return

    flight.passengers.forEach(pax => {
      if (!Array.isArray(pax.meals)) return;

      segment.legs.forEach(leg => {
        const prevSelected = Array.isArray(pax.selectedMeals) && pax.selectedMeals.find(m => m.physicalFlightId === leg.physicalFlightId);
        const selected = pax.meals.find(m => m.selected && m.physicalFlightId === leg.physicalFlightId);

        if (prevSelected) {
          if (!selected) { // removed
            changed.push({
              passengerId: pax.id,
              logicalFlightId: flight.logicalFlightId,
              physicalFlightId: leg.physicalFlightId,
              prevSsrCode: prevSelected.ssrCode,
              ssrCode: null
            })
          }
          else if (prevSelected.ssrCode !== selected.ssrCode) { // changed
            changed.push({
              passengerId: pax.id,
              logicalFlightId: flight.logicalFlightId,
              physicalFlightId: leg.physicalFlightId,
              prevSsrCode: prevSelected.ssrCode,
              ssrCode: selected.ssrCode
            })
          }
        }
        else if (selected) { // added
          changed.push({
            passengerId: pax.id,
            logicalFlightId: flight.logicalFlightId,
            physicalFlightId: leg.physicalFlightId,
            prevSsrCode: null,
            ssrCode: selected.ssrCode
          })
        }
      })
    });
  });

  return changed
}

export function selectMealForPassenger(currentPassenger, meal) {
  if (!currentPassenger || !Array.isArray(currentPassenger.meals)) return false;

  const paxMeals = currentPassenger.meals.filter(m => m.physicalFlightId === meal.physicalFlightId);

  if (meal.ssrCode) { // select/change meal
    paxMeals.forEach(m => {
      const currentSelected = Array.isArray(currentPassenger.selectedMeals) &&
        currentPassenger.selectedMeals.find(m => m.physicalFlightId === meal.physicalFlightId);
      const removable = !currentSelected || currentSelected.removable;

      if (m.ssrCode === meal.ssrCode) {
        m.selected = true;
        if (m.removable) m.removable = removable; // if the meal is not removable, then we should keep the value as is
      }
      else {
        m.selected = false;
        m.removable = true;
      }
    });
  }
  else { // remove meal
    paxMeals.forEach(m => {
      m.selected = false;
      m.removable = true;
    })
  }

  return true
}
