import React from "react";


import SeatRow from '../SeatRow';
import Heading from '../SeatRow/head';

const SeatMap = ( props ) => {

  let carbins = props.carbins;

  if(!Array.isArray(carbins) || carbins.length === 0 || !carbins.some(c => Array.isArray(c.seatRows) && c.seatRows.length > 0)) {
    carbins = getDefaultSeatMap()
  }

  return (
    <React.Fragment>
      <Heading />
      {
        carbins.map(carbin => carbin.seatRows.map(row => 
          <SeatRow key={row.rowNumber}
            carbin={carbin.carbinName}
            row={row} 
            selectedSeat={props.selectedSeat} 
            passenger={props.passenger} 
            onSeatClick={(seat) => props.onSeatClick({rowNumber: row.rowNumber, ...seat})} />
          )
        )
      }
    </React.Fragment>
  );  
}

const getDefaultSeatMap = () => {

  const seats = [];
  for(let i=0; i<=6; i++) {
    let seatNumber = String.fromCharCode(65 + i);
    if(i === 3) seatNumber = " ";
    seats.push({
      seatNumber,
      isAvailable: false,
    });
  }

  const rows = [];
  for(let i=0; i<29; i++) {
    rows.push({
      rowNumber: i+1,
      seats: seats
    })
  }

  return [{
    carbinName: 'ECONOMY',
    seatRows: rows
  }]
}

export default SeatMap;
