import React, { useState } from "react";
import dayjs from "dayjs";
import Icon from "react-icons-kit/Icon";
import { ReactSVG } from "react-svg";
import { user } from "react-icons-kit/fa/user";
import { suitcase } from "react-icons-kit/fa/suitcase";
import { chevronRight } from "react-icons-kit/fa/chevronRight";

import { ListGroupItem, Row, Col } from "react-bootstrap";

import { numberFormatter, stringFormatter } from "../../../helpers";
import { FlightDetailsWindow } from "../../../components/FlightDetailsWindow";

import { PassengerType } from "../../../constants/settings";

import SeatSVG from "../../../images/seat.svg";
import MealSVG from "../../../images/cmeal.svg";
import ListSVG from "../../../images/list.svg";

import "./index.scss";
import { ls } from "../../../locale";

export default function FlightDetail({ segment, passengers }) {

  const [showDetail, setShowDetail] = useState(false);

  const rFlight = ls.t('Flight');
  const rFlightDetails = ls.t('FlightDetails');
  if (!rFlight.Departure) return false;

  const rFareType = ls.t('FareType');

  if (!segment) return <div>{rFlight.NoFlight}</div>;

  const fare = segment.fares[0];

  const stops = segment.legs.length ? segment.legs.length - 1 : 0;
  const durationText = segment.flightTime > 0 ? numberFormatter.formatFlightTime(segment.flightTime, rFlight.Duration) : '';

  return (
    <React.Fragment>
      <ListGroupItem>
        <Row className="flight-info">
          <Col md={3}>
            <i className="picon picon-plane" />
            <span>{segment.originCity} {rFlight.To} {segment.destinationCity}</span>
          </Col>
          <Col md={5}>
            <p className="flight-date">{dayjs(segment.departureDate).format("ddd D MMM YYYY")}</p>
            <div className="flight-times row">
              <Col md={4} xs={4}>
                <p className="time">{dayjs(segment.departureDate).format("HH:mm")}</p>
                <p>{segment.originCode} - {rFlight.Departure}</p>
              </Col>
              <Col md={4} xs={4}>
                <i className={stops > 0 ? "picon picon-flights" : "picon picon-flight"} />
              </Col>
              <Col md={4} xs={4}>
                <p className="time">{dayjs(segment.arrivalDate).format("HH:mm")}</p>
                <p>{segment.destinationCode} - {rFlight.Arrival}</p>
              </Col>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="fare-name">{fare.fareTypeName ? rFareType[stringFormatter.removeSpace(fare.fareTypeName)].FullName : ''}</h4>
            <div className="fare-info">
              <span>{segment.carrierCode}{segment.flightNumber}</span>
              <span>{stops > 0 ? (stops + " " + rFlight.Stop) : rFlight.Direct}</span><span>{durationText}</span>
              <span className="details-span">
                <button className="link-button" onClick={() => setShowDetail(true)}> {rFlightDetails.Label}</button>
                <Icon icon={chevronRight} />
              </span>
            </div>
            {segment.operatingDisclosure && <div className="padt20 operating-message">{segment.operatingDisclosure}</div>}
          </Col>
        </Row>
        <Row className="passenger-info">
          <div className="col">
            <div className="heading">
              <h5><span><Icon icon={user} className="user-circle" /></span> {rFlightDetails.Heading.Passenger}</h5>
            </div>
          </div>
          <div className="col">
            <div className="heading">
              <h5><span><Icon icon={suitcase} /></span> {rFlightDetails.Heading.Baggage} </h5>
            </div>
          </div>
          <div className="col">
            <div className="heading">
              <h5>
                <span>
                  <ReactSVG src={SeatSVG} className="inline-icon" />
                </span>
                {rFlightDetails.Heading.Seat} </h5>
            </div>
          </div>
          <div className="col">
            <div className="heading">
              <h5>
                <span>
                  <ReactSVG src={MealSVG} className="inline-icon" />
                </span>
                {rFlightDetails.Heading.Meal} </h5>
            </div>
          </div>
          <div className="col">
            <div className="heading">
              <h5><span><ReactSVG src={ListSVG} className="inline-icon" /></span> {rFlightDetails.Heading.Other} </h5>
            </div>
          </div>
        </Row>
        {
          passengers && passengers.map((passenger, index) => {
            const seats = [];
            let isInSegment = false;
            if (Array.isArray(segment.legs)) {
              segment.legs.forEach(leg => {
                if (Array.isArray(leg.customers)) {
                  leg.customers.forEach(customer => {
                    if (customer.passengerId === passenger.id) {
                      isInSegment = true;
                      if (customer.seat) seats.push(customer.seat.rowNumber + customer.seat.seatNumber);
                    }
                  })
                }
              })
            }

            // if the passenger is not present in current segment, then skip.
            if (!isInSegment) return null;

            let title = passenger.title && passenger.title.value ? passenger.title.value : passenger.title;
            if (passenger.paxType === PassengerType.ExtraSeat) title = null;

            return (
              <Row key={index} className="passenger-info">
                <div className="col">
                  <div className="content">
                    <div className="passenger-data">{title} {passenger.firstName} {passenger.lastName}</div>
                  </div>
                </div>
                <div className="col">
                  <div className="baggage-data">
                    {Array.isArray(passenger.handBaggages) && passenger.handBaggages.map((bag, bagIndex) => {
                      return bag.logicalFlightId === segment.logicalFlightId ? <p key={bagIndex}>{bag.description}</p> : null
                    })}
                    {
                      Array.isArray(passenger.baggages) && passenger.baggages.map((bag, bagIndex) => {
                        if (bag.logicalFlightId === segment.logicalFlightId) {
                          return bag.ssrCode === 'XBAG'
                            ? <p key={bagIndex}>1 &times; {bag.label} ({rFlightDetails.notes40kgBaggage})</p>
                            : <p key={bagIndex}>1 &times; {bag.label}</p>
                        }
                        return null;
                      })
                    }
                  </div>
                </div>
                <div className="col">
                  <div className="seat-data">
                    {
                      seats.map((seat, seatIndex) => (
                        <div key={seatIndex}>{seat}</div>
                      ))
                    }
                  </div>
                </div>
                <div className="col">
                  <div className="meal-data">
                    {
                      passenger.paxType !== PassengerType.ExtraSeat && passenger.meals && passenger.meals.map((meal, mealIndex) => (
                        meal.logicalFlightId === segment.logicalFlightId && <p key={mealIndex}>{meal.label}</p>
                      ))
                    }
                  </div>
                </div>
                <div className="col">
                  <div className="other-data">
                    {
                      passenger.paxType !== PassengerType.ExtraSeat && passenger.insurances && passenger.insurances.map((insurance, insIndex) => (
                        insurance.logicalFlightId === segment.logicalFlightId && <p key={insIndex}>{insurance.label}</p>
                      ))
                    }
                    {
                      Array.isArray(passenger.lounges) && passenger.lounges.map((lounge, lngIndex) => (
                        lounge.logicalFlightId === segment.logicalFlightId && <p key={lngIndex}>{lounge.label}</p>
                      ))
                    }
                    {
                      Array.isArray(passenger.priorityCheckin) && passenger.priorityCheckin.map((service, lngIndex) => (
                        service.logicalFlightId === segment.logicalFlightId && <p key={lngIndex}>{service.label}</p>
                      ))
                    }
                    {
                      Array.isArray(passenger.omaniFares) && passenger.omaniFares.map((service, lngIndex) => (
                        service.logicalFlightId === segment.logicalFlightId && <p key={lngIndex}>{service.label}</p>
                      ))
                    }
                  </div>
                </div>
              </Row>
            )
          })
        }

      </ListGroupItem>
      {showDetail && <FlightDetailsWindow flight={segment} onClose={() => setShowDetail(false)} />}
    </React.Fragment>
  );
}

