import React from 'react'
import { ls } from "../../../locale";

export function Terms() {

  const rTravelVoucher = ls.t('TravelVoucher')

  const terms = rTravelVoucher.Terms.split(/[\r\n]/)

  return (
    <ul>
      { terms.map((term, index) => <li dangerouslySetInnerHTML={{__html: term}} key={index}></li>) }
    </ul>
  )
}