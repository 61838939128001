import React, { useEffect, useReducer, useCallback, useImperativeHandle, forwardRef } from "react";
import dayjs from "dayjs";
import Icon from "react-icons-kit/Icon";
import { user } from "react-icons-kit/fa/user";
import { Col, FormControl, FormGroup, Row } from "react-bootstrap";
import Dropdown from "react-dropdown";

import CountrySelect from "../../../../components/Common/CountrySelect";
import DateSelect from "../../../../components/Common/DateSelect";
import { passengerHelper } from "../../../../helpers";
import {  DocumentTypeList, PassengerType } from "../../../../constants/settings";

import "./index.scss";
import { ls } from "../../../../locale";
import FormErrorBlock from "../../../../components/FormErrorBlock";
import { reducer, initialState, getCountryOptions, validateApis, getValuesFromState } from "./reducer";


export default forwardRef(function Passenger({ passenger = {}, countries = [], flightDate = new Date() }, ref) {

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const apisInfo = passenger.apisInfo || {birthDate: passenger.dateOfBirth};
    const birthDate = dayjs(apisInfo.birthDate);
    if(!birthDate.isValid() || birthDate.year() <= 1900) apisInfo.birthDate = new Date();

    apisInfo.documentType = passengerHelper.getDocumentType(apisInfo.documentType);
    apisInfo.documentNumber = apisInfo.documentNumber || '';
    apisInfo.expiryDate = apisInfo.expiryDate || new Date();
    if(!apisInfo.citizenship) apisInfo.citizenship = 'OM';

    const values = {
      id: passenger.id || 0,
      title: passenger.title || 'MR',
      firstName: passenger.firstName || '',
      lastName: passenger.lastName || '',
      paxType: passenger.paxType,
      apisInfo
    }

    dispatch({ type: 'SET_VALUES_STATE', values })

  }, [])

  const setDocumentType = type => dispatch({ type: 'SET_APISINFO_STATE', key: 'documentType', value: type })
  const setDocumentNumber = number => dispatch({ type: 'SET_APISINFO_STATE', key: 'documentNumber', value: number })
  const setExpiryDate = date => dispatch({ type: 'SET_APISINFO_STATE', key: 'expiryDate', value: date })
  const setBirthDate = date => dispatch({ type: 'SET_APISINFO_STATE', key: 'birthDate', value: date })
  const setCitizenship = value => dispatch({ type: 'SET_APISINFO_STATE', key: 'citizenship', value })

  const validateForm = useCallback(() => {
    const errors = validateApis(state.values, flightDate, countries);
    
    dispatch({ type: 'SET_ERRORS_STATE', errors })
    return !Object.values(errors).some(x => !!x);

  }, [state.values, flightDate, countries])

  const getValues = useCallback(() => {
    return getValuesFromState(state.values)
  }, [state.values])

  useImperativeHandle(ref, () => ({
    validateForm,
    getValues
  }), [validateForm, getValues])


  // render

  const { values, errors } = state;
  const { apisInfo } = values; 

  const citizenship = apisInfo.citizenship;
  const citizenshipValue = getCountryOptions(citizenship, countries);

  let minBirthDate = dayjs(flightDate).add(-100, 'year').toDate();
  const maxExpiryDate = dayjs(flightDate).add(30, 'year').toDate();

  const rInput = ls.t('Input');

  if(values.paxType === PassengerType.Child) minBirthDate = dayjs(flightDate).add(-13, 'year');

  return (
    <div className="passenger-wrap">
      <Icon icon={user} className="passenger-icon" />
      <span className="passenger-name">{values.title} {values.firstName} {values.lastName}</span>
      <Row className="document-details">
        <Col md={4}>
          <div className="country-select">
            <Dropdown
              className={"document-select " + ls.t('rootClass') }
              options={DocumentTypeList}
              placeholder={rInput.DocumentType}
              value={apisInfo.documentType}
              onChange={option => setDocumentType(option)} />
          </div>
          <FormErrorBlock error={errors.documentType}/>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl
              type="text"
              placeholder={rInput.DocumentNumber}
              value={apisInfo.documentNumber} 
              onChange={event => setDocumentNumber(event.target.value)}/>
          </FormGroup>
          <FormErrorBlock error={errors.documentNumber} />
        </Col>
        <Col md={4} className="date-select-wrap">
          <DateSelect date={apisInfo.expiryDate} 
            minDate={flightDate} 
            maxDate={maxExpiryDate} 
            order="asc" 
            onDateChanged={date => setExpiryDate(date)} className={"date-select " + ls.t('rootClass')}>
            <span className="birthday-label">{rInput.ExpiryDate} <span className="mandatory">*</span></span>
          </DateSelect>
          <FormErrorBlock error={errors.expiryDate}/>
        </Col>
      </Row>
      <Row className='document-details'>
        <Col md={4} className="date-select-wrap">            
          <div className="country-select">    
            <span className="birthday-label">{rInput.Nationality} <span className="mandatory">*</span></span>          
            <CountrySelect
              className="country-control"
              options={countries}
              selected={citizenshipValue}
              onSelected={(selected) => setCitizenship(selected.value)} 
              />                
          </div>            
        </Col>
        <Col md={4} className="date-select-wrap">
            <DateSelect date={apisInfo.birthDate} 
              minDate={minBirthDate} 
              maxDate={flightDate} 
              onDateChanged={date => setBirthDate(date)} 
              className={"date-select " + ls.t('rootClass')}>
              <span className="birthday-label">{rInput.DateOfBirth} <span className="mandatory">*</span></span>
            </DateSelect>
            <FormErrorBlock error={errors.birthDate} />
        </Col>

      </Row>
    </div>
  );

})



