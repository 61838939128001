import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from "react-bootstrap";
import { history } from '../../../helpers'
import { ls } from '../../../locale'

import BasePage from '../../../components/BasePage'
import SigninForm from './SigninForm'
import SignupForm from './SignupForm'
import './index.scss'

import { memberActions } from "../../../state/ducks/member";

function SignupPage({forgotPassword}) {

  const [tab, setTab] = useState('signin')
  const [user, setUser] = useState()
  const [messageBox, setMessageBox] = useState({show: false})
  const [passwordBox, setPasswordBox] = useState({show: false})

  const jumpTo = next => {
    history.push("/" + ls.lang + next);
  }

  const handleCreateAccount = () => {
    setTab('signup')
  }

  
  const handleSignedUp = ({user, loggedIn}) => {
    if (loggedIn) {
      jumpTo("/member/portal")
      return
    }
    // switch to sign in tab
    setUser(user)
    setTab('signin')
  }

  const handleSignedIn = () => {
    jumpTo("/member/portal")
    return
  }

  const handleForgotPassword = data => {
    const rLoginPage = ls.t('Member.LoginPage');
    showPasswordBox(rLoginPage.ResetPassword, data.email)
  }

  const showMessageBox = (message, title) => {
    setMessageBox({
      show: true,
      message,
      title: title ?? 'Notification'
    })
  }

  const closeMessageBox = () => {
    setMessageBox({
      show: false
    })
  }

  const showPasswordBox = (message, email) => {
    setPasswordBox({
      show: true,
      message,
      email,
    })
  }

  const closePasswordBox = () => {
    setPasswordBox({
      show: false
    })
  }

  const resetPassword = () => {
    closePasswordBox();

    const email = passwordBox.email;
    if (!email) return;

    const rLoginPage = ls.t('Member.LoginPage');
    forgotPassword(email).then(() => {
      showMessageBox(rLoginPage.PasswordSent);
    })
  }

  const rButton = ls.t('Button')

  return (
    <BasePage containerClass="mobile-view">
      <div className="page-container">
        <div className="card-container">
          <div className="card-content">
            <div className="heading">SalamAir Members</div>
            <div className="card-tabs">
              <div className={"card-tabs-item " + (tab === 'signin' ? 'active' : '')} onClick={() => setTab('signin')}>Sign in</div>
              <div className={"card-tabs-item " + (tab === 'signup' ? 'active' : '')} onClick={() => setTab('signup')}>Create account</div>
            </div>

            {tab === 'signin' && <SigninForm user={user} 
              onSignedIn={handleSignedIn} 
              onForgotPassword={handleForgotPassword} 
              onCreateAccount={handleCreateAccount} />}
            {tab === 'signup' && <SignupForm onSignedUp={handleSignedUp} onShowMessage={showMessageBox} />}
          </div>
        </div>
      </div>

      <Modal className={ls.t("rootClass")} show={messageBox.show} onHide={closeMessageBox}>
        <Modal.Header closeButton>
          <Modal.Title>{messageBox.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Array.isArray(messageBox.message) 
            ? messageBox.message.map((m, index) => (<p key={index}>{m}</p>)) 
            : <p>{messageBox.message}</p> }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeMessageBox}>{rButton.Close}</Button>
        </Modal.Footer>
      </Modal>

      <Modal className={ls.t("rootClass")} show={passwordBox.show} onHide={closePasswordBox}>
        <Modal.Header closeButton>
          <Modal.Title>{'Warning'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{passwordBox.message}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={resetPassword}>{rButton.Yes}</Button>
          <Button onClick={closePasswordBox}>{rButton.No}</Button>
        </Modal.Footer>
      </Modal>
    </BasePage>
  )
}

function mapStateToProps() {
  return {
  }
}

const mapDispatchToProps = {
  ...memberActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);