import React from "react";
import { ls } from "../../locale";

import "./index.scss";
import { isInAppBrowser } from "../../helpers";

export default function StatusBar(props) {
  
  const navItems = [
    {
      order: 1,
      label: 'flights',
      title: ls.t('BookingStep.Flights') //  'Flights'
    },
    {
      order: 2,
      label: 'extra',
      title: ls.t('BookingStep.Extras') //  'Extras'
    },
    {
      order: 3,
      label: 'seats',
      title: ls.t('BookingStep.Seats') //  'Seats'
    },
    {
      order: 4,
      label: 'checkout',
      title: ls.t('BookingStep.CheckOut') //  'Check out'
    },
    {
      order: 5,
      label: 'confirmation',
      title: ls.t('BookingStep.Confirmation') //  'Confirmation'
    },
  ]

  const inAppBrowser = isInAppBrowser();

  const label = props.label;
  let clickable = true;

  return (
    <React.Fragment>
      {!inAppBrowser && <div className="statusBar">
        <div className="container">
          <ul>
            {
              navItems.map(item => {
                let classname = "";
                let clicked = function () { };

                if (label === item.label) {
                  classname = "current";
                  clickable = false;
                }

                if (clickable) {
                  classname += " clickable";
                  clicked = itemClicked;
                }
                return (
                  <li key={item.label} className={classname} onClick={() => clicked(item)}>
                    <span>{item.order}<span className="hidden-xs">. {item.title}</span></span>
                  </li>
                );
              })
            }
          </ul>
        </div>
      </div>}
    </React.Fragment>
  );

  function itemClicked(item) {
    if (!item || !item.label) return;

    if (typeof (props.onItemClick) === 'function') props.onItemClick(item.label);
  }
}

