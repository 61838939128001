import React, { Component } from 'react';
import { ls } from "../../locale";
import { Row, Col, Checkbox, FormControl, FormGroup } from "react-bootstrap";

import { customerValidator } from "../../helpers";
import FormErrorBlock from '../../components/FormErrorBlock';


export default class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      password: '',
      confirmPassword: '',
      errors: {}
    }
  }

  toggleChecked = () => {
    const checked = !this.state.checked;
    this.setState({
      checked
    })
  }

  setInputState = (key, value) => {
    const errors = {...this.state.errors};
    errors[key] = null;

    this.setState({
      [key]: value,
      errors
    })
  }

  setErrorState = (key, value) => {
    const errors = {...this.state.errors};
    errors[key] = value;

    this.setState({errors});
  }

  validateForm = () => {
    if(!this.state.checked) return true;
    
    const errors = this.state.errors;
    errors.password = customerValidator.validatePassword(this.state.password);
    if(!errors.password && this.state.password !== this.state.confirmPassword) {
      errors.confirmPassword = "Confirm password doesn't match";
    }

    this.setState({errors});

    return !(errors.password || errors.confirmPassword)
  }

  getFormValues = () => {
    return {
      checked: this.state.checked,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    }
  }

  render() {
    const rCheckoutPage = ls.t('CheckoutPage');
    const rPax = ls.t('Pax');

    const errors = this.state.errors;
    return (
      <div className="booking-contact">
        <Row>
          <Col md={4} className="padt10">
            <FormGroup >
              <Checkbox name="checkRegister" 
                value={this.state.checked} onChange={() => {}} onClick={() => this.toggleChecked()}>
                <label></label>
              </Checkbox>
              <span>{rCheckoutPage.RegisterMember}</span>
            </FormGroup>
          </Col>
          <Col md={4}>
            {this.state.checked && <FormGroup className={errors.password ? "has-error" : null}>
              <FormControl type="password" placeholder={rPax.Password} 
                value={this.state.password} 
                onChange={event => this.setInputState('password', event.target.value)} />
            </FormGroup>}
            {
              this.state.checked && <FormErrorBlock error={errors.password}/>
            }
          </Col>
          <Col md={4}>
            {this.state.checked && <FormGroup className={errors.confirmPassword ? "has-error" : null}>
              <FormControl type="password" placeholder={rPax.ConfirmPassword} 
                value={this.state.confirmPassword} 
                onChange={event => this.setInputState('confirmPassword', event.target.value)} />
            </FormGroup>}
            {
              this.state.checked && <FormErrorBlock error={errors.confirmPassword}/>
            }
          </Col>
        </Row>
      </div>
    )
  }
}