import React, { useEffect, useState } from "react";
import { ls } from "../../../locale";
import queryString from "query-string";
import { connect } from "react-redux";

import { Row, Col } from "react-bootstrap";
import { history } from "../../../helpers";
import { memberActions } from "../../../state/ducks/member";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import AlertBox from "../../../components/Common/AlertBox";

import "./index.scss"

function Activate({location, activate}) {

  const [error, setError] = useState('')
  const [showLoginButton, setShowLoginButton] = useState(false)

  useEffect(() => {
    const params = queryString.parse(location.search);

    const token = params.token;
    if(!token || token.length < 10) {
      setError('Invalid token. If you copied the link from email, please make sure you have copied all characters.')
      return
    }

    activate(token, onActivateError).then(member => {
      // error happened
      if(!member) return

      const url = "/" + ls.lang + "/member/portal";
      history.push(url)
    })

  }, [location, activate])

  const onActivateError = response => {
    // console.log(response.status)
    response.json().then(errors => {
      if(!Array.isArray(errors) || errors.length === 0) {
        setError("Unknown error! Please try again.")
        return
      }

      setError(errors[0].message)
      // if the error code shows the account has been activated, then show the login button
      if(errors[0].errorCode === 41) {
        setError('The account has been activated. Please process login.')
        setShowLoginButton(true)
      } 
      else {
        setShowLoginButton(false)
      }
    })
  }

  const onLogin = () => {
    const url = "/" + ls.lang + "/member";
    history.push(url)
  }

  return (
    <div className={ls.t("rootClass") + " main-container activate-page"} dir={ls.t("direction")}>
      <div className="header-con">
        <Header />
      </div>
      <div className="header-bar" />
      <div className="container padt80 padb20">
        <Row className="min-height">
          <Col className="message-container">
            <AlertBox show={!!error} className="no-margin-top">
              {error || "Activating your account..."}
            </AlertBox>
            {showLoginButton && <div className="btnCon">
              <button className="btn btn-primary btn-submit" onClick={onLogin}>Go to Login</button>
            </div>}
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  )
}

function mapStateToProps() {
  return {
  };
}

const mapDispatchToProps = {
  activate: memberActions.activate
}

export default connect(mapStateToProps, mapDispatchToProps)(Activate);