import React from "react";

import { Row, Col, FormGroup, FormControl } from "react-bootstrap";

import CountrySelect from "../../../components/Common/CountrySelect";
import DialingCodeSelect from "../../../components/Common/DialingCodeSelect";
import { getSelectedOptionFromCountries, getDialingOptionByDialingCode } from "../../../helpers"; 
import { ls } from "../../../locale";
import FormErrorBlock from "../../../components/FormErrorBlock";
import { profileValidator as validator } from "../../../helpers";

export default function ({ values, errors, setInputState, countries, isCorpCustomer }) {

  const country = getSelectedOptionFromCountries(values.country, countries) || ' ';
  const phoneCountry = getDialingOptionByDialingCode(values.phoneCountry, countries) || ' ';
  const mobileCountry = getDialingOptionByDialingCode(values.mobileCountry, countries) || ' ';

  const rInput = ls.t('Input');
  const rProfilePage = ls.t('Member.ProfilePage');

  const setPhoneValue = (field, value) => {
    if(field === 'phone' || field === 'mobile') {
      if(validator.validatePhoneTyping(value)) setInputState(field, value)
    }
  }

  return (
    <React.Fragment>
      <h1>{rProfilePage.Contact}</h1>
      <div className="single-passenger">
        <Row>
          <Col md={4}>
            <FormGroup>
              <FormControl disabled={isCorpCustomer}
                type="text"
                value={values.street || ''}
                onChange={event => setInputState('street', event.target.value)}
                placeholder={rInput.Street} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <FormControl disabled={isCorpCustomer}
                type="text"
                value={values.city || ''}
                onChange={event => setInputState('city', event.target.value)}
                placeholder={rInput.City} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <div className="country-select">
              <CountrySelect disabled={isCorpCustomer}
                className="country-control"
                options={countries}
                selected={country}
                onSelected={(selected) => setInputState("country", selected.value)}
                placeholder={rInput.Country} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Row className="phone-dropdown">
              <Col md={4} xs={4}>
                <DialingCodeSelect 
                    className="dialingcode-select"
                    selected={phoneCountry}
                    options={countries}
                    onSelected={selected => setInputState("phoneCountry", selected.label)} />
              </Col>
              <Col md={8} xs={8}>
                <FormGroup>
                  <FormControl
                    type="telephone"
                    value={values.phone || ''}
                    onChange={event => setPhoneValue('phone', event.target.value)}
                    placeholder={rInput.PhoneNumber} />
                    <FormErrorBlock error={errors.phone}/>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row className="phone-dropdown">
              <Col md={4} xs={4}>
                <DialingCodeSelect
                    className="dialingcode-select"
                    selected={mobileCountry}
                    options={countries}
                    onSelected={selected => setInputState("mobileCountry", selected.label)} />
              </Col>
              <Col md={8} xs={8}>
                <FormGroup>
                  <FormControl
                    type="telephone"
                    value={values.mobile || ''}
                    onChange={event => setPhoneValue('mobile', event.target.value)}
                    placeholder={rInput.MobileNumber}
                  />
                  <FormErrorBlock error={errors.mobile}/>
                </FormGroup>
              </Col>
            </Row>            
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}