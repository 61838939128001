import { Promise } from "core-js";
import { api } from "../api";

export const bookingService = {
  getBooking,
  getBookingSummary,
  getBookingWithSummary,
  updatePassengers,
  getAncillaries,
  getSeatMap,
  addBaggage,
  removeBaggage,
  addInsurance,
  removeInsurance,
  addLounge,
  removeLounge,
  addPriorityCheckin,
  removePriorityCheckin,
  assignSeat,
  clearSeat,
  clearAllSeat,
  changeMeals,
  populateBooking,
  resetSegment,
  summaryPnr,
  printItinerary,
  printItineraryWithoutPrice,
  getPKPassFile,
  feedback,
  ratePostDeparture,
}

function getBooking(showAll) {
  const data = showAll ? { showAll } : undefined;
  return api.getFetch(`api/booking`, data)
}


function getBookingSummary() {
  return api.getFetch(`api/booking/summary`)
}

function getBookingWithSummary() {
  return api.getFetch(`api/booking/BookingSummary`)
}

function updatePassengers(passengers, contact) {
  const data = {
      passengers,
      contact
    };
  return api.postFetch(`api/booking/passengers`, data)
}


function getAncillaries(request) {
  return api.getFetch(`api/booking/ancillaries`, request)
}

function getSeatMap() {
  return api.getFetch(`api/booking/Seats`)
} 

function addBaggage(flightId, passengerId, ssrCode, prevSsrCode) {
  const params = {
      logicalFlightId: flightId,
      passengerId,
      ssrCode,
      prevSsrCode,
    };

    return api.postFetch(`api/booking/addBaggage`, params)
}

function removeBaggage(flightId, passengerId, ssrCode) {
  const params = {
      logicalFlightId: flightId,
      passengerId: passengerId,
      ssrCode
    };

  return api.postFetch(`api/booking/removeBaggage`, params)
}

function addInsurance() {
  return api.postFetch(`api/booking/addInsurance`)
}

function removeInsurance() {
  return api.postFetch(`api/booking/removeInsurance`)
}

function addLounge(logicalFlightId) {
  const data = { logicalFlightId }

  return api.postFetch(`api/booking/addLounge`, data)
}

function removeLounge(logicalFlightId) {
  const data = { logicalFlightId }

  return api.postFetch(`api/booking/removeLounge`, data)
}

function addPriorityCheckin(logicalFlightId) {
  const data = { logicalFlightId }

  return api.postFetch(`api/booking/addPriorityCheckin`, data)
}

function removePriorityCheckin(logicalFlightId) {
  const data = { logicalFlightId }

  return api.postFetch(`api/booking/removePriorityCheckin`, data)
}

function changeMeals(selection) {
  if (!selection || !(selection.length > 0)) {
      return Promise.reject("Invalid data");
    }

  return api.postFetch(`api/booking/changeMeals`, selection)
}

function assignSeat(data) {
  return api.postFetch(`api/booking/assignSeat`, data)
}

function clearSeat(data) {
  return api.postFetch(`api/booking/clearSeat`, data)
}

function clearAllSeat() {
  return api.postFetch(`api/booking/clearAllSeat`)
}

function populateBooking() {
  return api.postFetch(`api/booking/populateBooking`)
}

function resetSegment(segmentIndex) {
  const data = {
      segmentIndex
    }

  return api.postFetch(`api/booking/resetSegment`, data)
}

function summaryPnr() {
  return api.postFetch(`api/booking/summaryPnr`)
}

function printItinerary() {
  return api.getFetch(`api/booking/printItinerary`)
}

function printItineraryWithoutPrice() {
  return api.getFetch(`api/booking/printItineraryWithoutPrice`)
}

function getPKPassFile() {
  return api.postFetch(`api/booking/pkPassFile`)
}

function feedback(data) {
  return api.postFetch(`api/booking/feedback`, data);
}


function ratePostDeparture(data) {
return api.postFetch(`api/booking/ratePostDeparture`, data);
}