import React, { Component } from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  Nav,
  Row,
  TabContainer,
  TabContent,
  TabPane,
  Modal,
} from "react-bootstrap";

import Cleave from 'cleave.js/react';
import { jsonHelper } from "../../../helpers";

import { TabNavItemCS, TabNavItemINVC, TabNavItemKNET, TabNavItemSP, TabNavItemON, TabNavItemVCHR, TabNavItemPL, TabNavItemFT, TabNavItemCASH } from "./PaymentNavItem";
import { validator } from "./validator";

import "./index.scss";
import { ls } from "../../../locale";
import FormErrorBlock from "../../../components/FormErrorBlock";
import TermsCheckbox from "../../../components/Common/TermsCheckbox";

import { CardLogo } from "./CardLogo";

const defaultPaymentSettings = {
  CS: {
    type: "",
    cardNumber: null,
    nameOnCard: null,
    expiration: null,
    cvc: null,
    error: {}
  },
  ON: {
    error: {}
  },
  VCHR: {
    code: null,
    pin: null,
    error: {}
  },
  INVC: {
    error: {}
  },
  PL: {
    error: {}
  },
  CASH: {
    refNumber: '',
    error: {}
  }
}

export default class PaymentOptions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      terms: true,
      payments: jsonHelper.cloneDeep(defaultPaymentSettings),
      message: null,
      isShowMessageBox: false,
    };
  }

  resetCurrentPayment = () => {
    
    const method = this.props.paymentMethodCode;
    if(!method) return;

    if(method === 'CS') {
      if(this.creditCardNumberCleave) this.creditCardNumberCleave.setRawValue(defaultPaymentSettings.CS.cardNumber);
      if(this.creditCardExpirationCleave) this.creditCardExpirationCleave.setRawValue(defaultPaymentSettings.CS.expiration);
    }
    this.setState({
      payments: {
        ...this.state.payments,
        [method]: {...defaultPaymentSettings[method]}
      }
    })
  }

  callApplyVoucher = () => {
    if (this.props.onApplyVoucher && typeof (this.props.onApplyVoucher) === 'function')
      this.props.onApplyVoucher();
  }

  setPaymentMethodState = code => {

    // if the payment option is disabled, then we don't fire the method change event
    if(this.props.disabled) return;

    if (this.props.onPaymentMethodChanged && typeof (this.props.onPaymentMethodChanged) === 'function')
      this.props.onPaymentMethodChanged(code);
  }

  toggleTermsState = () => {
    this.setState({
      terms: !this.state.terms
    });
  }

  setCreditCardState = (field, value) => {

    this.setState(({payments}) => {

      if(typeof value === 'string') value = value.trimStart();
      
      const creditcard = {...payments.CS}
      creditcard[field] = value    
      creditcard.error[field] = null;

      if(field === 'cardNumber') {
        // check if mada card
        const type = this.getGatewayFromBins(value)
        if(type === 'mada') {
          creditcard.type = type
        }
      }

      return {
        payments: { ...this.state.payments, CS: creditcard }
      }
    })

  }

  toggleEppSelection = () => {
    const value = !this.state.payments.CS.eppSelected;
    this.setCreditCardState('eppSelected', value)

    if(typeof this.props.onEppSelectionChange === 'function') {
      this.props.onEppSelectionChange(value)
    }
  } 

  setCreditCardCvcState = (value) => {

    if(value) value = value.trim();
    const regex = /^\d{0,3}$/;
    if(!regex.test(value)) return;

    this.setCreditCardState('cvc', value);
  }

  setCreditCardExpirationState = (value) => {

    const creditcard = this.state.payments.CS;

    if(value) value = value.trim();
    if (value && value.endsWith("//")) return;

    const regex = /^\d{2}$/;
    if (regex.test(value)) {
      if (creditcard["expiration"] === value + "/") { //backspace
        value = value.slice(0, 1);
      }
      else {
        value += "/";
      }
    }

    this.setCreditCardState('expiration', value);
  }

  setCreditCardErrorState = (field, value) => {
    const creditcard = this.state.payments.CS;

    creditcard.error[field] = value;
    this.setState({
      payments: this.state.payments
    });
  };

  checkCardNumber = () => {
    const value = this.state.payments.CS.cardNumber
    if(!value) return

    const cardNumber = value.replace(/\s/g, "")
    const type = validator.getCreditCardType(cardNumber)
    // if it's a valid credit card, a card type will be return
    if(type && typeof this.props.onCreditCardNumberChange === 'function') {
      this.props.onCreditCardNumberChange(cardNumber)
    }
  }

  clearPaymentErrorState = () => {
    this.setCreditCardErrorState("cardNumber", null);
    this.setCreditCardErrorState("nameOnCard", null);
    this.setCreditCardErrorState("expiration", null);
    this.setCreditCardErrorState("cvc", null);

    this.setVoucherErrorState("code", null);
    this.setVoucherErrorState("pin", null);
  };

  setVoucherState = (field, value) => {
    const voucher = this.state.payments.VCHR;

    value = value.trim().toUpperCase();
    if(value.length > 6) value = value.substring(0, 6);
    voucher[field] = value;

    this.setState({
      payments: {...this.state.payments, VCHR: voucher }
    });
    this.setVoucherErrorState(field, null);
  };

  setVoucherErrorState = (field, value) => {
    const voucher = this.state.payments.VCHR;

    voucher.error[field] = value;
    this.setState({
      payments: {...this.state.payments, VCHR: voucher }
    });
  };

  setCashState = (field, value) => {
    const cash = this.state.payments.CASH;

    value = value.trim().toUpperCase();
    cash[field] = value;

    this.setState({
      payments: {...this.state.payments, CASH: cash }
    });
    this.setCashErrorState(field, null);
  };

  setCashErrorState = (field, value) => {
    const cash = this.state.payments.CASH;

    cash.error[field] = value;
    this.setState({
      payments: {...this.state.payments, CASH: cash }
    });
  };

  validateForm = () => {
    this.clearPaymentErrorState();

    const paymentMethodCode = this.props.paymentMethodCode;

    if (paymentMethodCode === "CS") {
      const cc = this.state.payments.CS;

      let cardNumber = cc.cardNumber;
      let cardType = null;
      if (typeof (cardNumber) === "string") {
        cardNumber = cardNumber.replace(/ /g, "");
        cardType = validator.getCreditCardType(cardNumber);
      }

      if (cardType)
        this.setCreditCardState("type", cardType);

      this.setCreditCardErrorState("cardNumber", !cardType ? validator.invalidField('CardNumber') : null);
      this.setCreditCardErrorState("nameOnCard", validator.validateCreditCardName(cc.nameOnCard));
      this.setCreditCardErrorState("expiration", validator.validateCreditCardExpiration(cc.expiration));
      this.setCreditCardErrorState("cvc", validator.validateCreditCardCvc(cc.cvc));
    }
    else if (paymentMethodCode === "VCHR") {
      const voucher = this.state.payments.VCHR;
      this.setVoucherErrorState("code", validator.validateVoucherCode(voucher.code));
      this.setVoucherErrorState("pin", validator.validateVoucherPin(voucher.pin));
    }
    else if(paymentMethodCode === 'CASH') {
      const cash = this.state.payments.CASH;
      this.setCashErrorState('refNumber', validator.validateRefNumber(cash.refNumber))
    }

    return this.isFormValid();
  }

  isFormValid = () => {
    const paymentMethodCode = this.props.paymentMethodCode;

    if (paymentMethodCode === "CS") {
      const cc = this.state.payments.CS;
      if (cc.error.cardNumber || cc.error.nameOnCard || cc.error.expiration || cc.error.cvc)
        return false;
    }
    else if (paymentMethodCode === "VCHR") {
      const voucher = this.state.payments.VCHR;
      if (voucher.error.code || voucher.error.pin)
        return false;
    }
    else if(paymentMethodCode === 'CASH') {
      const cash = this.state.payments.CASH;
      if(cash.error.refNumber) return false;
    }

    return true;
  }

  getFormValues = () => {
    const paymentMethodCode = this.props.paymentMethodCode;

    const result = {
      paymentMethodCode,
      ...this.state.payments[paymentMethodCode]
    }

    if(result.cardNumber) 
      result.cardNumber = result.cardNumber.replace(/\s/g, '')

    return result
  }

  getTermsState = () => {
    return this.state.terms;
  }

  closeMessageBox = () => {
    this.setState({
      isShowMessageBox: false,
      message: null
    });
  };

  showMessageBox = (message, title) => {
    this.setState({
      isShowMessageBox: true,
      message: message,
      messageTitle: title
    });
  };

  stayOnCyberSource = () => {
   this.closeMessageBox();
   this.setCreditCardState('cardNumber', '');
  }

  contiueWithOmanNet = () => {
    this.closeMessageBox();
    this.setCreditCardState('cardNumber', '');
    this.setPaymentMethodState('ON');
  }

  getGatewayFromBins = (cardNumber) => {
    const bins = this.props.localDebitBins
    if(!cardNumber || !Array.isArray(bins)) return null

    cardNumber = cardNumber.replace(/\s/g, '')
    const match = bins.find(x => cardNumber.startsWith(x.bin))
    if(match) {
      return match.paymentMethod.toLowerCase()
    }
    return null
  }

  render() {

    const rPayment = ls.t('Payment');
    if(!rPayment.PaymentOptions) return null;

    const methods = this.props.paymentMethods;
    if(!Array.isArray(methods) || !methods.length) return null;

    const rButton = ls.t('Button');
    const rMessageBox = ls.t('MessageBox');

    const {paymentMethodCode, paymentStatus, voucherApplied, eppEligible } = this.props;

    const autoCompleteSeed = new Date().getMilliseconds();

    let omannetDesc = rPayment.OmanNet.Desc || '';
    omannetDesc = omannetDesc.split(/[\r\n]/);
    
    let knetDesc = rPayment.Knet.Desc || '';
    knetDesc = knetDesc.split(/[\r\n]/);

    let spDesc = rPayment.SmartPay.Desc || '';
    spDesc = spDesc.split(/[\r\n]/);

    let agpayDesc = rPayment.AGPay.Desc || '';
    agpayDesc = agpayDesc.split(/[\r\n]/);

    const agencyInfo = this.props.userData || {currencyCode: 'OMR', availableCredit: 0};

    
    return (
      <React.Fragment>
        <h1>{rPayment.PaymentOptions}</h1>

        <div className={ "payment-options" + (this.props.className ? ' ' + this.props.className : '') }>          

          {this.props.statement && <TermsCheckbox 
            checked={this.state.terms === true} 
            onClick={() => this.toggleTermsState()} 
            terms={this.props.statement} />}
          <TabContainer id="payment-options" activeKey={paymentMethodCode} defaultActiveKey="CS" onSelect={() => { }}>
            <div className="tab-wrapper clearfix">
              <Col md={5} className="panel-box">
                <Nav bsStyle="pills" stacked className={ls.t('rootClass')}>
                  {methods.map((method) => { switch(method) {
                    case 'ON': return <TabNavItemON key={method} paymentMethodCode={paymentMethodCode} setPaymentMethodState={this.setPaymentMethodState} />
                    case 'KNET': return <TabNavItemKNET key={method} paymentMethodCode={paymentMethodCode} setPaymentMethodState={this.setPaymentMethodState} />
                    case 'SP': return <TabNavItemSP key={method} paymentMethodCode={paymentMethodCode} setPaymentMethodState={this.setPaymentMethodState} />
                    case 'CS': return <TabNavItemCS key={method} paymentMethodCode={paymentMethodCode} methods={methods} setPaymentMethodState={this.setPaymentMethodState} />
                    case 'VCHR': return (!paymentStatus || voucherApplied) 
                                ? <TabNavItemVCHR key={method} paymentMethodCode={paymentMethodCode} setPaymentMethodState={this.setPaymentMethodState} />
                                : false;
                    case 'INVC': return <TabNavItemINVC key={method} paymentMethodCode={paymentMethodCode} setPaymentMethodState={this.setPaymentMethodState} />
                    case 'PL': return <TabNavItemPL key={method} paymentMethodCode={paymentMethodCode} setPaymentMethodState={this.setPaymentMethodState} />
                    case 'FT': return <TabNavItemFT key={method} paymentMethodCode={paymentMethodCode} setPaymentMethodState={this.setPaymentMethodState} />
                    case 'CASH': return <TabNavItemCASH key={method} paymentMethodCode={paymentMethodCode} setPaymentMethodState={this.setPaymentMethodState} />
                    default: return false;
                  }})}
                </Nav>
              </Col>
              <Col md={7} className="panel-box">
                <TabContent animation>
                  <TabPane eventKey="CS">
                      <Row className="credit-cards">
                        <Col md={12}>
                          <FormGroup 
                            className={'credit-card-number ' + ls.t('rootClass')  + (this.state.payments.CS.error.cardNumber ? " has-error" : '')}>
                            <Cleave placeholder={rPayment.CreditCard.Number} className="form-control"
                              options={{creditCard: true, onCreditCardTypeChanged: t => this.setCreditCardState('type', t)}}
                              onChange={event => this.setCreditCardState("cardNumber", event.target.value)}
                              onInit={cleave => this.creditCardNumberCleave=cleave}
                              onBlur={this.checkCardNumber}
                             />
                            <CardLogo cardType={this.state.payments.CS.type} />
                            <FormErrorBlock error={this.state.payments.CS.error.cardNumber}/>
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup className={this.state.payments.CS.error.nameOnCard ? "has-error" : null}>
                            <FormControl
                              type="text"
                              placeholder={rPayment.CreditCard.NameOnCard}
                              value={this.state.payments.CS.nameOnCard || ""}
                              autoComplete={autoCompleteSeed}
                              onChange={event => this.setCreditCardState("nameOnCard", event.target.value)}
                            />
                            <FormErrorBlock error={this.state.payments.CS.error.nameOnCard} />
                          </FormGroup>
                          <span className="expiration-label">{rPayment.CreditCard.Expiration}</span>
                        </Col>

                        <Col md={6}>
                          <FormGroup className={this.state.payments.CS.error.expiration ? "has-error" : null}>
                            {/* <FormControl
                              type="text"
                              placeholder="MM/YY"
                              value={this.state.payments.CS.expiration || ""}
                              autoComplete={autoCompleteSeed}
                              onChange={event => this.setCreditCardExpirationState(event.target.value)}
                            /> */}
                            <Cleave placeholder="MM/YY"
                              className="form-control"
                              autoComplete={autoCompleteSeed}
                              options={{date: true, delimiter: '/', datePattern: ['m', 'y']}}
                              onChange={event => this.setCreditCardExpirationState(event.target.value)}
                              onInit={cleave => this.creditCardExpirationCleave=cleave} />
                            <FormErrorBlock error={this.state.payments.CS.error.expiration} />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className={this.state.payments.CS.error.cvc ? "has-error" : null}>
                            <FormControl
                              type="text"
                              placeholder={rPayment.CreditCard.SecurityCode}
                              value={this.state.payments.CS.cvc || ""}
                              autoComplete={autoCompleteSeed}
                              onChange={event => this.setCreditCardCvcState(event.target.value)}
                            />
                            <FormErrorBlock error={this.state.payments.CS.error.cvc} />
                          </FormGroup>
                        </Col>
                        {eppEligible && <Col md={12}>
                          <TermsCheckbox checked={this.state.payments.CS.eppSelected === true} onClick={() => this.toggleEppSelection()} terms={rPayment.EPP} />
                        </Col>}
                      </Row>
                  </TabPane>
                  <TabPane eventKey="ON">
                    <Row className="credit-cards">
                      <Col md={12}>
                        {omannetDesc.map((m, index) => <p key={index}>{m}</p>)}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane eventKey="KNET">
                    <Row className="credit-cards">
                      <Col md={12}>
                        {knetDesc.map((m, index) => <p key={index}>{m}</p>)}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane eventKey="SP">
                    <Row className="credit-cards">
                      <Col md={12}>
                        {spDesc.map((m, index) => <p key={index}>{m}</p>)}
                      </Col>
                    </Row>
                  </TabPane>
                  {methods.find(x => x === 'INVC') && <TabPane eventKey="INVC">
                    <Row className="credit-cards">
                      <Col md={12}>
                        {agpayDesc.map((m, index) => <p key={index}>{m}</p>)}
                        <p>Available Credit: <strong>{agencyInfo.currencyCode} {agencyInfo.availableCredit}</strong></p>
                      </Col>
                    </Row>
                  </TabPane>}
                  {methods.find(x => x === 'PL') && <TabPane eventKey="PL">
                    <Row className="credit-cards">
                      <Col md={12}>
                        <p>You can book your flight on timelimit and pay later within the specified time. </p>
                        <p>Please proceed and click "pay now" option to complete the booking.</p>
                      </Col>
                    </Row>
                  </TabPane>}
                  {methods.find(x => x === 'FT') && <TabPane eventKey="FT">
                    <Row className="credit-cards">
                      <Col md={12}>
                        <p>Free ticket for staff.</p>
                      </Col>
                    </Row>
                  </TabPane>}
                  {
                    methods.find(x => x === 'VCHR') && (!paymentStatus || voucherApplied) && <TabPane eventKey="VCHR">
                      {/* {
                        voucherApplied
                          ? <Row className="credit-cards">
                            <Col md={12}>
                              <p>{rPayment.Voucher.AppliedMessage}</p>
                            </Col>
                          </Row> : */}                           
                          <Row className="credit-cards">
                            <Col md={6}>
                              <FormGroup className={this.state.payments.VCHR.error.code ? "has-error" : null}>
                                <FormControl
                                  type="text"
                                  placeholder={rPayment.Voucher.Code}
                                  value={this.state.payments.VCHR.code || ""}
                                  autoComplete={autoCompleteSeed}
                                  onChange={event => this.setVoucherState("code", event.target.value)}
                                />
                                <FormErrorBlock error={this.state.payments.VCHR.error.code}/>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup className={this.state.payments.VCHR.error.pin ? "has-error" : null}>
                                <FormControl
                                  type="text"
                                  placeholder={rPayment.Voucher.Pin}
                                  value={this.state.payments.VCHR.pin || ""}
                                  autoComplete={autoCompleteSeed}
                                  onChange={event => this.setVoucherState("pin", event.target.value)}
                                />
                                <FormErrorBlock error={this.state.payments.VCHR.error.pin} />
                              </FormGroup>
                              {!this.props.disabled && <div className="btnCon">
                                <Button className="btn btn-submit pull-right"
                                  onClick={() => this.callApplyVoucher()}>{rButton.Apply}</Button>
                              </div>}
                            </Col>
                          </Row>
                      {/* } */}
                    </TabPane>
                  }
                  {methods.find(x => x === 'CASH') && <TabPane eventKey="CASH">
                    <Row className="credit-cards">
                      <Col md={8}>
                        <FormGroup className={this.state.payments.CASH.error.refNumber ? "has-error" : null}>
                          <FormControl
                            type="text"
                            placeholder="Reference Number"
                            value={this.state.payments.CASH.refNumber || ""}
                            autoComplete={autoCompleteSeed}
                            onChange={event => this.setCashState("refNumber", event.target.value)}
                          />
                          <FormErrorBlock error={this.state.payments.CASH.error.refNumber}/>
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>}
                </TabContent>
              </Col>
            </div>
          </TabContainer>
        </div>
        <Modal className={ls.t("rootClass")} show={this.state.isShowMessageBox} >
          <Modal.Header>
            <Modal.Title>{this.state.messageTitle || rMessageBox.Title.Notification}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{this.state.message}</h4>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary pull-left" onClick={this.stayOnCyberSource}>{rButton.Cancel}</button>
            <button className="btn btn-default" onClick={this.contiueWithOmanNet}>{rButton.ContinueWithOmanNet}</button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
