export const commonHelpers = {
  createFinderprintScriptTag,
  createScriptTag,
  createDownloadTag,
  isiOS,
  checkBrowser,
  isPromise,
  getHashCode,
};


function createFinderprintScriptTag(scriptUrl, onload, onerror) {
  const iframe = document.createElement("iframe");
  iframe.setAttribute("src", scriptUrl);
  iframe.style.width = "0";
  iframe.style.height = "0";
  
  if(typeof onload === 'function') iframe.onload = onload;
  if(typeof onerror === 'function') iframe.onerror = onerror;
  
  document.body.appendChild(iframe);
  return iframe;
}

function createScriptTag(scriptUrl, onload, onerror) {
  const script = document.createElement("script");
  
  if(typeof onload === 'function') script.onload = onload;
  if(typeof onerror === 'function') script.onerror = onerror;
  
  script.type = "text/javascript";
  script.async = false;
  script.src = scriptUrl;

  document.head.appendChild(script);
  return script;
}

function createDownloadTag(blob, filename = 'download.pdf', contentType = 'application/pdf') {

  // const win =  window.open(URL.createObjectURL(blob));
  // if (!win || win.closed || typeof win.closed === 'undefined') {
    //POPUP BLOCKED
    const url = window.URL.createObjectURL(new Blob([blob], { type: contentType}));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
  //}
}

function isiOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function checkBrowser() { 
          
  // Get the user-agent string 
  const userAgentString =  
      navigator.userAgent; 

  // Detect Chrome 
  let chromeAgent =  
      userAgentString.indexOf("Chrome") > -1 || userAgentString.indexOf("CriOS") > -1; 

  // Detect Internet Explorer 
  const IExplorerAgent =  
      userAgentString.indexOf("MSIE") > -1 ||  
      userAgentString.indexOf("rv:") > -1; 

  // Detect Firefox 
  const firefoxAgent =  
      userAgentString.indexOf("Firefox") > -1 || userAgentString.indexOf("FxiOS") > -1; 

  // Detect Safari 
  let safariAgent =  
      userAgentString.indexOf("Safari") > -1; 
        
  // Discard Safari since it also matches Chrome 
  if ((chromeAgent || firefoxAgent) && (safariAgent))  
      safariAgent = false; 

  // Detect Opera 
  const operaAgent =  
      userAgentString.indexOf("OP") > -1; 
        
  // Discard Chrome since it also matches Opera      
  if ((chromeAgent) && (operaAgent))  
      chromeAgent = false; 

  return {
    safariAgent,
    chromeAgent,
    IExplorerAgent,
    operaAgent,
    firefoxAgent
  }
}

function isPromise(foo) {
  return foo && typeof foo.then === 'function';
}

function getHashCode(...values) {
  const value = values.map(v => typeof(v) === 'string' ? v : JSON.stringify(v)).join('')

  const arr = value.split('');
  return arr.reduce(
    (hashCode, currentVal) =>
      (hashCode = currentVal.charCodeAt(0) + (hashCode << 6) + (hashCode << 16) - hashCode),
    0
  )
}
