import React from 'react'
import { Checkbox } from 'react-bootstrap'

import './index.scss'

export default function TermsCheckbox(props) {

  return (
    <Checkbox className="terms" checked={!!props.checked} onChange={() => {}} onClick={props.onClick}>
      <label></label>
      <p dangerouslySetInnerHTML={{__html: props.terms}}></p>
    </Checkbox>
  )
}