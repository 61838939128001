import React, { useReducer } from 'react'
import { connect } from 'react-redux'
import { ls } from "../../../locale"
import { FormControl, FormGroup } from 'react-bootstrap'
import FormErrorBlock from '../../../components/FormErrorBlock'

import { memberActions } from "../../../state/ducks/member";
import { sessionActions } from "../../../state/ducks/session";

import { reducer, isEmptyErrors } from './reducer'
import { initialState, validateValues } from './signinFormData'

function SigninForm({ user, memberLogin, getProfile, setPassengerData, onSignedIn, onForgotPassword, onCreateAccount }) {

  initialState.values.email = user?.email ?? process.env.REACT_APP_STAFF_UserName
  initialState.values.password = process.env.REACT_APP_STAFF_Password
  
  const [state, dispatch] = useReducer(reducer, initialState)

  const rInput = ls.t('Input')

  const setErrors = errors => {
    dispatch({
      type: 'errors',
      payload: errors
    })
  }

  const setValue = (key, value) => {
    dispatch({
      type: 'value',
      payload: { key, value}
    })
  }

  const handleForgotPassword = () => {
    const errors = validateValues(values)
    if(errors?.email) {
      errors.password = null
      setErrors(errors)
      return
    }

    onForgotPassword({email: values.email})
  }

  const handleSubmit = form => {
    form.preventDefault()
    
    const values = state.values
    const errors = validateValues(values)
    if(isEmptyErrors(errors)) {
      setErrors(errors)
      return
    }

    memberLogin(values.email, values.password)
    .then(() => {
      getProfile().then(profile => {
        if (profile.contact && profile.customer) {
          const user = {
            title: profile.customer.title,
            firstName: profile.customer.firstName,
            lastName: profile.customer.lastName,
            dateOfBirth: profile.customer.dateOfBirth,
            mobile: profile.contact.mobile,
            email: profile.contact.email,
            country: profile.contact.country,
          }
          setPassengerData(user);
        }
        onSignedIn({email: values.email})
      })
    })
  }

  const {values, errors } = state;

  return (
    <div className="form-content">
      <form onSubmit={handleSubmit}>
        <FormGroup className={errors.email ? "has-error" : null}>
          <FormControl type="text"
            placeholder={rInput.Email + " *"}
            value={values.email}
            onChange={event => setValue('email', event.target.value)}
          />
          <FormErrorBlock error={errors.email} />
        </FormGroup>

        <FormGroup className={errors.password ? "has-error" : null}>
          <FormControl type="password"
            placeholder={rInput.Password + " *"}
            value={values.password}
            onChange={event => setValue('password', event.target.value)} />
          <FormErrorBlock error={errors.password} />
        </FormGroup>

        <div className="links">
          <span className="link" onClick={handleForgotPassword}>Forgot Password?</span>
        </div>

        <div className="actions">
          <button type="submit">Sign in</button>
          <div>Don't have an account? <span className="link" onClick={onCreateAccount}>Create an account</span></div>
        </div>
      </form>
    </div>
  )
}

function mapStateToProps() {
  return {
  }
}

const mapDispatchToProps = {
  ...memberActions,
  setPassengerData: sessionActions.setPassengerData,
}

export default connect(mapStateToProps, mapDispatchToProps)(SigninForm);
