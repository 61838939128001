// Convert your react router component to an array with all static routes. 
// Dynamic routes and their corresponding nested static routes are ignored. No match (*) route is also ignored. 
// origin: https://github.com/alansouzati/react-router-to-array
// enhanced: parse language parameters with the function parseParameters

function extractChildRoutes (route, prefix) {
  let paths = [];
  const childRoutes = route.props && route.props.children ?
    route.props.children : route.childRoutes;
  if (childRoutes) {
    if (Array.isArray(childRoutes)) {
      childRoutes.forEach((r) => {
        paths = paths.concat(extractRoute(r, prefix));
      });
    } else {
      paths = paths.concat(extractRoute(childRoutes, prefix));
    }
  }
  return paths;
}

function extractRoute (route, prefix) {
  const path = route.props && route.props.path ? route.props.path : route.path;
  let paths = [];

  if (!path) {
    if (Array.isArray(route)) {
      route.forEach((r) => {
        paths = paths.concat(extractRoute(r, prefix));
      });

      return paths;
    } else {
      return extractChildRoutes(route, prefix)
    }
  }
  const currentPath = (
    `${prefix || ''}${path}`.replace(/\/+/g, '/')
  );

  const pp = parseParameters(currentPath);
  
  if(Array.isArray(pp)) {
    pp.forEach(p => {
      if (!/:|\*/.test(p)) {
        paths.push(`${p.startsWith('/') ? '' : '/'}${p}`);
        paths = paths.concat(extractChildRoutes(route, `${p}/`));
      }
    })
  }
  
  return paths;
}

function parseParameters(path) {
  const regex = /:\w+\((\w+[|\w+]*)\)\??/;
  if(!path) return [path];
  
  const param = path.match(regex);
  if(!Array.isArray(param) || param.length < 2) return [path];

  let values = [];
  if(param[1].indexOf('|') < 0) {
    values = [param[1]];
  }
  else {
    values = param[1].split('|');
  }
  
  const result = [];
  values.forEach(value => {
    result.push(path.replace(param[0], value))
  });

  return result;
}

export function reactRouterToArray(route) {
  let paths = [];
  if (Array.isArray(route)) {
    route.forEach((r) => {
      paths = paths.concat(extractRoute(r));
    });
  } else {
    paths = paths.concat(extractRoute(route));
  }

  return paths;
}