import React, { Component } from 'react'
import dayjs from 'dayjs';
import { jsonHelper } from '../../helpers';
import {
  Col,
  Row,
  FormControl,
  FormGroup,
} from "react-bootstrap";

import Icon from "react-icons-kit/Icon";
import { userCircleO } from "react-icons-kit/fa/userCircleO";

import { isInAppBrowser } from "../../helpers";
import { PassengerType, UserType } from "../../constants/settings";
import { ReactSVG } from "react-svg";
import CheckinIcon from "../../images/web-checkin.svg";

import { titles } from "../../constants/settings";
import { customerValidator } from "../../helpers";
import { ls } from "../../locale";
import TitleSelect from '../../components/Common/TitleSelect';

import SelectEmployee from './SelectEmployee';
import FormErrorBlock from '../../components/FormErrorBlock';
import { DateInput } from './DateInput';

export default class Passengers extends Component {

  constructor(props) {
    super(props);

    const { flightDate, employeeInfo, isStaffFare } = props;
    const { passengers = [] } = props;

    const pps = passengers.map(pax => {
      const passenger = jsonHelper.cloneDeep(pax);
      const date = dayjs(passenger.dateOfBirth);
      if(!date.isValid() || date < dayjs().add(-100, 'year'))
        passenger.dateOfBirth = '';
      else 
        passenger.dateOfBirth = date.format('YYYY-MM-DD');

      passenger.dateMin = dayjs().add(-100, 'year').format('YYYY-MM-DD');
      passenger.dateMax = dayjs().format('YYYY-MM-DD');
      passenger.error = {};

      if(!passenger.title) {
        if(passenger.paxType === PassengerType.Adult)
          passenger.title = "MR";
        else
          passenger.title = "MSTR";
      }

      passenger.passport = pax.passport || '';

      return passenger;
    });

    if (pps.length > 0) {
      let passenger = pps[0];
      if (isStaffFare) {
        if (Array.isArray(employeeInfo) && employeeInfo.length > 0) {
          const employee = employeeInfo[0];
          passenger = this.setPassengerWithEmployee(passenger, employee);
        }
        else {
          passenger.firstName = '';
          passenger.lastName = '';
        }
      }
    }

    this.state = {
      passengers: pps,
      flightDate,
      currentPassenger: null,
      isShowSelectEmployeeWindow: false,
      // isShowMessageBox: false,
    }
  }

  onStateChanged = () => {
    if(typeof(this.props.onStateChanged) === 'function') this.props.onStateChanged(this.state.passengers);
  }

  setPassengerState = (index, field, value) => {
    const passenger = this.state.passengers[index];
    passenger[field] = value;
    passenger.error[field] = null; // clear the error message

    this.setState({
      passengers: this.state.passengers
    });

    this.onStateChanged();
  };

  setPassengerErrorState = (index, field, error) => {
    const passenger = this.state.passengers[index];
    passenger.error[field] = error;
    this.setState({
      passengers: this.state.passengers
    });
  };

  validateForm = () => {
    const lastFlightDate = this.state.flightDate;
    if (!lastFlightDate) {
      return false;
    }
    
    const userData = this.props.userData;
    // skip DOB validation for agents
    let isSkipDOB = userData && userData.loggedIn && userData.userType === UserType.agent;

    // if insurance is purchased then DOB validation can't be skipped
    if(this.props.hasInsurance) isSkipDOB = false;

    
    // validate passengers
    this.state.passengers.forEach((passenger, index) => {
      if (passenger.paxType === PassengerType.ExtraSeat) return;

      const dobValidation = customerValidator.validateBirthDate(passenger.dateOfBirth, passenger.paxType, lastFlightDate);
      let dobMessage = null;

      if (dobValidation.valid === true) {
        passenger.dateOfBirth = dobValidation.value;
      }
      else if(passenger.paxType === PassengerType.Adult && isSkipDOB) {
        passenger.dateOfBirth = dobValidation.value || '0001-01-01';
      }
      else {
        dobMessage = dobValidation.message;
      }

      this.setPassengerErrorState(index, "firstName", customerValidator.validateName(passenger.firstName));
      this.setPassengerErrorState(index, "lastName", customerValidator.validateLastName(passenger.lastName));
      this.setPassengerErrorState(index, "dateOfBirth", dobMessage);

      // check if any employee/dependents is selected twice
      if(passenger.empId && this.state.passengers.filter(x => x.empId === passenger.empId).length > 1) {
        this.setPassengerErrorState(index, "lastName", "Passenger was selected more than once!");
      } 
    });

    return this.isFormValid();
  };

  isFormValid = () => {

    // check if there are any error in the validating result
    let valid = true;

    this.state.passengers.forEach((passenger) => {
      if (passenger.paxType === PassengerType.ExtraSeat) return;
      const errors = Object.values(passenger.error);
      if(errors.some(x => !!x)) {
        // console.log('is passenger valid: ', passenger, errors)
        valid = false;
      }
    });
    return valid;
  };

  getFormValues = () => {
    const passengers = [];

    this.state.passengers.forEach(passenger => {
      if (passenger.paxType === PassengerType.ExtraSeat) return;

      let title = passenger.title;
      if (!title) title = "Mr";

      passengers.push({
        id: passenger.id,
        title: title,
        firstName: passenger.firstName.trim(),
        lastName: passenger.lastName.trim(),
        dateOfBirth: passenger.dateOfBirth,
        passport: passenger.passport ? passenger.passport.trim() : null,
        empId: passenger.empId
      });
    });
    
    return passengers;
  };

  login = () => {
    if(typeof(this.props.onLogin) === 'function') this.props.onLogin();
  }

  onSelectEmployee = employee => {
    let currentPassenger = this.state.currentPassenger;
    if(!currentPassenger) return;

    currentPassenger = this.setPassengerWithEmployee(currentPassenger, employee);

    this.closeSelectEmployeeWindow();
  }

  setPassengerWithEmployee = (passenger, employee) => {
    passenger.firstName = employee.firstName;
    passenger.lastName = employee.lastName;

    const dob = dayjs(employee.dateOfBirth);
    passenger.dateOfBirth = dob.format('YYYY-MM-DD');  

    if(employee.gender && employee.gender.toUpperCase()[0] === 'F') 
      passenger.title = 'MS';
    else
      passenger.title = 'MR';

      // used to mark the selected employee
    passenger.empId = employee.empId;
    return passenger;
  }

  showSelectEmployeeWindow = passenger => {
    if(passenger.empId && passenger.error.lastName) {
      this.state.passengers.filter(x => x.empId === passenger.empId).forEach(x => x.error.lastName = null);
    }
    this.setState({
      currentPassenger: passenger,
      isShowSelectEmployeeWindow: true
    })
  }

  closeSelectEmployeeWindow = () => {
    this.setState({
      currentPassenger: null,
      isShowSelectEmployeeWindow: false
    })
  }

  // closeMessageBox = () => {
  //   this.setState({
  //     isShowMessageBox: false
  //   });
  // };

  // showMessageBox = () => {
  //   this.setState({
  //     isShowMessageBox: true
  //   });
  // };

  render() {

    const rCheckoutPage = ls.t('CheckoutPage');
    if(!rCheckoutPage.TotalDue) return null;

    const rPax = ls.t('Pax');
    const rPaxType = ls.t('PaxType');
    const rCommon = ls.t('Common');
    const rTitle = ls.t('Title');
    // const rMessageBox = ls.t('MessageBox');
    // const rButton = ls.t('Button');

    const { isShowLogin, isStaffFare, employeeInfo } = this.props;
    const { passengers, flightDate } = this.state;

    const flightYear = flightDate.getFullYear();
    const yearList = {
      Adult: [],
      Child: [],
      Infant: []
    };

    for (let i = flightYear; i >= flightYear - 100; i--)
      yearList.Adult.push(i);

    for (let i = flightYear; i >= flightYear - 13; i--)
      yearList.Child.push(i);

    for (let i = flightYear; i >= flightYear - 3; i--)
      yearList.Infant.push(i);

    const autoCompleteSeed = new Date().getMilliseconds();

    const selectedEmployeeIds = passengers.map(p => p.empId).filter(e => e);

    const inAppBrowser = isInAppBrowser();

    return (
      <React.Fragment>
        <div style={{position: 'relative'}}>
          <h1>{rCheckoutPage.PaxDetails}</h1>
          { isShowLogin && !inAppBrowser && <div className="login-wrap clickable" onClick={this.login}>
            <ReactSVG src={CheckinIcon} className="login-icon"/>
            <span>{rCommon.Login}</span>
          </div>}
        </div>
        <div className="padb20">* Please enter passenger names as per ID or Passport</div>
        {passengers.map((passenger, index) => {

          if (passenger.paxType === PassengerType.ExtraSeat) return null;
          return (
            <div className="single-passenger" key={index}>
              <h4>
                <Icon icon={userCircleO} />
                <span> {rPaxType[passenger.paxTypeName].Passenger} {passenger.paxIndex}</span>
                {isStaffFare && <span>
                  <button className="link-button select-employee" onClick={() => this.showSelectEmployeeWindow(passenger)}>SELECT</button>
                </span>}
              </h4>
              <Row>
                <Col className="desktop-one-half" xs={3}>
                  <div className="sex-select">
                    <TitleSelect rTitle={rTitle}
                      options={titles[passenger.paxTypeName]} 
                      value={passenger.title || titles[passenger.paxTypeName][0]} 
                      onChange={selected => this.setPassengerState(index, "title", selected)}
                      disabled={passenger.namesLocked} />
                  </div>
                </Col>
                <Col className="desktop-three-half" xs={9} key={"firstname-" + index}>
                  <FormGroup className={passenger.error.firstName ? "has-error" : null}>
                    <FormControl
                      type="text"
                      placeholder={rPax.FirstName + ' *'}
                      value={passenger.firstName || ""}
                      autoComplete={autoCompleteSeed}
                      onChange={e => this.setPassengerState(index, "firstName", e.target.value)}
                      onFocus={e => e.target.select()}
                      disabled={passenger.namesLocked}
                    />
                    <FormErrorBlock error={passenger.error.firstName} />
                  </FormGroup>
                </Col>
                <Col md={4} xs={5} key={"lasttname-" + index}>
                  <FormGroup className={passenger.error.lastName ? "has-error" : null}>
                    <FormControl
                      type="text"
                      placeholder={rPax.LastName + ' *'}
                      value={passenger.lastName || ""}
                      autoComplete={autoCompleteSeed}
                      onChange={e => this.setPassengerState(index, "lastName", e.target.value)}
                      disabled={passenger.namesLocked}
                    />
                    <FormErrorBlock error={passenger.error.lastName} />
                  </FormGroup>
                </Col>
                <Col md={4} xs={7}>
                  <span className="birthday-label">{rPax.DateOfBirth} <span className="mandatory">*</span></span>
                  <FormGroup className={passenger.error.dateOfBirth ? "has-error" : null}>                  
                    <DateInput value={passenger.dateOfBirth} onChange={value => this.setPassengerState(index, 'dateOfBirth', value)} />
                    <FormErrorBlock error={passenger.error.dateOfBirth}/>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          );
        })
        }
      {this.state.isShowSelectEmployeeWindow && <SelectEmployee 
        isShow={this.state.isShowSelectEmployeeWindow} 
        employeeInfo={employeeInfo} 
        selectedEmployeeIds={selectedEmployeeIds}
        onSelect={this.onSelectEmployee} 
        onClose={this.closeSelectEmployeeWindow} />}
      </React.Fragment>
    )
  }
}
