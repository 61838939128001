import types from "./types";

const INITIAL_STATE = {
  show: false
};


export default function (state = INITIAL_STATE, { type: actionType }) {
  switch (actionType) {
  case types.SHOW_BACKDROP:
    return {
      ...state,
      show: true
    };
  case types.HIDE_BACKDROP:
    return {
      ...state,
      show: false
    };
  default:
    return state;
  }
}