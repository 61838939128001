import React from "react";
import { Row, Col, Checkbox } from "react-bootstrap";
import Icon from "react-icons-kit/Icon";
import {ic_done} from 'react-icons-kit/md/ic_done'


import "./index.scss";
import InsuranceLogo from "../../../images/tune.png"

import { numberFormatter, stringFormatter } from "../../../helpers";
import { ls } from "../../../locale";

export default function Insurance({insurance, addInsurance, removeInsurance}) {

  if (!insurance || !insurance.service) return null;

  const rInsurance = ls.t('ExtraPage.Insurance');
  if(!rInsurance.Label) return null;

  const amount = insurance.service.amount; 
  const selected = insurance.service.selected;
  const removable = insurance.service.removable;

  const serviceAmount = numberFormatter.decimalSplitter(numberFormatter.formatCurrency(amount));
  serviceAmount.decimal = numberFormatter.oneDecimalPoint(serviceAmount.decimal, insurance.currencyCode);

  const terms = rInsurance.Note + ' ' + 
    stringFormatter.formatString(rInsurance.Statement, `<a href="${rInsurance.Link}" target="_blank"> ${rInsurance.Terms}</a>`);
  const items = rInsurance.Items.split(/[\r|\n]/);

  return (
    <div className="insurance-section">
      <h1>{rInsurance.Label}</h1>
      <Row>
        <Col md={10} className="details">
          <img height="80" src={InsuranceLogo} alt="Travel Insurance" />
          <div className="terms">
            <div className="terms-cont">{rInsurance.Title}</div>
            <div className="terms-cont items">{items.map((item, index) => <span key={index}><Icon icon={ic_done}/> {item}</span>)}</div>
            <div className="terms-cont tips first-tips" dangerouslySetInnerHTML={{__html: terms}}/>
          </div>
        </Col>
        <Col md={2} className= { selected ? "selection-button selected" : "selection-button" }
          onClick={() => { if(selected && removable) {removeInsurance()} else if(!selected) {addInsurance()} }}>
          <span className="title">{insurance.currencyCode} {serviceAmount.amount}
            <span className="decimal-point">{serviceAmount.decimal}</span>
          </span>
          <p className="description">{rInsurance.Label}</p>
          <Checkbox name="checkInsurance" checked={selected} onChange={() => {}}>
            <label></label>
          </Checkbox>
        </Col>
      </Row>
    </div>
  )
}
