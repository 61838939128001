import React, { useState, useEffect, useCallback } from 'react'
import dayjs from "dayjs";
import { Col, Row } from "react-bootstrap";
import Dropdown from "react-dropdown";


export default function DateSelect({date, minDate, maxDate, onDateChanged, className, disabled, order, children }) {

  const [state, setState] = useState();

  useEffect(() => {

    if(!date) date = new Date();
    date = dayjs(date);

    if(!minDate) minDate = date.clone().add(-10, 'year');
    minDate = dayjs(minDate);

    if(!maxDate) maxDate = date.clone().add(10, 'year');
    maxDate = dayjs(maxDate);

    if(minDate.diff(maxDate) > 0) maxDate = minDate.clone().add(10, 'year');

    setState({ minDate, maxDate })
  }, [])

  const callDateChanged = useCallback(date => {
    const result = date.format('YYYY-MM-DD');    
    if(typeof(onDateChanged) === 'function') onDateChanged(result);
  }, [onDateChanged])

  const changeDay = (date, day) => callDateChanged(date.date(day));

  const changeMonth = (date, month) => callDateChanged(date.month(month));

  const changeYear = (date, year) => callDateChanged(date.year(year));

  // render()

  // not initialized yet
  if(!state) return null;

  if(!date) date = new Date();
  date = dayjs(date);

  const year = date.year(),
        month = date.month(),
        day = date.date();

  const yearValue = {value: year, label: year + ''},
        monthValue = monthList.find(m => m.value === month),
        dayValue = { value: day, label: day + '' };

  const days = [], months = [], years = [];

  if(order === 'asc')
    for(let i= state.minDate.year(); i<=state.maxDate.year(); i++) years.push(i);
  else
    for(let i=state.maxDate.year(); i>=state.minDate.year(); i--) years.push(i);

  const monthStart = 0, monthEnd = 11;
  const dayStart = 1
  let dayEnd = 31;

  const monthOption = monthList.find(m => m.value === month);
  if(monthOption) dayEnd = monthOption.days;

  for(let i=monthStart; i<=monthEnd; i++) months.push(monthList.find(m => m.value === i));
  for(let i=dayStart; i<=dayEnd; i++) days.push(i);

  return (
    <Row>
      <Col md={4} xs={4}>
        {children}
        <Dropdown disabled={disabled}
          className={className}
          options={days}
          placeholder="Date"
          value={dayValue}
          onChange={option => changeDay(date, option.value)} />
      </Col>
      <Col md={4} xs={4}>
        <Dropdown disabled={disabled}
          className={className}
          options={months}
          placeholder="Month"
          value={monthValue}
          onChange={option => changeMonth(date, option.value)} />
      </Col>
      <Col md={4} xs={4}>
        <Dropdown disabled={disabled}
          className={className}
          options={years}
          placeholder="Year"
          value={yearValue}
          onChange={option => changeYear(date, option.value)} />
      </Col>
    </Row>
  )  
}


const monthList = [
  { value: 0,  label: "Jan", days: 31},
  { value: 1,  label: "Feb", days: 29},
  { value: 2,  label: "Mar", days: 31},
  { value: 3,  label: "Apr", days: 30},
  { value: 4,  label: "May", days: 31},
  { value: 5,  label: "Jun", days: 30},
  { value: 6,  label: "Jul", days: 31},
  { value: 7,  label: "Aug", days: 31},
  { value: 8,  label: "Sep", days: 30},
  { value: 9,  label: "Oct", days: 31},
  { value: 10, label: "Nov", days: 30},
  { value: 11, label: "Dec", days: 31}
];