import React, { Component } from "react";
import { connect } from "react-redux";
import { ls } from "../../locale";
import dayjs from "dayjs";
import { getAgentPath, jsonHelper } from "../../helpers";

import { Button, Col, Modal } from "react-bootstrap";
import scrollToElement from "scroll-to-element";
import { history, numberFormatter, 
  stringFormatter, isInAppBrowser, 
  commonHelpers, paymentHelper, 
  logEvent } from "../../helpers";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import StatusBar from "../../components/StatusBar";

// Ducks
import { resourceActions } from "../../state/ducks/resources";
import { bookingActions } from "../../state/ducks/booking";
import { paymentActions } from "../../state/ducks/payment";
import { sessionActions } from "../../state/ducks/session";
import { memberActions } from "../../state/ducks/member";
import { mmbActions } from "../../state/ducks/mmb";

import PaymentOptions from "./PaymentOptions";
import Passengers from "./Passengers";
import Contact from "./Contact";
import LoginWindow from "./LoginWindow";
import RegisterForm from "./RegisterForm";

import "./index.scss";
import { DataCollectionIframe } from "./DataCollectionIframe";
import { PaymentStepupWindow } from "./PaymentStepupWindow";
import { backdropActions } from "../../state/ducks/backdrop";
import Backdrop from "../../components/Backdrop";
import { isDomesticFlight } from "./helper";

const loadedFingerprints = [];

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.lastRefreshedPayment = null;

    let userData = props.getUserData();
    let isShowLogin = false;

    if (!userData || !userData.loggedIn) {
      isShowLogin = true;
      userData = null;
    }

    this.state = {
      formValid: true,
      message: null,
      isShowMessageBox: false,
      errors: {},
      isShowLogin,
      userData,

      passengers: [],
      contact: { error: {} },

      currencyCode: "OMR",
      paymentMethodCode: "CS",
      paymentConvertionMessage: null,
      amount: 0,
      amountToPay: 0,
      adminFee: 0,
      localDebitBins: [],
      paymentStatus: this.props.location.state && this.props.location.state.payment,
      isEppEligible: false,
      paymentMethods: [],
      enrollDetail: {},

      isShowInsuranceMessageBox: false,
      insuranceMessage: [],
    };
  }

  componentDidMount() {

    this.unblockHistory = history.block(() => {
      //return 'Are you sure you want to leave this page?'
      if (!this.props.loadingStatus.loading) {
        const message = ls.t('Common.BlockBackButton.Message')
        const title = ls.t('MessageBox.Title.Warning');

        this.showMessageBox(message, title); // ('Please use the back button on the page below to navigate back.', 'Warning');
      }
      return false;
    });
    
    this.setupBooking();

    // setup cardinal event 
    window.addEventListener("message", this.handleCardinalMessage)
    window.document.addEventListener('TDS_PAYMENT_RESULT', this.handleTdsPaymentResult)
  }

  componentWillUnmount() {
    this.unblockHistory();
    window.removeEventListener("message", this.handleCardinalMessage)
    window.document.removeEventListener('TDS_PAYMENT_RESULT', this.handleTdsPaymentResult)
  }

  componentDidUpdate(prevProps) {
    const currentState = this.props.isShowBackdrop
    if (prevProps.isShowBackdrop !== currentState && !currentState) {
      this.setState({
        enrollDetail: { show: false }
      });
    }
  }

  handleCardinalMessage = event => {

    if (event.origin !== process.env.REACT_APP_CENTINELAPI) {
      //console.log('origin not match: ', event.origin, process.env.REACT_APP_CENTINELAPI)
      return;
    }
    
    const data = JSON.parse(event.data);

    logEvent({
      trackId: data.SessionId,
      eventName: 'cardinal event', 
      details: {
        eventData: data
      }
    })

    this.processedCallback(data)
  }

  handleTdsPaymentResult = event => {
    const self = this
    const detail = event.detail

    logEvent({
      eventName: 'tds payment result received',
      details: detail
    })

    setTimeout(() => {

      this.props.showBackdrop(false)
      
      const state = {
        enrollDetail: {
          show: false
        },
      }

      // detail is null: invalid parameter:
      // detail.code is not null: validation failed
      if(!detail || detail.code) {
        // handle error code
        self.setState({
          paymentStatus: {
            code: "1000",
            reference: detail.reference
          },
          ...state
        })
        return
      }
      
      self.setState(state)
      // refresh the page
      self.goNext()

    }, 100);
  }

  setupFingerPrint = (eppSelected) => {
    this.props.getFingerprint(eppSelected).then(response => {
      if(!response.fingerprintUrl) return;

      if(loadedFingerprints.includes(response.fingerprintUrl)) return;
      loadedFingerprints.push(response.fingerprintUrl);

      // fingerprint onload callback
      const onload = () => {
        logEvent({
          trackId: response.reference ?? 'notracking',
          eventName: 'fingerprint loaded', 
          details: {
            url: response.fingerprintUrl
          }
        })
      }
      
      // fingerprint onerror callback
      const onerror = () => {
        logEvent({
          trackId: response.reference ?? 'notracking',
          eventName: 'fingerprint failed', 
          details: {
            url: response.fingerprintUrl
          }
        })
        // try one more time. no more retry because we don't pass onerror again
        setTimeout(() => {
          logEvent({
            trackId: response.reference ?? 'notracking',
            eventName: 'retry loading fingerprint', 
            details: {
              url: response.fingerprintUrl
            }
          })
          commonHelpers.createFinderprintScriptTag(response.fingerprintUrl, onload);
        }, 1000)
      }
      
      // load the script 
      commonHelpers.createFinderprintScriptTag(response.fingerprintUrl, onload, onerror);
      
    })
  }

  setupPaymentMethods = () => {
    return this.props.getPaymentMethods()
    .then(response => {
      const paymentMethods = response.paymentMethods || [];
      this.setState({
        paymentMethods,
        localDebitBins: response.localDebitBins || []
      });

      return paymentMethods;
    });
  }

  setupBooking = () => {
    this.props.getCountries().then(countries => {
      this.props.summaryPnr().then(({booking}) => {
        if (booking.bookingStatus === 'Confirmed' && booking.paymentStatus === "Paid") {
          this.goNext();
          return;
        }

        this.setState({
          currencyCode: booking.currencyCode
        });

        // update passenger info
        const paxData = this.props.getPassengerData();
        if (paxData && paxData.firstName && paxData.lastName) {
          this.prefillPassengerInfo(booking, paxData, countries);
        }
        // get tatol to pay
        this.setupPaymentMethods().then(methods => {

          if (!Array.isArray(methods) || !methods.length) return;
          if(this.state.amountToPay > 0) {
            this.setPaymentMethodState(methods[0])
          }
          else if(booking.voucherApplied) { 
            // no amount to pay, most likely the booking is fully paid by voucher,
            // so now we set the payment method to voucher and disable the option
            let paymentMethod = methods.find(x => x === 'VCHR');
            if(!paymentMethod) paymentMethod = methods[0];
            this.setPaymentMethodState(paymentMethod)
          }
          else {
            this.setPaymentMethodState(methods[0])
          }
        });

        if (paymentHelper.hasStaffFare(booking)) {
          this.props.getEmployeeInfo();
        }
      });
    });
  }

  processedCallback = results => {

    //console.log('processedCallback: ', results)

    // in correspondence with the call showLoading() before calling window.Cardinal.setup("init")
    this.props.hideLoading();
    if (!results.Status) {
      const errors = [{ errorCode: 500, message: "unable to validate the card number." }];
      throw errors;
    }

    return this.props.tdsEnrol(this.paymentDetails, results)
      .then((tdsResponse) => {

        //console.log('tdsResponse: ', tdsResponse)
        logEvent({
          trackId: "notrakcing",
          eventName: 'tds enroll complete',
          details: tdsResponse
        })

        if (!tdsResponse.success) {
          const errors = [{ errorCode: 500, message: "unable to complete processing payment." }];
          this.handlePaymentError(errors);
          this.setupPaymentMethods().then(() => {
            this.setupFingerPrint();
          });
          return
        }

        if (tdsResponse.paymentComplete) {
          this.goNext();
        }
        else if (tdsResponse.require3DS) {
          // scroll to the top of the page to show the 3DS iframe
          window.scrollTo(0, 0);          
          this.props.showBackdrop(true);
          this.setState({
            enrollDetail: {
              show: true,
              ...tdsResponse.enrollDetail
            },
          })
        }
        else {
          this.setState({
            paymentStatus: {
              code: "1000",
              reference: ''
            }
          })
          scrollToElement('main-container');
          this.setupPaymentMethods().then(() => {
            this.setupFingerPrint()
          });
        }
      })
      .catch((error) => {
        // in correspondence with the call showLoading() before calling window.Cardinal.setup("init")
        this.props.hideLoading();
        this.handlePaymentError(error);
      })
  }

  prefillPassengerInfo(booking, paxData, countries) {
    const firstPax = Array.isArray(booking.passengers) && booking.passengers.length > 0 ? booking.passengers[0] : null;
    if (firstPax && !firstPax.firstName && !firstPax.lastName) {

      const searchParams = this.props.getSearchParams()
      // don't populate passenger names if it's a booking for friends
      if(!searchParams || searchParams.fare !== 'discounted') {
        firstPax.firstName = paxData.firstName;
        firstPax.lastName = paxData.lastName;
        firstPax.dateOfBirth = paxData.dateOfBirth;
        firstPax.title = paxData.title;
      }

      const contact = booking.contact;
      if (!contact.mobile) contact.mobile = paxData.mobile;
      if (!contact.email) contact.email = paxData.email;

      if (paxData.country && Array.isArray(countries)) {
        const country = countries.find(c => c.countryCode === paxData.country);
        if (country) {
          contact.address.countryCode = paxData.country;
          contact.address.country = country.countryName;
        }
      }
    }
  }

  setPaymentMethodState = (code) => {
    this.setState({
      paymentMethodCode: code
    });

    if(code === 'CS') {
      this.setupFingerPrint();
    }

    return this.refreshPaymentTotal(code);
  };

  refreshPaymentTotal = (code, forceRefresh) => {

    if(code === this.lastRefreshedPayment && !forceRefresh) {
      return Promise.resolve({
        amount: this.state.amount,
        amountToPay: this.state.amountToPay,
        adminFee: this.state.adminFee,
        paymentConvertionMessage: this.state.paymentConvertionMessage,
        additionalOptions: this.state.additionalOptions
      });
    }

    return this.props.getPaymentTotal(code)
    .then((data) => {

      this.lastRefreshedPayment = code;

      let message = null;
      if (data.needsConvertion)
        message = `Payment will be in ${data.convertedTo} ${data.convertedAmount}. ` +
                  `(Conversion rate: 1 ${data.convertedFrom} = ${data.rate.toPrecision(4)} ${data.convertedTo})`;

      const result = {
        amount: data.amount,
        amountToPay: data.amountToPay,
        adminFee: data.adminFee,
        paymentConvertionMessage: message,
        additionalOptions: data.additionalOptions,
        isEppEligible: false, // always set to false when the payment method is changed
      };
      this.setState({...result});
      return result;
    });
  }


  continue = () => {

    // prevent double click by remember the last called time
    const now = new Date().getTime();
    if(this.lastCalledTime && now - this.lastCalledTime < 3000) return;
    this.lastCalledTime = now;
    
    const rError = ls.t('Error')
    const rCheckoutPage = ls.t('CheckoutPage')

    const termsState = this.paymentOptionsComponent.getTermsState()
    if (!termsState) {
      this.showMessageBox(rError.CheckoutTerms); // "You must agree to the terms and conditions to process checkout"
      return;
    }

    const valid = this.validateForm();
    if (!valid) {
      this.showMessageBox(rError.InputError.Desc); //  "Some fields do not match the requirements. Please check."
      return;
    }

    const insurance = this.hasInsuranceAdded();
    if(insurance) {
      // check if there are passengers over 75 years old
      const formValues = this.getFormValues();
      const { ageExceeded, ageBase } = this.hasAgeCriteriaForInsurance(formValues.passengers);
      if (ageExceeded) {
        const message = rCheckoutPage.RemoveInsuranceConfirmation.replace('_A_', ageBase);
        this.showInsuranceMessageBox(insurance, message);
        return;
      }

      // check if the payment is going to be made with Pay Later
      const paymentOption = this.paymentOptionsComponent.getFormValues();
      if(paymentOption.paymentMethodCode === 'PL') {
        this.showInsuranceMessageBox(insurance, rCheckoutPage.RemoveInsuranceConfirmationPL);
        return;
      }
    }
    
    this.commitCheckout();
    
  };

  commitCheckout = () => {
    const paymentOption = this.paymentOptionsComponent.getFormValues();
    const formValues = this.getFormValues();

    if (!formValues) return;

    // skip calling updatePassengers in mmb if payment has started
    const booking = this.props.booking;
    if (booking.paymentStarted && booking.bookingMode === 'MMB') {
      this.makePayment(paymentOption);
      return;
    }

    // commit passenger info
    this.props.updatePassengers(formValues.passengers, formValues.contact)
      .then(passengerResponse => {

        if (!this.state.isShowLogin || !formValues.contact.registerProfile) return passengerResponse;

        const pax = passengerResponse.passengers[0];
        const contact = passengerResponse.contact;

        const register = {
          userName: contact.email,
          password: formValues.contact.profilePassword,
          title: pax.title,
          firstName: pax.firstName,
          lastName: pax.lastName,
          nationality: contact.address.countryCode,
          dateOfBirth: pax.dateOfBirth,
          mobile: contact.mobile,
          email: contact.email
        }
        return this.props.memberRegister(register);
      })
      .then(() => {
        this.makePayment(paymentOption);
      })
  }

  hasInsuranceAdded = () => {
    const booking = this.props.booking;
    if (!booking
      || booking.bookingMode !== 'Booking'
      || !Array.isArray(booking.segments)
      || !booking.segments.length
      || !Array.isArray(booking.passengers)
      || !booking.passengers.length)
      return false;

    // check if the insurance is added
    const passenger = booking.passengers[0];
    if (!passenger.insurances || !passenger.insurances.length) return false;

    const insurance = jsonHelper.cloneDeep(passenger.insurances[0]);
    for (let i = 1; i < booking.passengers.length; i++) {
      const p = booking.passengers[i];
      if (p && p.insurances && p.insurances.length)
        insurance.amount += p.insurances[0].amount;
    }

    return insurance;
  }

  // this function returns the insurance object if it should be removed;
  // otherwise return false (or null)
  hasAgeCriteriaForInsurance = (passengers) => {
    if (!Array.isArray(passengers)) return false;
    
    // find the last departure flight
    const booking = this.props.booking;
    const lastFlight = booking.segments[booking.segments.length - 1];
    const departureDate = dayjs(lastFlight.departureDate);

    let ageBase = 75;

    let ageExceeded = false;
    passengers.forEach(p => {
      const dob = dayjs(p.dateOfBirth);
      const diff = departureDate.diff(dob, 'year');
      if (diff >= ageBase) ageExceeded = true;
    })

    return { ageExceeded, ageBase };
  }

  start3DS = (paymentDetails) => {
    this.paymentDetails = paymentDetails;

    this.props.authorizeCS(paymentDetails)
      .then((tokenResponse) => {

        // console.log('authorizeCS: ', tokenResponse)

        // setup data collection iframe
        const cardinalCollectionForm = document.querySelector('#cardinal_collection_form');
        if(cardinalCollectionForm) { // form exists
          logEvent({
            trackId: "notracking",
            eventName: 'data collection form submitted',
            details: {
              authDetail: tokenResponse.authDetail,
            }
          })

          this.props.showLoading();

          const cardinal_collection_form_input = document.getElementById("cardinal_collection_form_input")
          cardinal_collection_form_input.value = tokenResponse.authDetail.accessToken        
          
          //console.log('start data collection: ', tokenResponse.authDetail.dataCollectionUrl)
          
          cardinalCollectionForm.action = tokenResponse.authDetail.dataCollectionUrl
          cardinalCollectionForm.submit();

        }

        this.authDetail = tokenResponse.authDetail
        
        this.props.getBooking();
      })
      .catch((errors) => {
        this.handlePaymentError(errors);
      });
  }

  handlePaymentError = errors => {

    //console.log('handlePaymentError: ', errors)

    if (!Array.isArray(errors)) return;

    const rejected = errors.find(x => x.errorCode === 700);
    if(rejected) {
      this.setState({
        paymentStatus: {
          code: "1000",
          reference: ''
        }
      })
      scrollToElement('#main-container');
      return;
    }

    const paymentError = errors.find(x => x.errorCode === 502);
    if (!paymentError) return;

    const nextPage = "/payment-error";

    this.unblockHistory();
    history.push("/" + ls.lang + nextPage);
  }

  
  isLocalDebitBins = (cardNumber, gateway) => {
    const debitBins = this.state.localDebitBins;
    if(!Array.isArray(debitBins) || !debitBins.length) return false;
    const bins = debitBins.filter(x => x.paymentMethod === gateway).map(b => b.bin)
    if(!bins.length) return false;

    cardNumber = cardNumber.replace(/\s/g, '');

    const match = bins.find(x => cardNumber.startsWith(x));
    return !!match;
  }

  makePayment = (paymentOption) => {
    this.setState({
      paymentStatus: null
    });

    // make payment
    if (paymentOption.paymentMethodCode === "CS") {

      // check if the card number is in OmanNet bins
      if(this.isLocalDebitBins(paymentOption.cardNumber, 'ON') || this.isLocalDebitBins(paymentOption.cardNumber, 'SP')) {
        const rPayment = ls.t('Payment');
        // "This card is locally issued in Oman. 
        // As per the regulations issued by Central Bank Of Oman, 
        // you will need to choose OmanNet payment option to process the payment."
        const message = rPayment.LocalDebit; 
        this.showMessageBox(message);
        return;
      }

      const expiryFields = paymentOption.expiration.split("/")

      // check if card number is in MADA bins
      if(this.isLocalDebitBins(paymentOption.cardNumber, 'MADA')) {
          this.props.getMadaPaymentParams().then(response => {
          
            if(response.formAction) {
              const data = [...response.paymentFormData]
              data.push({fieldName: 'card_number', fieldValue: paymentOption.cardNumber, includeInForm: true })
              data.push({fieldName: 'expiry_date', fieldValue: `${expiryFields[1]}${expiryFields[0]}`, includeInForm: true })
              data.push({fieldName: 'card_holder_name', fieldValue: paymentOption.nameOnCard, includeInForm: true })
              data.push({fieldName: 'card_security_code', fieldValue: paymentOption.cvc, includeInForm: true })

              const form = paymentHelper.createMadaForm(response.formAction, data)

              this.props.showLoading()

              form.submit()
            }
        })
        return
      }

      const details = {
        methodCode: "CS",
        cardNumber: paymentOption.cardNumber,
        nameOnCard: paymentOption.nameOnCard,
        expiryYear: expiryFields[1],
        expiryMonth: expiryFields[0],
        cvNumber: paymentOption.cvc,
        eppSelected: this.state.isEppEligible && !!paymentOption.eppSelected
      };
      this.start3DS(details);
      return;
    }
    else if (paymentOption.paymentMethodCode === "VCHR") {
      const request = {
        voucherNumber: paymentOption.code,
        pin: paymentOption.pin
      };

      this.props
        .makeVoucherPayment(request)
        .then((voucherResponse) => {
          if (voucherResponse.remaining === 0) {
            if(voucherResponse.bookingConfirmed === true) {
              this.goNext();
            }
            else {
              this.paymentOptionsComponent.resetCurrentPayment(true);
              this.props.getBookingWithSummary()
              .then(() => this.refreshPaymentTotal('VCHR', true));
            }
          } else {
            this.paymentOptionsComponent.resetCurrentPayment();
            this.setPaymentMethodState("CS")
            .then(() => {
              this.props.getBookingWithSummary();
            });
          }
          return;
        })
        .catch((error) => {
          console.log(error)
          this.props.getBooking();
        });
    }
    else if (paymentOption.paymentMethodCode === "ON") {
      this.props.makeOmanDebitPayment()
      .then((onResponse) => {
        // we show the loading mask to prevent the 'Pay Now' button is hit again
        this.props.showLoading(); 
        window.location.href = onResponse.redirectUrl;
      })
    }
    else if(paymentOption.paymentMethodCode === 'KNET') {
      // console.log('pay with KNET.')
      this.props.getKnetPaymentParams().then(response => {
        if(response.formAction) {
          // console.log(response)
          const data = [...response.paymentFormData]
          const form = paymentHelper.createMadaForm(response.formAction, data)
          this.props.showLoading()
          form.submit()
        }
      })
    }
    else if(paymentOption.paymentMethodCode === 'SP') {
      // console.log('pay with SmartPay.')
      this.props.getSmartPayParams().then(response => {
        if(response.url && response.encryptedRequest) {
          // console.log(response)
          const data = [{
            fieldName: 'encRequest',
            fieldValue: response.encryptedRequest
          },{
            fieldName: 'access_code',
            fieldValue: response.accessCode
          }]
          const form = paymentHelper.createSmartPayForm(response.url, data)
          this.props.showLoading()
          form.submit()
        }
      })
    }
    else if (paymentOption.paymentMethodCode === 'INVC') {
      this.props.makeAGPayment().then(() => {
        this.goNext();
      })
    }
    else if (paymentOption.paymentMethodCode === 'PL') {
      this.props.makePLPayment().then(() => {
        this.goNext();
      })
    }
    else if(paymentOption.paymentMethodCode === 'FT') {
      this.props.makeFTPayment().then(() => {
        this.goNext();
      })
      .catch(errors => {
        this.handlePaymentError(errors);
      })
    }
    else if(paymentOption.paymentMethodCode === 'CASH') {
      const request = {
        refNumber: paymentOption.refNumber
      }
      this.props.makeCashPayment(request).then(() => {
        this.goNext();
      })
      .catch(errors => {
        this.handlePaymentError(errors);
      })
    }
  };

  checkCardNumberForMadaPayment = cardNumber => {

    if(this.state.paymentMethodCode !== 'CS') return

    if(this.isLocalDebitBins(cardNumber, 'MADA')) {
      this.refreshPaymentTotal('MADA')
    }
    else if(this.isLocalDebitBins(cardNumber, 'KNET')) {
      this.refreshPaymentTotal('KNET')
    }
    else {
      this.refreshPaymentTotal('CS').then(data => {
        if(data.additionalOptions?.includes('EPP')) {
          this.setState({
            isEppEligible: this.isLocalDebitBins(cardNumber, 'EPP')
          })          
        }
      })
    }
  }

  goNext = () => {
    const { booking } = this.props;

    let nextPage = `/confirmation`;
    if (booking.bookingMode === "MMB") nextPage = `/mmb/summary`;
    else if (booking.bookingMode === "WCI") nextPage = "/wci/confirmation";

    const url = "/" + ls.lang + getAgentPath() + nextPage;

    this.unblockHistory();
    history.push(url);

  };

  goBack = () => {
    const booking = this.props.booking;
    if (booking && booking.bookingMode === 'MMB')
      this.jumpTo(`/mmb/summary`);
    else
      this.jumpTo(`/seats`);
  };

  jumpTo = (item) => {
    if (!item || this.props.booking.paymentStarted) return;

    this.unblockHistory();
    history.push("/" + ls.lang + getAgentPath() + item);
  };

  validateForm = () => {
    let isPassengersValid = true;
    let isContactValid = true;
    const booking = this.props.booking;

    if (!booking.paymentStarted) {
      // validate passengers
      if (this.passengersComponent && booking.bookingMode === "Booking") {
        isPassengersValid = this.passengersComponent.validateForm();
      }
    }

    if (this.contactComponent) {
      isContactValid = this.contactComponent.validateForm();
    }

    const isRegistrationFormValid = this.state.isShowLogin && this.registerForm ? this.registerForm.validateForm() : true;

    const isPaymentFormValid = this.state.amountToPay  > 0 ? this.paymentOptionsComponent.validateForm() : true;
    return isPassengersValid && isContactValid && isPaymentFormValid && isRegistrationFormValid;
  };

  getFormValues = () => {
    const result = {
      passengers: [],
      contact: {},
    };

    if (this.passengersComponent)
      result.passengers = this.passengersComponent.getFormValues();
    if (this.contactComponent)
      result.contact = this.contactComponent.getFormValues();
    else
      result.contact = this.props.booking.contact;

    if (this.state.isShowLogin && this.registerForm) {
      const register = this.registerForm.getFormValues();
      if (register.checked) {
        result.contact.registerProfile = true;
        result.contact.profilePassword = register.password;
      }
    }

    return result;
  };

  getLastFlightDate = () => {
    let segments = this.props.booking.segments;
    if (!segments || !segments.length) return null;

    segments = segments.filter((x) => x); // filter out null segment
    const flight = segments[segments.length - 1];
    let date = flight.departureDate;

    const isDate = date instanceof Date;
    if (isDate) return date;

    date = Date.parse(date);
    if (Number.isNaN(date)) return null;

    return new Date(date);
  };

  removeInsurance = () => {
    this.closeInsuranceMessageBox();

    this.props.removeInsurance()
      .then(() => {
        this.commitCheckout();
      });
  }

  closeMessageBox = () => {
    this.setState({
      isShowMessageBox: false,
      message: null
    });
  };

  showMessageBox = (message, title) => {
    this.setState({
      isShowMessageBox: true,
      message: message,
      messageTitle: title
    });
  };

  showLoginWindow = () => {
    this.loginWindow.show();
  }

  showInsuranceMessageBox = (insurance, message) => {
    if (!insurance) return;

    const bookingSummary = this.props.bookingSummary;
    const insuranceRemovedTotal = numberFormatter.formatCurrency(bookingSummary.bookingTotal - bookingSummary.paidTotal - insurance.amount);
    const insConfirmation = message
      ? stringFormatter.formatString(message, `${this.state.currencyCode} ${insuranceRemovedTotal}`).split(/[\r|\n]/)
      : [];

    this.setState({
      isShowInsuranceMessageBox: true,
      insuranceMessage: insConfirmation,
    })
  }

  closeInsuranceMessageBox = () => {
    this.setState({
      isShowInsuranceMessageBox: false,
      insuranceMessage: [],
    })
  }

  login = (username, password) => {
    this.loginWindow.hide();
    this.props.memberLogin(username, password).then(() => {
      this.setState({
        isShowLogin: false,
      });

      this.props.populateBooking().then(() => {
      })
    });
  }

  resetPassword = username => {
    this.loginWindow.hide();
    this.props.forgotPassword(username).then(() => {
      this.showMessageBox('Your new password has been sent to your contact email. Please check your mailbox.', 'Notification');
    })
  }

  handlePaymentStepupFormSubmit = data => {
    logEvent({
      trackId: data.SessionId ?? "notracking",
      eventName: 'stepup form submitted',
      details: data
    })
  }

  handlePaymentStepupFormError = (message, data) => {
    logEvent({
      trackId: data.SessionId ?? "notracking",
      eventName: 'stepup form error',
      details: {
        error: message,
        data
      }
    })
  }

  render() {

    const rCheckoutPage = ls.t('CheckoutPage');
    if (!rCheckoutPage.TotalDue) return null;

    const rButton = ls.t('Button');
    const rMessageBox = ls.t('MessageBox');
    const rError = ls.t('Error');
    const rPayment = ls.t('Payment');

    const { countries, booking } = this.props;
    const passengers = (booking && booking.passengers) || [];
    const contact = (booking && booking.contact) || {};

    let flightDate = this.getLastFlightDate();
    if (!flightDate) flightDate = new Date();

    const paymentStatus = this.state.paymentStatus;
    const paymentStarted = booking.paymentStarted;

    const inAppBrowser = isInAppBrowser();

    const employeeInfo = this.props.employeeInfo || {};

    const inBookingMode =
      !booking || booking.bookingMode === "Booking";

    const bookingSummary = this.props.bookingSummary;

    const amount = this.state.amount + bookingSummary.paidTotal - this.state.adminFee
    const splittedAmount = numberFormatter.decimalSplitter(numberFormatter.formatCurrency(amount));
    const splittedPaidTotal = numberFormatter.decimalSplitter(bookingSummary.paidTotal);
    const splittedAmountToPay = numberFormatter.decimalSplitter(numberFormatter.formatCurrency(this.state.amountToPay));
    const splittedAdminFee = numberFormatter.decimalSplitter(this.state.adminFee);

    const userData = this.state.userData;

    const isStaffFare = paymentHelper.hasStaffFare(booking)

    const terms = `<a href="${rPayment.Links.TermsAndConditions}" target="_blank">${rPayment.TermsConditions}</a>`;
    const usage = `<a href="${rPayment.Links.UsagePolicy}" target="_blank">${rPayment.UsagePolicy}</a>`;
    const baggage = `<a href="${rPayment.Links.BaggagePolicy}" target="_blank">${rPayment.BaggagePolicy}</a>`;
    const carriage = `<a href="${rPayment.Links.CarriageConditions}" target="_blank">${rPayment.CarriageConditions}</a>`
    const statement = `${stringFormatter.formatString(rPayment.Statement, terms, usage, baggage, carriage)}`;

    const isShowPaymentDenied = paymentStatus && (paymentStatus.code === "1000" || paymentStatus.code === '1001')

    return (
      <div id="main-container"
        className={!inAppBrowser 
          ? ls.t("rootClass") + " main-container checkout-page " 
          : ls.t("rootClass") + " main-container checkout-page in-mobile-app"}
        dir={ls.t("direction")}>
        <div className="header-con">
          <Header key={commonHelpers.getHashCode(bookingSummary)}
            hasSummary={true}
            beforeChangeLocation={() => this.unblockHistory()}
          />
        </div>
        <Backdrop />
        {!inAppBrowser && <StatusBar label="checkout" onItemClick={(item) => this.jumpTo('/' + item)} />}
        <div className="container passenger padt40 padb40">
          <div className="min-height">
            <input type="hidden" value="salamair" />
            {isShowPaymentDenied && <PaymentDeniedMessage message={rError.PaymentDeclined} />}

            {!paymentStarted && inBookingMode && <Passengers
              ref={(element) => (this.passengersComponent = element)}
              key={commonHelpers.getHashCode(passengers, employeeInfo)}
              passengers={passengers}
              flightDate={flightDate}
              isShowLogin={this.state.isShowLogin}
              onLogin={this.showLoginWindow}
              employeeInfo={employeeInfo}
              isStaffFare={isStaffFare}
              userData={userData}
              ancillaries={this.props.ancillaries}
              currencyCode={booking && booking.currencyCode}
              hasInsurance={this.hasInsuranceAdded()}
            />
            }
            {/* hide contact in mmb */}
            {booking && booking.bookingMode !== 'MMB' && <Contact
              ref={(element) => (this.contactComponent = element)}
              key={commonHelpers.getHashCode(contact, employeeInfo)}
              contact={contact}
              countries={countries}
              employeeInfo={employeeInfo}
            // readOnly={booking && booking.modified}
            />}

            {booking && booking.bookingMode !== 'MMB' && this.state.isShowLogin && <RegisterForm 
              ref={element => (this.registerForm = element)} />}

            <PaymentOptions
              ref={(element) => (this.paymentOptionsComponent = element)}
              userData={userData}
              statement={statement}
              paymentMethods={this.state.paymentMethods}
              paymentMethodCode={this.state.paymentMethodCode}
              paymentStatus={this.state.paymentStatus}
              voucherApplied={booking.voucherApplied}
              onPaymentMethodChanged={(method) => this.setPaymentMethodState(method)}
              onApplyVoucher={this.continue}
              onCreditCardNumberChange={this.checkCardNumberForMadaPayment}
              localDebitBins={this.state.localDebitBins}
              disabled={this.state.amountToPay === 0}
              eppEligible={this.state.isEppEligible}
              onEppSelectionChange={eppSelected => this.setupFingerPrint(eppSelected)}
            />

            <Col xs={12} md={8} mdOffset={4}>
              <div className="final-amount center-block">
                <h4>{rCheckoutPage.TotalDue}</h4>
                {!inBookingMode && <p>
                  <span>{rCheckoutPage.Charge}</span>
                  <span className="price">
                    {this.state.currencyCode}
                    <span>{splittedAmount.amount}
                      <span className="decimal-point">
                        {numberFormatter.oneDecimalPoint(splittedAmount.decimal, 'OMR')}
                      </span>
                    </span>
                  </span>
                </p>}
                {inBookingMode && <p>
                  <span>{rCheckoutPage.TicketPrice}</span>
                  <span className={"price" + ( this.state.amountToPay === 0 ? " strike-price" : '')}>
                    {this.state.currencyCode}
                    <span>{splittedAmount.amount}
                      <span className="decimal-point">
                        {numberFormatter.oneDecimalPoint(splittedAmount.decimal, 'OMR')}
                      </span>
                    </span>
                  </span>
                </p>}
                {
                  this.state.adminFee > 0 && (
                    <p>
                      <span>{rCheckoutPage.AdminFee}</span>
                      <span className="price">
                        {this.state.currencyCode}
                        <span>{splittedAdminFee.amount}</span>
                        <span className="decimal-point">
                          {numberFormatter.oneDecimalPoint(splittedAdminFee.decimal, 'OMR')}
                        </span>
                      </span>
                    </p>
                  )
                }
                {
                  //paid by voucher
                  this.props.bookingSummary.paidTotal > 0 && (
                  <p>
                    <span>{rCheckoutPage.PaidByVoucher}</span>
                    <span className="price">
                      {this.state.currencyCode}
                      <span>{splittedPaidTotal.amount}
                        <span className="decimal-point">
                          {numberFormatter.oneDecimalPoint(splittedPaidTotal.decimal, 'OMR')}
                        </span>
                      </span>
                    </span>
                  </p>
                )}

                <p className="total">
                  <span>{rCheckoutPage.TotalToPay}</span>
                  <span className="price">
                    {this.state.currencyCode}
                    <span>
                      {splittedAmountToPay.amount}
                      <span className="decimal-point">
                        {numberFormatter.oneDecimalPoint(splittedAmountToPay.decimal, 'OMR')}
                      </span>
                    </span>
                  </span>
                </p>
                {this.state.paymentConvertionMessage && (
                  <span className="conversion-rate">
                    {this.state.paymentConvertionMessage}
                  </span>
                )}
              </div>
            </Col>
            <div className="clearfix btnCon col-xs-12">
              {!this.props.booking.paymentStarted && !inAppBrowser && <Button
                className="btn btn-primary btn-cancel btn-effect"
                onClick={() => this.goBack()}>
                <span>
                  <i className="picon picon-btn-arrow-left-w" /> {rButton.Back}
                </span>
              </Button>}
              <div className="pull-right flip">
                <Button
                  className="btn btn-submit pull-right flip btn-effect"
                  onClick={() => this.continue()}
                >
                  <span>
                    { this.state.amountToPay > 0 ? rButton.PayNow : rButton.Confirm} 
                    <i className="picon picon-btn-arrow-right-w" />
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        {!inAppBrowser && <Footer />}
        <Modal className={ls.t("rootClass")} 
          show={this.state.isShowMessageBox && !this.props.loadingStatus.loading} 
          onHide={this.closeMessageBox} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{this.state.messageTitle || rMessageBox.InputError}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{this.state.message}</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeMessageBox}>{rButton.Close}</Button>
          </Modal.Footer>
        </Modal>
        <Modal className={ls.t('rootClass')} 
          show={this.state.isShowInsuranceMessageBox && !this.props.loadingStatus.loading} 
          onHide={this.closeInsuranceMessageBox} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{'Confirmation'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.insuranceMessage.map((c, index) => (<p key={index}>{c}</p>))}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeInsuranceMessageBox}>{rButton.Cancel}</Button>
            <Button onClick={this.removeInsurance}>{rButton.Continue}</Button>
          </Modal.Footer>
        </Modal>
        <LoginWindow ref={node => this.loginWindow = node} onLogin={this.login} 
          onResetPassword={this.resetPassword} />
        <DataCollectionIframe />
        {this.state.enrollDetail.show && <PaymentStepupWindow 
          md={this.state.enrollDetail.md} 
          jwt={this.state.enrollDetail.accessToken} 
          stepUpUrl={this.state.enrollDetail.stepUpUrl} 
          windowHeight={this.state.enrollDetail.windowHeight} 
          windowWidth={this.state.enrollDetail.windowWidth} 
          onSubmit={this.handlePaymentStepupFormSubmit}
          onError={this.handlePaymentStepupFormError}
        />}
      </div>
    );
  }
}

const PaymentDeniedMessage = (props) => {

  let message = props.message || '';
  message = message.split(/[\r\n]/);

  return (
    <div className="alert alert-danger">
      {message.map((m, index) => <p key={index}>{m}</p>)}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    countries: state.resources.countries,
    booking: state.booking.details,
    bookingSummary: state.booking.summary,
    loadingStatus: state.redux.loadingStatus,
    employeeInfo: state.member.employeeInfo,
    ancillaries: state.booking.ancillaries,
    isShowBackdrop: state.backdrop.show
  };
}

const mapDispatchToProps = {
  ...bookingActions,
  ...paymentActions,
  ...resourceActions,
  ...backdropActions,
  getUserData: sessionActions.getUserData,
  getPassengerData: sessionActions.getPassengerData,
  getSearchParams: sessionActions.getSearchParams,
  showLoading: sessionActions.showLoading,
  hideLoading: sessionActions.hideLoading,
  memberLogin: memberActions.memberLogin,
  forgotPassword: memberActions.forgotPassword,
  memberRegister: memberActions.memberRegister,
  getEmployeeInfo: memberActions.getEmployeeInfo,
  resetBooking: mmbActions.resetBooking,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);

