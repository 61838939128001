import React, { forwardRef, useReducer, useEffect, useImperativeHandle } from "react";
import {Row, Col, FormControl, FormGroup} from "react-bootstrap";
import DialingCodeSelect  from "../../../../components/Common/DialingCodeSelect";
import { customerValidator } from "../../../../helpers";
import "./index.scss";
import { ls } from "../../../../locale";
import FormErrorBlock from "../../../../components/FormErrorBlock";

import * as form from "../../../../reducers/formReducer";

export default forwardRef(function Contact({contact = {}, countries}, ref ) {

  const [state, dispatch] = useReducer(form.reducer, form.initialState);

  useEffect(() => {
    let country = '968';
    if(contact.mobile) {
      const values = contact.mobile.trim().split(' ');
      if(values.length > 1) {
        contact.mobile = values[1];
        country = values[0];
      }
    }

    country = { label: country }

    const values = {
      emailChanged: false,
      email: contact.email ||  '',
      confirm: '',
      mobile: contact.mobile || '',
      country: country
    }

    dispatch(form.setValues(values))
  }, [contact])

  useImperativeHandle(ref, () => {
    return {
      validateForm: () => {
        const errors = validateForm(state.values);
        dispatch(form.setErrors(errors));
        return !form.hasError(errors);
      },
      getValues: () => getValues(state.values),
    }
  }, [state])

  const setEmail = email => {
    dispatch(form.setInput('email', email));
    dispatch(form.setInput('emailChanged', true));
  }

  const setConfirm = confirm => {
    dispatch(form.setInput('confirm', confirm));
    dispatch(form.setInput('emailChanged', true));
  }

  const setMobile = mobile => {
    if(!customerValidator.validatePhoneTyping(mobile)) return;
    dispatch(form.setInput('mobile', mobile));
  }

  const setCountry = country => {
    dispatch(form.setInput('country', country));
  }

  // render()

  if(!countries || !state.values) return null;

  const rPax = ls.t('Pax');

  return (
    <Row className="booking-details">
      <Col md={4}>
        <FormGroup>
          <FormControl
            type="text"
            placeholder={rPax.Email + " *"}
            value={state.values.email}
            onChange={event => setEmail(event.target.value)} />
        </FormGroup>
        <FormErrorBlock error={state.errors.email}/>
      </Col>
      <Col md={4}>
        <FormGroup>
          <FormControl
            type="text"
            placeholder={rPax.ConfirmEmail + " *"}
            value={state.values.confirm}
            onChange={event => setConfirm(event.target.value)} />
        </FormGroup>
        <FormErrorBlock error={state.errors.confirm} />
      </Col>
      <Col md={4}>
        <Row className="phone-dropdown mobile-number">
          <Col md={4} xs={4}>
            <FormGroup>
              <DialingCodeSelect
                className="dialingcode-select"
                selected={state.values.country}
                options={countries}
                onSelected={selected => setCountry(selected)} />
            </FormGroup>
          </Col>
          <Col md={8} xs={8}>
            <FormGroup>
              <FormControl
                type="telephone"
                placeholder={rPax.Phone + " *"}
                value={state.values.mobile}
                onChange={event => setMobile(event.target.value)} />
            </FormGroup>
            <FormErrorBlock error={state.errors.mobile} />
          </Col>
        </Row>
      </Col>
    </Row>
  );

})

function validateForm(state) {

  const errors = {};

  errors.email = customerValidator.validateEmail(state.email);
  if(state.emailChanged)
    errors.confirm = customerValidator.validateConfirmEmail(state.email, state.confirm);

  errors.mobile = customerValidator.validateMobile(state.mobile);

  return errors;
}

function getValues(state) {
  let mobile = state.mobile;

  const country =state.country;
  if(country && country.label) mobile = country.label + ' ' + mobile;

  return {
    email: state.email,
    mobile: mobile,
  }
}