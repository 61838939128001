import React, { useState } from "react";
import dayjs from "dayjs";
import { FormControl, Row, Col, Button, Table } from "react-bootstrap";

import './index.scss';
import Icon from "react-icons-kit/Icon";
import { edit } from "react-icons-kit/fa/edit";

export default function FindBooking({ onSearchBooking, onManageBooking, lastSearchedBooking }) {

  const [pnr, setPnr] = useState('');

  const setBookingReference = value => {
    value = value.toUpperCase();
    if (value.length > 6) value = value.substring(0, 6);

    setPnr(value)
  }

  const searchBooking = () => {
    if (pnr && pnr.length === 6) {
      if (typeof (onSearchBooking) === 'function') onSearchBooking(pnr);
    }
  }

  const manageBooking = booking => {
    if (typeof (onManageBooking) === 'function') onManageBooking(booking);
  }

  const bookings = lastSearchedBooking && lastSearchedBooking.pnr ? [lastSearchedBooking] : []
  return (

    <React.Fragment>
      <div className="find-booking">
        <div className="booking-search">
          <Row>
            <Col md={6}>
              <FormControl type="text" placeholder="Booking Reference"
                value={pnr}
                onChange={event => setBookingReference(event.target.value)} />
            </Col>
            <Col md={3}>
              <div className="btnCon">
                <Button type="submit" className="btn btn-primary pull-left flip btn-submit btn-effect"
                  onClick={searchBooking}>
                  <span>Search<i className="picon picon-btn-arrow-right-w" /></span>
                </Button>
              </div>
            </Col>
            <Col md={3}>
              <label></label>
            </Col>
          </Row>
          {bookings.length > 0 && <div className="search-results">
            <Table>
              <thead>
                <tr>
                  <th>Ref. No</th>
                  <th>Book Date</th>
                  <th>Depart Date</th>
                  <th>City Pair</th>
                  <th>Pax</th>
                  <th>Status</th>
                  <th><span className="hidden-in-mobile">Actions</span></th>
                </tr>
              </thead>
              <tbody>
                {bookings.map(b => {

                  const booking = mapBooking(b);

                  return (
                    <tr key={booking.pnr}>
                      <td>{booking.pnr}</td>
                      <td>{dayjs(booking.bookingDate).format('DD MMM YYYY')}</td>
                      <td>{booking.departureDate ? dayjs(booking.departureDate).format('DD MMM YYYY') : null}</td>
                      <td>{booking.cityPair}</td>
                      <td>{booking.passengerCount}</td>
                      <td>{booking.status}</td>
                      <td>
                        {booking.status !== 'Cancelled'
                          && <Icon title="Manage booking" icon={edit} className="action-icon" onClick={() => manageBooking(booking)} />}
                      </td>
                    </tr>)
                })}
              </tbody>
            </Table>
          </div>}
        </div>
      </div>
    </React.Fragment>
  );

}

function mapBooking(booking) {
  if (!booking) return booking;

  const segment = booking.segments && booking.segments.length > 0 ? booking.segments[0] : null;
  const passenger = booking.passengers && booking.passengers.length > 0 ? booking.passengers[0] : null;

  let status = booking.paymentStatus === 'Paid' ? 'Confirmed' : 'Pending';
  if (!booking.passengers.length) status = 'Cancelled';

  return {
    pnr: booking.pnr,
    bookingDate: booking.bookingDate,
    departureDate: segment ? segment.departureDate : null,
    cityPair: segment ? `${segment.originCode} - ${segment.destinationCode}` : null,
    passengerCount: booking.passengers ? booking.passengers.length : 0,
    status,
    lastName: passenger ? passenger.lastName : null
  }
}

