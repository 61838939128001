import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ls } from "../../locale";
import queryString from "query-string";

import { history } from "../../helpers";
import { sessionActions } from "../../state/ducks/session";

function DeepLinkRedirectPage() {

  useEffect(() => {

    const params = queryString.parse(this.props.location.search);

    const tripType = params.isReturn ? "round" : "oneway";
    const origin = params.OriginCode;
    const destination = params.DestinationCode;
    const adult = params.Adult || "1";
    const child = params.Child || "0";
    const infant = params.Inf || "0";

    const departureDate = params.departureDate;
    const deptYear = departureDate.substring(0, 4);
    const deptMonth = departureDate.substring(4, 6);
    const deptDay = departureDate.substring(6, 8);

    let dates = `${deptYear}-${deptMonth}-${deptDay}`;
    if (tripType === "round") {
      const returnDate = params.returnDate;
      const retYear = returnDate.substring(0, 4);
      const retMonth = returnDate.substring(4, 6);
      const retDay = returnDate.substring(6, 8);

      dates += `|${retYear}-${retMonth}-${retDay}`;
    }


    const searchParams = {
      lng: ls.lang,
      flightType: tripType,
      route: `${origin}-${destination}`,
      dates: dates,
      counts: `${adult}-${child}-${infant}`,
    }

    this.props.setSearchParams(searchParams);

    const url = "/" + ls.lang + "/flights";
    history.push(url);
  }, []);

  return (
    <div className={ls.t("rootClass") + " main-container"} dir={ls.t("direction")}>
      Redirecting ...
    </div>
  );
}

const mapDispatchToProps = {
  setSearchParams: sessionActions.setSearchParams
}

export default connect(null, mapDispatchToProps)(DeepLinkRedirectPage);