const sourceVariable = "SalamAir.Source"

export function getSource() {
  return sessionStorage.getItem(sourceVariable);
}

export function setSource(source) {
  // we change the setting only when the source has a value
  if(source)
    sessionStorage.setItem(sourceVariable, source)
}

export function isInAppBrowser() {
  const source = getSource();
  return source === 'ios' || source==='android';
}