import React, { useEffect, useState, useCallback, useMemo, useReducer } from "react";
import dayjs from "dayjs";
import { Col, Row, Panel, Modal, Button } from "react-bootstrap";
import Icon from "react-icons-kit/Icon";
import { close } from "react-icons-kit/fa/close";

import { PassengerType } from "../../constants/settings";
import { passengerHelper } from "../../helpers";

import Passenger from "./Passenger";
import SeatMap from "./SeatMap";
import SeatGuide from "./SeatGuide";

import { ls } from "../../locale";

import { filterOutLegsWithSeatMap, findLeg, findPassenger, scrollToSegment } from "./helpers";

import "./index.scss";

function SeatSelection({ passengers, segments, seatMaps, currencyCode, onAssignSeat, onClearSeat, onClearAllSeat }) {

  const [, forceUpdate] = useReducer(x => x + 1, 0);
  
  const [passengerState, setPassengerState] = useState({
    logicalFlightId: -1,
    physicalFlightId: -1,
    passenger: null,
  });

  const [isShowMessageBox, setShowMessageBox] = useState(false);
  const [clickedSeat, setClickedSeat] = useState(null);

  const setSeatMap = useCallback((logicalFlightId, physicalFlightId, passenger) => {

    // scroll to the trip
    scrollToSegment(segments, logicalFlightId);

    // find saved seat
    const segment = Array.isArray(segments) && segments.find(x => x && x.logicalFlightId === logicalFlightId);
    const leg = segment && Array.isArray(segment.legs) && segment.legs.find(x => x.physicalFlightId === physicalFlightId);
    const customer = leg && passenger && Array.isArray(leg.customers) && leg.customers.find(x => x.passengerId === passenger.id);
    if(passenger) passenger.savedSeat = customer && customer.savedSeat;

    setPassengerState({
      passenger: passenger,
      logicalFlightId: logicalFlightId,
      physicalFlightId: physicalFlightId
    });
  }, [segments]);

  useEffect(() => {
    if (Array.isArray(passengers) && Array.isArray(segments)) {
      const segment = segments.find(seg => seg && seg.permissions && seg.permissions.canChangeSeats);
      if(segment) {

        const legs = filterOutLegsWithSeatMap(segment, seatMaps)
        if(legs && legs.length > 0) {

          const leg = legs[0];
          const customer = leg.customers[0];

          const passenger = passengers.find(pax => pax.id === customer.passengerId);
          if(passenger)
            setSeatMap(segment.logicalFlightId, leg.physicalFlightId, passenger);
        }
      }
    }
  }, [])


  const moveToNextPassenger = useCallback(() => {
    if (!passengerState.passenger) return;

    if (!segments || !passengers) return;

    const segmentIndex = segments.findIndex(s => s && s.logicalFlightId === passengerState.logicalFlightId);
    if (segmentIndex < 0) return;
    let segment = segments[segmentIndex];

    let legs = filterOutLegsWithSeatMap(segment, seatMaps);

    const legIndex = legs.findIndex(l => { return l.physicalFlightId === passengerState.physicalFlightId });
    if (legIndex < 0) return;

    let leg = legs[legIndex];
    let customerIndex = leg.customers.findIndex(c => c.passengerId === passengerState.passenger.id);
    if (customerIndex < 0) return;

     // there are more passengers
    while (leg.customers.length > customerIndex + 1) {
      const nextCustomer = leg.customers[customerIndex + 1];
      const nextPassenger = passengers.find(p => {return p.id === nextCustomer.passengerId});
      if (nextPassenger.paxType !== PassengerType.Infant) {
        setSeatMap(passengerState.logicalFlightId, passengerState.physicalFlightId, nextPassenger);
        return;
      }

      customerIndex = leg.customers.findIndex(c =>c.passengerId === nextPassenger.id);
      if (customerIndex < 0) return;
    }

    // now try to move to next leg
    if (legs.length > legIndex + 1) {
      leg = legs[legIndex + 1];
      const passenger = passengers.find(p => p.id === leg.customers[0].passengerId);
      setSeatMap(passengerState.logicalFlightId, leg.physicalFlightId, passenger);
      return;
    }

    // now try to move to next flight
    if (segments.length > segmentIndex + 1) {
      segment = segments[segmentIndex + 1];
      if(segment) {
        legs = filterOutLegsWithSeatMap(segment, seatMaps)
        if(legs && legs.length > 0)
        {
          leg = legs[0];
          const passenger = passengers.find(p => p.id === leg.customers[0].passengerId );
          setSeatMap(segment.logicalFlightId, leg.physicalFlightId, passenger);
          return;
        }
      }
    }
  }, [seatMaps, segments, passengers, passengerState]);


  const closeMessageBox = useCallback(() => {
    setShowMessageBox(false);
    setClickedSeat(null);
  }, []);

  const assignSeat = useCallback((seat) => {
    // hide the message box
    closeMessageBox();

    if(!seat || !seat.seatNumber) return;

    if(typeof(onAssignSeat) !== 'function') return;

    const data = {
      passengerId: passengerState.passenger.id,
      logicalFlightId: passengerState.logicalFlightId,
      physicalFlightId: passengerState.physicalFlightId,
      rowNumber: seat.rowNumber,
      seatNumber: seat.seatNumber,
    }

    onAssignSeat(data).then(() => {
      forceUpdate();
      moveToNextPassenger();
    })
  }, [passengerState, moveToNextPassenger]);

  const onSeatClick = useCallback(seat => {
    if(!seat.isExitSeat) {
      assignSeat(seat);
      return;
    }
    setShowMessageBox(true);
    setClickedSeat(seat);
  }, [assignSeat]);


  const clearSeat = useCallback((logicalFlightId, physicalFlightId, passenger) => {

    if(typeof(onClearSeat) !== 'function') return;

    const data = {
      logicalFlightId,
      physicalFlightId,
      passengerId: passenger.id
    }

    onClearSeat(data).then(() => {
      forceUpdate();
    })
  }, [onClearSeat]);


  const clearAllSeatEnabled = useMemo(() => {
    return typeof(onClearAllSeat) === 'function';
  }, [onClearAllSeat]);

  const clearAllSeat = useCallback(() => {
    if(!clearAllSeatEnabled) return;

    onClearAllSeat().then(() => {
      forceUpdate();
    })
  }, [clearAllSeatEnabled]);


  // render() 

  const rSeatPage = ls.t('SeatPage');
  if(!rSeatPage.Title) return null;

  const rMessageBox = ls.t('MessageBox');
  const rButton = ls.t('Button');
  const rFlight = ls.t('Flight');
  //const locale = ls.t('rootClass');

  const rExitSeat = ls.t('SeatPage.ExitSeat');
  const rExitSeatAgreements =rExitSeat.Agreements.split(/[\r\n]/);

  let seatmap = {};
  const selectedSeat = [];
  if (Array.isArray(seatMaps)) {
    const segment = seatMaps.find(seg => { return seg.logicalFlightId === passengerState.logicalFlightId });
    seatmap = segment && segment.legs && segment.legs.length > 0 ?
    segment.legs.find(leg => { return leg.physicalFlightId === passengerState.physicalFlightId }) : {};

    const legInBooking = findLeg(passengerState.logicalFlightId, passengerState.physicalFlightId, segments);
    if (legInBooking && Array.isArray(legInBooking.customers)) {
      legInBooking.customers.forEach(customer => {
        if(!customer.seat) return;

        const pax = findPassenger(customer.passengerId, passengers);
        const title = passengerHelper.getPassengerTitle(pax);
        selectedSeat.push({
          rowNumber: customer.seat.rowNumber,
          seatNumber: customer.seat.seatNumber,
          passengerId: customer.seat.passengerId,
          title
        })
      });
    }
  }

  return (
    <React.Fragment>
    <Row className="seat-selection-wrapper">
      <Col className="single-trip" md={7}>
        {
          segments && segments.map((segment, index) => {
            if(!segment || !segment.permissions || !segment.permissions.canChangeSeats) return null;

            const { departureDate } = segment;
            let { originCity, destinationCity } = segment;

            const legs = filterOutLegsWithSeatMap(segment, seatMaps)
            if(legs.length === 1 && !segment.isThroughFlight) {
              originCity = legs[0].originCity
              destinationCity = legs[0].destinationCity
            }

            return (
              <React.Fragment key={`trip-key-${index}`}>
                <p className="trip-label" id={'trip-id'+ index }>
                  {index + 1}. {originCity} {rFlight.To} {destinationCity}
                  &nbsp;<span className="date">{dayjs(departureDate).format("dddd DD MMMM YYYY")}</span>
                </p>
                {
                  legs && legs.map((leg, legIndex) => {
                    const legPanel = legs.length > 1 && <p className="trip-label">
                      {"(" + (legIndex + 1) + ")"} {leg.origin} {rFlight.To} {leg.destination}
                      <span className="date">{dayjs(leg.departureDate).format("dddd DD MMMM YYYY")}</span>
                    </p>;

                    return (
                      <React.Fragment key={legIndex}>
                        {legPanel}
                        {
                          passengers && passengers.map((passenger, pIndex) => {
                            if(passenger.paxType === PassengerType.Infant) return null;

                            const legCustomer = leg.customers.find(c => { return c.passengerId === passenger.id});
                            if(!legCustomer) return null;

                            const active = segment.logicalFlightId === passengerState.logicalFlightId
                              && leg.physicalFlightId === passengerState.physicalFlightId
                              && passengerState.passenger && passengerState.passenger.id === passenger.id;

                            const customer = leg.customers && leg.customers.find(s => { return s.passengerId === passenger.id });
                            const selected = customer && customer.seat;

                            return (
                              <Passenger key={pIndex}
                                active={active}
                                passenger={passenger}
                                selectedSeat={selected}
                                currency={currencyCode}
                                onPassengerClicked={() => setSeatMap(segment.logicalFlightId, leg.physicalFlightId, passenger, pIndex)}
                                onClearSeatClicked={() => clearSeat(segment.logicalFlightId, leg.physicalFlightId, passenger)}
                              />)
                          })
                        }
                      </React.Fragment>)
                  })
                }
              </React.Fragment>
            );
          })
        }
        {clearAllSeatEnabled && <div className="text-right clear-selection" onClick={() => clearAllSeat()}>
          {rSeatPage.ClearSeat}
          <Icon icon={close} />
        </div>}
        <SeatGuide currency={currencyCode} categories={seatmap.categories} />
      </Col>
      <Col md={5}>
        <Panel className="seat-selection-panel">
          <SeatMap carbins={seatmap.carbins}
            selectedSeat={selectedSeat}
            passenger={passengerState.passenger}
            onSeatClick={(seat) => onSeatClick(seat)} />
        </Panel>
      </Col>
    </Row>
    <Modal className={ls.t("rootClass")} show={isShowMessageBox} onHide={closeMessageBox}>
        <Modal.Header closeButton>
          <Modal.Title>{rMessageBox.Title.Warning}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{rExitSeat.Statement1}</p>
          <ol>
            {rExitSeatAgreements.map((item, index) => <li key={index}>{item}</li>)}
          </ol>
          <p>{rExitSeat.Statement2}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={() => assignSeat(clickedSeat)}>{rButton.Agree}</Button>
          <Button className="btn btn-default" onClick={() => closeMessageBox()}>{rButton.Cancel}</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
  
}

export default SeatSelection;
