import React, { useEffect, useReducer } from 'react'
import { connect } from 'react-redux';
import { FormControl, FormGroup, Row, Col } from 'react-bootstrap'
import { ls } from "../../../locale"
import dayjs from 'dayjs';
import Dropdown from "react-dropdown";
import FormErrorBlock from '../../../components/FormErrorBlock'
import { titles } from "../../../constants/settings";
import DateSelect from '../../../components/Common/DateSelect';
import CountrySelect from '../../../components/Common/CountrySelect';
import DialingCodeSelect from '../../../components/Common/DialingCodeSelect';

import { memberActions } from "../../../state/ducks/member";
import { resourceActions } from "../../../state/ducks/resources";
import { sessionActions } from "../../../state/ducks/session"
// import SignupInfo from './SignupInfo';

import { reducer, isEmptyErrors } from './reducer';
import { initialState, validateValues } from './signupFormData';

function SignupForm({ getCountries, countries = [], memberRegister, getProfile, setPassengerData, onSignedUp, onShowMessage}) {

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    getCountries();
  }, [getCountries])


  const rInput = ls.t('Input')
  const rPax = ls.t('Pax')

  const year = (new Date()).getFullYear();
  const maxDateOfBirth = dayjs(`${year}-12-31`).toDate();
  const minDateOfBirth = dayjs(`${year - 100}-01-01`).toDate();

  const setErrors = errors => {
    dispatch({
      type: 'errors',
      payload: errors
    })
  }

  const setValue = (key, value) => {
    dispatch({
      type: 'value',
      payload: { key, value}
    })
  }

  const handleSubmit = form => {
    form.preventDefault();
  
    const values = state.values;
    const errors = validateValues(values)
    if(isEmptyErrors(errors)) {
      setErrors(errors)
      return
    }
    
    const data = {
      userName: values.userName,
      password: values.password,
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      nationality: values.country.value,
      dateOfBirth: dayjs(values.dateOfBirth).format('YYYY-MM-DD'),
      mobile: values.mobileCountry.label + ' ' + values.mobile,
      email: values.userName,
    };

    memberRegister(data).then(response => {
      if(response.inactive) {

        const rTitle = ls.t('MessageBox.Title')
        const message = [
          `Thank you for registering with us. We have sent you a verification link to your email ${data.email}. `,
          `Please click the link to activate your account.`,
          '',
          'Note: In case you do not find the verification email in your inbox, please check your junk folders.'
        ]
        onShowMessage(message, rTitle.Notification)
        onSignedUp({
          user: { userName: values.userName }, 
          loggedIn: false
        })
        return
      }

      getProfile().then(profile => {
        if (profile.contact && profile.customer) {
          const user = {
            title: profile.customer.title,
            firstName: profile.customer.firstName,
            lastName: profile.customer.lastName,
            dateOfBirth: profile.customer.dateOfBirth,
            mobile: profile.contact.mobile,
            email: profile.contact.email,
            country: profile.contact.country,
          }
          setPassengerData(user)
        }
        onSignedUp({
          user: { email: values.userName }, 
          loggedIn: true
        })
      })
    })
  }


  const {values, errors } = state;
  
  return (

    <div className="form-content">
      <form onSubmit={handleSubmit}>
        <FormGroup className={errors.userName ? "has-error" : null}>
          <FormControl type="text"
            placeholder={rInput.Email + " *"}
            value={values.userName}
            onChange={event => setValue('userName', event.target.value)}
          />
          <FormErrorBlock error={errors.userName} />
        </FormGroup>

        <FormGroup className={errors.password ? "has-error" : null}>
          <FormControl type="password"
            placeholder={rInput.Password + " *"}
            value={values.password}
            onChange={event => setValue('password', event.target.value)} />
          <FormErrorBlock error={errors.password} />
        </FormGroup>

        <FormGroup className={errors.confirmPassword ? "has-error" : null}>
          <FormControl type="password"
            placeholder={rInput.ConfirmPassword + " *"}
            value={values.confirmPassword}
            onChange={event => setValue('confirmPassword', event.target.value)} />
          <FormErrorBlock error={errors.confirmPassword} />
        </FormGroup>

        <Row>
          <Col md={3} xs={3}>
            <Dropdown options={titles["Adult"]}
              placeholder="Mr" value={values.title}
              onChange={option => setValue('title', option.value)} />
          </Col>
          <Col md={9} xs={9}>
            <FormGroup className={errors.firstName ? 'has-error' : null}>
              <FormControl type="text"
                placeholder={rPax.FirstName + " *"}
                value={values.firstName}
                onChange={event => setValue('firstName', event.target.value)} />
              <FormErrorBlock error={errors.firstName} />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup className={errors.lastName ? 'has-error' : null}>
          <FormControl type="text"
            placeholder={rPax.LastName + " *"}
            value={values.lastName}
            onChange={event => setValue('lastName', event.target.value)} />
          <FormErrorBlock error={errors.lastName} />
        </FormGroup>

        <FormGroup>
          <div>{rPax.DateOfBirth}<span className="mandatory">*</span></div>
          <DateSelect date={values.dateOfBirth}
            minDate={minDateOfBirth}
            maxDate={maxDateOfBirth}
            onDateChanged={date => setValue('dateOfBirth', date)}>
          </DateSelect>
          <FormErrorBlock error={errors.dateOfBirth} />
        </FormGroup>

        <FormGroup>
          <CountrySelect
            defaultCountryOption={initialState.values.country}
            className="country-control"
            options={countries}
            selected={values.country}
            onSelected={(selected) => setValue('country', selected)} />
          <FormErrorBlock error={errors.country} />
        </FormGroup>

        <Row>
          <Col md={5} xs={5}>
            <DialingCodeSelect
              className="dialingcode-select"
              selected={values.mobileCountry}
              options={countries}
              onSelected={selected => setValue('mobileCountry', selected)} />
          </Col>
          <Col md={7} xs={7}>
            <FormGroup className={errors.mobile ? 'has-error' : null}>
              <FormControl type="telephone"
                placeholder={rInput.MobileNumber + " *"}
                value={values.mobile}
                onChange={event => setValue('mobile', event.target.value)} />
              <FormErrorBlock error={errors.mobile} />
            </FormGroup>
          </Col>
        </Row>

        <div className="actions">
          <button type="submit">Create account</button>
        </div>

      </form>
      {/* <SignupInfo /> */}

    </div>
  )

}

function mapStateToProps(state) {
  return {
    countries: state.resources.countries,
  }
}

const mapDispatchToProps = {
  ...memberActions,
  ...resourceActions,
  setPassengerData: sessionActions.setPassengerData,
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm)