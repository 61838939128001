import React from "react";
import { ls } from "../../locale";
import { numberFormatter } from "../../helpers";

export function FareDetail({ fareName, flightNumber, stops, flightTime }
  : { fareName: string, flightNumber: string, stops: number, flightTime: number }) {

    const rFlight = ls.t('Flight');
    const stopsText = stops > 0 
      ? (stops + " " + rFlight.Stop) 
      : rFlight.Direct;

    const durationText = flightTime > 0 
      ? numberFormatter.formatFlightTime(flightTime, rFlight.Duration) 
      : '';
  return (
    <div>
      <div className="tw-text-2xl">{fareName} fare</div>
      <div className="tw-flex tw-gap-1 tw-text-sm">
        <span>{flightNumber}</span>
        <span className="tw-text-gray-200">|</span>
        <span>{stopsText}</span>
        <span className="tw-text-gray-200">|</span>
        <span>{durationText}</span>
      </div>
    </div>
  )
}