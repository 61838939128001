import React from "react";
import { Redirect } from 'react-router-dom';
import queryString from "query-string";
import dayjs from "dayjs";


import "./index.scss";

import { getAgentPath, getLanguageFromCulture } from "../../helpers";
import { sessionService } from "../../state/ducks/session/sessionService";

import SearchPageLayout from './SearchPageLayout'

export default function SearchPage(props) {

  let search = props.location.search;
  if(search && search.indexOf('?q=') === 0) {
    search = atob(search.substring(3));
  }
  const params = queryString.parse(search);  
  
  const query = setupSearchParams(params);
  // no redirection
  if (!query.route) {
    return <SearchPageLayout tripType={query.flightType} />
  }

  setupPassengerData(params);  

  const url = `/${query.lng}${getAgentPath()}/flights`;
  return <Redirect to={{pathname: url, state:{fromSearch: true}}} />
}

function setupSearchParams(params) {

  const tripType = params.tripType || 'round';

  const origin = params.origin;
  const destination = params.destination;
  const departureDate = params.departureDate;

  if (!origin || !destination || !departureDate) 
    return {
      flightType: tripType
    };

  const culture = sessionService.getCulture();
  const lang = getLanguageFromCulture(culture);

  const adult = params.adult || "1";

  const child = params.child || "0";
  const infant = params.infant || "0";
  const extra = params.extra || "0";

  const deptYear = departureDate.substring(0, 4);
  const deptMonth = departureDate.substring(4, 6);
  const deptDay = departureDate.substring(6, 8);

  let dates = `${deptYear}-${deptMonth}-${deptDay}`;
  let route = `${origin}-${destination}`

  let returnDate = null;
  if (tripType === "round") {
    returnDate = params.returnDate;
  }
  else if (tripType === "multi") {
    returnDate = params.departureDate2;
    route += `|${params.origin2}-${params.destination2}`;
  }

  if (returnDate && returnDate.length === 8) {
    const retYear = returnDate.substring(0, 4);
    const retMonth = returnDate.substring(4, 6);
    const retDay = returnDate.substring(6, 8);

    dates += `|${retYear}-${retMonth}-${retDay}`;
  }

  const searchParams = {
    lng: lang,
    flightType: tripType,
    route: route,
    dates: dates,
    counts: `${adult}-${child}-${infant}-${extra}`,
    promoCode: params.promo,
    deviceId: params.deviceId || params.deviceid,
    fare: params.fare,
  }

  sessionService.setSearchParams(searchParams);
  sessionService.setBookingMode({ mode: "Booking" });

  // read currency from query string
  if (isValidCurrency(params.currency)) {
    sessionService.setCurrency(params.currency);
  }

  return searchParams;
}

function setupPassengerData(params) {

  // check if passenger info is passed in
  if (params.firstname && params.lastname) {
    const user = {
      title: params.title,
      firstName: params.firstname,
      lastName: params.lastname,
      dateOfBirth: new Date(),
      mobile: params.mobile,
      email: params.email,
      country: params.country,
    }

    if (params.dob && params.dob.length === 8) {
      const format = 'YYYYMMDD';
      if (dayjs(params.dob, format).isValid())
        user.dateOfBirth = dayjs(params.dob, format).toDate();
    }

    sessionService.setPassengerData(user);
    return user;
  }
}

function isValidCurrency(currency) {
  const regex = /^[A-Z]{3}$/;
  return regex.test(currency);
}