const dates = new Array(31);
export const dateObject = [];

for (let i = 1; i <= dates.length; i++) {
  dateObject.push(i);
}

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];



export const titles = {
  Adult: [
    "MR",
    "MS",
    "MRS",
  ],
  Child: [
    "MSTR",
    "MISS",
  ],
  Infant: [
    "MSTR",
    "MISS",
  ],
  All: [
    "MR",
    "MS",
    "MRS",
    "MSTR",
    "MISS",
  ]
};


export const PassengerType = {
  Adult: 1,
  Child: 6,
  Infant: 5,
  ExtraSeat: 11,
}

export const PassengerStatus = {
  Inactive: 0,
  Active: 1,
  CheckedIn: 4,
  CheckingIn: -1,
}

export const DocumentTypeCode = {
  Passport: 1,
  NationalId: 22
}

export const DocumentTypeList = [
  { value: 1, label: 'Passport'},
  { value: 22, label: 'National ID'},
]


export const ExternalLinks = {
  insuranceTermsAndConditions: 'http://salamair.com/images/docs/Insurance.pdf',
  paymentTermsAndConditions: 'http://salamair.com/en/about-us-f/advertise-with-us',
  paymentUsagePolicy: 'http://salamair.com/en/about-us-f/usage-of-website',
}

// export const UserType = {
//   guest: 'Guest',
//   member: 'Member',
//   agent: 'Agent'
// }

export const UserType = {
  guest: 0,
  member: 1,
  agent: 2,
}

export const socialSharedQueryKey = '7nFHFIM3brf6zuAv'