function cloneDeep(obj) {
  if(!obj) return obj;
  
  return JSON.parse(JSON.stringify(obj))
}

function isNumber(value) {
  return typeof value === 'number' && isFinite(value);
}

export const jsonHelper = {
  cloneDeep,
  isNumber
}