import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { ls } from "../../../locale";

import { Row, Button } from "react-bootstrap";

import { memberActions } from "../../../state/ducks/member";
import { resourceActions } from "../../../state/ducks/resources";
import { sessionActions } from "../../../state/ducks/session";

import { splitPhoneNumber } from "../../../helpers/dataHelper";
import { history, withControlledComponent } from "../../../helpers";
import { validateRegistrationDetails, validateCustomerDetails, validateContactDetails } from "./validator";

import "./index.scss";

import RegistrationDetails from "./RegistrationDetails";
import CustomerDetails from "./CustomerDetails";
import ContactDetails from "./ContactDetails";
import BasePage from "../../../components/BasePage";

import { usePopupMessage, PopupMessage } from "../../../components/PopupMessage";

const RegistrationDetailsForm = withControlledComponent(RegistrationDetails);
const CustomerDetailsForm = withControlledComponent(CustomerDetails);
const ContactDetailsForm = withControlledComponent(ContactDetails);


const initialState = {
  profileId: 0,
  customer: {
    title: 'Mr',
  },
  contact: {
    phoneCountry: '976',
    mobileCountry: '976',
  },
}

function Profile({ countries, getCountries, getProfile, updateProfile, getUserData }) {

  const registrationDetailsForm = useRef(null);
  const customerDetailsForm = useRef(null);
  const contactDetailsForm = useRef(null);

  const [state, setState] = useState(initialState);
  const popup = usePopupMessage();

  useEffect(() => {
    getCountries();
    getProfile().then(profile => {
      if (!profile.contact) {
        profile.contact = state.contact;
      }
      else {
        const contact = profile.contact;
        if (contact.phone) {
          const splitted = splitPhoneNumber(contact.phone);

          contact.phoneCountry = splitted.code;
          contact.phone = splitted.number;
        }
        else {
          contact.phoneCountry = "976";
        }
        if (contact.mobile) {
          const splitted = splitPhoneNumber(contact.mobile);

          contact.mobileCountry = splitted.code;
          contact.mobile = splitted.number;
        }
        else {
          contact.mobileCountry = "976";
        }
      }

      if (!profile.customer) {
        profile.customer = state.customer;
      }

      setState(profile);
    });
  }, [])

  const update = useCallback(() => {

    const rError = ls.t('Error');

    const validReg = registrationDetailsForm.current.validateForm() 
    const validCust = customerDetailsForm.current.validateForm() 
    const validCont = contactDetailsForm.current.validateForm();

    if (!validReg || !validCust || !validCont) {
      popup.showMessage(rError.InputError.Desc);  //"Some fields do not match the requirements. Please check."
      return;
    }

    const contact = contactDetailsForm.current.getValues();
    contact.phone = contact.phoneCountry + ' ' + contact.phone;
    contact.mobile = contact.mobileCountry + ' ' + contact.mobile;

    const data = {
      registration: registrationDetailsForm.current.getValues(),
      customer: customerDetailsForm.current.getValues(),
      contact,
    }

    updateProfile(data).then(profile => {
      //console.log(profile);
      const registration = {
        userName: profile.registration.userName
      };
      registrationDetailsForm.current.setValues(registration);

      popup.showMessage('Your profile details has been updated successfully.');

    });
  }, [registrationDetailsForm, customerDetailsForm, contactDetailsForm])


  // render

  const userData = getUserData();
  const isCorpCustomer = userData && userData.loggedIn && userData.isCorpCustomer

  const rProfilePage = ls.t('Member.ProfilePage');
  if (!rProfilePage.Title) return null;
  const rButton = ls.t('Button');

  return (
    <BasePage mainContainerClass="passenger profile-page">
      <div className="min-height">
        <h1>{rProfilePage.Title}</h1>
        <p>{rProfilePage.Greeting}</p>
        <p>{rProfilePage.Desc}</p>
        <RegistrationDetailsForm
          ref={registrationDetailsForm}
          key={'registration-' + state.profileId}
          onValidate={validateRegistrationDetails}
          initValues={state.registration}
          isCorpCustomer={isCorpCustomer} />
        <CustomerDetailsForm
          ref={customerDetailsForm}
          key={'customer-' + state.profileId}
          onValidate={validateCustomerDetails}
          initValues={state.customer}
          countries={countries}
          isCorpCustomer={isCorpCustomer} />
        <ContactDetailsForm
          ref={contactDetailsForm}
          key={'contact-' + state.profileId}
          onValidate={validateContactDetails}
          initValues={state.contact}
          countries={countries}
          isCorpCustomer={isCorpCustomer} />

        <Row className="buttons-row">
          <div className="clearfix btnCon col-xs-12">
            <Button className="btn btn-primary btn-cancel btn-effect" onClick={goBack}>
              <span>
                <i className="picon picon-btn-arrow-left-w" /> {rButton.Back}
              </span>
            </Button>
            <div className="pull-right flip">
              <Button className="btn btn-submit pull-right flip btn-effect" onClick={update}>
                <span>
                  {rButton.Update} <i className="picon picon-btn-arrow-right-w" />
                </span>
              </Button>
            </div>
          </div>
        </Row>
      </div>

      <PopupMessage show={popup.show} title={popup.title} message={popup.message} onHide={popup.hideMessage} hideCancelButton={true} />
    </BasePage>
  );
  
}

function goBack() {
  const next = "/member/portal";
  history.push("/" + ls.lang + next);
}

function mapStateToProps(state) {
  return {
    countries: state.resources.countries,
    profile: state.member.profile,
  }
}

const mapDispatchToProps = {
  ...memberActions,
  ...resourceActions,
  ...sessionActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
