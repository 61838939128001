import React, { useRef } from "react";
import { Row, Col, FormControl, Button, FormGroup} from "react-bootstrap";

import { ReactSVG } from "react-svg";
import CheckinIcon from "../../../images/web-checkin.svg";
import FormErrorBlock from "../../../components/FormErrorBlock";

export default function LoginForm(props) {

  const usernameEl = useRef();
  const passwordEl = useRef();

  const { values, errors, setInputState, setErrors } = props;

  function login() {
    const errors= {};

    if(!values.iata) {
      errors.iata = 'Travel Agent Number is required'
    }
    if(!values.username) {
      errors.username = 'User Name is required'
    }
    if(!values.password) {
      errors.password = 'Password is required'
    }

    if(Object.values(errors).find(x => x)) {
      setErrors(errors);
      return;
    }

    if(typeof(props.onLogin) === 'function') props.onLogin(values);
  }

  function forgotPassword() {
    // const errors= {};

    // if(!values.iata) {
    //   errors.iata = 'Travel Agent Number is required'
    // }
    // if(!values.username) {
    //   errors.username = 'User Name is required'
    // }

    // if(Object.values(errors).find(x => x)) {
    //   setErrors(errors);
    //   return;
    // }

    if(typeof(props.onForgotPassword) === 'function') props.onForgotPassword(values);
  }

  function handleIataKeyPress(e) {
    if(e && e.key === 'Enter' && usernameEl.current) {
      usernameEl.current.focus();
    }
  }

  function handleUsernameKeyPress(e) {
    if(e && e.key === 'Enter' && passwordEl.current) {
      passwordEl.current.focus();
    }
  }

  function handlePasswordKeyPress(e) {
    if(e && e.key === 'Enter') {
      login();
    }
  }

  return (
    <React.Fragment>
      <div className="heading-box">
        <h1>Login with your Travel Agent Account</h1>
        <div className="login-wrap">
          <ReactSVG src={CheckinIcon} className="login-icon" />
          <span>Travel Agent</span>
        </div>
      </div>
      <hr />
      <Row className="login-row">
        <Col md={4} xs={12}>
          <FormGroup>
            <FormControl type="text" placeholder="Travel Agent Number" value={values.iata} 
              onChange={event => setInputState('iata', event.target.value.trim().toUpperCase())} 
              onKeyUp={handleIataKeyPress} />
            <FormErrorBlock error={errors.iata} />
          </FormGroup>
        </Col>
        <Col md={4} xs={12}>
          <FormGroup>
            <FormControl inputRef={ref => usernameEl.current = ref} type="text" 
              placeholder="User Name" value={values.username} 
              onChange={event => setInputState('username', event.target.value.trim())} 
              onKeyUp={handleUsernameKeyPress} />
            <FormErrorBlock error={errors.username} />
          </FormGroup>
        </Col>
        <Col md={4} xs={12}>
          <FormGroup>
            <FormControl inputRef={ref => passwordEl.current = ref} type="password" 
              placeholder="Password" value={values.password} 
              onChange={event => setInputState('password', event.target.value)} 
              onKeyUp={handlePasswordKeyPress} />
            <FormErrorBlock error={errors.password} />
            <p className="text-right"><button className="link-button" onClick={forgotPassword}>Forgot Password</button></p>
          </FormGroup>
        </Col>
      </Row>
      <div className="clearfix btnCon">
        <Button type="submit" className="btn btn-primary pull-right flip btn-submit btn-effect" onClick={login}>
          <span>Login<i className="picon picon-btn-arrow-right-w"></i></span>
        </Button>
      </div>
    </React.Fragment>
  );

}

