import React from "react";
import queryString from "query-string";
import { Redirect } from 'react-router-dom';

import { getLanguageFromCulture } from "../../helpers";
import { sessionService } from "../../state/ducks/session/sessionService";

export default function PaymentPage(props) {

  const culture = sessionService.getCulture();
  const lng = getLanguageFromCulture(culture);

  const params = queryString.parse(props.location.search);

  let to = `/${lng}`;

  if(!params.code) {
    if(params.paytype === 'travelvoucher') {
      to += '/voucher/confirmation'
    }
    else {
      to +=  '/confirmation'
    }
  }
  else if (params.code === '1002') {
    to += `/payment-error?${props.location.search}`;
  }
  else {
    if(params.paytype === 'travelvoucher') {
      to = {
        pathname: `/${lng}/voucher`,
        search: null,
        state: {
          payment: {
            code: params.code,
            reference: params.reference
          }
        }
      }
    }
    else {
      to = {
        pathname: `/${lng}/checkout`,
        search: null,
        state: {
          payment: {
            code: params.code,
            reference: params.reference,
            message: "Failed to check the reference"
          }
        }
      }
    }
  }
  return <Redirect to={to} />
}
