import types from "./types";

const INITIAL_STATE = {
  request: {
    passengers: [],
    segments: [],
  },
  booking: { }
}


export default function (state = INITIAL_STATE, { type: actionType, payload}) {
  switch(actionType) {
    case types.CHECKIN_RESPONSE:
      return {
        ...state,
        request: payload
      };

    case types.BOOKING_RESPONSE:
      return {
        ...state,
        booking: payload
      };
      
    default:
      return state;
  }
}