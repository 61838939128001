import React, { useState, useEffect, useCallback } from "react";
import {connect} from "react-redux";
import dayjs from "dayjs";
import "./index.scss";
import logo from "../../images/logo.png";
import SummaryDropdown from "../Summary";
import {isInAppBrowser, numberFormatter} from "../../helpers";
import {sessionActions} from "../../state/ducks/session";
import {UserType} from "../../constants/settings";
import {history} from "../../helpers";

import Icon from "react-icons-kit/Icon";
import { ReactSVG } from "react-svg";
import {ic_expand_more} from "react-icons-kit/md/ic_expand_more";
import CartIcon from "../../images/cart-gold.svg";
import { ls } from "../../locale";

import { Profile } from './Profile'
import { backdropActions } from "../../state/ducks/backdrop";
import { usePrevious } from "../../helpers/hooks";

function Header({ bookingSummary, hasSummary, isShowBackdrop, ...props }) {

  const [actClass, setActClass] = useState(false)
  const [userState, setUserState] = useState(null)
  const prevShowBackdrop = usePrevious(isShowBackdrop);

  useEffect(() => {

    const userData = props.getUserData();
    if (userData) {
      const lastModified = dayjs(userData.lastModified);
      const now = dayjs();
      if (now.diff(lastModified, "minute") < 3) {
        const displayName = userData.loggedIn ? userData.displayName : null;
        setUserState({
          displayName,
          userType: userData.userType,
          userName: userData.userName,
          agencyCredit: userData.availableCredit,
          agencyCurrencyCode: userData.currencyCode,
          permissions: userData.permissions,
        });
        return;
      }
    }

    props.getUserStatus().then(response => {
      props.setUserData(response);
      if (response.loggedIn) {
        setUserState({
          displayName: response.displayName,
          userType: response.userType,
          userName: response.userName,
          agencyCredit: response.availableCredit,
          agencyCurrencyCode: response.currencyCode,
          permissions: response.permissions,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (!isShowBackdrop && prevShowBackdrop) {
      setActClass(false);
    }
  }, [isShowBackdrop, prevShowBackdrop])

  const toggleClass = () => {
    const nextState = !actClass;
    setActClass(nextState);
    props.showBackdrop(nextState);
  };

  const jumpTo = useCallback(next => {
    if(typeof(props.beforeChangeLocation) === 'function') props.beforeChangeLocation(next);
    history.push("/" + ls.lang + next);
  }, [props.beforeChangeLocation]);

  const logout = useCallback(() => {
    props.logout().then(userData => {

      if (!userData) userData = {};
      
      props.setCurrency()

      if (userData.userType === UserType.agent) {
        jumpTo("/agent");
      }
      else if (userData.userType === UserType.member) {
        jumpTo("/member");
      }
      else {
        jumpTo("/search");
      }
    });
  }, [props.setCurrency, props.logout]);

  const linkTo = useCallback(location => {
    const userData = props.getUserData();
    if (userData && userData.loggedIn) {
      if (userData.userType === UserType.agent)
        jumpTo("/agent/" + location);
      else if (userData.userType === UserType.member)
        jumpTo("/member/" + location);
    }
  }, [props.getUserData]);


  // render() 

  if(!userState) return null;

  const inAppBrowser = isInAppBrowser();

  const currency = (bookingSummary && bookingSummary.currencyCode) || "OMR";
  const total = bookingSummary && bookingSummary.bookingTotal ? bookingSummary.bookingTotal : 0;
  const totalWithCurrency = numberFormatter.formatCurrency(total);
    
  const rSummary = ls.t('Summary');

  const splitted = numberFormatter.decimalSplitter(totalWithCurrency)

  return (
    <header>
      <div className="container headerCon">
        { !inAppBrowser && <a href="https://salamair.com/">
          <img src={logo} className="logo" alt="SalamAir"/>
        </a>}
        { userState.displayName && <Profile userType={userState.userType} 
          displayName={userState.displayName} 
          userName={userState.userName} 
          agencyCurrencyCode={userState.agencyCurrencyCode} 
          agencyCredit={userState.agencyCredit} hasSummary={hasSummary} 
          onLogout={logout}
          onLink={linkTo} 
        />}

        {hasSummary &&
        <div className="dropdownCon flip">
          <div className="drop-inner">
            <button className="link-button" onClick={toggleClass}></button>
            <div className="summary-label">
              {rSummary.ViewSummary}
              <Icon icon={ic_expand_more} className="drop-icon"/>
            </div>
            <div className="price">
              <div className={currency}>
                <span>{currency}</span>
                {splitted.amount}
                <span className={currency + " decimal-point"}>
                  {numberFormatter.oneDecimalPoint(splitted.decimal, currency)}
                </span>
                <ReactSVG src={CartIcon} className="cart-icon"/>
              </div>
            </div>
          </div>
          <SummaryDropdown
            actClass={actClass}
            bookingSummary={bookingSummary}
            total={total}
            setBackdrop={toggleClass}
          />
        </div>
        }
      </div>
    </header>
  );
}

function mapStateToProps(state) {
  return {
    bookingSummary: state.booking.summary,
    isShowBackdrop: state.backdrop.show
  };
}

const mapDispatchToProps = {
  ...sessionActions,
  ...backdropActions
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
