import React, { useEffect, useReducer, useCallback, useRef } from "react";
import {Button} from "react-bootstrap";
import dayjs from "dayjs";

import PerfectScrollbar from "react-perfect-scrollbar";

import "./index.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import Icon from "react-icons-kit/Icon";
import {close} from "react-icons-kit/fa/close";


import MealMenuBox from "../MealMenuBox";
import { ls } from "../../../locale"
import { Passenger } from "./Passenger";
import { connect } from "react-redux";
import { backdropActions } from "../../../state/ducks/backdrop";

import { usePrevious } from "../../../helpers/hooks";
import { reducer, initialState, calculateTotalAmount, clearMealSelection, getChangedMeals, selectMealForPassenger } from "./reducer";

function MealSelection({show, isShowBackdrop, selection, booking, currencyCode, onClose, showBackdrop}) {

  const [state, dispatch] = useReducer(reducer, initialState);
  const prevShow = usePrevious(show);
  const prevShowBackdrop = usePrevious(isShowBackdrop);

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const perfectScrollbarRef = useRef(null);

  const closeMenu = useCallback((isCloseMenu) => {
    if (state.isShowMealMenuBox) {
      dispatch({ type: 'HIDE_MEALS_MENU' })
    }
    else {
      // close meal selection if the meal box is not shown (now it is showing the meal menu)
      isCloseMenu = true;
    }
    if(isCloseMenu) {
      onClose(null);
      showBackdrop(false);
    }
  }, [state.isShowMealMenuBox, onClose, showBackdrop]);

  // when the meal selection box is shown, we should show the backdrop
  useEffect(() => {
    if(show && !prevShow) {
      showBackdrop(true);
    }
  }, [show, prevShow, showBackdrop])

  // when the backdrop is hidden, we should hide the meal selection
  useEffect(() => {
    if(!isShowBackdrop && prevShowBackdrop) {
      closeMenu(true)
    }
  }, [isShowBackdrop, prevShowBackdrop, closeMenu])


  const showMealMenuBox = useCallback((logicalFlightId, physicalFlightId, passengerId) => {
    // find the meals for current passenger
    const flight = selection.find(f => f.logicalFlightId === logicalFlightId);

    if (flight) {
      const passenger = flight.passengers.find(p => p.id === passengerId);

      if (passenger) {
        dispatch({ type: 'SHOW_MEALS_MENU', passenger, physicalFlightId })
      }
    }
  }, [selection]);


  const selectMeal = useCallback(meal => {
    selectMealForPassenger(state.currentPassenger, meal);
    dispatch({ type: 'HIDE_MEALS_MENU' })
  }, [state, selection]);

  const clearSelection = () => {
    const cleared = clearMealSelection(selection);
    if(cleared > 0) forceUpdate();
  };

  const confirm = useCallback(() => {
    const changed = getChangedMeals(booking, selection);
    dispatch({ type: 'HIDE_MEALS_MENU' })

    showBackdrop(false);
    onClose(changed);
  }, [selection, booking, showBackdrop, onClose]);


  // render the component
  const mealClass = show ? "meals-selection-box show" : "meals-selection-box";
  // const backDropClass = this.props.show ? "backdrop" : "";

  const rExtraPage = ls.t('ExtraPage');
  if(!rExtraPage.Meal) return null;

  const rButton = ls.t('Button');
  //const locale = ls.t('rootClass');
  const rFlight = ls.t('Flight');

  const totalAmount = calculateTotalAmount(selection)

  return (
    <div className={mealClass}>
      <PerfectScrollbar ref={perfectScrollbarRef}>

        <div className="meal-container col-md-12">
          <div className="meal-heading">{rExtraPage.Meal.Label}</div>
          <Icon icon={close} onClick={() => closeMenu(false)} className="close-icon"/>
          {
            !state.isShowMealMenuBox && selection && selection.map((flight, index) => {

              const segment = booking && Array.isArray(booking.segments) && booking.segments.find(s => s.logicalFlightId === flight.logicalFlightId)
              // console.log('segment: ', segment)
              if(!segment || !Array.isArray(segment.legs) || segment.legs.length === 0) return null

              let legs = segment.legs;
              if(segment.isThroughFlight && legs.length > 1) {
                legs = [legs[0]]
              }

              return (
                <div key={"flight" + index} className="trip-box">
                  <p key={"f" + index} className="trip-label">
                    {index + 1} . <span className="code">{flight.originCity}</span> {rFlight.To} <span
                    className="code">{flight.destinationCity} </span>
                    <span className="date">
                      {dayjs(flight.departureDate).format("dddd DD MMMM YYYY")}
                      </span>
                  </p>
                  {legs.map((leg, legIndex) => {
                    return (<React.Fragment key={leg.physicalFlightId}>
                    {legs.length > 1 && <p className="trip-label">
                      ({legIndex + 1}) <span className="code">{leg.originCity}</span> 
                      {rFlight.To} <span className="code">{leg.destinationCity}</span>
                      <span className="date">
                        {dayjs(leg.departureDate).format("DD MMM HH:mm")}
                      </span>
                    </p>}
                    { flight.passengers.map((passenger, pindex) => <Passenger flight={flight} leg={leg} 
                      passenger={passenger} 
                      key={pindex} currencyCode={currencyCode} 
                      hasFreeMeal={segment.hasFreeMeal} onClick={showMealMenuBox} />) }
                    </React.Fragment>)
                  })}
      
                </div>
              );
            })
          }
          {
            state.isShowMealMenuBox && <MealMenuBox
              passenger={state.currentPassenger}
              physicalFlightId={state.currentPhysicalFlightId}
              onSelectMeal={selectMeal}
              currencyCode={currencyCode}
              onUpdateSize={() => perfectScrollbarRef.current.updateScroll()} />
          }
          {selection &&  !state.isShowMealMenuBox && <React.Fragment>
            <div className="clear-selection">
              <span onClick={() => clearSelection()}>{rExtraPage.Meal.ClearSelection}</span>
              <Icon icon={close}/>
            </div>
            <div className="meal-total text-left">
              <p>{rExtraPage.Meal.Total}</p>
              <p className="price-wrap"><span>{currencyCode}</span>{totalAmount}</p>
              <Button onClick={() => confirm()}>
                {rButton.Confirm}
              </Button>
            </div>
          </React.Fragment>
          }
        </div>
      </PerfectScrollbar>
    </div>
  );
}

const mapStateToProps = state => ({
  isShowBackdrop: state.backdrop.show
});

const mapDispatchToProps = {
  ...backdropActions
}

export default connect(mapStateToProps, mapDispatchToProps)(MealSelection);
