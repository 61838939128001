import { sessionService } from "../state/ducks/session/sessionService";

export function logEvent(data) {
  if(!data) return;
  
  try {
    setTimeout(() => {
      sessionService.logging(data)
    })
  }
  catch(err) {
    console.log('error: ', err)
  }
}