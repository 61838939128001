import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ls } from "../../../locale";
import { ListGroup, Button } from "react-bootstrap";

import "./index.scss";
import { commonHelpers, isInAppBrowser, webkitHelper, stringFormatter } from "../../../helpers";
import { bookingActions } from "../../../state/ducks/booking";
import { sessionActions } from "../../../state/ducks/session";
import { wciActions } from "../../../state/ducks/wci";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import StatusBar from "../../../components/StatusBar";
import FlightDetail from "./FlightDetail";
import Backdrop from "../../../components/Backdrop";

function Confirmation({ booking, getBookingWithAllSegments, getBookingSummary, getBoardingPass, getBookingMode, match }) {

  useEffect(() => {
    getBookingWithAllSegments().then(booking => {
      if (booking && booking.pnr) {

        const bookingMode = getBookingMode();

        window.dataLayer.push({
          event: 'online-checkin-complete',
          booking_reference: booking.pnr,
          ref: bookingMode ? bookingMode.ref : undefined
        })
      }
    });
    getBookingSummary();
  }, []);

  const onBoardingPass = () => {
    // check if webkit is available; if yes, it's in app browser
    if (webkitHelper.sendPrintBoardingPass()) return;

    getBoardingPass().then((response) => {
      commonHelpers.createDownloadTag(response, 'boarding-pass.pdf');
    });
  };

  if (!booking) return null;

  // render() {
  const contactEmail = booking.contact && booking.contact.email;
  const passengers = booking.passengers || [];
  const inAppBrowser = isInAppBrowser();

  const rConfirmationPage = ls.t('ConfirmationPage');
  if (!rConfirmationPage.BookingReference) return null;

  const rBookingStatus = ls.t('BookingStatus');
  const rButton = ls.t('Button');
  const rMessage = ls.t('WCI.ConfirmationPage.Message');

  const bpMessage = stringFormatter.formatString(rMessage.BoardingPass, `<span>${contactEmail}</span>`);

  return (
    <div
      className={ls.t("rootClass") + " main-container"}
      dir={ls.t("direction")}
    >
      <div className="header-con">
        <Header hasSummary={true} />
      </div>
      <Backdrop />
      {!inAppBrowser && <StatusBar path={match} label="confirmation" />}
      <div className="container confirmation padt20 padb40">
        <div className="min-height">
          <div className="booking-reference">
            <p>{rConfirmationPage.BookingReference}</p>
            <h2>{booking.pnr}</h2>
          </div>
          <h1>{rConfirmationPage.FlightDetails}</h1>
          <p className="booking-status">{rConfirmationPage.BookingStatus} <span>{rBookingStatus.CheckedIn}</span></p>
          <p className="confirm-message" dangerouslySetInnerHTML={{ __html: bpMessage }}></p>
          <ListGroup>
            {Array.isArray(booking.segments) &&
              booking.segments.map((segment, index) => (
                <FlightDetail
                  key={index}
                  passengers={passengers}
                  segment={segment}
                />
              ))}
          </ListGroup>
          <div className="clearfix btnCon">
            <Button
              type="submit"
              className="btn btn-primary pull-right flip btn-submit btn-effect"
              onClick={onBoardingPass}
            >
              <span>
                {rButton.ViewBoardingPass}
                <i className="picon picon-btn-arrow-right-w" />
              </span>
            </Button>
          </div>
        </div>
      </div>
      {!inAppBrowser && <Footer />}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    booking: state.booking.details
  };
}

const mapDispatchToProps = {
  getBookingWithAllSegments: bookingActions.getBookingWithAllSegments,
  getBookingSummary: bookingActions.getBookingSummary,
  getBoardingPass: wciActions.getBoardingPass,
  getBookingMode: sessionActions.getBookingMode
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirmation);
