import React from "react";
import { Col, Row } from "react-bootstrap";
import { ReactSVG } from "react-svg";

import UnavailableSeat from "../../../images/unavialble.svg";
import ExpressSeat from "../../../images/expressseat.svg";
import ExtraSeat from "../../../images/extraseat.svg";
import FrontSeat from "../../../images/frontseat.svg"
import BackSeat from "../../../images/backseat.svg";
import StandardSeat from "../../../images/standerd.svg";
import LubanSeat from "../../../images/lubanseat.svg";
import SelectedSeat from "../../../images/selected.svg";

const SeatRow = ({row, passenger, carbin, selectedSeat, onSeatClick}) => {

  const { rowNumber, seats } = row;  
  
  // if(isExitRow) console.log('exit row: ', rowNumber);
  
  // seats layout are returned like "ABC DEF", there is a space in between.
  // so seat A is mapped to seat[0], seat D is mapped to seat[4]
  const leftSide = seats.slice(0, 3);
  const rightSide = seats.slice(4);

  const isExitRow = seats.some(x => x.isExitSeat === true);
  let seatClass = 'seat-row'
  if(carbin === 'BUSINESS')
    seatClass += ' seat-badge luban'
  else if(isExitRow)
    seatClass += ' seat-badge exit'

  return (
    <div className="seat-row-container">
      <Row className={seatClass}>
        <Col md={5} xs={5} className="pd0">
          <SeatGroup onSeatClick={onSeatClick} seats={leftSide} rowNumber={rowNumber} passenger={passenger} selectedSeat={selectedSeat} />
        </Col>
        <Col md={2} xs={2} className="text-center row-number">
          {rowNumber}
        </Col>
        <Col md={5} xs={5} className="pd0">
          <SeatGroup onSeatClick={onSeatClick} seats={rightSide} rowNumber={rowNumber} passenger={passenger} selectedSeat={selectedSeat} />
        </Col>
      </Row>
    </div>
  )
};

const SeatGroup = ({seats, rowNumber, passenger, selectedSeat, onSeatClick}) => {

  const isChildPassenger = passenger && passenger.paxTypeName === "Child";
  const savedSeatAmount = passenger && passenger.savedSeat && passenger.savedSeat.amount > 0 ? passenger.savedSeat.amount : 0;

  return (<Row>
    {
      seats.map((seat, index) => {
        let title = null;
        let seatType = getSeatType(seat);
        let available = seat.isAvailable;
        
        if(seat.amount < savedSeatAmount || (seat.isExitSeat && isChildPassenger)) {
          available = false;
          seatType = {
            icon: UnavailableSeat,
            title: "Not Available"
          }
        }

        if (Array.isArray(selectedSeat)) {
          const selected = selectedSeat.find(s => {return s.rowNumber === rowNumber && s.seatNumber === seat.seatNumber});
          if(selected) {
            seatType = {
              icon: SelectedSeat,
              title: 'Selected Seat'
            }
            title = selected.title;
            available = false;
          }
        }

        if(seatType.icon !== UnavailableSeat) {
          seatType.title += `\n${seat.amount} ${seat.currency}`
        }

        return (
          <Col md={4} xs={4} key={index} onClick={() => { if(available)onSeatClick(seat)}} >
            {seat.seatNumber && seat.seatNumber.trim() // if seat number is empty then it's not a real seat
            ? <div className={available ? "single-seat" : "single-seat unavailable"} data-tooltip={seatType.title}>
              <ReactSVG src={seatType.icon} />
              <div className="title">{title}</div>
            </div>
            : <div className="single-seat"></div>
            }
          </Col>
        )
      })
    }
  </Row>)
}

const getSeatType = seat => {

  const result = {
    icon: StandardSeat,
    title: 'Standard Seat'
  }

  if (!seat.isAvailable) {
     result.icon = UnavailableSeat
     result.title = "Not Available"
  }
  else if (seat.isExitSeat) {
    result.icon = ExtraSeat
    result.title = "Extra Leg" 
  }
  else if (seat.categoryCode === "Luban") {
    result.icon = LubanSeat
    result.title = "Luban Seat"
  }
  else if (seat.categoryCode === "Business") {
    result.icon = FrontSeat
    result.title = "Front Seat"
  }
  else if (seat.categoryCode === "Extra") {
    result.icon = ExtraSeat
    result.title = "Extra Seat"
  }
  else if (seat.categoryCode === "Express") {
    result.icon = ExpressSeat
    result.title = "Express Seat"
  }
  else if (seat.categoryCode === "Front") {
    result.icon = FrontSeat
    result.title = "Front Seat"
  }
  else if(seat.categoryCode === "Back") {
    result.icon = BackSeat
    result.title = "Back Seat"
  }

  return result;
}

export default SeatRow;
