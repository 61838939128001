import React from "react";
import "./index.scss";
import logo from "../../images/logo2.png";
import { ls } from "../../locale";
import { stringFormatter } from "../../helpers";

export default function Footer() {

  const rFooter = ls.t('Footer');
  if(!rFooter.CopyRight) return null;

  const year = (new Date()).getFullYear();
  const copyright = stringFormatter.formatString(rFooter.CopyRight, year);

  return (
    <footer>
      <div className="container">
        <img src={logo} className="logo" alt="SalamAir" />
        <div className="copyrightCon">{copyright}</div>
      </div>
    </footer>
  );
}

