import React from "react"
import {
  NavItem,
  Radio,
} from "react-bootstrap"

import { ls } from "../../../locale"

import OmanDebit from "../../../images/oman-deb.png"
import MastercardPNG from "../../../images/mastercard-logo.png"
import VisaLogoPNG from "../../../images/visa-logo.png"
import MadaLogoPNG from "../../../images/mada-logo.png"
import KnetLogoPNG from "../../../images/knet-logo.png"
import SmartPayLogoPNG from "../../../images/smartpay-logo.png"

export function TabNavItemON({paymentMethodCode, setPaymentMethodState}) {

  const rPayment = ls.t('Payment');

  return (
    <PaymentNavItem paymentCode="ON"
    paymentName={rPayment.OmanNet.Label}
    selected={paymentMethodCode === "ON"}
    setPaymentMethodState={setPaymentMethodState}>
    <div className="payment-logos">
      <img src={OmanDebit} alt="Oman Debit" style={{height: '35px'}} />
    </div>
  </PaymentNavItem>
  )
}

export function TabNavItemKNET({paymentMethodCode, setPaymentMethodState}) {

  const rPayment = ls.t('Payment');

  return (
    <PaymentNavItem paymentCode="KNET"
    paymentName={rPayment.Knet.Label}
    selected={paymentMethodCode === "KNET"}
    setPaymentMethodState={setPaymentMethodState}>
    <div className="payment-logos">
      <img src={KnetLogoPNG} alt="KNET" />
    </div>
  </PaymentNavItem>
  )
}

export function TabNavItemSP({paymentMethodCode, setPaymentMethodState}) {

  const rPayment = ls.t('Payment');

  return (
    <PaymentNavItem paymentCode="SP"
    paymentName={rPayment.SmartPay.Label}
    selected={paymentMethodCode === "SP"}
    setPaymentMethodState={setPaymentMethodState}>
    <div className="payment-logos">
      <span className="tags">Powered By</span>
      <img src={SmartPayLogoPNG} alt="SmartPay" />
    </div>
  </PaymentNavItem>
  )
}

export function TabNavItemCS({paymentMethodCode, methods, setPaymentMethodState }) {

  const rPayment = ls.t('Payment');
  const label = methods.includes("MADA")
                ? rPayment.CreditCard.LabelMada
                : rPayment.CreditCard.Label

  return (
    <PaymentNavItem paymentCode="CS"
      paymentName={label}
      selected={paymentMethodCode === "CS"}
      setPaymentMethodState={setPaymentMethodState} >
        <div className="payment-logos">
          {methods.includes('MADA') && <img src={MadaLogoPNG} alt="MADA" />}
          <img src={VisaLogoPNG} alt="VISA" />
          <img src={MastercardPNG} alt="Mastercard" />
        </div>
    </PaymentNavItem>
  )
}

export function TabNavItemVCHR({paymentMethodCode, setPaymentMethodState}) {

  const rPayment = ls.t('Payment');

  return (
    <PaymentNavItem paymentCode="VCHR"
      paymentName={rPayment.Voucher.Label}
      selected={paymentMethodCode === "VCHR"}
      setPaymentMethodState={setPaymentMethodState} />
  )
}

export function TabNavItemINVC({paymentMethodCode, setPaymentMethodState}) {

  const rPayment = ls.t('Payment');

  return (
    <PaymentNavItem paymentCode="INVC"
      paymentName={rPayment.AGPay.Label}
      selected={paymentMethodCode === "INVC"}
      setPaymentMethodState={setPaymentMethodState} />
  )
}

export function TabNavItemPL ({paymentMethodCode, setPaymentMethodState}) {
  const rPayment = ls.t('Payment');

  return (
    <PaymentNavItem paymentCode="PL"
      paymentName={rPayment.PayLater.Label}
      selected={paymentMethodCode === "PL"}
      setPaymentMethodState={setPaymentMethodState} />
  )
}

export function TabNavItemFT({paymentMethodCode, setPaymentMethodState}) {
  const rPayment = ls.t('Payment');

  return (
    <PaymentNavItem paymentCode="FT"
      paymentName={rPayment.FreeTicket.Label}
      selected={paymentMethodCode === "FT"}
      setPaymentMethodState={setPaymentMethodState} />
  )
}

export function TabNavItemCASH({paymentMethodCode, setPaymentMethodState}) {
  return (
    <PaymentNavItem paymentCode="CASH"
      paymentName={'Cash'}
      selected={paymentMethodCode === 'CASH'}
      setPaymentMethodState={setPaymentMethodState} />
  )
}

function PaymentNavItem(props) {

  const {paymentName, paymentCode, selected,  setPaymentMethodState} = props;

  return (      
  <NavItem eventKey={paymentCode} onClick={() => setPaymentMethodState(paymentCode)} className={ selected ? "active" : ""}>
    {props.children}
    <Radio name="paymentMethodCode" value={paymentCode} checked={selected} onChange={() => {}}>
      <label></label>
      <p>{paymentName}</p>
    </Radio>
  </NavItem>)
}