import React from 'react'
import { ls } from "../../locale";

import { MemberProfile } from './MemberProfile'
import { AgentProfile } from './AgentProfile'
import {isInAppBrowser} from "../../helpers";
import { UserType } from '../../constants/settings';

export function Profile({userType, displayName, userName, hasSummary, agencyCurrencyCode, agencyCredit, onLogout, onLink}) {
  
  const inAppBrowser = isInAppBrowser();
  
  return (
    <div className={"userinfoCon " + ls.t('rootClass') + (hasSummary ? " withSummary" : "")}>
      {!inAppBrowser && userType === UserType.member && <MemberProfile profile={{displayName, userName}} onLogout={onLogout} onLink={onLink} />}
      {!inAppBrowser && userType === UserType.agent && <AgentProfile displayName={displayName} onLogout={onLogout} onLink={onLink} />}
      {userType === UserType.agent && agencyCurrencyCode && <div className="agency-credit">
        Available Credit: <span className="credit">{agencyCurrencyCode} {agencyCredit}</span>
      </div>}
    </div>
  )

}