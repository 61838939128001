import React from "react";
import { Panel } from "react-bootstrap";
import Icon from "react-icons-kit/Icon";
import { close } from "react-icons-kit/fa/close";
import { angleRight } from "react-icons-kit/fa/angleRight";
import { userCircleO } from "react-icons-kit/fa/userCircleO";

import { PassengerType } from "../../../constants/settings";

import "../index.scss";
import { ls } from "../../../locale";

const PassengerPanel = (props) => {

  const rSeatPage = ls.t('SeatPage')
  if(!rSeatPage.Title) return null;

  const rPaxType = ls.t('PaxType');

  const { passenger, onPassengerClicked, onClearSeatClicked, active, selectedSeat, currency } = props;
  const className = active ? "passenger-panel row active" : "passenger-panel row";

  const passengerName = passenger.lastName ? `${passenger.firstName} ${passenger.lastName}` : `${rPaxType[passenger.paxTypeName].Passenger} ${passenger.paxIndex}`;

  return (
    <Panel className={className}  onClick={() => onPassengerClicked()}>
      <div className="passenger-data">
        <Icon icon={userCircleO} />
        { passenger.paxType === PassengerType.ExtraSeat ? <span>{rPaxType[passenger.paxTypeName].Passenger}</span> : <span>{passengerName}</span> }
      </div>
      {
        selectedSeat ?
          (<div className="seat-clear-button">
            <span className="seat">Seat {selectedSeat.rowNumber}{selectedSeat.seatNumber}</span>
            <span className="currency">{currency}</span>
            <span className="price">{selectedSeat.amount}</span>
            { typeof(onClearSeatClicked) === 'function' && <Icon className={!active ? "hide" : "" } icon={close} onClick={() => onClearSeatClicked()} />}
          </div>)
          :
          (<div className="seat-selection-button">
            {rSeatPage.ChooseSeat}
        <Icon icon={angleRight} />
          </div>)
      }

    </Panel>
  )
}

export default PassengerPanel;
