import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { connect } from "react-redux";

import LoadingBar from "../../components/LoadingBar";
import Alert from "../../components/Alert";
import { history, logEvent } from "../../helpers";
import { webkitHelper } from "../../helpers";
import ScrollToTop from "../../components/ScrollToTop";
import HtmlLocale from "../../components/HtmlLocale";

import { routes } from './routes'

import "./index.scss";
import { VersionChecker } from "./VersionChecker";
import PageTitle from "../../components/Common/PageTitle";

function App({ hasToken, alert }) {

  useEffect(() => {
    const unlisten = history.listen((location) => {
      //console.log(`change location: ${action}`, location, this.prevLocation);
      if (location.pathname) {
        webkitHelper.sendCurrentLocation(location.pathname);
      }
    })
    // add handler for window.onerror
    window.addEventListener('error', handleWindowError)

    return () => {
      unlisten();
      window.removeEventListener('error', handleWindowError)
    }
  }, [])

  useEffect(() => {
    // if has token, we leave the alert component to handle the 503 error
    if(hasToken) return;

    if (Array.isArray(alert.errors)) {
      alert.errors.forEach(error => {
        if (error.errorCode === 503 || error.errorCode === "503") {
          window.location.href = "/maintain.html";
        }
      })
    }
  }, [alert, hasToken])


  return (
    <>
      <VersionChecker />
      <Router history={history}>
        <HtmlLocale className="in-mobile">
          <PageTitle title="SalamAir | Book A Flight" />
          {
            !hasToken
              ? <div>Loading...</div>
              : <React.Fragment>
                <ScrollToTop>
                  {routes}
                  <LoadingBar />
                  <Alert />
                </ScrollToTop>
              </React.Fragment>}
        </HtmlLocale>
      </Router>
    </>
  );
}

function handleWindowError(event, source) {
  // if (event.message.includes('ResizeObserver')) return;

  logEvent({
    trackId: 'notracking',
    eventName: 'window error',
    details: {
      message: `${event.type}: ${event.message}`,
      source
    }
  })
}

function mapStateToProps(state) {
  return {
    hasToken: state.session.hasToken,
    alert: state.redux.alert
  };
}

export default connect(mapStateToProps)(App);

