import { DocumentTypeList } from "../constants/settings";

export const passengerHelper = {
  getPassengerTitle,
  getDateOptions,
  getDocumentType,
}


function getPassengerTitle( passenger) {
  if (!passenger || !passenger.paxTypeName) return null;
  return passenger.paxTypeName[0] + passenger.paxIndex;
}



function getDateOptions(startYear, endYear) {

  const years = [];
  for (let i = startYear; i <= endYear; i++) {
    years.push({value: i, label: i + ''});
  }
  
  const days = [];
  for (let i = 1; i <=31; i++) days.push({value: i, label: i + ''});

  return {years, months, days};
}

function getDocumentType(docTypeId) {
  if(!docTypeId) return DocumentTypeList[0];

  const type = DocumentTypeList.find(t => { return t.value === docTypeId });
  if(type) return type;

  return DocumentTypeList[0];
}

const months = [
  { value: 1,  label: "Jan"},
  { value: 2,  label: "Feb"},
  { value: 3,  label: "Mar"},
  { value: 4,  label: "Apr"},
  { value: 5,  label: "May"},
  { value: 6,  label: "Jun"},
  { value: 7,  label: "Jul"},
  { value: 8,  label: "Aug"},
  { value: 9,  label: "Sep"},
  { value: 10, label: "Oct"},
  { value: 11, label: "Nov"},
  { value: 12, label: "Dec"}
];

