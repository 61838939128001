import React, { useState, useEffect } from 'react'

import "./index.scss"

export default function AlertBox(props) {

  const [show, setShow] = useState(props.show);

  useEffect(() => {
    setShow(props.show);
  }, [props.show])

  function hideAlert() {
    setShow(false);
  }

  if (!show) return null;

  return (
    <div className={"alert " + (props.className || '')}>
      {props.closeButton && <button className="link-button close" aria-label="close" onClick={hideAlert}>&times;</button>}
      <div className="info">
        {props.children}
      </div>
    </div>
  )
}
