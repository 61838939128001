import { Booking, Summary } from "../../state/models";
import { numberFormatter } from "../../helpers";

export function getRefundCharges(booking: Booking, bookingSummary: Summary, rSummaryPage: any): RefundCharge {
  const result: RefundCharge = {};
  
  if(!booking || !Array.isArray(booking.cancelledSegments) || booking.cancelledSegments.length === 0) return result;
  if(!bookingSummary || !Array.isArray(bookingSummary.cancelledSegments) || bookingSummary.cancelledSegments.length === 0) return result;

  // calculate total charges and cancellation fee
  const totalCharges = bookingSummary.cancelledSegments.reduce((acc, x) => acc + x.segmentTotal, 0) + bookingSummary.nonRefundablesTotal;
  const cancellationFee = totalCharges + booking.reservationBalance;

  // format result
  result.totalCharges = formatRefundCharges(totalCharges, booking.currencyCode);
  result.totalCharges.text = rSummaryPage.TotalCharges;

  result.cancellationFee = formatRefundCharges(cancellationFee, booking.currencyCode);
  result.cancellationFee.text = rSummaryPage.CancellationFee;

  return result;
} 

function formatRefundCharges(totalCharges: number, currencyCode: string) {
  const result = numberFormatter.decimalSplitter(numberFormatter.formatCurrency(totalCharges)); 
  result.decimal = numberFormatter.oneDecimalPoint(result.decimal, currencyCode);
  return result;
}


export type RefundCharge = {
  totalCharges?: {
    amount: string;
    decimal: string;
    text?: string;
  };
  cancellationFee?: {
    amount: string;
    decimal: string;
    text?: string;
  };
}