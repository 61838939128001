import React from "react";
import { Row, Col, FormGroup, FormControl } from "react-bootstrap";
import Dropdown from "react-dropdown";
import dayjs from "dayjs";

import CountrySelect from "../../../components/Common/CountrySelect";
import DateSelect from "../../../components/Common/DateSelect";

import { titles } from "../../../constants/settings";
import { getSelectedOptionFromCountries } from "../../../helpers";
import { ls } from "../../../locale";
import FormErrorBlock from "../../../components/FormErrorBlock";

export default function (props) {
  const { values, errors, setInputState, countries, isCorpCustomer } = props;

  const year = (new Date()).getFullYear();
  const maxDateOfBirth = dayjs(`${year}-12-31`).toDate();
  const minDateOfBirth = dayjs(`${year - 100}-01-01`).toDate();

  const nationality = getSelectedOptionFromCountries(values.nationality, countries) || ' ';

  const rProfilePage = ls.t('Member.ProfilePage');
  const rInput = ls.t('Input');
  const rPax = ls.t('Pax');
  
  return (
    <React.Fragment>
      <h1>{rProfilePage.CustomerDetails}</h1>
      <div className="single-passenger">
        <Row className="profile-row">
          <Col className="desktop-one-half" xs={3}>
            <div className="sex-select">
              <Dropdown disabled={isCorpCustomer}
                options={titles["Adult"]} 
                placeholder="Mr" 
                value={values.title || 'Mr'}
                onChange={option => setInputState('title', option.value)} />
            </div>
          </Col>
          <Col className="desktop-three-half" xs={9}>
            <FormGroup>
              <FormControl disabled={isCorpCustomer}
                type="text"
                placeholder={rPax.FirstName + " *"}
                value={values.firstName || ''} 
                readOnly //onChange={event => setInputState('firstName', event.target.value)}
                />
                <FormErrorBlock error={ errors.firstName } />
            </FormGroup>
          </Col>
          <Col md={4} xs={5}>
            <FormGroup>
              <FormControl disabled={isCorpCustomer}
                type="text"
                placeholder={rPax.LastName + " *"}
                value={values.lastName || ''}
                readOnly // onChange={event => setInputState('lastName', event.target.value)} 
                />
                <FormErrorBlock error={ errors.lastName } />
            </FormGroup>
          </Col>
          <Col md={4}>
            <div className="country-select">
              <span className="birthday-label">{rInput.Nationality} <span className="mandatory">*</span></span>
              <CountrySelect disabled={isCorpCustomer}
                className="country-control"
                options={countries}
                selected={nationality}
                onSelected={(selected) => setInputState("nationality", selected.value)}
                placeholder={rInput.Nationality} />
                <FormErrorBlock error={ errors.nationality } />
            </div>
          </Col>
        </Row>
        <Row className="profile-row">
          <Col md={4} xs={7}>
            <DateSelect date={values.dateOfBirth} disabled={isCorpCustomer}
              minDate={minDateOfBirth} 
              maxDate={maxDateOfBirth}
              onDateChanged={date => setInputState('dateOfBirth', date)} 
              className="date-select">
              <span className="birthday-label">{rPax.DateOfBirth} <span className="mandatory">*</span></span>
            </DateSelect>
            <FormErrorBlock error={ errors.dateOfBirth } />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}