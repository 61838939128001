import React , { useEffect, useCallback, useState, useRef } from "react";
import { connect } from "react-redux";
import { ls } from "../../../locale";
import {Button, Col, Row } from "react-bootstrap";

import {getAgentPath, isInAppBrowser, jumpTo, stringFormatter} from "../../../helpers";

import Header from "../../../components/Header";
import StatusBar from "../../../components/StatusBar";
import Footer from "../../../components/Footer";
import TermsCheckbox from "../../../components/Common/TermsCheckbox";

import { bookingActions } from "../../../state/ducks/booking";
import { wciActions } from "../../../state/ducks/wci";
import { resourceActions } from "../../../state/ducks/resources";
import { sessionActions } from "../../../state/ducks/session";

import Contact from "./Contact";
import Passenger from "./Passenger";
import ProhibitedItem from '../../../components/ProhibitedItem'

import "./index.scss";
import { PopupMessage, usePopupMessage } from "../../../components/PopupMessage";

function PassengerPage({countries, getCountries, getBookingWithSummary, updateCustomers, getBookingMode, confirm, booking}) {

  const paxNodes = useRef({});
  const contactNode = useRef(null);

  const [termsStatus, setTermsStatus] = useState(true); // terms ticked by default

  const popup = usePopupMessage();

  useEffect(() => {
    getCountries().then(() => {
       getBookingWithSummary();
    })
  }, [])

  const onContinue = useCallback(termsStatus => {
    if(!termsStatus) {
      popup.showMessage();
      return;
    }

    const data = {
      passengers: [],
      contact: {}
    };

    if(!Array.isArray(booking.passengers) || !booking.passengers.length) return;

    let isPassengerFormValid = true;
    booking.passengers.forEach(pax => {
      const node = paxNodes.current['p' + pax.id];
      if(node) {
        if(!node.validateForm()) {
          isPassengerFormValid = false;
          return;
        }
        const values = node.getValues();
        data.passengers.push(values);
      }
    })

    if(!contactNode.current) return;

    const isContactFormValid = contactNode.current.validateForm();
    if(!isPassengerFormValid || !isContactFormValid) return;

    data.contact = contactNode.current.getValues();

    updateCustomers(data).then(booking => {

      const bookingMode = getBookingMode();
      
      // we will skip extra ans seats page if it is express checkin from Booking process
      if(bookingMode.expressCheckin !== 'Booking') {
        jumpTo(getAgentPath() + '/wci/seats');
        return
      }

      // skip extra in express checkin if seats have already been reserved
      if(!Array.isArray(bookingMode.segments) || bookingMode.segments.length === 0) return

      const checkinSegment = bookingMode.segments[0]
      const segment = booking.segments.find(x => x.logicalFlightId === checkinSegment.logicalFlightId)
      if(!segment) return

      // check if all seats are reserved
      const allSeatsReserved = segment.legs.every(leg => leg.customers.every(c => c.seat && c.seat.serviceCode))
      if(allSeatsReserved) {

        if(booking.reservationBalance === 0) {
          // check in
          confirm().then(() => {
            jumpTo(getAgentPath() + "/wci/confirmation");
            return;
          })
        }
        else {
          jumpTo(getAgentPath() + '/checkout')
          return;
        }
      }
      else {
        jumpTo(getAgentPath() + '/wci/seats')
      }
    });
  }, [booking, updateCustomers, getBookingMode, confirm])


  // render()

  const passengers = booking.passengers || [];

  const inAppBrowser = isInAppBrowser();

  const rCheckoutPage = ls.t('CheckoutPage');
  if(!rCheckoutPage.TotalDue) return null;

  const rButton = ls.t('Button');
  const rPayment = ls.t('Payment');
  const rMessageBox = ls.t('MessageBox');
  const rError = ls.t('Error');
  const rProhibitedItems = ls.t('WCI.ProhibitedItems')

  let contactKey = (booking.contact && booking.contact.email) || 'unknown_email';
  contactKey = contactKey + countries.length;

  const flightDate = (booking.segments && booking.segments.length && booking.segments[0].departureDate) || new Date();

  const terms = `<a href="${rPayment.Links.TermsAndConditions}" target="_blank">${rPayment.TermsConditions}</a>`;
  const usage = `<a href="${rPayment.Links.UsagePolicy}" target="_blank">${rPayment.UsagePolicy}</a>`;
  const baggage = `<a href="${rPayment.Links.BaggagePolicy}" target="_blank">${rPayment.BaggagePolicy}</a>`;
  const carriage = `<a href="${rPayment.Links.CarriageConditions}" target="_blank">${rPayment.CarriageConditions}</a>`;
  const statement = stringFormatter.formatString(rPayment.Statement, terms, usage, baggage, carriage);

  return(
    <div className={ls.t("rootClass") + " main-container passenger-details"} dir={ls.t("direction")}>
      <div className="header-con">
        <Header/>
      </div>
      { !inAppBrowser && <StatusBar label="flights"/> }
      <div className="container padt40 padb40">
        <Row className="min-height wci-passenger">
          <Col md={12} className="select-passenger-inner">
            <h1>{rCheckoutPage.BookingContact}</h1>
            <hr/>
            <Contact ref={contactNode} contact={booking.contact} key={contactKey} countries={countries}/>
            <h1>{rCheckoutPage.PassengerDetails}</h1>
            <hr/>
            {
              passengers.map(passenger => {
                return <Passenger
                  ref={node => paxNodes.current['p' + passenger.id] = node}
                  key={passenger.id}
                  flightDate={flightDate}
                  passenger={passenger}
                  countries={countries} />
              })
            }
          </Col>
          <Col md={12} className="payment-options">
            <TermsCheckbox checked={termsStatus === true} onClick={() => setTermsStatus(prevStatus => !prevStatus)} terms={statement} />
            <ProhibitedItem items={rProhibitedItems}/>
          </Col>
        </Row>
        <div className="clearfix btnCon wci-passenger">
          { !inAppBrowser && <Button className="btn btn-primary btn-cancel btn-effect" onClick={() => jumpTo(getAgentPath() + '/wci/flight')}>
              <span><i className="picon picon-btn-arrow-left-w">
              </i> {rButton.Back}</span>
          </Button> }
          <Button type="submit" className="btn btn-primary pull-right flip btn-submit btn-effect" onClick={() => onContinue(termsStatus)}>
            <span>{rButton.Continue}<i className="picon picon-btn-arrow-right-w"/></span>
          </Button>
        </div>
      </div>
      { !inAppBrowser && <Footer/> }

      <PopupMessage show={popup.show} title={rMessageBox.Title.Notification} message={rError.CheckoutTerms} onHide={popup.hideMessage} hideCancelButton={true} />
    </div>
  );
}


const mapStateToProps = (state) => ({
  booking: state.booking.details,
  countries: state.resources.countries,
})

const mapDispatchToProps = {
  ...wciActions,
  ...resourceActions,
  getBookingWithSummary: bookingActions.getBookingWithSummary,
  getBookingMode: sessionActions.getBookingMode,
}

export default connect(mapStateToProps, mapDispatchToProps)(PassengerPage)
