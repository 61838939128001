import types from "./types";

const INITIAL_STATE = {
  isLoading: false,
  details: {},
  summary: {},
  ancillaries: [],
  errors: [],
}

export default function (state = INITIAL_STATE, { type: actionType, payload }) {
  switch (actionType) {
    case types.BOOKING_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.BOOKING_RESPONSE: {
      return {
        ...state,
        isLoading: false,
        isShowAll: false,
        details: payload ?? {},
      }
    }

    case types.BOOKING_WITH_ALLSEGMENTS_RESPONSE: {
      return {
        ...state,
        isLoading: false,
        isShowAll: true,
        details: payload ?? {},
      }
    }

    case types.SUMMARY_REQUEST:
      return {
        ...state,
        isLoading: false,
      };

    case types.SUMMARY_RESPONSE:
      return {
        ...state,
        isLoading: false,
        summary: payload ?? {}
      };

      case types.ANCILLARY_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.ANCILLARY_RESPONSE: {
      return {
        ...state,
        isLoading: false,
        ancillaries: payload,
      }
    }

    default: return state;
  }
}