import React, { Component } from 'react'
import { connect } from "react-redux"
import { ls } from "../../../locale"
import { Button } from "react-bootstrap"


import { commonHelpers, getAgentPath, history, isInAppBrowser } from "../../../helpers"

import { bookingActions } from "../../../state/ducks/booking"
import { mmbActions } from "../../../state/ducks/mmb"
import { resourceActions } from "../../../state/ducks/resources"
import { sessionActions } from "../../../state/ducks/session";

import Header from "../../../components/Header"
import Footer from "../../../components/Footer"

import Contact from "../../CheckoutPage/Contact"
import Passengers from "../../CheckoutPage/Passengers"
import "../../CheckoutPage/index.scss"
import "./index.scss"
import Backdrop from '../../../components/Backdrop'

export class ContactPage extends Component {

  constructor(props) {
    super(props);

    let userData = props.getUserData();
    if(!userData || !userData.loggedIn) userData = null;

    this.state = {
      contactChanged: false,
      userData,
    };
  }

  componentDidMount() {
    this.props.getCountries().then(() => {
      this.props.getBookingWithSummary();
    });
  }


  continue = () => {
    if(!this.contactComponent.validateForm()) return;

    if(this.passengersComponent) {
      const isPassengersValid = this.passengersComponent.validateForm();
      if(!isPassengersValid) return;
    }
    const contactData = this.contactComponent ? this.contactComponent.getFormValues() : undefined;
    const passengerData = this.passengersComponent ? this.passengersComponent.getFormValues() : undefined;

    const data = {
      contact: contactData,
      passengers: passengerData
    }

    this.props.updateContact(data).then(() => {
      this.goBack();
    })
  }

  goBack = () => {
    const nextPage = getAgentPath() + "/mmb/summary";
    history.push("/" + ls.lang + nextPage);
  }

  onContactChanged = () => {
    this.setState({
      contactChanged: true
    })
  }

  onPassengerChanged = () => {
    this.setState({
      passengerChanged: true
    })
  }

  getLastFlightDate = () => {
    let segments = this.props.booking.segments;
    if (!segments || !segments.length) return null;

    segments = segments.filter((x) => x); // filter out null segment
    const flight = segments[segments.length - 1];
    let date = flight.departureDate;

    const isDate = date instanceof Date;
    if (isDate) return date;

    date = Date.parse(date);
    if (Number.isNaN(date)) return null;

    return new Date(date);
  };


  render() {

    const rButton = ls.t('Button');
    if(!rButton.Confirm) return null;

    const inAppBrowser = isInAppBrowser();
    const { countries, booking } = this.props;

    const contact = (booking && booking.contact) || {};
    
    const canChangePassenger = booking && Array.isArray(booking.segments) && 
      booking.segments.some(x => x && x.permissions && x.permissions.canChangePassenger);
    const passengers = booking && Array.isArray(booking.passengers) ? booking.passengers : [];

    let flightDate = this.getLastFlightDate();
    if (!flightDate) flightDate = new Date();

    const userData = this.state.userData;

    return (
      <div className={ls.t("rootClass") + " main-container"} dir={ls.t("direction")}>
        <div className="header-con">
          <Header hasSummary={true} />
        </div>
        <Backdrop />
        <div className="container passenger summary-contact padt20 padb40">
          <div className="min-height">

            {canChangePassenger && <Passengers
              ref={(element) => (this.passengersComponent = element)}
              key={commonHelpers.getHashCode(passengers)}
              passengers={passengers}
              flightDate={flightDate}
              userData={userData}
              onStateChanged={this.onPassengerChanged}
            />}

            <Contact
              ref={(element) => (this.contactComponent = element)}
              key={commonHelpers.getHashCode(contact)}
              contact={contact}
              countries={countries}
              // hideCountry={true}
              onStateChanged={this.onContactChanged}
            />

            <div className="clearfix btnCon">
              {!inAppBrowser && <Button type="submit" className="btn btn-primary pull-left flip btn-cancel btn-effect"
                onClick={() => this.goBack()}>
                <span><i className="picon picon-btn-arrow-left-w" />{rButton.Back}</span>
              </Button>}
              <Button type="submit" className="btn btn-primary pull-right flip btn-submit btn-effect" 
                disabled={!(this.state.contactChanged || this.state.passengerChanged) }
                onClick={() => this.continue()}><span>{rButton.Confirm}
                  <i className="picon picon-btn-arrow-right-w" /></span>
              </Button>
            </div>
          </div>
        </div>
        {!inAppBrowser && <Footer />}
      </div>
    )
  }

}

function mapStateToProps(state) {
  return {
    booking: state.booking.details,
    countries: state.resources.countries,
  };
}

const mapDispatchToProps = {
  getBookingWithSummary: bookingActions.getBookingWithSummary,
  getCountries: resourceActions.getCountries,
  ...mmbActions,
  getUserData: sessionActions.getUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage)
