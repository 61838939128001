
export const webkitHelper = {
  sendBookingConfirmation,
  sendCurrentLocation,
  sendLoadingStatus,
  sendSessionExpired,
  sendPrintBoardingPass,
}

function sendBookingConfirmation(booking) {
  if(!booking?.pnr) return false;

  const data = {
    contact: {
      email: booking.contact.email,
      mobile: booking.contact.mobile,
      countryCode: booking.contact.address.countryCode
    },
    passengers: booking.passengers.map(p => ({
      firstName: p.firstName,
      lastName: p.lastName,
      dateOfBirth: p.dateOfBirth,
      title: p.title,
    })),
    pnr: booking.pnr,
  };

  return sendMessageToWebview('confirmation', data)
}

function sendCurrentLocation(location) {
  if(!location) return false;
  location = location.replace(/^\/\w{2}\//, '');

  return sendMessageToWebview('currentLocation', location)
}

function sendLoadingStatus(loading) {
  loading = !!loading;
  return sendMessageToWebview('loadingStatus', loading);
}

function sendSessionExpired() {
  return sendMessageToWebview('session', 'expired');
}

function sendPrintBoardingPass() {
  return sendMessageToWebview('boardingPass', 'print');
}

function sendMessageToWebview(eventName, message) {

  const webkitHandler = window.webkit?.messageHandlers?.[eventName];
  // ios webkit
  if(typeof webkitHandler?.postMessage === 'function') {
    try {
      webkitHandler.postMessage(message);
      return true;
    }
    catch(err) {
      console.log(`failed to send message ${eventName} to mobile app via webkit. Error: ` + err)
      return false;
    }
  }
  // android
  const androidHandler = window.Android?.[eventName];
  if(typeof androidHandler === 'function') {
    try {
      if (typeof message === 'object') {
        message = JSON.stringify(message);
      }
      androidHandler(message);
      return true;
    }
    catch(err) {
      console.log(`failed to send message ${eventName} to mobile app via Android. Error: ` + err)
      return false;
    }
  }
  // Android Flutter
  const flutterHandler = window[eventName];
  if(typeof flutterHandler?.postMessage === 'function') {
    try {
      if (typeof message === 'object') {
        message = JSON.stringify(message);
      }
      flutterHandler.postMessage(message);
      return true;
    }
    catch(err) {
      console.log(`failed to send message ${eventName} to mobile app via flutter. Error: ` + err)
      return false;
    }
  }

  // no handler found
  return false;
}
