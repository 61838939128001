import React from 'react'
import { numberFormatter } from '../../../helpers'

export function FarePrice({isFree, currencyCode, amount}: { isFree: boolean, currencyCode: string, amount: number }) {
  const splitted = numberFormatter.decimalSplitter(amount)
  return (
    <div className={"priceCon" + (isFree ? " strike-price" : '')}>
      <span>{currencyCode}&nbsp;</span>
      {splitted.amount}
      <span
        className={`${currencyCode} decimal-point`}>{numberFormatter.oneDecimalPoint(splitted.decimal, currencyCode)}</span>
    </div>
  )
}

export function BaseFarePrice({currencyCode, amount}: { currencyCode: string, amount: number }) {
  const splitted = numberFormatter.decimalSplitter(amount)
  return (
    <div className="tw-text-xs tw-line-through sa-text-green">
      <span>{currencyCode}&nbsp;</span>
      <span>{splitted.amount}</span>
      <span>{numberFormatter.oneDecimalPoint(splitted.decimal, currencyCode)}</span>
    </div>
  )
}