import { alertConstants } from "../constants";

const initialState = {
  show: false,
  message: null,
  errors: []
}

export function alert(state = initialState, action) {
  switch (action.type) {
  case alertConstants.SUCCESS:
    return {
      show: false,
      message: action.payload,
      errors: []
    };

  case alertConstants.ERROR:
    // we hide the payment error from alert, leave it to front-end components (or the caller)
    if(Array.isArray(action.payload)) {
      const paymentError = action.payload.find(x => x.errorCode === 502 || x.errorCode === 700);
      if(paymentError) {
        //console.log('found payment error: ', paymentError);
        return state;
      }
    }

    return {
      show: true,
      message: null,
      errors: action.payload
    };

  case alertConstants.CLEAR:
    return {
      show: false,
      message: null,
      errors: []
    };

  default:
    return state;
  }
}
