import React, { useState } from 'react'
import { Modal, Row, Col, Checkbox, Button } from "react-bootstrap";
import { ls } from "../../../locale";

import "./index.scss";

export function SelectEmployee({ isShow, employeeInfo, selectedEmployeeIds, onClose, onSelect }) {

  const [selected, setSelected] = useState(null);

  const onConfirm = () => {
    if (!selected) return;
    if (typeof (onSelect) === 'function') onSelect(selected);
  }

  let hasItems = Array.isArray(employeeInfo) && employeeInfo.length > 0;
  if(hasItems && employeeInfo.length === 1) {
    // already selected
    if(Array.isArray(selectedEmployeeIds) && selectedEmployeeIds.length === 1) hasItems = false;
  }

  // render()
  return (
    <Modal className={ls.t("rootClass")} show={isShow} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Passenger</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {hasItems
          ? <p>Select the passenger from the list below.</p>
          : <p>No employee info available to select.</p>
        }

        {Array.isArray(employeeInfo) && employeeInfo.map(employee => {

          if (Array.isArray(selectedEmployeeIds) && selectedEmployeeIds.find(x => x === employee.empId)) return null;

          const checked = (selected && selected.id === employee.id) || false;

          return (
            <Row className="employee-section"
              key={`${employee.firstName}${employee.lastName}${employee.dateOfBirth}`}>
              <Col md={12}>
                <Checkbox checked={checked} onChange={() => { }} onClick={() => setSelected(employee)}>
                  <label></label>
                </Checkbox>
                <div className="employee-details">
                  <span className="clickable" onClick={() => setSelected(employee)}>{employee.firstName} {employee.lastName}</span>
                </div>
              </Col>
            </Row>
          )
        })}
      </Modal.Body>
      <Modal.Footer>
        { hasItems && <Button onClick={onConfirm}>Select</Button> }
        { !hasItems && <Button onClick={onClose}>Close</Button> }
      </Modal.Footer>
    </Modal>
  )
}

export default SelectEmployee
