import React, { useState, useEffect } from 'react'

import { ls } from '../../locale'

import sad from '../../images/face-sad-gray.png'
import sad2 from '../../images/face-sad-green.png'
import frown from '../../images/face-frown-gray.png'
import frown2 from '../../images/face-frown-green.png'
import meh from '../../images/face-meh-gray.png'
import meh2 from '../../images/face-meh-green.png'
import smile from '../../images/face-smile-gray.png'
import smile2 from '../../images/face-smile-green.png'
import happy from '../../images/face-happy-gray.png'
import happy2 from '../../images/face-happy-green.png'

import './index.scss'

export default function RatingBar({ size = 24, title, message, rated, editable, onRate, className }) {

  const [focus, setFocus] = useState(rated)

  useEffect(() => {
    setFocus(rated);
  }, [rated])

  const focusOn = item => {
    if (rated > 0) return;
    setFocus(item)
  }

  const rate = rating => {
    if ((rated > 0 && !editable) || typeof (onRate) !== 'function') return;

    onRate(rating);
  }

  const Face = ({ grayIcon, greenIcon, value }) => (
    <span className={rated > 0 && !editable ? '' : ' clickable'}
      onMouseEnter={() => focusOn(value)}
      onMouseLeave={() => focusOn(rated)}
      onClick={() => rate(value)}  >
      <img alt={value} width={size} height={size} src={focus === value ? greenIcon : grayIcon} />
    </span>
  )

  const inArabic = ls.t('rootClass') === 'ar'

  return (
    <div className={`rating-bar ${className}`}>
      <div className="title">{title}</div>
      <div className="rating">
        <span className="faces">
          <Face size={size} grayIcon={sad} greenIcon={sad2} value={1} />
          <Face size={size} grayIcon={frown} greenIcon={frown2} value={2} />
          <Face size={size} grayIcon={meh} greenIcon={meh2} value={3} />
          <Face size={size} grayIcon={smile} greenIcon={smile2} value={4} />
          <Face size={size} grayIcon={happy} greenIcon={happy2} value={5} />
        </span>
        {rated > 0 && message && <span className="message text-nowrap">{message}</span>}
      </div>
      { !inArabic && <div className="details">For detailed feedback, <a href="https://www.salamair.com/en/booking-experience-survey" target="_blank">click here</a></div> }
      { inArabic && <div className="details">للاعطاء المزيد من التعليقات المفصلة،<a href="https://www.salamair.com/en/booking-experience-survey" target="_blank"> يرجى الضغط هنا</a></div> }
    </div>

  )
}