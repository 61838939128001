import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Modal, Button } from "react-bootstrap";
import { ls } from "../../../locale";

import { resourceActions } from "../../../state/ducks/resources";
import { agentActions } from "../../../state/ducks/agent";
import { sessionActions } from "../../../state/ducks/session";

import BasePage from '../../../components/BasePage';
import SideMenu from '../SideMenu';

import CreateAgency from './CreateAgency';
import ModifyAgency from './ModifyAgency';
import CreateAgent from './CreateAgent';
import ModifyAgent from './ModifyAgent';

import './index.scss';

const actionMode = {
  CreateAgency: 'create-agency',
  ModifyAgency: 'modify-agency',
  CreateAgent: 'create-agent',
  ModifyAgent: 'modify-agent'
}

export class Management extends Component {

  constructor(props) {
    super(props);

    this.state = {
      childAgencies: [],
      childAgencyOptions: [],
      currentAgency: null,
      childAgents: [],
      currentAgent: null,
      mode: actionMode.ModifyAgency,

      isShowMessageBox: false,
      message: null,
    }
  }

  componentDidMount() {
    this.props.getCountries();
    this.loadChildAgencies(true).then(response => {
      if (response.length > 0) {
        this.selectAgency(response[0].iataNumber);
      }
    });
  }

  loadChildAgencies = forceRefresh => {
    return this.props.getChildAgencies(forceRefresh).then(response => {
      if (Array.isArray(response)) {
        const options = response.map(x => ({
          value: x.iataNumber,
          label: `${x.iataNumber} - ${x.agencyName}`
        }));
        this.setState({
          childAgencies: response,
          childAgencyOptions: options
        });
      }
      return response;
    });
  }

  selectAgency = iataNumber => {
    return this.props.getAgencyDetails(iataNumber).then(response => {
      this.setState({
        currentAgency: response
      })
      return response;
    })
  }

  createAgency = details => {
    this.props.createAgency(details).then(response => {
      //console.log('create agency response: ', response);
      const message = 'The agency is created.';
      this.showMessageBox(message);
      return response;
    })
    .then(agency => {
      if(agency && agency.iataNumber) {
        this.setState({
          mode: actionMode.ModifyAgency
        });
        this.loadChildAgencies(true).then(() => {
          this.selectAgency(agency.iataNumber);
        })
      }
    })
  }

  modifyAgency = (agencyDetail, fundDetails) => {
    const message = 'The agency is updated.';
    if (agencyDetail) {
      this.props.modifyAgency(agencyDetail).then(() => {
        //console.log('modify agency response: ', response);
        if (fundDetails) {
          this.transferFunds(fundDetails)
            .then(() => {
              this.showMessageBox(message);
            });
        }
        else {
          this.showMessageBox(message);
        }
      });
    }
    else if (fundDetails) {
      this.transferFunds(fundDetails)
        .then(() => {
          this.showMessageBox(message);
        });
    }
  }

  transferFunds = details => {
    if (details.fundTransferAmount > 0) {
      const request = {
        iataNumber: details.iataNumber,
        amount: details.fundTransferAmount,
        action: details.fundTransferType,
      };

      return this.props.transferFunds(request)
        .then(() => {
          return this.selectAgency(details.iataNumber);
        });
    }
  }

  createAgent = details => {
    this.props.createAgent(details).then(response => {
      //console.log('create agent response: ', response);
      const message = 'The agent is created.';
      this.showMessageBox(message);

      this.setState({
        mode: actionMode.ModifyAgent,
        childAgents: [...this.state.childAgents, response],
        currentAgent: response
      })
    })
  }

  modifyAgent = details => {
    const currentAgent = this.state.currentAgent;
    if(!currentAgent) return;

    this.props.modifyAgent(details).then(() => {
      //console.log('modify agent response: ', response);

      if(currentAgent.isActive !== details.isActive) {
        this.props.modifyAgentStatus(details).then(() => {
          const message = 'The agent is updated.';
          this.showMessageBox(message);
        })
      }
      else {
        const message = 'The agent is updated.';
        this.showMessageBox(message);
      }
    });
  }

  changePassword = request => {
    const currentAgent = this.state.currentAgent;
    if(!currentAgent) return;

    this.props.changePassword(request).then(() => {
      const message = 'Password was changed successfully.';
      this.showMessageBox(message);
    })
  }

  manageUser = () => {
    const agency = this.state.currentAgency;
    if (!agency || !agency.iataNumber) return;

    this.props.getChildAgents(agency.iataNumber).then(response => {
      this.setState({
        mode: actionMode.ModifyAgent,
        childAgents: response,
        currentAgent: response.length > 0 ? response[0] : null
      })
    })
  }

  setMode = mode => {
    this.setState({ mode })
  }

  showMessageBox = message => {
    this.setState({
      isShowMessageBox: true,
      message,
    })
  }

  hideMessageBox = () => {
    this.setState({
      isShowMessageBox: false,
      message: null
    })
  }

  render() {

    const currentAgency = this.state.currentAgency;
    const countries = this.props.countries;
    const userData = this.props.getUserData();

    return (
      <BasePage>
        <Row className="min-height padt40">
          <Col md={9} xs={12} className="agent-management">
            {this.state.mode === actionMode.CreateAgency && <CreateAgency
              key={this.state.mode}
              countries={countries}
              onCreateAgency={this.createAgency}
              onCancel={() => this.setMode(actionMode.ModifyAgency)}
            />}

            {this.state.mode === actionMode.ModifyAgency && <ModifyAgency
              key={currentAgency ? currentAgency.iataNumber : this.state.mode}
              agencies={this.state.childAgencyOptions}
              countries={countries}
              agency={currentAgency}
              parentAgency={userData}
              onSelectAgency={this.selectAgency}
              onModifyAgency={this.modifyAgency}
              onCreateAgency={() => this.setMode(actionMode.CreateAgency)}
              onManageUser={this.manageUser}
            />}

            {this.state.mode === actionMode.CreateAgent && <CreateAgent
              key={currentAgency ? currentAgency.iataNumber : this.state.mode}
              agency={currentAgency}
              countries={countries}
              onCancel={() => this.setMode(actionMode.ModifyAgent)}
              onCreateAgent={this.createAgent}
            />}

            {this.state.mode === actionMode.ModifyAgent && <ModifyAgent
              key={currentAgency ? currentAgency.iataNumber : this.state.mode}
              agency={currentAgency}
              agents={this.state.childAgents}
              currentAgent={this.state.currentAgent}
              countries={countries}
              onSelectAgent={agent => this.setState({currentAgent: agent})}
              onCreateAgent={() => this.setMode(actionMode.CreateAgent)}
              onModifyAgent={this.modifyAgent}
              onChangePassword={this.changePassword}
            />}

          </Col>
          <Col md={3} xs={12}>
            <SideMenu
              activeItem="AgentManagement"
              path={this.props.location.pathname}
              onAgentManagement={() => this.setMode(actionMode.ModifyAgency)}
            />
          </Col>
        </Row>

        <Modal className={ls.t("rootClass")} show={this.state.isShowMessageBox} onHide={this.hideMessageBox} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.message}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideMessageBox}>Close</Button>
          </Modal.Footer>
        </Modal>
      </BasePage>
    )
  }
}

function mapStateToProps(state) {
  return {
    countries: state.resources.countries,
  };
}

const mapDispatchToProps = {
  ...resourceActions,
  ...agentActions,
  getUserData: sessionActions.getUserData,
}

export default connect(mapStateToProps, mapDispatchToProps)(Management)
