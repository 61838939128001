import React from 'react'
import { Row, Col, FormControl, FormGroup } from 'react-bootstrap'
import { ls } from '../../../locale'
import dayjs from 'dayjs'

import Icon from "react-icons-kit/Icon"
import { userCircleO } from "react-icons-kit/fa/userCircleO"
import FormErrorBlock from '../../../components/FormErrorBlock'
import TitleSelect from '../../../components/Common/TitleSelect'
import DateSelect from '../../../components/Common/DateSelect'
import { titles } from "../../../constants/settings"

export function Passenger({values, errors, setInputState}) {
  
  const rTitle = ls.t('Title');
  const rPax = ls.t('Pax');
  const rPassenger = ls.t('FlightDetails.Heading.Passenger')

  const year = (new Date()).getFullYear();
  const maxDateOfBirth = dayjs(`${year}-12-31`).toDate();
  const minDateOfBirth = dayjs(`${year - 100}-01-01`).toDate();

  return (
    <div className="padding-top-0">
      <div className="heading">
        <Icon icon={userCircleO} />
        <span>{rPassenger}</span>
      </div>

      <div className="single-passenger">
        <Row>
          <Col sm={4} xs={12}>
            <Row>
              <Col xs={4} style={{paddingRight: 0}}>
                <TitleSelect rTitle={rTitle}
                  options={titles.All}
                  value={values.title}
                  onChange={value => setInputState('title', value)}
                />
              </Col>
              <Col xs={8}>
                <FormGroup>
                  <FormControl type="text" name="firstname" 
                    value={values.firstName || ''}
                    onChange={e => setInputState('firstName', e.target.value)}
                    placeholder={rPax.FirstName + ' *'} />
                  <FormErrorBlock error={errors.firstName} />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col sm={4} xs={12}>
            <FormGroup>
              <FormControl type="text" name="lastname" 
                value={values.lastName || ''}
                onChange={e => setInputState('lastName', e.target.value)}
                placeholder={rPax.LastName + ' *'} />
              <FormErrorBlock error={errors.lastName} />
            </FormGroup>
          </Col>
          <Col sm={4} xs={12}>
            <DateSelect date={values.dateOfBirth}
              minDate={minDateOfBirth} 
              maxDate={maxDateOfBirth}
              onDateChanged={date => setInputState('dateOfBirth', date)} 
              className="date-select">
              <span className="birthday-label">{rPax.DateOfBirth} <span className="mandatory">*</span></span>
            </DateSelect>
            <FormErrorBlock error={ errors.dateOfBirth } />
          </Col>
        </Row>
      </div>
    </div>
  )
}