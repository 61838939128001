import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, PanelGroup, Modal } from "react-bootstrap";
import { ls } from "../../../locale";
import dayjs from "dayjs";
import scrollToElement from "scroll-to-element";
import dot from "dot-object";

import * as helper from "./helper";

import { history, numberFormatter, isInAppBrowser, buildSearchQuery, getSource, getAgentPath, jumpTo } from "../../../helpers";
import { UserType } from "../../../constants/settings";
// Ducks
import { flightActions } from "../../../state/ducks/flights";
import { bookingActions } from "../../../state/ducks/booking";
import { sessionActions } from "../../../state/ducks/session";
import { mmbActions } from "../../../state/ducks/mmb";

import FlightCard from "./flightCard";
import DirectionHeader from "./directionHeader";
import { PopupMessage } from "../../../components/PopupMessage";

import CalendarStrip from "./CalendarStrip";
import SocialShare from '../../../components/SocialShare'
import { isFlightDisrupted } from "../../DisruptionPage/helpers";

import shareButton from '../../../images/share-button.png'

import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

class SelectFlights extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedFlights: [],
      activeKey: [],
      message: null,
      isShowMessageBox: false,
      messageTitle: "Error",
      closeButtonText: "Close",

      isShowSocialShare: false,
      shareUrl: null,
      shareTitle: null,

      // multi messages
      // objects in the list takes the following format:
      // {
      //   title: '',  // optional, default will be 'Notification'
      //   message: '',  // the message to be displayed, can have multiple lines
      //   closeButtonText: '', // optional, default will be 'Close'
      // }
      multiMessages: []
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);

    const days = this.getDaysToLoad();
    this.daysToLoad = days;

    const urlParams = this.props.getSearchParams();
    const fromSearch = dot.pick('location.state.fromSearch', history);

    if(fromSearch) {
      this.props.clearSession().then(() => {
        this.loadFlights(urlParams).then(response => {
          if(response && response.additionalMessage) {
            this.showMessageBox(response.additionalMessage, 'Warning', 'OK')
          }
        })
      });
    }
    else {
      this.loadFlights(urlParams).then(response => {
        if(response && response.additionalMessage) {
          this.showMessageBox(response.additionalMessage, 'Warning', 'OK')
        }
      });
    }    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const days = this.getDaysToLoad();
    if (days !== this.daysToLoad) {
      this.daysToLoad = days;

      const params = this.props.getSearchParams();
      if (!params || !params.dates) return;

      const trips = this.props.trips;
      if (!trips || !trips.length) return;

      const dates = [];
      trips.forEach((trip, index) => {
        const date = dayjs(trip.date);
        dates[index] = date.format('YYYY-MM-DD');
      })
      params.dates = dates.join('|');
      this.loadFlights(params);
    }
  }

  getDaysToLoad() {
    return this.getWindowWidthInPixel() > 767 ? 7 : 3;
  }

  getWindowWidthInPixel() {
    return window.innerWidth;
  }

  loadFlights = (urlParams) => {

    const currencyCode = this.props.getCurrency()
    const days = this.daysToLoad;
    const params = {
      ...urlParams,
      days,
      currencyCode,
      source: getSource()
    }

    return this.props.getFlights(params).then(response => {
      const tripData = response && response.trips ? response.trips : []
      //if (helper.isTripEmpty(tripData)) return response;

      this.props.getBookingWithSummary().then(response => {
        this.bindBookingSelection(response.booking, tripData);
        this.expendDefaultFlight();
      });

      return response;

    });
  };

  expendDefaultFlight = () => {
    const activeKey = this.state.activeKey;
    //if(activeKey.find(x => x)) return;
    const trips = this.props.trips;
    if (!Array.isArray(trips) || !trips.length) return;

    trips.forEach((trip, bIndex) => {
      if (activeKey[bIndex]) return;

      if (!trip.date || !Array.isArray(trip.markets)) return;
      const market = trip.markets.find(x => x.date === trip.date);
      if (!market || !Array.isArray(market.flights)) return;

      const availables = market.flights.filter(x => x.segments && x.fares);
      // expend the default flight
      if (availables.length === 1) {
        const seg = availables[0].segments[0]
        if(seg.originCode === seg.originSearchCode && seg.destinationCode === seg.destinationSearchCode) {
          const index = market.flights.indexOf(availables[0]);
          if (index >= 0) this.handleSelect(`${index}-${bIndex}`, bIndex);
        }
      }
    })
  }

  bindBookingSelection = (booking, tripData) => {

    if (!booking || !Array.isArray(booking.segments)) return;
    if (!Array.isArray(tripData)) return;

    booking.segments.forEach((segment, index) => {
      if (!segment || !Array.isArray(segment.fares) || !segment.fares.length) return;
      const fare = segment.fares[0];
      if (!Array.isArray(fare.fareInfos) || !fare.fareInfos.length) return;
      //const fareInfo = fare.fareInfos[0];
      const selectedFare = {
        logicalFlightId: segment.logicalFlightId,
        departureDate: segment.departureDate,
        fare: fare.fareTypeName,
      };

      this.bindBookingDate(segment.departureDate, index, tripData);
      this.setSelectedFlight(selectedFare, index);

      if (tripData[index]) {
        const flightIndex = this.findFlightIndex(selectedFare, tripData[index]);
        if (flightIndex >= 0) {
          const key = `${flightIndex}-${index}`;
          this.handleSelect(key, index);
        }
      }
    });
  };

  bindBookingDate = (departureDate, index, tripData) => {
    if (!Array.isArray(tripData)) return;
    const trip = tripData[index];
    if (!trip) return;

    const markets = trip.markets;
    if (!Array.isArray(markets)) return;

    let date = null;
    departureDate = dayjs(departureDate).format("YYYY-MM-DDT00:00:00");
    markets.forEach(market => {
      if (market.date === departureDate) {
        date = departureDate;
      }
    });

    if (!date) {
      const mid = Math.floor(markets.length / 2);
      date = markets[mid].date;
    }
    this.props.setBookingDate({ selectedDate: date, trip: index });
  };

  findFlightIndex = (selectedFare, trip) => {
    let result = -1;
    if (!selectedFare || !trip || !Array.isArray(trip.markets)) return result;

    const marketDate = dayjs(selectedFare.departureDate).format("YYYY-MM-DDT00:00:00");
    const market = trip.markets.find(x => x.date === marketDate);
    if (!market) return result;

    if (!Array.isArray(market.flights) || market.flights.length < 1) return result;

    for (let index = 0; index < market.flights.length; index++) {
      const flight = market.flights[index];

      if (flight.logicalFlightId !== selectedFare.logicalFlightId || flight.departureDate !== selectedFare.departureDate) continue;
      if (!Array.isArray(flight.fares)) continue;

      const fare = flight.fares.find(f => {
        return f.fareTypeName === selectedFare.fare;
      });
      if (fare) {
        result = index;
        break;
      }
    }

    return result;
  };

  selectFlight = (selectedFare, fareSellKey, index) => {
    if (!fareSellKey) return;

    const fi = fareSellKey.indexOf("|");
    const fareID = fareSellKey.substring(0, fi);
    if (!fareID) return;

    this.props.setFareSellKey(fareSellKey).then(() => {
      this.setSelectedFlight(selectedFare, index);

      if (index === 0) {
        if (this.getWindowWidthInPixel() < 480) {
          return;
        }
        scrollToElement("#return-flight");
      }
    });

  };

  setSelectedFlight = (selectedFare, index) => {
    const selectedFlights = this.state.selectedFlights;
    selectedFlights[index] = selectedFare;

    this.setState({
      ...this.state,
      selectedFlights: selectedFlights,
    });
  };

  resetSegment = (selectedDate, trip) => {
    const data = { selectedDate, trip };

    const booking = this.props.booking;
    if (!booking || booking.bookingMode !== 'Booking') {
      this.props.setBookingDate(data);
      return;
    }

    if (!this.state.selectedFlights[trip]) {
      this.props.setBookingDate(data);
      return;
    }

    this.props.resetSegment(trip).then(response => {
      if (!response.booking.segments[trip]) this.setSelectedFlight(null, trip);
      this.props.setBookingDate(data);
    })
  };

  moveSlide = (index, days) => {

    const params = this.props.getSearchParams();
    if (!params || !params.dates) return;

    const dates = params.dates.split("|");
    if (index < 0 || index > dates.length - 1) return;

    const today = dayjs();
    let date = dayjs(dates[index]);

    if (days < 0) {
      if (date.isSame(today, "day")) return;

      date = date.add(days, "day");
      if (date.diff(today, "day") < 0) date = dayjs(today);
    }
    else {
      date = date.add(days, "day");
    }

    dates[index] = date.format("YYYY-MM-DD");

    // adjust the date to align up the departure and return date:
    // - return date should not be early than departure date;
    // - departure date should not be later than return date;
    if (dates.length === 2) {
      // assume there are only 2 dates 
      if(index === 0) {
        const otherDate = dayjs(dates[1])
        if(otherDate.diff(date, 'day') < 0) dates[1] = dates[0]
      }
      else {
        const otherDate = dayjs(dates[0])
        if(otherDate.diff(date, 'day') > 0) dates[0] = dates[1]
      }
    }

    params.dates = dates.join("|");

    if (typeof (this.props.onSearchParamsChanged) === "function") this.props.onSearchParamsChanged(params);

    this.props.resetSegment(index).then(() => {
      //this.setSelectedFlight(null, index);
      this.setState({selectedFlights: []}); // reset selected flights for all trip
      this.loadFlights(params);
    })
  };

  handleSelect = (key, index) => {
    const activeKey = this.state.activeKey;
    if (key === activeKey[index]) {
      activeKey[index] = null;
    }
    else {
      activeKey[index] = key;
    }

    this.setState({
      activeKey
    });
  };

  closeMessageBox = () => {
    this.setState({
      isShowMessageBox: false,
      message: null,
    });
  };

  showMessageBox = (message, messageTitle, closeButtonText) => {
    this.setState({
      isShowMessageBox: true,
      message: message,
      messageTitle,
      closeButtonText
    });
  };
 
  closeMultiMessagesBox = (isContinue) => {

    if(!isContinue) {
      this.setState({multiMessages: []})
      return;
    }

    let multiMessages = this.state.multiMessages;
    if(multiMessages.length > 0) {
      multiMessages = multiMessages.slice(1)
    }

    this.setState({multiMessages})
    if (multiMessages.length === 0 && isContinue) this.goNext();
  };

  onSelectCurrency = currency => {    
    this.props.setCurrency(currency)

    const urlParams = this.props.getSearchParams();
    
    this.loadFlights(urlParams).then(response => {
      this.setState({ selectedFlights: []})
      if(!response || !Array.isArray(response.trips || response.trips.length === 0)) {
        const rButton = ls.t('Button');
        const rTitle = ls.t('MessageBox.Title');

        this.showMessageBox(`Fares not available in the selected ${currency} currency`, rTitle.Error, rButton.Close)
      }
    })
  }

  FlightList = ({tripData, bIndex }) => {

    const markets = Array.isArray(tripData.markets) ? tripData.markets.filter(m => m.date === tripData.date) : [];
    const fareData = markets.length ? markets[0] : null;

    const rFlightPage = ls.t('FlightPage');
    //const locale = ls.t('rootClass');

    if (fareData && Array.isArray(fareData.flights) && fareData.flights.length > 0) {
      return (
        <React.Fragment>
          <div className="flightList">
            <div className="dateCon">{dayjs(tripData.date).format("dddd DD MMMM YYYY")}</div>
            <PanelGroup
              key={"flight-accordion" + bIndex}
              accordion id={"flight-accordion" + bIndex}
              activeKey={this.state.activeKey[bIndex]}
              onSelect={key => this.handleSelect(key, bIndex)}
            >
              {
                fareData.flights.map((flight, index) => {
                  if (!Array.isArray(flight.segments) || !flight.segments.length) return null;

                  return (
                    <FlightCard
                      key={index}
                      flight={flight}
                      currencyCode={tripData.currencyCode}
                      flightIndex={index}
                      bookIndex={bIndex}
                      selectFlight={this.selectFlight}
                      updateSummary={this.updateSummary}
                      selectedFlight={this.state.selectedFlights[bIndex]}
                      lowestFare={fareData.lowestFare}
                      onExpendFlight={() => this.handleSelect(`${index}-${bIndex}`, bIndex)}
                    />
                  );
                })
              }

            </PanelGroup>
          </div>
        </React.Fragment>
      );
    } else {
      return (<h1>{rFlightPage.NoFlights}</h1>);
    }
  };

  continue = () => {
    const rError = ls.t('FlightPage.Error');
    const rButton = ls.t('Button');
    const rTitle = ls.t('MessageBox.Title');
    const rOmanisFare = ls.t('FlightPage.OmanisFare');
    const rDestinationTerms = ls.t('FlightPage.DestinationTerms');

    // check if the selected flights match the trip
    if (this.props.trips && this.props.trips.length > 0) {
      const booking = this.props.booking;
      const bookingSegments = (booking && booking.segments) || [];
      if (!bookingSegments[0]) {
        this.showMessageBox(rError.DepartingFlight, rTitle.Error, rButton.Close);
        return;
      }

      if (this.props.trips.length > 1 && !bookingSegments[this.props.trips.length - 1]) {
        this.showMessageBox(rError.ReturningFlight, rTitle.Error, rButton.Close);
        return;
      }

      // check ID00 fare
      if(bookingSegments.length > 1) {
        const fares = bookingSegments.map(s => s.fares[0].fareTypeName);
        const faresID00 = fares.filter(f => f === 'ID00');
        if(faresID00.length > 0 && faresID00.length !== fares.length) {
          this.showMessageBox(rError.ID00Combination, rTitle.Error, rButton.Close);
          return;
        }
      }

      // check the transition time for return flights
      if (booking.tripType === 2) {
        if (booking.transitionTime < 0) {
          this.showMessageBox(rError.ReturnTime, rTitle.Error, rButton.Close);
          return;
        }
        else if (booking.transitionTime < 180) {
          this.showMessageBox(rError.TransitionTime, rTitle.Error, rButton.Close);
          return;
        }
      }

      const multiMessages = []

      // check if any diverted flights are selected
      const messageMetroGroup = helper.getMetroGroupMessage(bookingSegments, this.props.trips);
      if (messageMetroGroup) {
        multiMessages.push({message: messageMetroGroup, closeButtonText: rButton.Continue})
      }

      // check for Omanis Fare terms
      const messageOmanisFare = helper.getOmanisFareMessage(bookingSegments, rOmanisFare.Terms);
      if(messageOmanisFare) {
        multiMessages.push({message: messageOmanisFare, closeButtonText: rButton.Continue})
      }

      // check for destination terms
      const messageDestinationTerms = helper.getDestinationTermsMessage(bookingSegments, rDestinationTerms);
      if(messageDestinationTerms) {
        multiMessages.push({message: messageDestinationTerms, closeButtonText: rButton.Continue})
      }

      if(multiMessages.length > 0) {
        this.setState({multiMessages})
      }
      else {
        this.goNext();
      }
    }
  };

  goNext = () => {
    const { booking } = this.props;
    let nextPage = '/extra';
    if(booking.bookingMode === "MMB") {
      nextPage = isFlightDisrupted(booking) ? '/disruption' : '/mmb/summary';
    }
    jumpTo(getAgentPath() + nextPage)
  };

  goBack = () => {
    const userData = this.props.getUserData();
    const { booking } = this.props;
    let nextPage = `${getAgentPath()}/search`;
    if (booking.bookingMode === "MMB") {
      nextPage = isFlightDisrupted(booking) ? `${getAgentPath()}/disruption` : `${getAgentPath()}/mmb/summary`;
    }
    else if (userData && userData.loggedIn) {
      if (userData.userType === UserType.agent)
        nextPage = "/agent/portal";
      else if (userData.userType === UserType.member)
        nextPage = "/member/portal";
    }
    jumpTo(nextPage)
  };

  showSocialShare = () => {

    const trips = Array.isArray(this.props.trips) ? this.props.trips : [];
    if(trips.length === 0) return;

    const search = this.props.getSearchParams()
    const tripDates = trips.map(t => t.date);
    const query = buildSearchQuery(search, tripDates);

    if(!query) return;
    
    const encryped = btoa(query);

    const url = `${window.location.origin}/${search.lng || 'en'}/search?q=${encryped}`;
    const title = `Book a Flight to ${trips[0].destinationCity}`;
    
    if(navigator.share) {
      try
      {
        navigator.share({
          title,
          text: title,
          url
        })
        //.then(() => {
          //this.setState({shareTitle: 'share done'})
        //})
        .catch(error => {
          console.log(error)
          //this.setState({shareTitle: 'promise error:' +  JSON.stringify(error)})
        });
      }
      catch(error) {
        console.log(error)
        //this.setState({shareTitle: 'caught error:' +  JSON.stringify(error)})
      }
    }
    else {
      this.setState({
        shareUrl: url,
        shareTitle: title,
        isShowSocialShare: true
      })
    }
  }

  hideSocialShare = () => {
    this.setState({
      isShowSocialShare: false
    })
  }


  cancelChanges = () => {
    this.props.resetBooking()
      .then(() => {
        this.goBack();
      });
  };

  setPriceAlert = email => {
    if(!email) return;
    const request = { email }
    return this.props.setPriceAlert(request).then(() => {
      const rButton = ls.t('Button')
      const rTitle = ls.t('MessageBox.Title')
      this.showMessageBox('Your price alert is set successfully.', rTitle.Confirmation, rButton.Close)
    })
  }

  render() {

    const { trips, currencies, booking, bookingSummary, getBookingMode } = this.props;
    if (!Array.isArray(trips) || !trips.length) return null;

    const inAppBrowser = isInAppBrowser();
    const rButton = ls.t('Button');
    const rCommon = ls.t('Common');

    if (!rButton.Back) return null;

    const bookingMode = getBookingMode();
    const selectedCurrency = booking.currencyCode || this.props.getCurrency()
    const userData = this.props.getUserData();

    const bookingTotal = numberFormatter.formatCurrency(bookingSummary?.bookingTotal)
    const multiMessages = this.state.multiMessages || [];

    return (
      <div className="selectFlight">
        {
          trips.map((tripData, bindex) => {
            if (bookingMode && bookingMode.mode === "MMB" && Array.isArray(bookingMode.flights)) {
              const selected = bookingMode.flights.find(x => x.segmentIndex === bindex);
              if (!selected) return null;
            }

            return (
              <div key={bindex} className="flight-types" id={"flight-type" + bindex}>
                <DirectionHeader
                  bIndex={bindex}
                  origin={tripData.originCity}
                  destination={tripData.destinationCity}
                  date={tripData.date}
                  onModifySearch={() => this.goBack()}
                  onSelectCurrency={currency => this.onSelectCurrency(currency)}
                  selectedCurrency={selectedCurrency}
                  userData={userData}
                  bookingMode={bookingMode}
                  currencies={currencies}
                  onPriceAlert={email => this.setPriceAlert(email)}
                />
                <CalendarStrip tripData={tripData} bIndex={bindex}
                  onSelectDate={(date, trip) => this.resetSegment(date, trip)}
                  onMoveSlide={(index, days) => this.moveSlide(index, days)} />
                <this.FlightList tripData={tripData} bIndex={bindex} />
              </div>
            );
          })
        }
        <div className="clearfix btnCon">
          {booking.bookingMode !== "Booking" && booking.modified
            ? <Button type="submit" className="btn btn-primary pull-left flip btn-cancel btn-effect"
              onClick={() => this.cancelChanges()}>
              <span>{rButton.Cancel}</span>
            </Button>
            : !inAppBrowser && <Button type="submit" className="btn btn-primary pull-left flip btn-cancel btn-effect"
              onClick={() => this.goBack()}>
              <span><i className="picon picon-btn-arrow-left-w" />{rButton.Back}</span>
            </Button>}
          <Button type="submit" className="btn btn-primary pull-right flip btn-submit btn-effect"
            onClick={() => this.continue()}><span>{rButton.Continue}
              <i className="picon picon-btn-arrow-right-w" /></span>
          </Button>
          <div className="bottom-total pull-right">
            <label>{rCommon.BookingTotal}</label>
            <p>
              <span className="currency">
                {bookingSummary.currencyCode || "OMR"}
              </span>
              {
                Object.keys(bookingSummary).length > 0 ? numberFormatter.decimalSplitter(bookingTotal).amount : "0"
              }
              <span className={`${bookingSummary.currencyCode} decimal-point`}>
                { Object.keys(bookingSummary).length > 0 
                  ? numberFormatter.oneDecimalPoint(numberFormatter.decimalSplitter(bookingTotal).decimal, bookingSummary.currencyCode) 
                  : ".00"
                }
              </span>
            </p>
          </div>
          <div className={"social-share-button " + ls.t('rootClass')}>
            <div onClick={this.showSocialShare}>
              <span>Share</span>
              <img src={shareButton} alt="" />
            </div>
          </div>
        </div>
        <Modal className={ls.t("rootClass")} show={this.state.isShowMessageBox} onHide={this.closeMessageBox}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.messageTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{this.state.message}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeMessageBox}>{this.state.closeButtonText}</Button>
          </Modal.Footer>
        </Modal>
        { multiMessages.length > 0 && <PopupMessage 
          show={true} 
          message={multiMessages[0].message} 
          closeButtonText={multiMessages[0].closeButtonText} 
          onHide={this.closeMultiMessagesBox} /> }
        <SocialShare className={ inAppBrowser ? 'web-view' : ''} 
          url={this.state.shareUrl} 
          title={this.state.shareTitle} 
          show={this.state.isShowSocialShare} 
          onHide={this.hideSocialShare} />
      </div>

    );
  }
}

function mapStateToProps(state) {
  return {
    currencies: state.flights.currencies,
    trips: state.flights.trips,
    additionalMessage: state.flights.additionalMessage,
    tripsLoading: state.flights.isLoading,
    booking: state.booking.details,
    bookingSummary: state.booking.summary
  };
}

const mapDispatchToProps = {
  ...flightActions,
  ...sessionActions,
  getBookingWithSummary: bookingActions.getBookingWithSummary,
  resetSegment: bookingActions.resetSegment,
  resetBooking: mmbActions.resetBooking
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectFlights);
