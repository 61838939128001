import React from "react"
import { Button } from "@headlessui/react";
import { Booking, Summary } from "../../state/models";
import { numberFormatter } from "../../helpers";
import { RefundCharge, getRefundCharges } from "../MMB/helper";
import { ls } from "../../locale";

import { ChargeDetails } from "./ChangeDetails";
import btnStyles from "../../styles/btn.module.scss"

export function Payment({ booking, bookingSummary, onConfirm }: { booking: Booking, bookingSummary: Summary, onConfirm: () => void}) {

  const rSummaryPage = ls.t('MMB.SummaryPage');
  const rButton = ls.t('Button');

  let refundMessage = null;
  let refundCharge: RefundCharge = {};
  // refund info only show on the customer and member portal, not on the agent portal and staff portal
  if (booking.reservationBalance < 0) {
    refundCharge = getRefundCharges(booking, bookingSummary, rSummaryPage);
    if (refundCharge?.totalCharges) {
      refundMessage = Array.isArray(booking.additionalMessages) && booking.additionalMessages[0];
    }
  }

  const bookingCancelled = booking.paymentAmount < 0;
  const buttonText = booking.reservationBalance > 0 ? rButton.PayNow : rButton.ConfirmChanges;
  const amountText = bookingCancelled ? rSummaryPage.TotalRefund : rSummaryPage.TotalAmount;

  const paymentAmount = booking.paymentAmount < 0 ? booking.paymentAmount : booking.reservationBalance;
  const amount = numberFormatter.decimalSplitter(numberFormatter.formatCurrency(paymentAmount));
  const amountWhole = amount.amount;
  const amountDecimal = numberFormatter.oneDecimalPoint(amount.decimal, booking.currencyCode);

  return (
    <>
      <div className="tw-p-5 tw-mt-20 md:tw-mt-5 tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-3 tw-border-solid tw-border-2 sa-border-blue">
        <div className="lg:tw-col-span-2">
          {refundCharge?.totalCharges && <ChargeDetails {...refundCharge.totalCharges} currencyCode={booking.currencyCode} />}
          {refundCharge?.cancellationFee && <ChargeDetails {...refundCharge.cancellationFee} currencyCode={booking.currencyCode} />}
          <ChargeDetails amount={amountWhole} decimal={amountDecimal} currencyCode={booking.currencyCode} text={amountText} />
        </div>
        <div className="lg:tw-col-span-1 tw-flex tw-justify-center tw-items-end">
          <Button className={`${btnStyles.secondary} tw-w-5/6 tw-h-12`} onClick={() => onConfirm()}>{buttonText}</Button>
        </div>
      </div>
      {refundMessage && <div className="tw-mt-5">{refundMessage}</div>}
    </>
  )
}