import React from 'react';
import { ls } from "../../../locale";
import { Modal, Button, Row, Col, FormControl } from 'react-bootstrap';

import { customerValidator } from '../../../helpers';
import FormErrorBlock from '../../../components/FormErrorBlock';

export default function ChangePasswordBox(props) {

  const { values, errors, setInputState, setErrors } = props;

  function changePassword() {
    if(typeof(props.onChangePassword) === 'function') {
      const ee = {};
      if(!values.currentPassword) {
        ee.currentPassword = 'Current Password is required'
      }
      
      if(!values.newPassword)
        ee.newPassword = 'New Password is required'
      else 
        ee.newPassword = customerValidator.validatePassword(values.newPassword);
      
      if(!ee.newPassword && values.confirmPassword !== values.newPassword)
        ee.confirmPassword = 'Confirm Password does not match'; 

      if(Object.values(ee).find(x => x)) {
        setErrors(ee);
        return;
      }

      props.onChangePassword(values);
    }
  }

  return (
    <Modal className={ls.t("rootClass")} show={props.show} onHide={props.onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{props.message || 'Please enter the details below to change your password.'}</h5>
        <Row>
          <Col md={4} xs={12}>
            <FormControl type="password" placeholder="Current Password" 
              value={values.currentPassword} 
              onChange={event => setInputState('currentPassword', event.target.value)} />
            <FormErrorBlock error={errors.currentPassword} />
          </Col>
          <Col md={4} xs={12}>
            <FormControl type="password" placeholder="New Password" 
              value={values.newPassword} 
              onChange={event => setInputState('newPassword', event.target.value)} />
            <FormErrorBlock error={errors.newPassword} />
          </Col>
          <Col md={4} xs={12}>
            <FormControl type="password" placeholder="Confirm Password" 
              value={values.confirmPassword} 
              onChange={event => setInputState('confirmPassword', event.target.value)} />
            <FormErrorBlock error={errors.confirmPassword} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={changePassword}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}