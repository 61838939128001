import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { ls } from "../../locale";
import queryString from 'query-string'

import RatingBar from '../../components/RatingBar'

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import './index.scss'


import { bookingService } from "../../state/ducks/booking/bookingService"

function FeedbackPage({location}) {

  const [feedbackItems, setFeedbackItems] = useState([]);
  const [ratings, setRatings] = useState({})
  const [pnr, setPnr] = useState(null)
  const [rated, setRated] = useState(false);


  useEffect(() => {
    const feedbackKey = 'Feedback.PostDeparture.Items'
    const rItems = ls.t(feedbackKey)
    const keys = Object.keys(rItems);

    const items = keys.map(k => ({
      key: `${feedbackKey}.${k}`,
      title: rItems[k]
    }))

    // console.log('feedback items: ', items)
    setFeedbackItems(items);
  }, [])

  useEffect(() => {
    const search = queryString.parse(location.search);
    setPnr(search.pnr);
  }, [location])

  const rate = (key, rating) => {

    if (!key) return;
    if (!rating) rating = 0;

    setRatings({
      ...ratings,
      [key]: rating
    })
  }

  const save = () => {

    const keys = Object.keys(ratings);
    if(keys.length === 0) return;

    const items = keys.map(key => ({
      key,
      rating: ratings[key]
    }))

    const request = {
      pnr,
      items
    }

    bookingService.ratePostDeparture(request).then(() => {
      setRated(true);
    })
  }

  return (
    <div className={ls.t("rootClass") + " main-container"} dir={ls.t("direction")}>
      <div className="header-con">
        <Header />
      </div>
      <div className="top-bar"></div>
      <div className="container padt40 feedback-container">
        <div className="min-height feedback-main">
          <h1>
            RATE YOUR EXPERIENCE WITH SALAMAIR &nbsp;
            <img src="/images/email/survey-icon.png" alt="survey" />
          </h1>
          <hr />
          {feedbackItems.map(item => (
            <RatingBar 
              className="ratingbar-spacing" 
              key={item.key} 
              size={24} 
              title={item.title} 
              rated={ratings[item.key] || 0} 
              editable 
              onRate={rating => rate(item.key, rating)} />
          ))}

          {!rated && <div className="clearfix btnCon">
            <Button disabled={!pnr || Object.keys(ratings).length === 0} className="btn btn-primary btn-submit" onClick={save}>Save</Button>
          </div>}
          { rated && <div>{ls.t('Feedback.PostDeparture.Thanks')}</div>}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default FeedbackPage