import types from "./types";

const INITIAL_STATE = {
  isLoading: false,
  trips: [],
  currencies: [],
  errors: [],
  searchRequest: null,
};


export default function (state = INITIAL_STATE, { type: actionType, payload }) {
  switch (actionType) {
    case types.FLIGHT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.FLIGHT_RESPONSE:
      return {
        ...state,
        isLoading: false,
        trips: payload.trips,
        currencies: payload.currencies
      };

    case types.SET_BOOKINGDATE: {
      const newUpdate = {
        ...state.trips,
        [payload.trip]: { ...state.trips[payload.trip], date: payload.selectedDate }
      };

      return {
        ...state,
        trips: Array.from(Object.keys(newUpdate), k => newUpdate[k])
      };
    }
    case types.FLIGHT_SEARCH_RESPONSE:
      return {
        ...state,
        searchRequest: payload
      }

    default:
      return state;
  }
}
