import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import dayjs from "dayjs";
import { store } from "./helpers";
import App from "./pages/App";

import "./index.scss";

// extend dayjs with utc plugin
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)
