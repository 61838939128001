import React, {useState, useCallback, useRef} from 'react'
import { connect } from 'react-redux'

import { Col, Row, Modal, Button } from "react-bootstrap"

import { withControlledComponent } from "../../../helpers";
import BasePage from "../../../components/BasePage"
import SideMenu from "../SideMenu"

import { agentActions } from "../../../state/ducks/agent";
import { sessionActions } from "../../../state/ducks/session";
import PasswordForm from './PasswordForm';

import './index.scss';

const initPasswordDetails = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
}

const PasswordComponent = withControlledComponent(PasswordForm);

function PasswordPage({getUserData, changePassword, location}) {

  const [isShowMessageBox, setIsShowMessageBox] = useState(false);
  const [message, setMessage] = useState(null);

  const passwordComponent = useRef(null);

  const showMessageBox = useCallback(message => {
    setIsShowMessageBox(true);
    setMessage(message);
  }, [])

  const closeMessageBox = useCallback(() => {
    setIsShowMessageBox(false);
  }, [])

  const handleSave = useCallback(values => {
    const userData = getUserData();
    
    // check if there is an agent logged in, although no-login shouldn't happen
    if(!userData || !userData.userName) {
      showMessageBox('Please login.');
      return;
    }

    const data = {
      ...values,
      userName: userData.userName
    }

    changePassword(data).then(() => {
      showMessageBox("Your password has been changed.");
      if(passwordComponent.current) passwordComponent.current.reset();
    })
  }, [getUserData, changePassword])

    return (
      <BasePage>
        <Row className="min-height padt40">
          <Col md={9} xs={12}>
            <h1>Change Password</h1>
            <hr />
            <PasswordComponent ref={passwordComponent} 
              initValues={initPasswordDetails} onSave={handleSave} />
          </Col>
          <Col md={3} xs={12}>
            <SideMenu activeItem="Password" path={location.pathname} />
          </Col>
        </Row>

        <Modal show={isShowMessageBox} onHide={closeMessageBox}>
          <Modal.Header closeButton>
            <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>{message}</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeMessageBox}>OK</Button>
          </Modal.Footer>
        </Modal>
      </BasePage>
    )  
}


function mapStateToProps() {
  return {
  }
}

const mapDispatchToProps = {
  ...agentActions,
  getUserData: sessionActions.getUserData,
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordPage);