import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from "query-string";

import { Col, Row, Modal, Button } from "react-bootstrap"
import Dropdown from "react-dropdown";

import { commonHelpers, numberFormatter, logEvent, paymentHelper } from "../../../helpers";

import BasePage from "../../../components/BasePage"
import SideMenu from "../SideMenu"

import PaymentOptions from "../../CheckoutPage/PaymentOptions"

import { agentTopupActions } from "../../../state/ducks/agentTopup";
import { sessionActions } from "../../../state/ducks/session";

import { DataCollectionIframe } from "../../CheckoutPage/DataCollectionIframe";
import { PaymentStepupWindow } from "../../CheckoutPage/PaymentStepupWindow";
import './index.scss'
import { backdropActions } from '../../../state/ducks/backdrop';

class TopupPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      paymentMethods: ['CS'],
      paymentMethodCode: 'CS',
      topupValues: [],
      topupSelected: null,
      topupOptions: [],
      topupAmount: { value: 0, label: '' },
      payRate: null,
      enrollDetail: {},
      isShowMessageBox: false,
      message: null,
      messageBoxTitle: 'Notification'
    }
  }

  fingerprintDetail = null;

  componentDidMount() {

    this.setupTopup()
    this.setupFingerPrint();

    const { location } = this.props;
    const params = queryString.parse(location?.search);
    
    const paytype = (params.paytype || '').toLowerCase();

    if(paytype === 'agenttopup') {
      if(params.code) {
        const errors = [
          { code: params.code, message: 'Your payment was declined.'}
        ];

        this.handlePaymentError(errors);
      }
      else {
        this.showMessageBox('Top up is processed successfully.')
      }
    }


    // setup cardinal event 
    window.addEventListener("message", this.handleCardinalMessage)
    window.document.addEventListener('TDS_PAYMENT_RESULT', this.handleTdsPaymentResult)
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleCardinalMessage)
    window.document.removeEventListener('TDS_PAYMENT_RESULT', this.handleTdsPaymentResult)
  }

  setupTopup = () => {
    this.props.getTopupInfo().then(response => {
      //console.log('topup: ', response);
      if (Array.isArray(response.values) && response.values.length > 0) {
        const options = response.values.map(x => ({
          value: x.amount,
          label: `${x.topupCurrency} ${x.topupAmount}`
        }))

        this.setState({
          topupValues: response.values,
          topupSelected: response.values[0],
          topupOptions: options,
          topupAmount: options[0],
          payRate: this.getPayRate(response.values[0])
        })
      }
    })

    this.setupPaymentMethods().then(methods => {
      if(methods.length > 0) this.setPaymentMethodState(methods[0])
    });
  }

  handleCardinalMessage = event => {

    if (event.origin !== process.env.REACT_APP_CENTINELAPI) {
      //console.log('origin not match.', process.env.REACT_APP_CENTINELAPI);
      return;
    }

    const data = JSON.parse(event.data);

    logEvent({
      trackId: data.SessionId,
      eventName: 'topup cardinal event', 
      details: {
        eventData: data
      }
    })

    this.processedCallback(data)
  }

  handleTdsPaymentResult = event => {
    const self = this
    const detail = event.detail

    logEvent({
      eventName: 'topup tds payment result received',
      details: detail
    })

    setTimeout(() => {
      
      this.props.showBackdrop(false);
      
      const state = {
        enrollDetail: {
          show: false
        },
      }
      // close the popup
      self.setState(state)
      
      const detail = event.detail

      // detail is null: invalid parameter:
      // detail.code is not null: validation failed
      if(!detail || detail.code) {
        self.showMessageBox(`Failed to process the payment. Error code: ${detail.code}. Please try again.`, 'Error')
      }
      else {
        self.showMessageBox('Top up is processed successfully.', 'Notification');
        self.paymentOptionsComponent.resetCurrentPayment();
      }
    }, 100);
  }

  processedCallback = results => {

    this.props.hideLoading();

    if (!results.Status) {
      const errors = [{ errorCode: 500, message: "unable to validate the card number." }]
      throw errors
    }

    return this.props.tdsEnrol(this.paymentDetails, this.getTopupAmount(), results)
      .then(tdsResponse => {
        //console.log('tdsEnrolment: ', tdsResponse)

        logEvent({
          trackId: "notrakcing",
          eventName: 'topup tds enroll complete',
          details: tdsResponse
        })

        if (!tdsResponse.success) {
          // show error
          this.showMessageBox('Unable to process the payment. Please try again.', 'Error');
          this.setupFingerPrint();
          return
        }
        else if (tdsResponse.paymentComplete) {
          // show success
          this.showMessageBox('Top up is processed successfully.', 'Notification');
          this.paymentOptionsComponent.resetCurrentPayment();
        }
        else if (tdsResponse.require3DS) {
          this.props.showBackdrop(true);
          this.setState({
            enrollDetail: {
              show: true,
              ...tdsResponse.enrollDetail
            }
          })
        }
        else {
          //console.log('payment failed: ', tdsResponse);
          this.showMessageBox('Failed to process the payment. Please try again.', 'Error');
          this.setupFingerPrint()
        }
      })
      .catch(errors => {
        console.log(errors);
        //this.handlePaymentError(errors);
      })
  }

  setupFingerPrint = () => {
    this.props.getFingerprint().then(response => {
      if(response.fingerprintUrl) {
        // fingerprint onload callback
        const onload = () => {
          logEvent({
            trackId: response.reference ?? 'notracking',
            eventName: 'topup fingerprint loaded', 
            details: {
              url: response.fingerprintUrl
            }
          })
        }
        
        // fingerprint onerror callback
        const onerror = () => {
          logEvent({
            trackId: response.reference ?? 'notracking',
            eventName: 'topup fingerprint failed', 
            details: {
              url: response.fingerprintUrl
            }
          })
          // try one more time. no more retry because we don't pass onerror again
          setTimeout(() => {
            logEvent({
              trackId: response.reference ?? 'notracking',
              eventName: 'topup retry loading fingerprint', 
              details: {
                url: response.fingerprintUrl
              }
            })
            commonHelpers.createFinderprintScriptTag(response.fingerprintUrl, onload);
          }, 1000)
        }
        
        // load the script 
        commonHelpers.createFinderprintScriptTag(response.fingerprintUrl, onload, onerror);
      }
    })
  }

  setPaymentMethodState = (code) => {
    this.setState({
      paymentMethodCode: code
    })
  }

  setupPaymentMethods = () => {
    return this.props.getPaymentMethods()
    .then(response => {
      const paymentMethods = response.paymentMethods || [];
      this.setState({
        paymentMethods,
      });

      return paymentMethods;
    });
  }

  setTopupAmount = option => {
    const selected = Array.isArray(this.state.topupValues) && this.state.topupValues.find(x => x.amount === option.value);
    this.setState({
      topupSelected: selected,
      topupAmount: option,
      payRate: this.getPayRate(selected)
    })
  }

  getTopupAmount = () => {
    const selected = this.state.topupSelected;

    if (selected) return {
      amount: selected.topupAmount,
      currency: selected.topupCurrency
    }
    else {
      return null;
    }
  }

  topup = () => {
    if (this.validateForm() === false) {
      return;
    }

    const paymentOption = this.paymentOptionsComponent.getFormValues();

    // cybersource
    if (paymentOption.paymentMethodCode === "CS") {
      const expiryFields = paymentOption.expiration.split("/");
      const details = {
        methodCode: "CS",
        cardNumber: paymentOption.cardNumber,
        nameOnCard: paymentOption.nameOnCard,
        expiryYear: expiryFields[1],
        expiryMonth: expiryFields[0],
        cvNumber: paymentOption.cvc
      };
      this.start3DS(details);
      return;
    }
    // smartpay
    if(paymentOption.paymentMethodCode === 'SP') {
      // console.log('pay with SmartPay.')
      this.props.getSmartPayParams(this.getTopupAmount()).then(response => {
        if(response.url && response.encryptedRequest) {
          // console.log(response)
          const data = [{
            fieldName: 'encRequest',
            fieldValue: response.encryptedRequest
          },{
            fieldName: 'access_code',
            fieldValue: response.accessCode
          }]
          const form = paymentHelper.createSmartPayForm(response.url, data)
          this.props.showLoading()
          form.submit()
        }
      })
    }
    // omannet
    if(paymentOption.paymentMethodCode === "ON") {
      this.props.makeOmanDebitPayment(this.getTopupAmount())
      .then((onResponse) => {
        // we show the loading mask to prevent the 'Pay Now' button is hit again
        this.props.showLoading(); 
        window.location.href = onResponse.redirectUrl;
      })
    }
  }

  start3DS = paymentDetails => {
    this.paymentDetails = paymentDetails;

    this.props.authorizeCS(paymentDetails, this.getTopupAmount())
      .then(tokenResponse => {
        //console.log('authorizeCS: ', tokenResponse)
        // setup data collection iframe
        const cardinalCollectionForm = document.querySelector('#cardinal_collection_form');
        if(cardinalCollectionForm) { // form exists
          logEvent({
            trackId: "notracking",
            eventName: 'topup data collection form submitted',
            details: {
              authDetail: tokenResponse.authDetail,
            }
          })


          this.props.showLoading();

          const cardinal_collection_form_input = document.getElementById("cardinal_collection_form_input")
          cardinal_collection_form_input.value = tokenResponse.authDetail.accessToken        
          
          //console.log('submit data collection form: ', tokenResponse.authDetail.dataCollectionUrl)
          cardinalCollectionForm.action = tokenResponse.authDetail.dataCollectionUrl
          cardinalCollectionForm.submit();
        }

        this.fingerprintDetail = tokenResponse.fingerprintDetail;
        this.authDetail = tokenResponse.authDetail
      })
      .catch(errors => {
        console.log(errors)
        // this.handlePaymentError(errors);
      })
  }

  validateForm = () => {

    const topupAmount = this.getTopupAmount();
    if (!topupAmount) return false;

    const isPaymentFormValid = this.paymentOptionsComponent.validateForm();
    return isPaymentFormValid;
  }

  handlePaymentError = errors => {
    let error = 'Unknown error.'
    if(Array.isArray(errors) && errors.length > 0) {
      error = errors[0]
      if(errors[0].code) error = 'Code: ' + errors[0].code;
      if(errors[0].message) error += ', message: ' + errors[0].message
    }
    this.showMessageBox(`Failed to process payment. ${error} please try again.`, 'Error')
  }

  getPayRate = option => {
    let payRate = null;
    if(option && option.topupCurrency !== option.payCurrency) {
      payRate = (option.payTotal / option.topupTotal).toFixed(5);

      payRate = `* Payment will be in ${option.payCurrency} ${option.payTotal} ` +
                `(Conversion rate: 1 ${option.topupCurrency} = ${payRate} ${option.payCurrency})`;
    }
    return payRate
  }

  showMessageBox = (message, title = 'Notification') => {
    this.setState({
      isShowMessageBox: true,
      message,
      messageBoxTitle: title
    })
  }

  closeMessageBox = () => {
    this.setState({
      isShowMessageBox: false
    })
  }

  
  handlePaymentStepupFormSubmit = data => {
    logEvent({
      trackId: data.SessionId ?? "notracking",
      eventName: 'topup stepup form submitted',
      details: data
    })
  }

  handlePaymentStepupFormError = (message, data) => {
    logEvent({
      trackId: data.SessionId ?? "notracking",
      eventName: 'topup stepup form error',
      details: {
        error: message,
        data
      }
    })
  }

  render() {

    const selected = this.state.topupSelected;
    let topupAmount, topupAdminFee, topupTotal;
    if(selected) {
      topupAmount = numberFormatter.decimalSplitter(selected.topupAmount);
      topupAdminFee = numberFormatter.decimalSplitter(selected.topupAdminFee);
      topupTotal = numberFormatter.decimalSplitter(selected.topupTotal);
    }

    return (
      <BasePage>
        <Row className="min-height padt40">
          <Col md={9} xs={12}>
            <h1>Top Up my Account (OMR)</h1>
            <hr />
            <Row>
              <Col sm={4} className="topup-title">
                Top Up Amount
              </Col>
              <Col sm={6} className="topup-amount">
                <div className="amount-select">
                  <Dropdown options={this.state.topupOptions} value={this.state.topupAmount} onChange={this.setTopupAmount} />
                </div>
              </Col>
            </Row>

            <PaymentOptions className="topup"
              ref={(element) => (this.paymentOptionsComponent = element)}
              paymentMethods={this.state.paymentMethods}
              paymentMethodCode={this.state.paymentMethodCode}
              onPaymentMethodChanged={code => this.setPaymentMethodState(code)}
            />
            {selected && <Row>
              <Col sm={4}></Col>
              <Col sm={8}>
              <div className="final-amount center-block">
                <p>
                  <span>Top Up Amount</span>
                  <span className="price">
                    {selected.topupCurrency}
                    <span>{topupAmount.amount}
                      <span className="decimal-point">{numberFormatter.oneDecimalPoint(topupAmount.decimal, selected.payCurrency)}</span>
                    </span>
                  </span>
                </p>
                <p>
                  <span>Admin Fee</span>
                  <span className="price">
                    {selected.topupCurrency}
                    <span>{topupAdminFee.amount}
                      <span className="decimal-point">
                        {numberFormatter.oneDecimalPoint(topupAdminFee.decimal, selected.payCurrency)}
                      </span>
                    </span>
                  </span>
                </p>
                <p className="total">
                  <span>Total to Pay</span>
                  <span className="price">
                    {selected.topupCurrency}
                    <span>{topupTotal.amount}
                      <span className="decimal-point">
                        {numberFormatter.oneDecimalPoint(topupTotal.decimal, selected.payCurrency)}
                      </span>
                    </span>
                  </span>
                </p>
              </div>
              </Col>
            </Row>}
            {this.state.payRate && <div className="pay-rate">
              <span>{this.state.payRate}</span> 
            </div>}
            <div className="clearfix btnCon">
              <Button disabled={this.state.loading} className="btn btn-primary pull-right flip btn-submit" 
                onClick={this.topup}>Pay Now</Button>
            </div>
          </Col>
          <Col md={3} xs={12}>
            <SideMenu activeItem="TopupAccount" path={this.props.location.pathname} />
          </Col>
        </Row>

        <Modal show={this.state.isShowMessageBox} onHide={this.closeMessageBox}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.messageBoxTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>{this.state.message}</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeMessageBox}>OK</Button>
          </Modal.Footer>
        </Modal>

        <DataCollectionIframe />
        {this.state.enrollDetail.show && <PaymentStepupWindow 
          md={this.state.enrollDetail.md} 
          jwt={this.state.enrollDetail.accessToken} 
          stepUpUrl={this.state.enrollDetail.stepUpUrl} 
          windowHeight={this.state.enrollDetail.windowHeight} 
          windowWidth={this.state.enrollDetail.windowWidth} 
          onSubmit={this.handlePaymentStepupFormSubmit}
          onError={this.handlePaymentStepupFormError}
        />}

      </BasePage>
    )
  }
}


function mapStateToProps() {
  return {
  }
}

const mapDispatchToProps = {
  ...agentTopupActions,
  ...backdropActions,
  showLoading: sessionActions.showLoading,
  hideLoading: sessionActions.hideLoading,
}

export default connect(mapStateToProps, mapDispatchToProps)(TopupPage);