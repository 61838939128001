// the landing page for download pkpass file from email
// a link in a boarding pass email will take the user to this page

import React, { useEffect, useCallback } from 'react'
import { Row, Col } from "react-bootstrap";
import { ls } from "../../locale";
import queryString from 'query-string'

import './index.scss'

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { commonHelpers } from '../../helpers'
import { bookingService } from '../../state/ducks/booking/bookingService'
import { mmbService } from '../../state/ducks/mmb/mmbServices'

export default function PkPassPage({ location }) {

  const download = useCallback(() => {

    const query = queryString.parse(location.search);
    
    //console.log("query: ", query);

    mmbService.retrieveBooking(query.pnr, query.lastName)
    .then(() => {
      bookingService.getPKPassFile()
      .then(response => {
        commonHelpers.createDownloadTag(response, 'salamair.pkpass', 'application/vnd.apple.pkpass');
      })
    });
  }, [location])

  useEffect(() => {
    setTimeout(() => {
      download();
    }, 1000);
  }, [download])


  return (

    <div className={ls.t("rootClass") + " main-container"}
      dir={ls.t("direction")}>
      <div className="header-con">
        <Header />
      </div>
      <div className="container padt40 padb40 pkpass-container">
        <Row className="min-height pkpass-main">
          <Col>
            <h3>Itinerary file for your flight will be ready to download shortly.</h3>
            <p>If the download didn't start automatically, please click the button below to download:</p>
            <button onClick={download}>Download my Boarding Pass</button>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>

  )
}