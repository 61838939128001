import React from "react";
import { ListGroup , ListGroupItem } from "react-bootstrap";

import { history } from "../../../helpers"
import { sessionService } from "../../../state/ducks/session/sessionService";

import "./index.scss";
import { isAllowAdmin } from "../../../helpers/userHelper";

export default function SideMenu(props) {

  function jumpTo(next) {
    if(!props.path || !next) return;

    let path = '';
    if(next.indexOf('/') === 0) {
      if(props.path.length < 3) return;

      path = props.path.slice(0, 3);
    }
    else {
      const index = props.path.lastIndexOf('/');
      if(index < 0) return;

      path = props.path.slice(0, index + 1)
    }
    path = `${path}${next}`;
    history.push(path);
  }

  function jumpToAgentManagement() {
    if(typeof(props.onAgentManagement) === 'function') 
      props.onAgentManagement();
    else 
      jumpTo('/agent/manage');
  }

  const userData = sessionService.getUserData();
  const isShowAgentManagement = isAllowAdmin(userData);

  return(
    <div className="side-menu">
      <h1>Agent Options</h1>
      <ListGroup as="ul">
        {/* <ListGroupItem as="li" active={props.activeItem==='MyBooking'} onClick={() => jumpTo('portal')}>
          <h2>My Bookings </h2>
          <p>Retrieve your booking. Past or future.</p>
        </ListGroupItem> */}
        <ListGroupItem as="li" active={props.activeItem==='MMB'} onClick={() => jumpTo("/agent/mmb")}>
          <h2>Manage my Bookings</h2>
        </ListGroupItem>
        <ListGroupItem as="li" active={props.activeItem=== 'TopupAccount'} onClick={() => jumpTo('/agent/topup')}>
          <h2>Top Up my Account</h2>
        </ListGroupItem>
        <ListGroupItem as="li" active={props.activeItem==='DashboardReport'} onClick={() => jumpTo('/agent/report')}>
          <h2>Reports</h2>
        </ListGroupItem>
        {isShowAgentManagement && <ListGroupItem as="li" active={props.activeItem=== 'AgentManagement'} onClick={jumpToAgentManagement}>
          <h2>Agent Management</h2>
        </ListGroupItem>}
        <ListGroupItem as="li" active={props.activeItem==='Password'} onClick={() => jumpTo('/agent/password')}>
          <h2>Change Password</h2>
        </ListGroupItem>
        {/* <ListGroupItem as="li" active={props.activeItem==='BuyAVoucher'} onClick={() => jumpTo('/voucher')}>
          <h2>Buy a Voucher</h2>
        </ListGroupItem>
        <ListGroupItem as="li" active={props.activeItem==='RetrieveVoucher'} onClick={() => jumpTo('/voucher/retrieve')}>
          <h2>Retreive my Vouchers</h2>
        </ListGroupItem> */}
      </ListGroup>
      <h1>Information</h1>
      <ListGroup as="ul">
        <ListGroupItem as="li">
          <h2>Latest Promotions </h2>
        </ListGroupItem>
        <ListGroupItem as="li" active={props.activeItem==='help'} onClick={() => jumpTo('/agent/help')}>
          <h2>Help</h2>
        </ListGroupItem>
        <ListGroupItem as="li">
          <h2>Contact Us</h2>
        </ListGroupItem>
      </ListGroup>
    </div>
  )
}
