import { api } from "../api";

export const mmbService = {
  retrieveBooking,
  confirmChanges,
  resetBooking,
  cancelFlight,
  checkCancelFlight,
  resendItinerary,
  updateContact,
  processDisruption,
};

function retrieveBooking(pnr, lastName) {
  const data = {
      pnr,
      lastName
    };

    return api.getFetch(`api/manage/retrieve`, data)
}

function confirmChanges() {
  return api.postFetch(`api/manage/confirm`)
}

function resetBooking() {
  return api.postFetch(`api/manage/resetbooking`)
}

// flights is an array of number containing the logical flight id of flights to cancel
function cancelFlight(flights) {
  return api.postFetch(`api/manage/cancelFlight`, {flights})
}

function checkCancelFlight() {
  return api.getFetch(`api/manage/cancelFlight`)
}

function resendItinerary(email) {
  return api.postFetch(`api/manage/resendItinerary`, {email})
}

function updateContact(data) {
  return api.postFetch(`api/manage/contact`, data)
}

function processDisruption(action) {
  const data = {
    action
  };
  return api.postFetch(`api/manage/disruption`, data)
}

