import React from "react";
import { ls } from "../../locale";
import "./index.scss";

import { history } from "../../helpers";

export default function SidePanel(props) {
  const bookingHotelUrl = props.bookingHotelUrl || "https://www.booking.com/index.html?aid=1212416";
  const contactUsUrl = props.contactUsUrl || "https://www.salamair.com/en/contact-us/call-centre";
  
  function jumpTo(next) {
    const locale = props.locale || 'en';
    history.push(`/${locale}${next}`);
  }

  return (
    <div className="panel sidePanel">
      <ul>
        <li>
          <button className="link-button" onClick={() => jumpTo('/search')}>
            <i className="picon picon-plane-gray">
            </i>
            <span>{ls.t('SideMenu.BookAFlight')}</span>
          </button>
        </li>
        <li>
          <button className="link-button" href={bookingHotelUrl} target="_blank">
            <i className="picon picon-hotel">
            </i>
            <span>{ls.t('SideMenu.BookAHotel')}</span>
          </button>
        </li>
        <li>
          <button className="link-button" onClick={() => jumpTo('/mmb')}>
            <i className="picon picon-member">
            </i>
            <span>{ls.t('SideMenu.ManageMyBooking')}</span>
          </button>
        </li>
        <li>
          <button className="link-button" onClick={() => jumpTo('/wci')}>
            <i className="picon picon-member">
            </i>
            <span>{ls.t('SideMenu.WebCheckIn')}</span>
          </button>
        </li>
        <li>
          <button className="link-button" onClick={() => jumpTo('/agent')}>
            <i className="picon picon-agent">
            </i>
            <span>{ls.t('SideMenu.AgentLogIn')}</span>
          </button>
        </li>
        <li>
          <button className="link-button" href={contactUsUrl} target="_blank">
            <i className="picon picon-register">
            </i>
            <span>{ls.t('SideMenu.ContactUs')}</span>
          </button>
        </li>
        {/* <li>
          <button className="link-button" onClick={() => jumpTo('/voucher')}>
            <i className="picon picon-register">
            </i>
            <span>Buy a Voucher</span>
          </button>
        </li>
        <li>
          <button className="link-button" onClick={() => jumpTo('/voucher/retrieve')}>
            <i className="picon picon-register">
            </i>
            <span>Retrieve Voucher</span>
          </button>
        </li> */}
      </ul>
    </div>
  );
}
