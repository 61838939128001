import React from 'react'
import { ls } from "../../locale";

export function AgentProfile({displayName, onLogout, onLink}) {

  const rCommon = ls.t('Common');

  return (
    <div>
      {rCommon.Welcome} &nbsp;
      <button className="link-button" onClick={() => onLink('portal')}>{displayName}</button>&nbsp;-&nbsp; 
      <button className="link-button" onClick={onLogout}> {rCommon.Logout}</button>
    </div>
  )

}