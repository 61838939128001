import types from "./types";
import { sessionService } from "./sessionService";
import { loadingActions } from "../../../actions";

export default {
  createSession,
  getSession,
  setSessionToken,
  clearSession,
  setSearchParams,
  getSearchParams,
  setBookingMode,
  getBookingMode,
  setUserData,
  getUserData,
  setPassengerData,
  getPassengerData,
  logout,
  getUserStatus,
  //updateUserData,
  setCulture,
  getCulture,
  showLoading,
  hideLoading,
  setCurrency,
  getCurrency,
};

function createSession() {
  return dispatch => {
    dispatch(request());
    sessionService.createSession();
    dispatch(response());
  };
}

function getSession() {
  return dispatch => {
    dispatch(request());
    return sessionService.getSession()
      .then(res => {
        dispatch(response());
        return res;
      });
  };
}

function setSessionToken(token) {
  return dispatch => {
    const result = sessionService.setSessionToStore(token);
    if(result) dispatch(response())
  }
}

function logout() {
  return () => {
    return sessionService.logout().then(response => {
      sessionService.setPassengerData(null);
      return response;
    });
  }
}

function getUserStatus() {
  return () => sessionService.getUserStatus()
}

function setSearchParams(params) {
  return () => sessionService.setSearchParams(params)
}

function getSearchParams() {
  return () => sessionService.getSearchParams()
}

function setBookingMode(mode) {
  return () => sessionService.setBookingMode(mode)
}

function getBookingMode() {
  return () => sessionService.getBookingMode()
}

function setUserData(data) {
  return () => sessionService.setUserData(data)
}

function getUserData() {
  return () => sessionService.getUserData()
}

function setPassengerData(data) {
  return () => sessionService.setPassengerData(data)
}

function getPassengerData() {
  return () => sessionService.getPassengerData()
}

function setCulture(culture) {
  return () => sessionService.setCulture(culture)
}

function getCulture() {
  return () => sessionService.getCulture()
}

function setCurrency(currency) {
  return () => sessionService.setCurrency(currency)
}

function getCurrency() {
  return () => sessionService.getCurrency()
}

function clearSession() {
  return () => sessionService.clearSession()
}

function showLoading() {
  return (dispatch) => dispatch(loadingActions.startloading())
}

function hideLoading() {
  return (dispatch) => dispatch(loadingActions.completeLoading())
}

function request() {
  return { type: types.SESSION_REQUEST };
}

function response() {
  return { type: types.SESSION_CREATE };
}

