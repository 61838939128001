import React from 'react';
import { ls } from "../../../locale";
import { Modal, Button, Row, Col, FormControl } from 'react-bootstrap';

import { customerValidator } from '../../../helpers';
import FormErrorBlock from '../../../components/FormErrorBlock';

export default function ForgotPasswordBox(props) {

  const { values, errors, setInputState, setErrors } = props;

  function forgotPassword() {
    if(typeof(props.onForgotPassword) === 'function') {
      const ee = {};
      if(!values.username) {
        ee.username = 'User Name is required'
      }

      if(!values.email)
        ee.email = 'Email is required'
      else 
        ee.email = customerValidator.validateEmail(values.email);
            
      if(Object.values(ee).find(x => x)) {
        setErrors(ee);
        return;
      }

      props.onForgotPassword(values);
    }
  }

  return (
    <Modal className={ls.t("rootClass")} show={props.show} onHide={props.onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Forgot Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Please enter the details below to reset your password.</h5>
        <Row>
          <Col md={6} xs={12}>
            <FormControl type="text" placeholder="User Name" value={values.username} 
              onChange={event => setInputState('username', event.target.value)} />
            <FormErrorBlock error={errors.username} />
          </Col>
          <Col md={6} xs={12}>
            <FormControl type="email" placeholder="Email" value={values.email}
              onChange={event => setInputState('email', event.target.value)} />
            <FormErrorBlock error={errors.email} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={forgotPassword}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}