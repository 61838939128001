import React from "react"
import DatePicker from "react-datepicker"
import Select from 'react-select'
import dayjs from "dayjs"

import { Row, Col, Button, FormGroup} from "react-bootstrap";
import FormErrorBlock from "../../../components/FormErrorBlock";

export default function RequestDetails(props) {

  const { values, errors, setInputState } = props;

  return (
    <React.Fragment>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Select classNamePrefix="report_select" 
              options={props.reportList} 
              value={values.report} 
              onChange={selected => setInputState('report', selected)} />
            <FormErrorBlock error={errors.report} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup className="datepickerCon">
            <div className="datepicker-display">
              <span className="label">From</span>
              <span className="dateCon">
                {values.startDate && dayjs(values.startDate).format("DD MMM, YYYY")}
              </span>
            </div>
            <DatePicker
              calendarClassName="custom-datepicker"
              selected={Date.parse(values.startDate)}
              onChange={date => setInputState('startDate', date)}
            />
            <FormErrorBlock error={errors.startDate} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup className="datepickerCon">
            <div className="datepicker-display">
              <span className="label">To</span>
              <span className="dateCon">
                {values.endDate && dayjs(values.endDate).format("DD MMM, YYYY")}
              </span>
            </div>
            <DatePicker
              calendarClassName="custom-datepicker"
              selected={Date.parse(values.endDate)}
              onChange={date => setInputState('endDate', date)}
            />
            <FormErrorBlock error={errors.endDate} />
          </FormGroup>
        </Col>

      </Row>
      <div className="clearfix btnCon">
        <Button disabled={props.disabled} className="btn btn-primary pull-right flip btn-submit" onClick={props.onRetrieve}>Submit</Button>
      </div>
    </React.Fragment>
  )
}