import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const titles = {
  '/extra': ' | Extras',
  '/seats': ' | Seat Selection',
  '/checkout': ' | Check Out',
  '/confirmation': ' | Confirmation',
  '/mmb/summary': ' | Manage My Booking',
};


const PageTitle = ({ title }: { title: string }) => {
  const location = useLocation();

  useEffect(() => {
    let key: keyof typeof titles;
    for(key in titles) {
      if (location.pathname.endsWith(key)) {
        document.title = title + titles[key];
        return;
      }
    }
    document.title = title;
  }, [location, title]);

  return null;
};

export default PageTitle;