import dayjs from 'dayjs';

export const numberFormatter = {
  formatCurrency,
  formatFlightTime,
  decimalSplitter,
  oneDecimalPoint,
  getMaxDecimalFromPrices
}

export const stringFormatter = {
  removeSpace,
  formatHoldTime,
  formatString,
  replaceTextLinks,
}

function getMaxDecimalFromPrices(prices) {
  const decimals = Array.isArray(prices) && prices.length > 0
    ? prices.map(p => {
      const amount = p + '';
      const index = amount.indexOf('.');
      return index > 0 ? amount.length - index - 1 : 0;
    })
    : [];

  const maxDecimal = decimals.length > 0
    ? Math.max(...decimals)
    : 0;

  return maxDecimal
}


function formatCurrency(number) {
  if(!isNaN(number))
    return number.toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2});
  
  return number;
}

function formatFlightTime(minutes, localeResource) {

  if(!localeResource) return minutes;

  if(!minutes) return `0${localeResource.Hour}`;

  const sign = minutes < 0 ? "-" : "";
  minutes = Math.abs(minutes);

  let time = Math.ceil(minutes);
  const min = time % 60;
  time = (time - min) / 60;
  const hr = time % 24;
  const day = (time - hr) / 24;

  let flightTime = "";
  if(day > 0)
    flightTime += day + `${localeResource.Day} `;

  if(hr > 0)
    flightTime += hr + `${localeResource.Hour} `;
  else if(flightTime)
    flightTime += `0${localeResource.Hour} `

  if(min > 0)
    flightTime += min + `${localeResource.Minute}`;
  else if(!flightTime)
    flightTime = `0${localeResource.Hour}`;

  return sign + flightTime;
}

function decimalSplitter( value ) {
  if(!value) value = "0";

  const fareAmount = value + "";
  const Points = fareAmount.split(".")

  return {
    amount: Points[0] ? Points[0] : "0",
    decimal: Points[1] > 0 ? Points[1] : "00"
  }

}

function oneDecimalPoint( value , currency ){

  // decimals will be hidden for these currencies:
  // AED, SAR, PKR, QAR, BDT, EGP, NPR, INR, LKR, KZT, IRR, MYR
  const noDecimalCurrencies = ['AED', 'SAR', 'PKR', 'QAR', 'BDT', 'EGP', 'NPR', 'IDR', 'INR', 'LKR', 'KZT', 'IRR', 'MYR'];

  if(noDecimalCurrencies.includes(currency, 0)) return ''

  if(!value) value = "0";

  if(currency === "OMR") {
    if(value.length > 1) 
      value = value.slice(0, 2);
    else 
      value = value[0] + "0";
  }
  else {
    value = value[0];
  }

  return '.' + value;
}

function removeSpace(value) {
  if(typeof(value) !== 'string') return value;
  return value.replace(/\s/g, '');
}

function formatHoldTime(booking, message) {
  let result = null;
  if(typeof(message) !== 'string') return result;

  if(!booking || booking.modified) return result;

  if (booking.bookingStatus === 'Pending') {
    const bookingDate = dayjs(booking.bookingDate);
    let holdTime = dayjs(booking.holdTime);
    const depature = Array.isArray(booking.segments) && booking.segments.length > 0 ? booking.segments[0] : null;
    if (holdTime.diff(bookingDate, 'hour') > 0.5 && depature) {
      holdTime = holdTime.local();
      result = formatString(message, holdTime.format("HHmm"), holdTime.format("DD/MM/YYYY"), depature.originCity);
    }
  }
  return result;
}

function formatString(s, ...params) {
  if(typeof(s) !== 'string') return s;

  for (let k=0; k < params.length; k++) {
    s = s.replace("{" + k + "}", params[k])
  }
  return s;
}

function replaceTextLinks(text) {

  return (text || "").replace(
    /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
    function(match, space, url){
      let hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      return space + '<a href="' + hyperlink + '" target="_blank">' + url + '</a>';
    }
  )
}