import { actions as bookingActions } from "../booking/actions";
import { mmbService } from "./mmbServices";

export default {
  retrieveBooking,
  confirmChanges,
  resetBooking,
  cancelFlight,
  checkCancelFlight,
  resendItinerary,
  updateContact,
  processDisruption,
};

function retrieveBooking(pnr, lastName) {
  return dispatch => {
    dispatch(bookingActions.ancillaryResponse([]))
    return mmbService.retrieveBooking(pnr, lastName);
  };
}

function confirmChanges() {
  return dispatch => mmbService.confirmChanges().then(response => {
    if(response && response.success) {
      dispatch(bookingActions.bookingResponse(response.booking));
      dispatch(bookingActions.summaryResponse(response.summary));
    }
    return response;
  });
}

function resetBooking() {
  return dispatch => mmbService.resetBooking().then(response => {
    dispatch(bookingActions.ancillaryResponse([]));
    if(response && response.success) {
      dispatch(bookingActions.bookingResponse(response.booking));
      dispatch(bookingActions.summaryResponse(response.summary));
    }
    return response;
  });
}

function cancelFlight(flights) {
  return dispatch => mmbService.cancelFlight(flights).then(response => {
    if(response && response.success) {
      dispatch(bookingActions.bookingResponse(response.booking));
      dispatch(bookingActions.summaryResponse(response.summary));
    }
    return response;
  });
}

function checkCancelFlight() {
  return () => mmbService.checkCancelFlight();
}

function resendItinerary(email) {
  return () => mmbService.resendItinerary(email);
}

function updateContact(data) {
  return () => mmbService.updateContact(data);
}

function processDisruption(action) {
  return () => mmbService.processDisruption(action);
}