import React from "react";
import Select from "react-select";

export default function CountrySelect(props) {

  const handleChange = selectedOption => {
    if (typeof (props.onSelected) === 'function')
      props.onSelected(selectedOption);
  }

  const handleMenuOpen = () => {
    if (typeof (props.onMenuOpen) === 'function') {
      props.onMenuOpen();
    }
  }

  const options = props.options || [];
  const selected = props.selected || { value: "" };
  const className = props.className;

  let countries = props.readOnly ? [] : options.map(opt => (
    {
      value: opt.countryCode,
      label: opt.countryName
    }
  ));

  if (props.defaultCountryOption)
    countries = [props.defaultCountryOption, ...countries]

  return (
    <Select isDisabled={props.disabled}
      className={className}
      placeholder={props.placeholder || "Country"}
      value={selected}
      options={countries}
      onChange={handleChange}
      onMenuOpen={handleMenuOpen} />
  )
}
