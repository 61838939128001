import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { history } from '../../../helpers'

import BasePage from "../../../components/BasePage"
import { VoucherDetails } from './VoucherDetails'
import { VoucherBanner } from '../PurchasePage/VoucherBanner'
import { Actions } from './Actions'

// actions
import { voucherActions } from "../../../state/ducks/voucher"

import './index.scss'

function ConfirmationPage({ getVoucher }) {

  const [voucher, setVoucher] = useState()

  const jumpTo = next => {
    history.push(`/en/${next}`)
  }

  useEffect(() => {
    getVoucher().then(response => {
      setVoucher(response.voucher)
    })
  }, [getVoucher, setVoucher])

  return (
    <BasePage>
      <div className="min-height purchase-con">
        <VoucherBanner />
        <VoucherDetails voucher={voucher} />
        <Actions onBookFlight={() => jumpTo('search') } onBuyVoucher={() => jumpTo('voucher') } />
      </div>
    </BasePage>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  ...voucherActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage)