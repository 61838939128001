import React, { useState } from "react";
import dayjs from "dayjs";
import { ListGroup, ListGroupItem, Row, Col } from "react-bootstrap";
import Icon from "react-icons-kit/Icon";
import { aircraft } from 'react-icons-kit/entypo/aircraft';
import { checkSquare } from 'react-icons-kit/feather/checkSquare';
import { ic_date_range } from 'react-icons-kit/md/ic_date_range'

const MAX_FLIGHT_IN_LIST = 5;

export default function FlightList({ title, message, bookings, onCheckIn, onManage }) {

  const [isShowAll, setIsShowAll] = useState(false);

  const checkinBooking = (pnr, lastName) => {
    if (typeof (onCheckIn) === 'function') onCheckIn(pnr, lastName);
  }

  const manageBooking = (pnr, lastName) => {
    if (typeof (onManage) === 'function') onManage(pnr, lastName);
  }

  let showMore = null;

  if (Array.isArray(bookings)) {
    bookings = bookings.filter(b => b.pnr);
    if (bookings.length > MAX_FLIGHT_IN_LIST) {
      if (!isShowAll) {
        const more = bookings.length - MAX_FLIGHT_IN_LIST;
        bookings = bookings.slice(0, MAX_FLIGHT_IN_LIST);
        showMore = `Show ${more} more`;
      }
      else {
        showMore = 'Show less';
      }
    }
  }

  return (
    <div className="flight-list">
      <h1>{title}</h1>
      <hr />
      <ListGroup>
        {
          !bookings
            ? <p>Loading...</p>
            : !bookings.length
              ? <p>{message}</p>
              : bookings.map(booking => {
                if (!booking.pnr) return null;

                const segment = Array.isArray(booking.segments) && booking.segments.length > 0 ? booking.segments[0] : null;
                if (!segment) return null;

                const date = dayjs(segment.departureDate);
                const canChange = booking.segments.find(x => x.canChange);
                const canCheckIn = booking.segments.find(x => x.canCheckIn)

                return (
                  <ListGroupItem key={booking.pnr}>
                    <Row>
                      <Col md={7}>
                        <h1>
                          <span>{booking.pnr}</span>
                          <span><Icon className="icon" icon={aircraft} /></span>
                          <span>{segment.origin} to {segment.destination}</span>
                        </h1>
                        <p>{date.format('ddd MMMM DD YYYY')}, <b>{date.format('hh:mm A')}</b></p>
                      </Col>
                      <Col md={5} className="icon-container">
                        <span className="clickable" onClick={() => manageBooking(booking.pnr, booking.lastName)}>
                          <Icon className="icon" icon={ic_date_range} />
                          {canChange ? 'Manage' : 'View'}
                        </span>
                        {booking.isCheckInAvailable && canCheckIn && <span className="clickable"
                          onClick={() => checkinBooking(booking.pnr, booking.lastName)}>
                          <Icon className="icon" icon={checkSquare} />
                          Check In
                        </span>}
                      </Col>
                    </Row>
                  </ListGroupItem>)
              })}

        <p className="text-right footer-link"><button className="link-button" onClick={() => setIsShowAll(s => !s)}>{showMore}</button></p>
      </ListGroup>
    </div>
  )
}
