import { stringFormatter } from "../../../helpers";
import { ls } from "../../../locale";


const creditCardTypes = [
  {
    name: 'visa',
    pattern: /^4[0-9]{12}(?:[0-9]{3})?$/
  },
  {
    name: 'mastercard',
    pattern: /^5[0-9]{15}$/
  },
  {
    name: 'amex',
    pattern: /^3[47][0-9]{13}$/
  }
];

export const validator = {
  getCreditCardType,
  validateCreditCardName,
  validateCreditCardExpiration,
  validateCreditCardCvc,
  validateVoucherCode,
  validateVoucherPin,
  validateRefNumber,
  invalidField,
  requiredField,
}

function getCreditCardType(number) {
  if(!number) return null;

  for(let i = 0; i < creditCardTypes.length; i++) {
    if(creditCardTypes[i].pattern.test(number)) {
      return creditCardTypes[i].name;
    }
  }

  return null;
}

function validateCreditCardName(name) {
  
  if(!name) return requiredField('NameOnCard'); // "Name is required";

  const regex = /^[A-Za-z ]{1,32}$/;
  return regex.test(name) ? null : ls.t('Error.NameOnCardInEnglish'); // invalidField('NameOnCard');
}

function validateCreditCardExpiration(date) {
  const regex = /^\d{2}\/\d{2}$/;
  if(!regex.test(date)) 
    return invalidField('ExpirationDate');
  
  const fields = date.split("/");
  const month = parseInt(fields[0], 10);
  const year = parseInt("20" + fields[1], 10);

  if(month < 1 || month > 12) return invalidField('ExpirationDate');

  const expMonth = year * 100 + month;
  const today = new Date();
  const theMonth = today.getFullYear() * 100 + today.getMonth() + 1;
  if(expMonth < theMonth) return "Expired";
  return null; 
}

function validateCreditCardCvc(code) {
  if(!code) return requiredField('SecurityCode');

  const regex = /^\d{3}$/;
  return regex.test(code) ? null : invalidField('SecurityCode');
}


function validateVoucherCode(code) {
  if(!code) return requiredField('VoucherCode');

  const regex = /[A-Za-z0-9]{6}/;
  if(!regex.test(code)) return invalidField('VoucherCode');
  
  return null;
}

function validateVoucherPin(pin) {
  if(!pin) return requiredField('VoucherPin');

  const regex = /[A-Za-z0-9]{6}/;
  if(!regex.test(pin)) return invalidField('VoucherPin');
  
  return null;
}

function validateRefNumber(ref) {
  if(!ref) {
    const result = requiredField('RefNumber');
    return result
  } 

  const regex = /[A-Za-z0-9-]{3,}/;
  if(!regex.test(ref)) return invalidField('RefNumber');
}

function invalidField(field) {
  const rError = ls.t('Error');
  const rInput = ls.t('Input');

  return stringFormatter.formatString(rError.InvalidField, rInput[field]);
}

function requiredField(field) {
  const rError = ls.t('Error');
  const rInput = ls.t('Input');

  return stringFormatter.formatString(rError.RequiredField, rInput[field]);
}

