import React from 'react';

export function Connector() {
  return (
    <div className="tw-flex tw-items-center">
      <i className="picon picon-plane" style={{width: "16px", height: "16px"}} />
      <div className="tw-grow" style={{height: "2px", borderTop: "1px solid #e0e0e0"}}></div>
      <div style={{color: "#e0e0e0"}}>•</div>
    </div>
  )
}