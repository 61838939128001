import React from "react";
import "./index.scss";
import { ls } from "../../locale";

import { reactRouterToArray } from "../../helpers"
import { routes } from "../App/routes"

export default function NotFoundPage(props) {

  const paths = reactRouterToArray(routes);

  // in case the Arabic version is not available, we switch to English version
  if (Array.isArray(paths) && paths.length) {
    let path = props.location.pathname;
    if (path.indexOf('/') === 0 && path.lastIndexOf('/') > 0) {
      const fields = path.split('/');
      if (fields[1].length === 2 && fields[1] !== 'en') {
        fields[1] = 'en';
        path = fields.join('/');
        if (paths.find(x => x === path)) {
          window.location.href = path;
          return <div>Loading...</div>;
        }
      }
    }
  }

  const rNotFoundPage = ls.t('NotFoundPage');
  const rCommon = ls.t('Common');

  return (
    <div className="notfound-message-container">
      <h1>{rNotFoundPage.Title}</h1>
      <p>{rNotFoundPage.Desc}</p>
      <hr />
      <p>
        <a className="btn btn-default" href="/">
          {rCommon.HomePage}
        </a>
      </p>
    </div>
  );
}
