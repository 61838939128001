// this is a component that is used to display the menu of the member page

import React from 'react'
import { ls } from "../../../locale";

import './index.scss'

export function ProfileMenu({profile, onLogout, onLink}) {

    const rCommon = ls.t('Common');

    return (
        <div className="profile-menu">
            <div className="profile-info">
                <div className="greeting">Hi {profile.displayName}</div>
                <div className="username">{profile.userName}</div>
            </div>
            {/* <div className="rewards">
                <span className="points">2</span>
                <i className="profile-icon icon-salamair"></i>
                <span>collected stamps</span>
            </div>
            <div className="rewards">
                <span className="points">0</span>
                <i className="profile-icon icon-plane"></i>
                <span>reward flights</span>
            </div> */}
            <div className="links">
                {/* <div>More rewards dashboard</div>
                <div>Your rewards activities</div> */}
                <div onClick={() => onLink('portal')}>My Trips</div>
                <div onClick={() => onLink('profile')}>My Profile</div>
            </div>
            <div className="actions">
                <div onClick={onLogout}>{rCommon.Logout}</div>
            </div>
        </div>
    )
}