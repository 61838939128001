import React, { useState, useEffect } from 'react';

import { getVersion } from './checkService';
import { isInAppBrowser } from '../../../helpers';

import './index.scss';

export function VersionChecker() {

  const [show, setShow] = useState(false);
  const [version, setVersion] = useState(null);

  useEffect(() => {
    const inMobile = isInAppBrowser();
    if(inMobile) return;

    setTimeout(() => {
      getVersion().then(v => {
        if(v) {
          setVersion(v);
          setShow(true);
        }
      }).catch(err => {
        console.error('Failed to check version. Error: ' + err);
      });
    }, 15000); // 15 seconds

  }, []);

  if(!show) return null;

  return (  
    <div className='version-checker'>
      <div className='message'>A new version {version} is available. Please refresh the page to get the latest version. </div>
      <div className='actions'>
        <button onClick={() => refreshPage()}>Refresh</button>
        <button onClick={() => setShow(false)}>Close</button>
      </div>
    </div>
  )
}

function refreshPage() {
  window.location.reload();
}
