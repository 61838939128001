import React, { useState } from 'react'
import { Modal, Button, FormControl, Radio, FormGroup } from 'react-bootstrap'

import { customerValidator } from '../../../helpers';
import FormErrorBlock from '../../../components/FormErrorBlock';

import './emailbox.scss'

const DEFAULT_EMAIL_SELECTOR = "default"
const CUSTOM_EMAIL_SELECTOR = "custom"

export function EmailBox(props) {
  const { show, defaultEmail, onHide } = props

  const [emailSelector, setEmailSelector] = useState(DEFAULT_EMAIL_SELECTOR)
  const [emailValue, setEmailValue] = useState('')
  const [emailError, setEmailError] = useState('')

  const hide = selected => {
    if(!selected) {
      onHide()
      return
    }

    let email = defaultEmail
    if(emailSelector === CUSTOM_EMAIL_SELECTOR) {
      email = emailValue

      const invalid = !email || email.trim() === ''
        ? customerValidator.requiredField('Email')
        : customerValidator.validateEmail(email)

      if(invalid) {
        setEmailError(invalid)  
        return
      }
      // clear the cache
      setEmailSelector(DEFAULT_EMAIL_SELECTOR)
      setEmailValue('')
    }
    onHide(email)    
  }


  const onEmailChange = email => {
    setEmailSelector(CUSTOM_EMAIL_SELECTOR)
    setEmailValue(email)
    setEmailError('')
  }


  return (
    <Modal show={show} onHide={() => hide(false)} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Re-send Itinerary</Modal.Title>
      </Modal.Header>
      <Modal.Body className='email-box'>
        <div>
          <div className="radio styled-radio">
            <Radio name="emailSelector" value="default" checked={emailSelector==='default'} onChange={() => setEmailSelector('default')}>
            <span className="checkmark" />
            {defaultEmail}
            </Radio>
          </div>
        </div>
        <div>
          <div className="radio styled-radio email-input">
            <Radio name="emailSelector" value="custom" checked={emailSelector==='custom'} onChange={() => setEmailSelector('custom')}>
              <span className="checkmark" />
              <FormGroup>
                <FormControl type="email" value={emailValue} onChange={e =>  onEmailChange(e.target.value)} placeholder="Email" />
                <FormErrorBlock error={emailError} />
              </FormGroup>
            </Radio>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => hide(true)}>Send</Button>
      </Modal.Footer>
    </Modal>
  )
}