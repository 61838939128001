import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Button } from "react-bootstrap";
import Select from 'react-select';

import { withControlledComponent, customerValidator } from '../../../../helpers';

import { agentValidator } from '../agenctValidator';
import AgentDetails from './ModifyAgentForm';
import ChangePasswordBox from '../../shared/ChangePasswordBox';

const AgentForm = withControlledComponent(AgentDetails);
const ChangePasswordForm = withControlledComponent(ChangePasswordBox);

export default function ModifyAgent(props) {

  const agentForm = useRef(null);
  const [currentAgent, setCurrentAgent] = useState(props.currentAgent);
  const [showChangePassword, setShowChangePassword] = useState(false);

  useEffect(() => {
    if (Array.isArray(props.agents) && props.agents.length > 0) {
      if (props.currentAgent && props.agents.find(x => x.userName === props.currentAgent.userName))
        setCurrentAgent(props.currentAgent);
      else
        setCurrentAgent(props.agents[0]);
    }
    else {
      setCurrentAgent(null);
    }
  }, [props.agents, props.currentAgent])


  function modifyAgent() {

    if (!props.agency || !currentAgent) return;

    const isFormValid = agentForm.current.validateForm();
    if (!isFormValid) return;

    const details = agentForm.current.getValues();

    if (typeof (props.onModifyAgent) === 'function') {
      props.onModifyAgent(details);
    }
  }

  // function onChangePassword() {
  //   if(!props.agency || !currentAgent) return;

  //   setShowChangePassword(true);
  // }

  function changePassword(values) {
    setShowChangePassword(false);

    const data = {
      ...values,
      userName: currentAgent.userName,
    }

    if(typeof props.onChangePassword === 'function') {
      props.onChangePassword(data);
    }
  }

  function validateAgentDetails(data) {
    const errors = {};
    errors.firstName = customerValidator.validateName(data.firstName);
    errors.lastName = customerValidator.validateName(data.lastName);
    errors.address = agentValidator.validateAddress(data.address);
    errors.city = agentValidator.validateCity(data.city);
    errors.postalCode = agentValidator.validatePostalCode(data.postalCode);
    errors.email = customerValidator.validateEmail(data.email);
    errors.phone = customerValidator.validatePhone(data.phone, true);

    return errors;
  }

  function selectAgent(userName) {
    if (!Array.isArray(props.agents)) return;
    const selected = props.agents.find(x => x.userName === userName);
    if(typeof props.onSelectAgent === 'function') props.onSelectAgent(selected);
  }

  function mapAgentToSelectOption(agent) {
    if (!agent) return null;
    return { value: agent.userName, label: `${agent.userName} - ${agent.firstName} ${agent.lastName}` }
  }

  const { agency } = props;
  const selected = mapAgentToSelectOption(currentAgent);

  const options = props.agents.map(x => mapAgentToSelectOption(x));

  return (
    <>
      <h1>Modify Agent</h1>
      <hr />
      {agency && <p className="agency-name">Agency: {agency.iataNumber} - {agency.agencyName}</p>}
      <Row>
        <Col md={6}>
          <Select placeholder="Select Agent ..." options={options} value={selected} onChange={selected => selectAgent(selected.value)} />
        </Col>
      </Row>

      <AgentForm
        key={(currentAgent && currentAgent.userName) + 'modify-form'}
        ref={agentForm}
        countries={props.countries}
        initValues={currentAgent || {}}
        onValidate={validateAgentDetails} />

      <ChangePasswordForm
        key={(currentAgent && currentAgent.userName) + 'change-password'}
        show={showChangePassword}
        onChangePassword={changePassword}
        onHide={() => setShowChangePassword(false)}
        initValues={{newPassword: '', confirmPassword: ''}}
      />

      <div className="clearfix btnCon flex col-xs-12">
        <Button className="btn btn-primary btn-submit" onClick={props.onCreateAgent}>Create Agent</Button>
        {/* {currentAgent && <Button className="btn btn-primary btn-submit" onClick={onChangePassword}>Change Password</Button>} */}
        {currentAgent && <Button className="btn btn-primary btn-submit" onClick={modifyAgent}>Modify Agent</Button>}
      </div>
    </>
  )
}
