import React from 'react'
import './index.scss'
import ArrowLeft from '../../../images/arrow-left.png'
import ArrowRight from '../../../images/arrow-right.png'

export function MetroGroupTips({message, target, className}) {

  const image = className === 'ar' ? ArrowRight :  ArrowLeft

  return (
    <div className={"metrogroup-message " + className + " " + target}>
      <img src={image} alt="" />
      <span>{message}</span>
    </div>
  )
}