import { logEvent } from "../../../helpers";

const baseUrl = process.env.REACT_APP_API_HOST

export function getVersion() {
  return fetch(`${baseUrl}ibe`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    },
  })
  .then(response => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response.statusText);
  })
  .then(data => {
    if(!data?.version) return null;

    if (data.version <= process.env.REACT_APP_VERSION) return null;

    logEvent({
      trackId: 'notracking',
      eventName: 'client version',
      details: {
        message: `Client code is outdated. Client version: ${process.env.REACT_APP_VERSION}, Latest version: ${data.version}`
      }
    })

    // get the saved version from local storage
    const savedVersion = localStorage.getItem('ibe_version');
    if (savedVersion && savedVersion === data.version) {
      return null;
    }
    // save the new version to local storage
    localStorage.setItem('ibe_version', data.version);
    return data.version;
  });
}
