import React, { useState } from "react";
import { Modal, Row, Col, Checkbox, Button } from "react-bootstrap";

import "./index.scss";
import { ls } from "../../../../locale";

export default function SelectFlightWindow({ isShow, segments, title, message, notes, autoSelectAllFlight, onClose }) {

  const [selectedFlights, setSelectedFlights] = useState([]);

  const onConfirm = () => {
    if (autoSelectAllFlight) {
      onClose([...segments])
    }
    else if (selectedFlights.length > 0) {
      onClose(selectedFlights);
    }
  }

  const toggleFlight = segment => {
    if (!segment || !segment.logicalFlightId) return;

    if (selectedFlights.find(x => x.logicalFlightId === segment.logicalFlightId)) {
      setSelectedFlights(selectedFlights.filter(x => x.logicalFlightId !== segment.logicalFlightId))
    }
    else {
      setSelectedFlights([...selectedFlights, segment])
    }
  }

  const rButton = ls.t('Button');

  return (
    <Modal className={ls.t("rootClass")} show={isShow} onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>

        {Array.isArray(segments) && segments.map(segment => {
          if (!segment || !segment.permissions || !(segment.permissions.canChangeFlight || segment.permissions.canCancelFlight)) return null;

          const checked = autoSelectAllFlight || selectedFlights.find(x => x.logicalFlightId === segment.logicalFlightId) || false;
          return (
            <Row key={segment.logicalFlightId} className="flight-section">
              <Col md={12}>
                <Checkbox checked={checked} onClick={() => toggleFlight(segment)} onChange={() => { }} disabled={autoSelectAllFlight}>
                  <label></label>
                </Checkbox>
                <div className="flight-details">
                  <span>{segment.originCity} - {segment.destinationCity}</span>
                </div>
              </Col>
            </Row>)
        })}
      </Modal.Body>
      <Modal.Footer>
        <div className="flight-select-footer">
          <div>{notes}</div>
          <Button onClick={onConfirm}>{rButton.Select}</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )

}
