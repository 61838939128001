import React from 'react'
import { ls } from "../../../locale";

import { Button } from 'react-bootstrap'

export function Actions({onBookFlight, onBuyVoucher}) {

  const rTravelVoucher = ls.t('TravelVoucher')

  return (
    <>
      <div className="btnCon form-actions padt40">
        <Button className="btn-cancel" onClick={onBuyVoucher}>{rTravelVoucher.BuyAnotherVoucher}</Button>
        <Button className="btn-submit" onClick={onBookFlight}>{rTravelVoucher.BookingAFlight}</Button>
      </div>
    </>
  )
}