import React, { useRef, useState } from 'react';
import {Row, Col, Button } from "react-bootstrap";
import Select from 'react-select';

import { withControlledComponent, customerValidator } from '../../../../helpers';

import { agentValidator } from '../agenctValidator';
import AgencyDetails from './ModifyAgencyForm';
const AgencyForm = withControlledComponent(AgencyDetails);


export default function ModifyAgency(props) {

  const agencyForm = useRef(null);
  const [agencyChanges, setAgencyChanges] = useState({});
  const [fundChanges, setFundChanges] = useState({});

  function onStateChanged(changes) {
    if(changes.key === 'fundTransferType' || changes.key === 'fundTransferAmount') {
      setFundChanges({
        ...fundChanges,
        [changes.key]: changes.value
      })
    }
    else {
      setAgencyChanges({
        ...agencyChanges,
        [changes.key]: changes.value
      })
    }
  }

  function modifyAgency() {

    if (!props.agency) return;

    const isFormValid = agencyForm.current.validateForm();
    if (!isFormValid) return;

    let agencyDetails = null;
    const changed = Object.values(agencyChanges).find(x => x || x === false || x === null)
    if(changed || changed === false || changed === null) {
      agencyDetails = { ...props.agency, ...agencyChanges };
    }

    let fundDetails = null;
    if(Object.values(fundChanges).find(x => x)) {
      fundDetails = { ...fundChanges, iataNumber: agency.iataNumber };
      if(!fundDetails.fundTransferAmount) fundDetails.fundTransferAmount = "0";
      fundDetails.fundTransferAmount = Number(fundDetails.fundTransferAmount);
    }

    if (typeof (props.onModifyAgency) === 'function') {
      props.onModifyAgency(agencyDetails, fundDetails);
    }
  }

  function validateAgencyDetails(data) {
    const errors = {};

    if(!props.agency) return errors;

    //errors.iataNumber = agentValidator.validateIataNumber(data.iataNumber);
    //errors.userName = agentValidator.validateUserName(data.userName);
    errors.agencyName = agentValidator.validateAgencyName(data.agencyName);
    errors.address = agentValidator.validateAddress(data.address);
    errors.city = agentValidator.validateCity(data.city);
    errors.postalCode = agentValidator.validatePostalCode(data.postalCode);
    errors.contactName = agentValidator.validateContactName(data.contactName);
    errors.email = customerValidator.validateEmail(data.email);
    errors.phone = customerValidator.validatePhone(data.phone, true);

    const fundAmount = Number(data.fundTransferAmount || '0');
    if(fundAmount > 0) {
      if(data.fundTransferType === 'increase') {
        // check if the parent agency has sufficient credit
        if(props.parentAgency && props.parentAgency.availableCredit < data.fundTransferAmount) {
          errors.fundTransferAmount = 'No sufficient credit from parent';
        }
      }
      else if(data.fundTransferType === 'decrease') {
        // check if the child agency has sufficient credit
        if(agency.availableBalance < fundAmount) {
          errors.fundTransferAmount = 'No sufficient credit from child';
        }
      }
    }

    return errors;
  }

  const agency = props.agency;
  const selected = agency && agency.iataNumber && Array.isArray(props.agencies) ? props.agencies.find(x => x.value === agency.iataNumber) : null;

  let canTransferFund = props.parentAgency && !props.parentAgency.isChildAgency;
  if(agency && props.parentAgency && agency.iataNumber === props.parentAgency.iataNumber) canTransferFund = false;

  return (
    <>
      <h1>Modify Agency</h1>
      <hr />
      <Row>
        <Col md={6}>
          <Select placeholder="Select Agency ..." 
            options={props.agencies} 
            value={selected} 
            onChange={selected => props.onSelectAgency(selected.value)} />
        </Col>
        <Col md={6} className="text-right">
          {agency && <span>Credit Available: <strong>{`${agency.currencyCode} ${agency.availableBalance}`}</strong></span>}
        </Col>
      </Row>

      <AgencyForm
        key={agency ? agency.iataNumber : 'modify-form'}
        ref={agencyForm}
        countries={props.countries}
        initValues={agency || {}}
        onValidate={validateAgencyDetails}
        onStateChanged={onStateChanged}
        canTransferFund={canTransferFund} />

      <div className="clearfix btnCon flex col-xs-12">
        {props.parentAgency && !props.parentAgency.isChildAgency 
          && <Button className="btn btn-primary btn-submit" onClick={props.onCreateAgency}>Create Agency</Button>}
        {agency && <Button className="btn btn-primary btn-submit" onClick={modifyAgency}>Modify Agency</Button>}
        {agency && <Button className="btn btn-primary btn-submit" onClick={props.onManageUser}>Manage User</Button>}
      </div>
    </>
  )
}
