import React from "react";
import Select from "react-select";

export default function DialingCodeSelect({ options = [], selected, className, readOnly, onSelected }) {

  const handleChange = selectedOption => {
    if (typeof (onSelected) === 'function')
      onSelected(selectedOption);
  }

  const selectedValue = selected || { value: "968" };


  // be aware of the trick:
  // in order to show the dialing code in the input box,
  // the value is the country name while the label is the dialing code
  const countries = readOnly ? [] : options.map(opt => (
    {
      value: opt.countryName,
      label: opt.dialingCode,
    }
  ));

  return (
    <Select
      className={className}
      placeholder="+"
      value={selectedValue}
      options={countries}
      components={{ Option: CustomOption }}
      onChange={handleChange} />
  )
}

const CustomOption = ({ innerProps, data }) => {
  return (
    <div {...innerProps} className="option-con clickable">
      <span>{data.value}</span>&nbsp;<span>({data.label})</span>
    </div>
  )
}

