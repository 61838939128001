// modify search and change currency toolbar

import React from 'react'

import { isInAppBrowser } from "../../../helpers";
import { ls } from "../../../locale";

import { CurrencySelect  } from "./CurrencySelect";
import { UserType } from "../../../constants/settings";

export const Toolbar = ({selectedCurrency, onModifySearch, onSelectCurrency, userData, bookingMode, currencies, isShowCurrencySelect}) => {
  const rFlightPage = ls.t('FlightPage');
  
  let show = false
  if (bookingMode.mode === 'Booking') {
    if(!userData || !userData.loggedIn || (userData.userType !== UserType.agent && !userData.isCorpCustomer)) {
      show = true
    }
  }

  isShowCurrencySelect = isShowCurrencySelect && show

  const inAppBrowser = isInAppBrowser();

  return (
    <div className="modify-search-toolbar">
      {isShowCurrencySelect 
        ? <CurrencySelect options={currencies} 
          onSelected={onSelectCurrency} 
          selected={selectedCurrency} className="flight-currency-select"/> 
        : <div></div> 
      }
      {!inAppBrowser && <button className="link-button btn-modify" onClick={onModifySearch}>{rFlightPage.Modify}
        <span className="hidden-xs">&nbsp;{rFlightPage.Search}</span>
        <i className="picon picon-modify"></i>
      </button>}
    </div>
  )
}
