import React, { Component } from "react";
import { connect } from "react-redux";
import { ls } from "../../locale";
import { Button, Modal } from "react-bootstrap";
import { getAgentPath, jsonHelper, jumpTo } from "../../helpers";
import dayjs from 'dayjs';

import { isInAppBrowser, stringFormatter } from "../../helpers";

// ducks
import { bookingActions } from "../../state/ducks/booking";
import { sessionActions } from "../../state/ducks/session";

import Baggages from "./Baggages";
import Meals from "./Meals";
import Insurance from "./Insurance";
import Lounge from "./Lounge"
import PriorityCheckin from './PriorityCheckin'

import { numberFormatter } from "../../helpers";
import { PassengerType } from "../../constants/settings";

import "./index.scss";
import BasePageWithStatusBar from "../../components/BasePage/BasePageWithStatusBar";


class ExtraPage extends Component {

  mealSelection = React.createRef()

  constructor(props) {
    super(props);
    this.state = {
      isShowInsuranceConfirmation: false,
      isShowMessageBox: false,
    };
  }

  componentDidMount() {
    this.props.getAncillaries().then(() => {
      this.props.getBookingWithSummary();
    });

  }

  selectBag = (flightId, passengerId, bagSSRCode, prevSsrCode) => {
    this.props.addBaggage(flightId, passengerId, bagSSRCode, prevSsrCode)
      .then(() => {
        this.props.getBookingWithSummary();
      });
  };

  clearBag = (flightId, passengerId, ssrCode) => {
    this.props.removeBaggage(flightId, passengerId, ssrCode)
      .then(() => {
        this.props.getBookingWithSummary();
      });
  };

  selectMeals = selected => {
    this.props.changeMeals(selected)
      .then(() => {
        this.props.getBookingWithSummary();
      });
  };

  getSelectedServices = () => {
    const { booking, ancillaries } = this.props;
    if (!booking || !Array.isArray(booking.segments) || !Array.isArray(booking.passengers)) return {};

    const paxTotal = booking.passengers.filter(x => x.paxType === PassengerType.Adult || x.paxType === PassengerType.Child).length;
    const services = booking.segments.map(segment => {
      let mealTotal = 0, mealSelected = 0;
      // disregard the validation on meal selection in WCI mode
      if(segment.hasFreeMeal && booking.bookingMode !== 'WCI') {

        // find the leg that provide meals
        const anciSegment = ancillaries.find(x => x.logicalFlightId === segment.logicalFlightId)
        const meals = anciSegment.passengers[0].meals
        const physicalLightFromMeals = !Array.isArray(meals) ? [] : meals.map(x => x.physicalFlightId)
        const uniquePhysicalFlightIds = physicalLightFromMeals.filter((v, i, a) => a.indexOf(v) === i)

        // calculate the number of meals to be expected based on the legs that provide meals
        mealTotal = paxTotal * uniquePhysicalFlightIds.length;
      }

      booking.passengers.forEach(pax => {
        if (Array.isArray(pax.meals)) {
          mealSelected += pax.meals.filter(x => x.logicalFlightId === segment.logicalFlightId).length;
        }
      })

      return {
        mealTotal,
        mealSelected,
        allMealSelected: mealSelected >= mealTotal,
        originCity: segment.originCity,
        destinationCity: segment.destinationCity,
      };
    })

    return {
      allMealSelected: services.map(x => x.allMealSelected).reduce((a, b) => a && b, true),
      segments: services,
    };
  }

  continue = () => {

    const selectedServices = this.getSelectedServices();

    if (!selectedServices.allMealSelected) {
      const rError = ls.t('ExtraPage.Error');
      const rCommon = ls.t('Common');

      let flights = [];

      selectedServices.segments.forEach(segment => {
        if (segment.allMealSelected) return;
        flights.push(`${segment.originCity} - ${segment.destinationCity}`);
      })
      flights = flights.join(` ${rCommon.And} `);

      const message = stringFormatter.formatString(rError.FreeMeal, flights);
      this.showMealMessageBox(message);
      return;
    }

    const { booking } = this.props;

    const bookingMode = booking.bookingMode;
    let nextPage = ""
    if (bookingMode === "MMB")
      nextPage = "/mmb/summary";
    else if (bookingMode === "WCI")
      nextPage = "/wci/seats";
    else
      nextPage = "/seats";

    jumpTo(getAgentPath() + nextPage);
  };

  goBack = () => {
    const bookingMode = this.props.booking.bookingMode;

    let nextPage = '';
    if (bookingMode === "MMB")
      nextPage = "/mmb/summary";
    else if (bookingMode === "WCI")
      nextPage = "/wci/passenger";
    else
      nextPage = "/flights";

    jumpTo(getAgentPath() + nextPage);
  };

  getSelection = () => {
    const result = [];

    const booking = this.props.booking;
    if (!booking || !Array.isArray(booking.segments)) return result;

    const ancillaries = this.props.ancillaries;
    if (!ancillaries || !Array.isArray(ancillaries)) return result;

    ancillaries.forEach(seg => {
      const segment = booking.segments.find(s => s && s.logicalFlightId === seg.logicalFlightId);
      if (!segment || !segment.permissions || !segment.permissions.canChangeExtras) return;

      const passengers = []
      seg.passengers.forEach(pax => {
        const passenger = booking.passengers.find(p => p.id === pax.passengerId);
        if(!passenger) return;

        const selectedMeals = Array.isArray(passenger.meals) && passenger.meals.filter(x => x.logicalFlightId === seg.logicalFlightId);
        const selectedBaggages = Array.isArray(passenger.baggages) && passenger.baggages.filter(x => x.logicalFlightId === seg.logicalFlightId);
        const selectedInsurances = Array.isArray(passenger.insurances) && passenger.insurances.filter(x => x.logicalFlightId === seg.logicalFlightId);
        passengers.push({
          ...pax,
          selectedMeals,
          selectedBaggages,
          selectedInsurances,
          id: passenger.id,
          firstName: passenger.firstName,
          lastName: passenger.lastName,
          paxType: passenger.paxType,
          paxTypeName: passenger.paxTypeName,
          paxIndex: passenger.paxIndex,
        });
      });

      result.push({
        trip: segment.direction,
        origin: segment.originCode,
        destination: segment.destinationCode,
        originCity: segment.originCity,
        destinationCity: segment.destinationCity,
        departureDate: segment.departureDate,
        arrivalDate: segment.arrivalDate,
        logicalFlightId: segment.logicalFlightId,
        passengers
      });
    });

    return result;
  };

  getInsurance = () => {
    const result = {};

    const { booking, ancillaries } = this.props;

    if (!booking || !Array.isArray(booking.segments) || !booking.segments.length) return result;
    if (!booking.segments.find(x => x && x.permissions && x.permissions.canChangeExtras)) return result;

    if (!booking || !Array.isArray(booking.passengers)) return result;
    if (!Array.isArray(ancillaries) || !ancillaries.length) return result;

    let service = null;
    ancillaries.forEach(ancil => {
      if (!Array.isArray(ancil.passengers) || !ancil.passengers.length) return;
      ancil.passengers.forEach(pax => {
        if (!Array.isArray(pax.insurances) || !pax.insurances.length) return;

        if (!service)
          service = jsonHelper.cloneDeep(pax.insurances[0]);
        else
          service.amount += pax.insurances[0].amount;
      })
    });

    // const passenger = ancillaries[0].passengers[0];
    // const service = Array.isArray(passenger.insurances) && passenger.insurances.length > 0 ? passenger.insurances[0] : null;

    return {
      service,
      passengerCount: booking.passengers.length,
      currencyCode: booking.currencyCode,
    };
  };

  getServices = key => {
    const result = [];

    const { booking, ancillaries } = this.props;

    if (!booking || !Array.isArray(booking.segments) || !booking.segments.length) return result;
    if (!booking.segments.find(x => x && x.permissions && x.permissions.canChangeExtras)) return result;

    if (!booking || !Array.isArray(booking.passengers)) return result;
    if (!Array.isArray(ancillaries) || !ancillaries.length) return result;

    ancillaries.forEach(ancil => {

      const bookingSegment = booking.segments.find(x => x.logicalFlightId === ancil.logicalFlightId);
      if(!bookingSegment) return;

      if (!Array.isArray(ancil.passengers) || !ancil.passengers.length) return;

      let service = null;
      ancil.passengers.forEach(pax => {
        if (!Array.isArray(pax[key]) || !pax[key].length) return;

        if (!service)
          service = jsonHelper.cloneDeep(pax[key][0]);
        else
          service.amount += pax[key][0].amount;
      })
      
      if(!service) return;

      result.push({
        logicalFlightId: ancil.logicalFlightId,
        route: `${bookingSegment.originCode} - ${bookingSegment.destinationCode}`,
        currencyCode: booking.currencyCode,
        service
      })
    });

    return result;
  };

  addInsurance = () => {
    const booking = this.props.booking;
    if (booking && booking.bookingMode === 'MMB' && Array.isArray(booking.passengers)) {
      let invalidDob = false;
      booking.passengers.forEach(pax => {
        const dob = dayjs(pax.dateOfBirth);
        const diff = dayjs().diff(dob, 'year');
        if (diff > 100) {
          invalidDob = true;
          return;
        }
      });

      if (invalidDob) {
        this.showMessageBox();
        return;
      }
    }

    this.props.addInsurance().then(() => {
      this.props.getBookingWithSummary();
    });
  }

  removeInsurance = () => {

    this.closeInsuranceConfirmation();

    this.props.removeInsurance().then(() => {
      this.props.getBookingWithSummary();
    });
  }


  addLounge = logicalFlightId => {
    //console.log('add lounge to ', logicalFlightId)
    this.props.addLounge(logicalFlightId).then(() => {
      this.props.getBookingWithSummary();
    });
  }

  removeLounge = logicalFlightId => {
    //console.log('remove lounge from ', logicalFlightId)
    this.props.removeLounge(logicalFlightId).then(() => {
      this.props.getBookingWithSummary();
    });
  }

  addPriorityCheckin = logicalFlightId => {
    //console.log('add lounge to ', logicalFlightId)
    this.props.addPriorityCheckin(logicalFlightId).then(() => {
      this.props.getBookingWithSummary();
    });
  }

  removePriorityCheckin = logicalFlightId => {
    //console.log('remove lounge from ', logicalFlightId)
    this.props.removePriorityCheckin(logicalFlightId).then(() => {
      this.props.getBookingWithSummary();
    });
  }


  closeInsuranceConfirmation = () => {
    this.setState({
      isShowInsuranceConfirmation: false,
    });
  };

  showInsuranceConfirmation = () => {
    this.setState({
      isShowInsuranceConfirmation: true,
    });
  };

  closeMessageBox = () => {
    this.setState({
      isShowMessageBox: false
    });
  };

  showMessageBox = () => {
    this.setState({
      isShowMessageBox: true
    });
  };

  showMealMessageBox = message => {
    this.setState({
      isShowMealMessageBox: true,
      mealMessage: message,
    })
  }

  closeMealMessageBox = () => {
    this.setState({
      isShowMealMessageBox: false,
      mealMessage: null
    })
    if(this.mealSelection.current) 
      this.mealSelection.current.showMealsSelection(true)
  }

  render() {

    const rExtraPage = ls.t('ExtraPage');
    if (!rExtraPage.Baggage) return null;

    const selection = this.getSelection();
    const insurance = this.getInsurance();
    const lounges = this.getServices('lounges');
    const priorityCheckin = this.getServices('priorityCheckin');

    const { booking, bookingSummary } = this.props;
    const currencyCode = booking.currencyCode;
    const total = (booking.bookingMode === "Booking" ? bookingSummary.bookingTotal : booking.reservationBalance) || 0;
    const amountSplit = numberFormatter.decimalSplitter(numberFormatter.formatCurrency(total));

    const hideInsurance = false; // userData && userData.userType === UserType.agent;

    const inAppBrowser = isInAppBrowser();

    const rButton = ls.t('Button');
    const rCommon = ls.t('Common');
    const rMessageBox = ls.t('MessageBox');
    const rSalamAir = ls.t('SalamAir');

    const rInsurance = rExtraPage.Insurance;
    const rConfirm = rInsurance.Confirm;
    const insuranceMore = stringFormatter.formatString(rInsurance.More, `<a href="${rInsurance.Link}" target="_blank">${rCommon.More}</a>`);
    const rCovers = rExtraPage.Insurance.Covers.split(/[\r\n]/);

    return (
      <>
        <BasePageWithStatusBar hasSummary statusLabel="extra" onItemClick={item => jumpTo(getAgentPath() + '/' + item)}>
          <div className="min-height extra-page">
            <Baggages selection={selection} onSelectBag={this.selectBag} onClearBag={this.clearBag}
              currencyCode={booking.currencyCode} />
            <Meals ref={this.mealSelection}
              booking={this.props.booking}
              bookingSummary={this.props.bookingSummary}
              selection={selection}
              onSelectMeals={this.selectMeals}
              currencyCode={booking.currencyCode} />
            {!hideInsurance && <Insurance insurance={insurance}
              addInsurance={this.addInsurance}
              removeInsurance={this.showInsuranceConfirmation} />}

            <Lounge lounges={lounges}
              addLounge={this.addLounge}
              removeLounge={this.removeLounge}
            />

            <PriorityCheckin services={priorityCheckin}
              addService={this.addPriorityCheckin}
              removeService={this.removePriorityCheckin}
            />

            <div className="clearfix btnCon extra-page-total">
              {!inAppBrowser && <Button type="submit" className="btn btn-primary pull-left flip btn-cancel btn-effect"
                onClick={() => this.goBack()}>
                <span><i className="picon picon-btn-arrow-left-w" />{rButton.Back}</span>
              </Button>}
              <Button type="submit" className="btn btn-primary pull-right flip btn-submit btn-effect"
                onClick={() => this.continue()}><span>{rButton.Continue}
                  <i className="picon picon-btn-arrow-right-w" /></span>
              </Button>
              <div className="bottom-total pull-right">
                <label>{rCommon.BookingTotal}</label>
                <p>
                  <span className="currency">{currencyCode} </span>
                  {amountSplit.amount}
                  <span className={`${currencyCode} decimal-point`}>
                    {
                      numberFormatter.oneDecimalPoint(amountSplit.decimal, currencyCode)
                    }
                  </span>
                </p>
              </div>
            </div>
          </div>
        </BasePageWithStatusBar>
        <Modal className={ls.t("rootClass")} show={this.state.isShowInsuranceConfirmation}>
          <Modal.Header>
            <Modal.Title>{rMessageBox.Title.Confirmation}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>{rConfirm}</h5>
            <ul>
              {rCovers.map((cover, index) => <li key={index}>{cover}</li>)}
              <li dangerouslySetInnerHTML={{ __html: insuranceMore }}></li>
            </ul>
            {/* <p dangerouslySetInnerHTML={{__html: insuranceMore}} /> */}
            <p>{rInsurance.TNCApply}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.removeInsurance}>{rExtraPage.Insurance.RemoveInsurance}</Button>
            <Button onClick={this.closeInsuranceConfirmation}>{rExtraPage.Insurance.KeepInsurance}</Button>
          </Modal.Footer>
        </Modal>
        <Modal className={ls.t("rootClass")} show={this.state.isShowMessageBox} onHide={this.closeMessageBox} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{rMessageBox.Title.Warning}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{rSalamAir.Greeting}</p>
            <p>{rInsurance.InvalidDOB}</p>
            <p>{rSalamAir.Email}<br />{rSalamAir.Phone}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeMessageBox}>{rButton.Close}</Button>
          </Modal.Footer>
        </Modal>
        <Modal className={ls.t("rootClass")} show={this.state.isShowMealMessageBox} onHide={this.closeMealMessageBox} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{rMessageBox.Title.Warning}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{this.state.mealMessage}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeMealMessageBox}>{rButton.Close}</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    booking: state.booking.details,
    bookingSummary: state.booking.summary,
    ancillaries: state.booking.ancillaries
  };
}

const mapDispatchToProps = {
  ...bookingActions,
  getUserData: sessionActions.getUserData
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraPage);

