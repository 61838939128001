import { alertConstants } from "../constants";

export const alertActions = {
  success,
  error,
  clear
};

function success(message) {
  return { type: alertConstants.SUCCESS, payload: message };
}

function error(errors) {
  return { type: alertConstants.ERROR, payload: errors };
}

function clear() {
  return { type: alertConstants.CLEAR };
}
