import React, { useState, useEffect } from "react";
import { Row, Col, Radio } from "react-bootstrap";
import dayjs from "dayjs";

import Icon from "react-icons-kit/Icon";
import { suitcase } from "react-icons-kit/fa/suitcase";
import { shoppingBag } from "react-icons-kit/fa/shoppingBag";
import { userCircleO } from "react-icons-kit/fa/userCircleO";
import { plus } from "react-icons-kit/fa/plus";


import './index.scss';
import { ls } from "../../../locale";

export default function Baggages(props) {

  const { currencyCode, selection, onSelectBag, onClearBag } = props;

  const [serviceSelection, setServiceSelection] = useState(selection);

  useEffect(() => {

    // console.log("select for baggages:", props.selection);

    setServiceSelection(props.selection)
  }, [props.selection])

  const rExtraPage = ls.t('ExtraPage');
  if (!rExtraPage.Baggage) return null;

  const rCommon = ls.t('Common');
  const rPaxType = ls.t('PaxType');
  const rFlight = ls.t('Flight');
  //const locale = ls.t('rootClass');

  return (
    <div className="baggage-selection row">
      <div className="col-md-12"> <h1>{rExtraPage.Baggage.Label}</h1></div>
      {
        serviceSelection && serviceSelection.map((flight, index) => {
          return (
            <div key={index} className="trip col-md-12">
              <div className="trip-info">
                <div>
                  <Icon icon={suitcase} />
                  {index === 0 ? rExtraPage.Departure : rExtraPage.Return} - &nbsp;
                    <span className="code">{flight.originCity} </span> {rFlight.To} &nbsp;
                    <span className="code">{flight.destinationCity}</span>&nbsp;
                    <span className="date">
                    {dayjs(flight.departureDate).format("dddd DD MMMM YYYY")}
                  </span>
                </div>
              </div>
              <Col mdOffset={4} md={8} smHidden={true} xsHidden={true} className="label-heading">
                <Row>
                  <Col md={3}><p> {rExtraPage.HandBaggage}</p></Col>
                  <Col md={9}><p> {rExtraPage.AddCheckedBaggage} </p></Col>
                </Row>
              </Col>
              {
                flight.passengers.map((passenger, count) => {
                  if (!Array.isArray(passenger.baggages)) return null;

                  const currentSelected = passenger.baggages.find(x => x.selected);
                  const prevSsrCode = currentSelected && currentSelected.ssrCode;

                  const passengerName = passenger.lastName 
                    ? `${passenger.firstName} ${passenger.lastName}` 
                    : `${rPaxType[passenger.paxTypeName].Passenger} ${passenger.paxIndex}`;

                  return (
                    <Col md={12} className="passenger-block" key={count}>
                      <Row>
                        <Col md={4} sm={12} className="passenger" >
                          <div>
                            <Icon icon={userCircleO} />
                            <span>{passengerName}</span>
                          </div>
                        </Col>
                        <Col md={8} sm={12} className="baggage-weights">
                          <Row>
                            <Col md={3} sm={12} className="hand-baggage">
                              <span className="hidden-lg hidden-md">{rExtraPage.Handbaggage}</span>
                              <div><span>{passenger.handBaggages[0].label} <Icon icon={shoppingBag} /></span></div>
                              <span>{rCommon.Included}</span>
                            </Col>
                            {
                              passenger.baggages.map((bag, index) => {
                                if (bag.included) {
                                  return (
                                    <Col md={3} sm={12} key={index} className="hand-baggage" >
                                      <span className="hidden-lg hidden-md">{rExtraPage.CheckedBaggage}</span>
                                      <div><span>{bag.label} <Icon icon={suitcase} /></span></div>
                                      <span>{rCommon.Included}</span>
                                    </Col>
                                  );
                                }
                                else {
                                  let option = (<React.Fragment key={`${bag.ssrCode}${bag.selection}`}>
                                    <div className="baggage-middle">
                                      <span>{bag.label}<Icon icon={suitcase} /></span>
                                    </div>
                                    <Radio name={"selectBag|" + flight.logicalFlightId + "|" + passenger.id} 
                                      checked={bag.selected} onChange={() => { }}>
                                      <label></label>
                                    </Radio>
                                  </React.Fragment>);
                                  if (!bag.selected && bag.quantity === 0) {
                                    option = (<React.Fragment key={`${bag.ssrCode}${bag.selection}`}>
                                      <div>
                                        <span>{bag.label}<Icon icon={suitcase} /></span>
                                      </div>
                                      <span className="unavailable">{rCommon.NotAvailable}</span>
                                    </React.Fragment>);
                                  }

                                  let onClickHandler = function () { };
                                  if (bag.selected) {
                                    if (bag.removable) onClickHandler = onClearBag
                                  }
                                  else if (bag.quantity > 0) {
                                    onClickHandler = onSelectBag;
                                  }

                                  return (
                                    <Col md={3} sm={12} key={index} 
                                      className={"checked-baggage " + (bag.selected ? "active dynamic-block" : "dynamic-block")} 
                                      onClick={() => onClickHandler(flight.logicalFlightId, passenger.id, bag.ssrCode, prevSsrCode)}>
                                      <span className="price"><span className="currency">{currencyCode}</span> {bag.amount}</span>
                                      { bag.included && <Icon icon={plus} className="plus-icon" /> }
                                      {option}
                                    </Col>
                                  )
                                }
                              })
                            }
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  );
                })
              }
            </div>
          )
        })
      }
    </div>
  )
}




