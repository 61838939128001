
export function isTripEmpty(tripData) {
  if(!Array.isArray(tripData) || tripData.length === 0) return true
  return !tripData.some(trip => trip && Array.isArray(trip.markets) && trip.markets.some(market => market.lowestFare > 0))
}

export function getOmanisFareMessage(bookingSegments, rTerms) {
  const hasOmanisFare = bookingSegments.some(seg => {
    const fare = seg.fares[0];
    return (fare.fareTypeName === 'Omani Special' || fare.fareTypeName === 'Omani Light');
  })

  return hasOmanisFare ? rTerms.split(/[\r\n]/) : null;
}

export function getMetroGroupMessage(bookingSegments, trips) {
  if (!Array.isArray(bookingSegments)) return null;

  let messageMetroGroup = "";
  for (let i = 0; i < bookingSegments.length; i++) {
    const fare = bookingSegments[i].fares[0];
    const flight = findFlightByFare(fare, trips[i]);

    if (flight && Array.isArray(flight.segments) && flight.segments.length > 0) {
      const seg = flight.segments[0];
      if (seg.originCode !== seg.originSearchCode) {
        if (messageMetroGroup) messageMetroGroup += ", ";
          messageMetroGroup += `${seg.originCity} as your departure airport`;
        if (i === 0)
          messageMetroGroup += " for departing flight";
        else
          messageMetroGroup += " for returning flight";
      }
      if (seg.destinationCode !== seg.destinationSearchCode) {
        if (messageMetroGroup) messageMetroGroup += ", ";
          messageMetroGroup += `${seg.destinationCity} as your arrival airport`;
        if (i === 0)
          messageMetroGroup += " for departing flight";
        else
          messageMetroGroup += " for returning flight";
      }
    }
  }

  if (messageMetroGroup) {
    messageMetroGroup = "You have choosen " + messageMetroGroup + ". Click on ‘Continue’ button to proceed.";
    return messageMetroGroup;
  }

  return null;  
}

export function findFlightByFare (fare, trip)  {
  if (!fare || !Array.isArray(fare.fareInfos) || !Array.isArray(trip.markets)) return null;
  const fareInfo = fare.fareInfos[0];
  if (!fareInfo) return null;

  let result = null;
  for (const market of trip.markets) {
    if (!Array.isArray(market.flights)) continue;

    for (const flight of market.flights) {
      if (!Array.isArray(flight.fares) || !Array.isArray(flight.segments)) continue;

      let foundFare = false;
      for (const f of flight.fares) {
        if (f.fareTypeID !== fare.fareTypeID) continue;
        if (Array.isArray(f.fareInfos) && f.fareInfos.find(fi => {
          return fi.fareID === fareInfo.fareID;
        })) {
          foundFare = true;
          break;
        }
      }
      if (foundFare) {
        result = flight;
        break;
      }
    }
    if (result) break;
  }
  return result;
}

// get the destination terms from locale resource
// bookingSegments: array of booking segements
// rTerms: locale resource of terms
export function getDestinationTermsMessage(bookingSegments, rTerms) {
  if(!rTerms) return null;
  if(!Array.isArray(bookingSegments) || bookingSegments.length === 0) return null;

  for(const segment of bookingSegments) {

    const keys = [
      segment.destinationCode,
      segment.destinationCountryCode,
      `${segment.destinationCountryCode}-${segment.destinationCode}`,      
    ]

    segment.legs.forEach(leg => {
      const flightNumber = `${leg.carrierCode}${leg.flightNumber}`
      keys.push(flightNumber)
      keys.push(`${segment.destinationCode}-${flightNumber}`)
      keys.push(`${segment.destinationCountryCode}-${flightNumber}`)
    })

    for(const key of keys) {
      const terms = rTerms[key];
      if(terms) {
        return terms.split(/[\r\n]/)
      }
    }
  }
  
  return null;
}