import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Row, Col, Radio, Button, FormGroup, FormControl, DropdownButton, Modal, Checkbox } from "react-bootstrap";
import dayjs from "dayjs";
import Select from "react-select";
// import "react-select/dist/react-select.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ls } from "../../../locale";

import { getAgentPath, history, tripTypeHelper } from "../../../helpers";
import { resourceActions } from "../../../state/ducks/resources";
import { sessionActions } from "../../../state/ducks/session";
import { flightActions } from "../../../state/ducks/flights";

import "./index.scss";


class SearchFlight extends Component {

  constructor(props) {
    super(props);

    let tripType = props.tripType;
    if(!tripTypeHelper.isValidValue(tripType)) tripType = tripTypeHelper.defaut;

    this.state = {
      maxCount: 9,
      destinations: [],
      destOpen: false,
      destinations2: [],
      dest2Open: false,
      origin: null,
      destination: null,
      origin2: null,
      flightType: tripType,
      adult: 1,
      child: 0,
      infant: 0,
      extra: 0,
      editmode: false,
      editmode2: false,
      promoCode: "",
      formValid: true,
      departureDate: null,
      returnDate: null,

      routesLoaded: false,
      fare: '',
      specialFares: [],
      showOmanisFare: false,
    };
  }

  componentDidMount() {
    this.props.getStations()
    .then(() => {
      const data = this.props.getSearchParams();

      const state = this.getStateFromSearchParams(data, this.props.stations);
      // always reset fare to empty as it might be set by deep link
      if (state) this.setState({...state, fare: ''});

      this.props.checkSpecialFares().then(response => {
        //console.log('special fares', response);

        this.setState({
          maxCount: response.paxLimit.adult,
          specialFares: response.specialFares,
        }, () => this.setOmanisFareAvailability())
      });
    })
    .finally(() => {
      this.setState({
        routesLoaded: true
      })
    });
  }

  getStateFromSearchParams = (data, stations) => {
    if (!data || !data.route || !data.flightType) return null;

    let route1 = data.route, route2 = null;
    if (data.route.indexOf('|') > 0) {
      const routes = data.route.split('|');
      route1 = routes[0];
      route2 = routes[1];
    }

    let date1 = data.dates, date2 = null;
    if (data.dates.indexOf('|') > 0) {
      const dates = data.dates.split('|');
      date1 = dates[0];
      date2 = dates[1];
    }

    let tripType = data.flightType;
    if(!tripTypeHelper.isValidValue(tripType)) {
      tripType = tripTypeHelper.findValueByCode(tripType);
      if(!tripType) tripType = tripTypeHelper.defaut;
    }

    const result = {
      flightType: tripType,
      promoCode: data.promoCode || "",
    };

    let codes = route1.split('-');
    result.origin = this.getStationOptionByCode(codes[0], stations);
    result.destination = this.getStationOptionByCode(codes[1], stations);
    result.destinations = this.filterDestiantionList(result.origin.destinations);

    if (route2) {
      codes = route2.split('-');
      result.origin2 = this.getStationOptionByCode(codes[0], stations);
      result.destination2 = this.getStationOptionByCode(codes[1], stations);
      result.destinations2 = this.filterDestiantionList(result.origin2.destinations);
    }

    if (date1) {
      const departureDate = dayjs(date1);
      if (departureDate && departureDate.isValid()) result.departureDate = departureDate.toDate();

      if (date2) {
        const returnDate = dayjs(date2);
        if (returnDate && returnDate.isValid()) {
          if (returnDate >= departureDate) result.returnDate = returnDate.toDate();
        }
      }
    }

    if (data.counts) {
      const counts = data.counts.split('-');
      result.adult = parseInt(counts[0], 10) || 1;
      result.child = parseInt(counts[1], 10) || 0;
      result.infant = parseInt(counts[2], 10) || 0;
      result.extra = parseInt(counts[3], 10) || 0;
    }
    else {
      result.adult = 1;
      result.child = 0;
      result.infant = 0;
      result.extra = 0;
    }

    // validate the number of passengers
    if(result.adult > this.state.maxCount) result.adult = this.state.maxCount;
    if(result.child > result.adult) result.child = result.adult;
    if(result.infant > result.adult) result.infant = result.adult;
    if(result.extra > (result.adult + result.child)) result.extra = result.adult + result.child;

    result.fare = data.fare

    return result;
  }

  getStationOptionByCode = (code, stations) => {
    if (!code || !Array.isArray(stations)) return null;

    const station = stations.find(s => {
      return s.code === code;
    });
    if (!station) return null;

    return {
      value: code,
      label: station.name,
      city: station.city,
      destinations: station.destinations
    };
  }


  selectOrigin = (selected) => {
    this.setState({ origin: selected, editmode: false }, () => this.setOmanisFareAvailability());
    if (selected && selected.value) {
      const destinationsList = this.filterDestiantionList(selected.destinations);

      this.setState({ destinations: destinationsList, destination: null, destOpen: true, editmode: false }, () => this.setOmanisFareAvailability());
      if (this.destinationSelect)
        this.destinationSelect.focus();
    }
  };

  clearLabel = mode => {
    this.setState({
      editmode: mode
    });
  };

  clearLabel2 = mode => {
    this.setState({
      editmode2: mode
    });
  };

  clearDestLabel = mode => {
    this.setState({
      destOpen: mode
    })
  }

  clearDestLabel2 = mode => {
    this.setState({
      dest2Open: mode
    })
  }

  selectDestination = (selected) => {
    this.setState({ destination: selected, destOpen: false }, () => this.setOmanisFareAvailability());
  };

  filterDestiantionList = (destinations) => {

    const destinationsList = destinations.map((dest) => {
      return {
        value: dest.code,
        label: `${dest.name}|${dest.city}|${dest.country}`,
        city: dest.city,
        country: dest.country
      };
    });

    return destinationsList;
  };

  selectOrigin2 = (selected) => {
    this.setState({ origin2: selected, editmode2: false });
    if (selected && selected.value) {
      const destinationsList = this.filterDestiantionList(selected.destinations);
      this.setState({ destinations2: destinationsList, destination2: null, dest2Open: true });

      if(this.destinationSelect2)
        this.destinationSelect2.focus()
    }
  };

  selectDestination2 = (selected) => {
    this.setState({ destination2: selected, dest2Open: false });
  };

  radioChange = e => {
    const val = e.target.value;
    if (e.target.checked) {
      this.setState({ flightType: val }, () => this.setOmanisFareAvailability());
    }
  };

  setDepartureDate = (date) => {

    let returnDate = this.state.returnDate;
    if (returnDate && returnDate < date) {
      returnDate = date;
    }
    this.setState({
      departureDate: date,
      returnDate
    }, () => this.setOmanisFareAvailability());
  };

  setReturnDate = (date) => {
    this.setState({
      returnDate: date
    }, () => this.setOmanisFareAvailability());
  };

  setOmanisFareAvailability = () => {
    const available = this.checkOmanisFareAvailability();
    let fare = this.state.fare;
    //console.log('setOmanisFareAvailability', available, fare);
    
    if(!available) {
      // remove omanis fare if it not available  
      if(fare === 'omanis') {
        fare = null;
      }
      this.setState({
        showOmanisFare: false,
        fare
      })
      return
    }

    if(fare !== 'omanis') {
      fare = null;
    }

    this.setState({
      showOmanisFare: true,
      fare,
      promoCode: null,
    })
  }

  checkOmanisFareAvailability = () => {
    const {specialFares, flightType, origin, destination, departureDate } = this.state;
    //console.log('checkOmanisFareAvailability', specialFares, flightType, origin, destination, departureDate);

    // check if omais fare is available
    if(!specialFares || !specialFares['omanis']) return false;
    const omanisFare = specialFares['omanis'];
    if(!Array.isArray(omanisFare.trips) || omanisFare.trips.length === 0) return false;

    // check if departure date is between the date range
    if(!departureDate) return false;
    const departureDateStr = dayjs(departureDate).format('YYYY-MM-DD');
    if(departureDateStr < omanisFare.startDate || departureDateStr > omanisFare.endDate) return false;

    // check if origin and destination are set to MCT-SLL
    if(!origin?.value || !destination?.value) return false;
    const route = `${origin.value}-${destination.value}`;
    return  omanisFare.trips.some(t => t.route === route && t.tripType === flightType);
  }

  renderPassengerCount = () => {
    const { adult, child, infant, extra} = this.state;

    const rPaxType = ls.t('PaxType');
    const rSearchPage = ls.t('SearchPage');

    return (
      <span>
        <label>{rSearchPage.Passengers}</label> <b>{rPaxType.Adult.Label} {adult}</b>
        {child > 0 && <b>,&nbsp;{rPaxType.Child.Label} {child} </b>}
        {infant > 0 && <b>,&nbsp;{rPaxType.Infant.Label} {infant}</b>}
        {extra > 0 && <b>,&nbsp;{rPaxType.Extra.Label} {extra}</b>}
      </span>
    );
  };

  addAdult = () => {
    const total = this.state.adult + this.state.child + this.state.extra;

    if (total < this.state.maxCount) {
      this.setState({
        adult: this.state.adult + 1
      });
    }
  };

  minorAdult = () => {
    let {adult, child, extra, infant } = this.state;
    if (adult > 1) {
      adult = adult - 1;
      const extraTotal = adult + child;
      if(extra > extraTotal) extra--;
      if(infant > adult) infant--;

      this.setState({
        adult,
        extra,
        infant
      });
    }
  };

  addChild = () => {
    const total = this.state.adult + this.state.child + this.state.extra;

    if (total < this.state.maxCount) {
      this.setState({
        child: this.state.child + 1,
      });
    }
  };

  minorChild = () => {
    if (this.state.child > 0) {
      let extra = this.state.extra;
      const extraTotal = this.state.adult + this.state.child;
      if(extra >= extraTotal) extra--;

      this.setState({
        child: this.state.child - 1,
        extra,
      });
    }
  };

  addInfant = () => {
    if (this.state.infant < this.state.adult) {
      this.setState({
        infant: this.state.infant + 1,
      });
    }
  };

  minorInfant = () => {
    if (this.state.infant > 0) {
      this.setState({
        infant: this.state.infant - 1,
      });
    }
  };

  addExtraSeat = () => {
    const extraTotal = this.state.adult + this.state.child;
    const total = extraTotal + this.state.extra

    if (this.state.extra < extraTotal && total < this.state.maxCount) {
      this.setState({
        extra: this.state.extra + 1,
      });
    }
  };

  minorExtraSeat = () => {
    if (this.state.extra > 0) {
      this.setState({
        extra: this.state.extra - 1,
      });
    }
  };

  setPromoCode = (value) => {
    if(value) value = value.toUpperCase();
    this.setState({
      promoCode: value,
    });
  };

  handleClose = () => {
    this.setState({
      show: false
    });
  };

  submitForm = e => {

    e.preventDefault();

    const isValid = this.validateForm();

    if (!isValid) {
      return;
    }

    const searchObj = {
      route: `${this.state.origin.value}-${this.state.destination.value}`,
      flightType: this.state.flightType,
      departureDate: dayjs(this.state.departureDate).format("YYYY-MM-DD"),
      returnDate: this.state.returnDate ? dayjs(this.state.returnDate).format("YYYY-MM-DD") : null,
      adult: this.state.adult,
      child: this.state.child,
      infant: this.state.infant,
      extra: this.state.extra,
      promoCode: this.state.promoCode,
      fare: this.state.fare,
    };

    //this.props.storeSearch(searchObj);
    let dates = searchObj.departureDate;
    if (searchObj.returnDate)
      dates += "|" + searchObj.returnDate;

    let route = searchObj.route;
    if (searchObj.flightType === "multi") {
      route += `|${this.state.origin2.value}-${this.state.destination2.value}`;
    }

    const counts = `${searchObj.adult}-${searchObj.child}-${searchObj.infant}-${searchObj.extra}`;

    const params = {
      lng: ls.lang,
      flightType: searchObj.flightType,
      route,
      dates,
      counts,
      promoCode: searchObj.promoCode,
      fare: searchObj.fare,
    }

    // add event tracking for search
    window.dataLayer.push({event: 'flights_search', request: params});

    //this.props.clearSession().then(response => {
      this.props.setSearchParams(params);
      this.props.setBookingMode({mode: "Booking"});

      //const flightsUrl = `/flights/${searchObj.flightType}/${route}/${dates}/${counts}`;
      const url = `/${ls.lang}${getAgentPath()}/flights`;
      history.push(url, {fromSearch: true});
    //})

  };


  validateForm() {

    const rError = ls.t('SearchPage.Error');

    if (this.state.origin === null) {
      this.setState({
        show: true,
        error: rError.Origin.Required,
        formValid: false
      });
      return false;
    }
    if (this.state.destination === null) {
      this.setState({
        show: true,
        error: rError.Destination.Required,
        formValid: false
      });
      return false;
    }
    if (this.state.departureDate === null) {
      this.setState({
        show: true,
        error: rError.DepartureDate.Required,
        formValid: false,
      });
      return false;
    }
    if (this.state.flightType !== "oneway" && this.state.returnDate === null) {
      this.setState({
        show: true,
        error: rError.ReturnDate.Required,
        formValid: false
      });
      return false;
    }
    if(this.state.flightType === 'multi' && this.state.destination.value === 'MCT' && this.state.origin2.value === 'MCT') {
      this.setState({
        show: true, 
        error: rError.InvalidMultiCityCombination, // 'The route combination is not allowed.',
        formValid: false
      });
      return false;
    }

    this.setState({
      show: false,
      formValid: true
    });
    return true;
  }

  renderContent = () => {
    const {
      flightType,
      departureDate,
      returnDate,
      origin,
      destination,
      origin2,
      destinations2,
      destination2,
      destinations,
    } = this.state;

    const originList = this.props.stations.map((station) => {
      return {
        value: station.code,
        label: `${station.name}|${station.city}|${station.country}` ,
        city: station.city,
        country: station.country,
        destinations: station.destinations
      };
    });

    const locale = ls.t('rootClass');
    const rSearchPage = ls.t('SearchPage');

    if (flightType === "multi") {
      return (
        <div className="multi-city">
          <Row>
            <Col md={4} xs={12}>
              <div className="destination-select">
                <label>{rSearchPage.From}</label>
                {origin && !this.state.editmode &&
                  <div className="select-value">{origin.value} <span>{normalizeAirportName(origin.label)}</span></div>
                }
                <Select
                  name="origin-input"
                  value=""
                  classNamePrefix="airport-select"
                  onChange={this.selectOrigin}
                  valueKey="code"
                  labelKey="name"
                  options={originList}
                  onMenuOpen={() => this.clearLabel(true)}
                  onMenuClose={() => this.clearLabel(false)}
                  components={{ Option }}
                  blurInputOnSelect={true}
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div className="destination-select">
                <label>{rSearchPage.To}</label>
                {destination && !this.state.destOpen &&
                  <div className="select-value">{destination.value}<span>{normalizeAirportName(destination.label)}</span></div>
                }
                <Select
                  ref={node => this.destinationSelect = node}
                  name="destination-input"
                  value=""
                  classNamePrefix="airport-select"
                  onChange={this.selectDestination}
                  options={destinations}
                  placeholder=""
                  components={{ Option }}
                  onMenuOpen={() => this.clearDestLabel(true)}
                  onMenuClose={() => this.clearDestLabel(false)}
                  openMenuOnFocus={true}
                  blurInputOnSelect={true}
                  //menuIsOpen={this.state.destOpen}
                />
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div className="margin-btm datepickerCon">
                <div className="datepicker-display">
                  <span className="label">{rSearchPage.Departing}</span>
                  <span className="dateCon">
                    {departureDate && dayjs(departureDate).format("DD MMM, YYYY")}
                  </span>
                </div>
                <DatePicker
                  calendarClassName="custom-datepicker"
                  selected={departureDate}
                  onChange={this.setDepartureDate}
                  minDate={dayjs().toDate()}
                  maxDate={returnDate}
                  locale={locale}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={12}>
              <div className="destination-select">
                <label>{rSearchPage.From}</label>
                {origin2 && !this.state.editmode2 &&
                  <div className="select-value">{origin2.value} <span>{normalizeAirportName(origin2.label)}</span></div>
                }
                <Select
                  name="origin-input"
                  value=""
                  classNamePrefix="airport-select"
                  onChange={this.selectOrigin2}
                  valueKey="code"
                  labelKey="name"
                  options={originList}
                  components={{ Option }}
                  onMenuOpen={() => this.clearLabel2(true)}
                  onMenuClose={() => this.clearLabel2(false)}
                  placeholder=""
                />
              </div>
            </Col>

            <Col md={4} xs={12}>
              <div className="destination-select">
                <label>{rSearchPage.To}</label>
                {destination2 && !this.state.dest2Open &&
                  <div className="select-value">{destination2.value} <span>{normalizeAirportName(destination2.label)}</span></div>
                }
                <Select
                  ref={node => this.destinationSelect2 = node}
                  name="destination-input"
                  value=""
                  classNamePrefix="airport-select"
                  onChange={this.selectDestination2}
                  options={destinations2}
                  placeholder=""
                  components={{ Option }}
                  onMenuOpen={() => this.clearDestLabel2(true)}
                  onMenuClose={() => this.clearDestLabel2(false)}
                  openMenuOnFocus={true}
                  blurInputOnSelect={true}
                  //menuIsOpen={this.state.dest2Open}
                />
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div className="margin-btm datepickerCon">
                {flightType === "oneway" && <div className="disabledScreen"></div>}
                <div className="datepicker-display">
                  <span className="label">{rSearchPage.Returning}</span>
                  <span className="dateCon">
                    {returnDate && dayjs(returnDate).format("DD MMM, YYYY")}
                  </span>
                </div>
                <DatePicker
                  calendarClassName="custom-datepicker"
                  selected={returnDate}
                  onChange={this.setReturnDate}
                  minDate={departureDate ? departureDate : dayjs().toDate()}
                  openToDate={returnDate || departureDate}
                  locale={locale}
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    else {
      return (
        <div>
          <Row>
            <Col md={6} xs={12}>
              <div className="destination-select" id="origin-select-box">
                <label>{rSearchPage.From}</label>
                {origin && !this.state.editmode &&
                  <div className="select-value">{origin.value} <span>{normalizeAirportName(origin.label)}</span></div>
                }
                <Select
                  name="origin-input"
                  value=""
                  classNamePrefix="airport-select"
                  onChange={this.selectOrigin}
                  valueKey="code"
                  labelKey="name"
                  options={originList}
                  components={{ Option }}
                  onMenuOpen={() => this.clearLabel(true)}
                  onMenuClose={() => this.clearLabel(false)}
                  blurInputOnSelect={true}
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="destination-select" id="destination-select-box">
                <label>{rSearchPage.To}</label>
                {destination && !this.state.destOpen &&
                  <div className="select-value">{destination.value} <span>{normalizeAirportName(destination.label)}</span></div>
                }
                <Select
                  ref={node => this.destinationSelect = node}
                  name="destination-input"
                  value=""
                  classNamePrefix="airport-select"
                  onChange={this.selectDestination}
                  options={destinations}
                  components={{ Option }}
                  onMenuOpen={() => this.clearDestLabel(true)}
                  onMenuClose={() => this.clearDestLabel(false)}
                  openMenuOnFocus={true}
                  blurInputOnSelect={true}
                  placeholder=""
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <div className="margin-btm" id="depart-date-box">
                <div className="datepicker-display">
                  <span className="label">{rSearchPage.Departing}</span>
                  <span className="dateCon">
                    {departureDate && dayjs(departureDate).format("DD MMM, YYYY")}
                  </span>
                </div>
                <DatePicker
                  calendarClassName="custom-datepicker"
                  inline
                  selected={departureDate}
                  onChange={this.setDepartureDate}
                  minDate={dayjs().toDate()}
                  locale={locale}
                />
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className={`margin-btm ${flightType === "oneway" ? "disabled" : ""}`} id="return-date-box">
                {flightType === "oneway" && <div className="disabledScreen"></div>}
                <div className="datepicker-display">
                  <span className="label">{rSearchPage.Returning}</span>
                  <span className="dateCon">
                    {returnDate && dayjs(returnDate).format("DD MMM, YYYY")}
                  </span>
                </div>
                <DatePicker
                  calendarClassName="custom-datepicker"
                  inline
                  selected={returnDate}
                  onChange={this.setReturnDate}
                  minDate={departureDate ? dayjs(departureDate).toDate() : dayjs().toDate()}
                  openToDate={returnDate || departureDate}
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  };

  render() {
    const {
      flightType,
      promoCode,
      adult,
      child,
      infant,
      extra,
    } = this.state;
    const { resources } = this.props;
    const { routesLoaded } = this.state;

    if (routesLoaded && ( !Array.isArray(resources.stations) || resources.stations.length === 0)) {
      return <Redirect to="/en/service-error" />
    }

    const rPaxType = ls.t('PaxType');
    const rSearchPage = ls.t('SearchPage');

    return (
      <>
        <form className="searchFlight">
          <h1>{ls.t("SearchPage.Title")}</h1>
          <hr />
          <div className="flightTypeCon">
            <div className="radio styled-radio">
              <Radio
                name="flight-type"
                onChange={this.radioChange}
                defaultValue="round"
                checked={flightType === "round"}
              >
                <span className="checkmark" />
                {ls.t("SearchPage.Return.Label")}
              </Radio>
            </div>
            <div className="radio styled-radio">
              <Radio
                name="flight-type"
                onChange={this.radioChange}
                defaultValue="oneway"
                checked={flightType === "oneway"}
              >
                <span className="checkmark" />
                {ls.t("SearchPage.Oneway.Label")}
              </Radio>
            </div>
            <div className="radio styled-radio">
              <Radio
                name="flight-type"
                onChange={this.radioChange}
                defaultValue="multi"
                checked={flightType === "multi"}
              >
                <span className="checkmark" />
                {ls.t("SearchPage.Multicity.Label")}
              </Radio>
            </div>
          </div>
          {this.renderContent()}
          <Row>
            <Col md={6} xs={12}>
              <div className="margin-btm custom-dropdown">
                <DropdownButton
                  bsStyle="default"
                  id="passengerdropdown"
                  title={this.renderPassengerCount()}
                  className="custom-dropdown-btn"
                >
                  <div className="passenger-input">
                    <label>
                      {rPaxType.Adult.Label}
                      <span>{rPaxType.Adult.Desc}</span>
                    </label>
                    <div className="passenger-count disable-select">
                      <span className="btn-count btn-minus" onClick={() => this.minorAdult()}>-</span>
                      <span className="count-con">{adult}</span>
                      <span className="btn-count btn-add" onClick={() => this.addAdult()}>+</span>
                    </div>
                  </div>
                  {this.state.maxCount > 1 && <>
                    <div className="passenger-input">
                      <label>
                        {rPaxType.Child.Label}
                        <span>{rPaxType.Child.Desc}</span>
                      </label>
                      <div className="passenger-count disable-select">
                        <span className="btn-count btn-minus" onClick={() => this.minorChild()}>-</span>
                        <span className="count-con">{child}</span>
                        <span className="btn-count btn-add" onClick={() => this.addChild()}>+</span>
                      </div>
                    </div>
                    <div className="passenger-input">
                      <label>
                        {rPaxType.Infant.Label}
                        <span>{rPaxType.Infant.Desc}</span>
                      </label>
                      <div className="passenger-count disable-select">
                        <span className="btn-count btn-minus" onClick={() => this.minorInfant()}>-</span>
                        <span className="count-con">{infant}</span>
                        <span className="btn-count btn-add" onClick={() => this.addInfant()}>+</span>
                      </div>
                    </div>
                    <div className="passenger-input">
                      <label>
                        {rPaxType.Extra.Label}
                        <span>{rPaxType.Extra.Desc}</span>
                      </label>
                      <div className="passenger-count disable-select">
                        <span className="btn-count btn-minus" onClick={() => this.minorExtraSeat()}>-</span>
                        <span className="count-con">{extra}</span>
                        <span className="btn-count btn-add" onClick={() => this.addExtraSeat()}>+</span>
                      </div>
                    </div>
                  </>}
                </DropdownButton>
              </div>
            </Col>
            <Col md={6} xs={12}>
              {/* show omanis fare check box */}
              {this.state.showOmanisFare && <div className="input-container">
                <Checkbox className="styled-checkbox" 
                  checked={this.state.fare === 'omanis'} 
                  onChange={e => this.setState({fare: e.target.checked ? 'omanis' : ''})}>                
                  <label></label>  
                </Checkbox>{rSearchPage.OmanisFare}</div>
              }
              {/* show promo code input */}
              {!this.state.showOmanisFare && this.state.specialFares['promotion'] && <div className="margin-btm">
                <FormGroup>
                  <FormControl
                    type="text"
                    maxLength="10"
                    name="promoCode"
                    className="customInput"
                    value={promoCode}
                    placeholder={rSearchPage.PromoCode}
                    onChange={(e) => this.setPromoCode(e.target.value)}
                  />
                </FormGroup>
              </div>}
            </Col>
          </Row>
          {/* show discounted fare checkbox */}
          {this.state.specialFares['discounted'] && <Row>
            <Col md={6} xs={12}>
              <Checkbox className="styled-checkbox" 
                checked={this.state.fare === 'discounted'} 
                onChange={e => this.setState({fare: e.target.checked ? 'discounted' : ''})}>                
                <label></label>  
              </Checkbox>{rSearchPage.DiscountedFare}
            </Col>
          </Row>}
          <div className="clearfix btnCon">
            <Button type="submit" className="btn btn-primary pull-right flip btn-submit btn-effect" onClick={this.submitForm}>
              <span>{ls.t('Button.Search')}<i className="picon picon-btn-arrow-right-w"/></span>
            </Button>
          </div>

        </form>
        <Modal className={ls.t("rootClass")} show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{ls.t('Error.InputError.Title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>{!this.state.formValid && this.state.error}</h3>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>{ls.t('Button.Close')}</Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

const Option = (props) => {
  const { className, cx, getStyles, isDisabled, isFocused, isSelected, innerRef, innerProps, value, data } = props;

  const labels = data.label.split('|');

  return (
    <div
      ref={innerRef}
      css={getStyles("option", props)}
      className={cx(
        {
          "option": true,
          "option--is-disabled": isDisabled,
          "option--is-focused": isFocused,
          "option--is-selected": isSelected,
        },
        className
      )}
      {...innerProps}
    >
      <div className="airport-city"><span className="city">{data.city} </span>{value}</div>
      <div className="airport-country"><span className="country">{labels[2]}</span> <span>{labels[0]}</span></div>
    </div>
  );
}

function normalizeAirportName(label) {
  if(!label) return label;

  const labelIndex = label.indexOf('|');
  if(labelIndex > 0)
    return label.substring(0, labelIndex);
  else
    return label;
}

function mapStateToProps(state) {
  const { resources } = state;
  return {
    resources,
    stations: Array.isArray(state.resources.stations) > 0 ?
      state.resources.stations.map(s => mapStation(s)) : [],
  };
}

function mapStation(station) {
  return {
    code: station.origin.airportCode,
    name: station.origin.airportName,
    city: station.origin.airportCity,
    country: station.origin.countryName,
    destinations: station.destinations.map(d => ({ 
      code: d.airportCode, 
      name: d.airportName, 
      city: d.airportCity, 
      country: d.countryName 
    }))
  };
}

const mapDispatchToProps = {
  ...resourceActions,
  ...sessionActions,
  getSearchRequest: flightActions.getSearchRequest,
  checkSpecialFares: flightActions.checkSpecialFares
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFlight);
