import React from "react";
import { ListGroup , ListGroupItem  } from "react-bootstrap";

import { history } from "../../../helpers";
import { ls } from "../../../locale";


const SideMenu = ( props ) => {


  function jumpTo(next) {
    if(!next) return;

    const locale = props.locale || 'en';
    history.push("/" + locale + next);
  }

  return(
    <div className="side-menu">
      <ListGroup>
        <ListGroupItem active={props.activeItem==='home'}>
          <button className="link-button" onClick={props.onHome}>{ls.t('SideMenu.Home')}</button>
        </ListGroupItem>
        <ListGroupItem active={props.activeItem==='profile'}>
          <button className="link-button" onClick={() => jumpTo('/member/profile')}>{ls.t('SideMenu.MyProfile')}</button>
        </ListGroupItem>
        <ListGroupItem active={props.activeItem==='history'}>
          <button className="link-button" onClick={props.onBookingHistory}>{ls.t('SideMenu.BookingHistory')}</button>
        </ListGroupItem>
        <ListGroupItem active={props.activeItem==='wci'}>
          <button className="link-button" onClick={() => jumpTo('/wci')}>{ls.t('SideMenu.WebCheckIn')}</button>
        </ListGroupItem>
        <ListGroupItem active={props.activeItem==='mmb'}>
          <button className="link-button" onClick={() => jumpTo("/mmb")}>{ls.t('SideMenu.ManageMyBooking')}</button>
        </ListGroupItem>
        {/* <ListGroupItem>My Boarding Pass</ListGroupItem>
        <ListGroupItem>Flight Status</ListGroupItem>
        <ListGroupItem>Flight Schedule</ListGroupItem> */}
      </ListGroup>
    </div>
  )
}

export default SideMenu;
