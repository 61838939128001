import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { jsonHelper } from "../../../helpers";

import MealsIcon from "../../../images/meals-icon.png";
// import MealsMenu from "../../../images/meals-menu.png";
import MealsMenu from "../../../images/meals/KSML.png";
import MealsSelection from "../MealsSelection";

import "./index.scss";
import { ls } from "../../../locale";
import { stringFormatter, numberFormatter } from "../../../helpers";

class Meals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowMealsSelection: false,
      mealboxClass: false,
      selection: [],
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.selection !== this.props.selection) {
      this.setState({
        selection: jsonHelper.cloneDeep(this.props.selection)
      });
    }
  }

  showMealsSelection = (show) => {
    this.setState({
      isShowMealsSelection: show
    });
  };

  close = (selected) => {

    this.showMealsSelection(false);
    if (selected && selected.length > 0) {
      this.props.onSelectMeals(selected);
    }
  };

  render() {
    const { bookingSummary, booking } = this.props;
    const { selection } = this.state;

    const rExtraPage = ls.t('ExtraPage');
    if(!rExtraPage.Meal) return null;

    let priceSection = null;
    const selectedAmount = getSelectedMealAmount(selection);
    if (selectedAmount.count > 0) {
      let discounted = rExtraPage.Meal.TotalDiscounted;
      if(discounted)
        discounted = stringFormatter.formatString(discounted, selectedAmount.count);

      priceSection = (
        <div className="priceCon">
          {/* Total <strong className="discounted">Discounted</strong> {selectedAmount.count}{" "}
          {selectedAmount.count > 1 ? "meals" : "meal"} */}
          <span className="summary" dangerouslySetInnerHTML={{__html: discounted}}></span>
          <span className="price">
            <span className="currency"> {bookingSummary.currencyCode}</span>
            {selectedAmount.amount}
          </span>
        </div>
      );
    } else {
      const minPrice = getMealMinPrice(selection);
      //console.log('min price:', minPrice);
      priceSection = (
        <div className="priceCon">
          <span className="summary">{rExtraPage.Meal.PriceFrom}</span>
          <span className="price">
            <span className="currency"> {bookingSummary.currencyCode}</span>
            {minPrice.amount}
          </span>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="meals-selection">
          <h1> {rExtraPage.Meal.Label} </h1>
          {/* <div className="meal-info">
            <ReactSVG className="meal-image" src={MealSVG} />
            <span>{rExtraPage.Meal.Title} </span>
          </div> */}
          <div className="meals-info">
            <div className="details">
              <img src={MealsIcon} alt="meals" />
              <div className="intro">
                <p>{rExtraPage.Meal.Desc}</p>
                  {priceSection}
              </div>
            </div>
            <div className="actions">
              <Button
                onClick={() => this.showMealsSelection(true)}>
                {rExtraPage.Meal.ViewMenu}
              </Button>
              <img src={ MealsMenu } alt="meals menu" />
            </div>
          </div>
          <div className="clearfix" />
        </div>
        {selection && (
          <MealsSelection
            booking={booking}
            selection={selection}
            onClose={this.close}
            show={this.state.isShowMealsSelection}
            currencyCode={bookingSummary.currencyCode}
          />
        )}
      </React.Fragment>
    );
  }
}

const getMealMinPrice = (selection) => {
  const result = {
    currencyCode: "OMR",
    amount: 1.5
  };

  if (!Array.isArray(selection)) return result;

  selection.forEach((flight) => {
    if (!Array.isArray(flight.passengers)) return;

    flight.passengers.forEach((passenger) => {
      if(!Array.isArray(passenger.meals)) return;

      passenger.meals.forEach(meal => {
        if ((meal.currencyCode !== result.currencyCode || meal.amount < result.amount) && meal.quantity > 0) {
          result.currencyCode = meal.currencyCode;
          result.amount = meal.amount;
        }
      })
    });
  });

  return result;
};

const getSelectedMealAmount = selection => {
  const result = {
    count: 0,
    amount: 0
  };

  const selectedPrices = []

  selection && selection.forEach(flight => {
    flight.passengers.forEach(pax => {
      if(!Array.isArray(pax.meals)) return;
      pax.meals.forEach(meal => {
        if(meal.selected) {
          selectedPrices.push(meal.amount)
          result.amount += meal.amount;
          result.count += 1;
        }
      });
    });
  });

  const maxDecimal = numberFormatter.getMaxDecimalFromPrices(selectedPrices)
  result.amount = result.amount.toFixed(maxDecimal)

  return result;
}

export default Meals;
