import React from 'react'

import './index.scss'

export default function ProhibitedItem({items}) {

  if(!items || !items.Title) return null

  const paxCabinDetails = items.PaxCabinDetails.split(/[\r\n]/)
  const baggageDetails = items.BaggageDetails.split(/[\r\n]/)

  return (
    <div className='prohibited-items'>
      <h1 className='prohibited-title'>{items.Title}</h1>
      <div className='prohibited-desc'>{items.Desc}</div>
      <div className='prohibited-subtitle'>{items.PaxCabinTitle}</div>
      <ul className='prohibited-list'>
        {paxCabinDetails.map((detail, index) => (
          <li key={index}>{detail}</li>
        ))}
      </ul>
      <div className='prohibited-subtitle'>{items.BaggageTitle}</div>
      <ul className='prohibited-list'>
        {baggageDetails.map((detail, index) => (
          <li key={index}>{detail}</li>
        ))}
      </ul>
      <div className='prohibited-images'>
        <img className='small' src="/images/prohibited/bullet.png" alt='bullet' />
        <img src="/images/prohibited/gun.png" alt='gun' />
        <img src="/images/prohibited/taser.png" alt='taser' />
        <img src="/images/prohibited/scissors.png" alt='scissors' />
        <img src="/images/prohibited/knife.png" alt='knife' />
        <img src="/images/prohibited/tools.png" alt='tools' />
        <img src="/images/prohibited/grinade.png" alt='grinade' />
        <img src="/images/prohibited/fireworks.png" alt='fireworks' />
        <img src="/images/prohibited/spray.png" alt='spray' />
        <img src="/images/prohibited/explosive.png" alt='explosive' />
        <img src="/images/prohibited/blunt.png" alt='blunt' />
        <img src="/images/prohibited/club.png" alt='club' />
      </div>
    </div>
  )
}
