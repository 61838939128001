import React from 'react';

export function ChargeDetails({text, amount, decimal, currencyCode}: {text?: string, amount: string, decimal: string, currencyCode: string}) {
  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-p-2 tw-text-lg tw-font-bold tw-uppercase sa-text-green">
      <div>{text ?? 'total charge'}</div>
      <div>
        <span className="tw-px-1 tw-text-sm tw-font-normal">{currencyCode}</span>
        <span className="tw-text-xl">{amount}</span>
        <span className="tw-text-sm tw-font-normal">{decimal}</span></div>
    </div>
  )
}