import types from "./types";

const INITIAL_STATE = {
  isLoading: false,
  hasToken:false,
  error:null
};


export default function (state = INITIAL_STATE, { type: actionType }) {
  switch (actionType) {
  case types.SESSION_REQUEST:
    return {
      ...state,
      isLoading: true,
      hasToken:false
    };
  case types.SESSION_CREATE:
    return {
      ...state,
      isLoading: false,
      hasToken:true
    };
  default:
    return state;
  }
}