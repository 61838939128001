import React from "react";
import { ls } from "../../locale";

import { Row, Col } from "react-bootstrap";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import "./index.scss";

export default function() {

  const rError = ls.t('Error');
  let message = rError.Transaction || '';
  message = message.split(/[\r\n]/);

  if(!Array.isArray(message)) message = [];

  return (
    <div className={ls.t("rootClass") + " main-container search-page"} dir={ls.t("direction")}>
      <div className="header-con">
        <Header />
      </div>
      <div className="header-bar" />
      <div className="container padt80 padb20">
        <Row className="min-height">
          <Col className="error-message-container">
            {message.map((m, index) => <p key={index} dangerouslySetInnerHTML={{__html: m}}></p>)}
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  )
}