export const initialState = {
  values: null,
  errors: null
}

export function reducer(state, action) {
  switch (action.type) {
    case 'SET_INPUT_STATE':
      return {
        values: {...state.values, [action.key]: action.value },
        errors: {...state.errors, [action.key]: null}
      }
    case 'SET_ERROR_STATE':
      return {
        values: state.values,
        errors: { ...state.errors, [action.key]: action.error }
      }
    case 'SET_VALUES_STATE':
      return {
        values: action.values,
        errors: {}
      }
    case 'SET_ERRORS_STATE':
      return {
        values: state.values,
        errors: action.errors
      }
    default:
      return state
  }
}

export function setInput(key, value) {
  return {type: 'SET_INPUT_STATE', key, value}
}

export function setError(key, error) {
  return {type: 'SET_ERROR_STATE', key, error}
}

export function setValues(values) {
  return {type: 'SET_VALUES_STATE', values}
}

export function setErrors(errors) {
  return {type: 'SET_ERRORS_STATE', errors}
}

export function hasError(errors) {
  return Object.values(errors).some(value => !!value)
}