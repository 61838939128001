import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { ls } from "../../../locale";

import "./index.scss";

import { history, buildSearchParams } from "../../../helpers"
import { sessionActions } from "../../../state/ducks/session";
import { memberActions } from "../../../state/ducks/member";
import { mmbActions } from "../../../state/ducks/mmb";
import { wciActions } from "../../../state/ducks/wci";

import FlightList from "./FlightList";
import SideMenu from "./SideMenu";


import SearchFlight from "../../SearchPage/SearchFlight";
import BasePage from "../../../components/BasePage";

class PortalPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showingHistoryBookings: false,
      isShowMessageBox: false,
      message: '',
    }
  }

  componentDidMount() {
    this.props.getMyBookings(true);
  }

  checkInBooking = (pnr) => {
    if (!pnr) return;

    this.props.retrieveBooking(pnr).then(booking => {

      if(!Array.isArray(booking.passengers) || !booking.passengers.length) {
        this.showMessageBox('Booking is cancelled.');
        return;
      }
      
      const lastName = booking.passengers[0].lastName;

      this.props.wciRetrieveBooking(pnr, lastName)
        .then(response => {
          if (response && response.pnr) {
            this.props.setBookingMode({ mode: "WCI" });
            this.jumpTo('/wci/flight');
          }
        });
    })  
  }

  manageBooking = (pnr) => {
    if (!pnr) return;

    this.props.retrieveBooking(pnr).then(booking => {

      if(!Array.isArray(booking.passengers) || !booking.passengers.length) {
        this.showMessageBox('Booking is cancelled.');
        return;
      }
      const lastName = booking.passengers[0].lastName;

      this.props.mmbRetrieveBooking(pnr, lastName).then((response) => {
        if (response && response.booking) {
          this.props.setBookingMode({ mode: "MMB" });
  
          // build search request
          const params = buildSearchParams(response.searchRequest);
          if (params) {
            params.lng = ls.lang;
            this.props.setSearchParams(params);
          }
          this.jumpTo('/mmb/summary');
        }
      });
    })    
  }


  goHome = () => {
    this.setState({
      showingHistoryBookings: false,
    })
  }

  showBookingHistory = () => {
    this.setState({
      showingHistoryBookings: true,
    })
  }

  jumpTo = next => {
    if (!next) return;

    history.push("/" + ls.lang + next);
  }

  closeMessageBox = () => {
    this.setState({
      isShowMessageBox: false,
      message: ''
    });
  };

  showMessageBox = (message) => {
    this.setState({
      isShowMessageBox: true,
      message
    });
  };

  render() {
    const rPortalPage = ls.t('Member.PortalPage');
    const rError = ls.t('Error');
    const rButton = ls.t('Button');

    if (!rPortalPage.NextDeparture) return null;

    const myBookings = Array.isArray(this.props.myBookings) ? this.props.myBookings : [];
    let bookings = myBookings.filter(x => !x.departed);
    let title = rPortalPage.NextDeparture;
    let message = rPortalPage.NoComingFlights;
    let sideMenuActiveItem = 'home';

    if (this.state.showingHistoryBookings) {
      bookings = myBookings.filter(x => x.departed);
      title = rPortalPage.PastBookings;
      message = rPortalPage.NoPastBookings;
      sideMenuActiveItem = 'history';
    }


    return (
      <BasePage mainContainerClass="search-page">
        <Row className="min-height">
          <Col md={9} xs={12}>
            <FlightList bookings={bookings}
              onCheckIn={this.checkInBooking}
              onManage={this.manageBooking}
              title={title}
              message={message}
            />
            <SearchFlight />
          </Col>
          <Col md={3} xs={12}>
            <SideMenu activeItem={sideMenuActiveItem}
              locale={ls.lang}
              onHome={this.goHome}
              onBookingHistory={this.showBookingHistory} />
          </Col>
        </Row>
        <Modal className={ls.t("rootClass")} show={this.state.isShowMessageBox} onHide={this.closeMessageBox}>
          <Modal.Header closeButton>
            <Modal.Title>{rError.InputError.Title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{this.state.message}</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeMessageBox}>{rButton.Close}</Button>
          </Modal.Footer>
        </Modal>
      </BasePage>
    );
  }
}

function mapStateToProps(state) {
  return {
    myBookings: state.member.myBookings,
  };
}

const mapDispatchToProps = {
  ...sessionActions,
  ...memberActions,
  mmbRetrieveBooking: mmbActions.retrieveBooking,
  wciRetrieveBooking: wciActions.retrieveBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortalPage);
