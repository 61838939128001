import React, { useEffect, useState, useRef, useCallback } from 'react'
import { connect } from "react-redux"
import { Col, Row } from "react-bootstrap"
import dayjs from 'dayjs';

import { withControlledComponent, commonHelpers } from "../../../helpers"

import BasePage from "../../../components/BasePage"
import SideMenu from "../SideMenu"
import RequestDetails from "./RequestDetails"


import "./index.scss";

import { agentActions } from "../../../state/ducks/agent";
import { PopupMessage, usePopupMessage } from '../../../components/PopupMessage';


const RequestForm = withControlledComponent(RequestDetails)

const today = new Date();
const start = new Date(today.getFullYear(), today.getMonth(), 1);
const initRequest = {
  startDate: start,
  endDate: today,
  report: null,
}

function ReportPage({ retrieveReportList, generateReport, location }) {

  const [reportList, setReportList] = useState([]);

  const requestForm = useRef(null);
  const popup = usePopupMessage();

  useEffect(() => {
    retrieveReportList().then(response => {
      if(Array.isArray(response)) {
        const reportList = response.map(x => ({value: x.reportId, label: x.reportName }));
        setReportList(reportList);
      }
    });
  }, []);


  const retrieveReport = useCallback(() => {
    const valid = requestForm.current.validateForm();
    if(!valid) return;

    let request = requestForm.current.getValues();
    request = normalizeRequest(request);

    generateReport(request).then(response => {
      if(response && response.length > 0) {
        commonHelpers.createDownloadTag(response, 'report.csv');
      }
      else {
        popup.showMessage('No data found for the selected date range', 'Notification');
      }
    })
  }, [generateReport, popup, requestForm]);

  // render() 
  return (
    <BasePage>
      <Row className="min-height padt40">
        <Col md={9} xs={12}>
          <div>
            <h1>Report</h1>
            <hr/>            
            <RequestForm
              ref={requestForm}
              reportList={reportList}
              initValues={initRequest}
              onValidate={validateRequestForm} 
              onRetrieve={retrieveReport} />
          </div>
        </Col>
        <Col md={3} xs={12}>
          <SideMenu activeItem="DashboardReport" path={location.pathname} />
        </Col>
      </Row>

    <PopupMessage show={popup.show} title={popup.title} message={popup.message} onHide={popup.hideMessage} hideCancelButton={true} />
    </BasePage>
  )
  
}

function normalizeRequest(request) {
  if(request.startDate) request.startDate =  dayjs(request.startDate).format('YYYY-MM-DD');
  if(request.endDate) request.endDate =  dayjs(request.endDate).format('YYYY-MM-DD');
  if(request.report) {
    request.reportId = request.report.value;
    delete request.report;
  }
  return request;
}

function validateRequestForm(values) {
  const errors = {};

  if(!values.report) 
    errors.report = 'Please select a report to process';

  return errors;
}

function mapStateToProps() {
  return {
  }
}

const mapDispatchToProps = {
  ...agentActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
