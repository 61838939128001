import React from 'react'
import { Row, Col } from 'react-bootstrap'

import { ls } from '../../../locale'

import { numberFormatter } from '../../../helpers'

export function PaymentTotal({total}) {
  if(!total) return null

  const rCheckoutPage = ls.t('CheckoutPage')
  const rTravelVoucher = ls.t('TravelVoucher')

  return (
    <Row>
      <Col xs={12} md={8} mdOffset={4}>
        <div className="final-amount center-block">
          <h4>{rCheckoutPage.TotalDue}</h4>
          <PaymentDetail value={total.amount} currency={total.convertedFrom} title={rTravelVoucher.VoucherPrice} />
          <PaymentDetail value={total.adminFee} currency={total.convertedFrom} title={rCheckoutPage.AdminFee} />
          <PaymentDetail value={total.amountToPay} currency={total.convertedFrom} title={rCheckoutPage.TotalToPay} className="total" />
        </div>
      </Col>
    </Row>
  )

}

function PaymentDetail({value, currency, title, className}) {
  if(!value) return null

  const splitted = numberFormatter.decimalSplitter(value)

  return (
    <p className={className}>
      <span>{title}</span>
      <span className="price">{currency}
        <span>{splitted.amount}
          <span className="decimal-point">{numberFormatter.oneDecimalPoint(splitted.decimal, currency)}</span>
        </span>
      </span>
    </p>
  )
}