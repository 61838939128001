import React from "react";
import { connect } from "react-redux";

import { SyncLoader } from "react-spinners";

import "./index.scss";

const Loader = props => (
  props.loadingStatus.loading &&
  <div className="loading-mask">
    <SyncLoader
      sizeunit="px"
      size={12}
      color="#81b43f"
      loading={props.loadingStatus.loading}
    />
  </div>
);

function mapStateToProps(state) {
  return {
    loadingStatus: state.redux.loadingStatus
  }
}

export default connect(mapStateToProps)(Loader);