import React, { useEffect, useState, useCallback, useRef } from 'react'
import dayjs from 'dayjs'
import { FormControl } from 'react-bootstrap'

import './DateInput.scss'

// DateInput component
// Renders a date input field
// Props: 
// - value: date string in format YYYY-MM-DD
// - onChange: callback function to be called when the date changes
// Usage: <DateInput value={value} onChange={handleChange} />
export function DateInput({ value = '', onChange }) {

  const [day, setDay] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  const dayRef = useRef(null)
  const monthRef = useRef(null)
  const yearRef = useRef(null)

  useEffect(() => {
    const values = value.split('-')
    if(values.length > 0) setYear(values[0])
    if(values.length > 1) setMonth(values[1])
    if(values.length > 2) setDay(values[2])
  }, [value, setDay, setMonth, setYear])

  const handleChange = () => {
    let dd = day
    if(dd && dd.length === 1) dd = `0${dd}`
    let mm = month
    if(mm && mm.length === 1) mm = `0${mm}`

    onChange(`${year}-${mm}-${dd}`)
  }

  const onDayChange = useCallback(value => {
    if (value === '' || value === '0') {
      setDay(value)
      return
    }

    const day = parseInt(value)
    if (isNaN(day) || day < 1 || day > 31) return
    setDay(value)
    
    setTimeout(() => {
      if(value.length === 2 && monthRef.current) monthRef.current.focus()
    }, 200)

  }, [setDay])


  const onMonthChange = useCallback(value => {
    if (value === '' || value === '0') {
      setMonth(value)
      return
    }

    const month = parseInt(value)
    if (isNaN(month) || month < 1 || month > 12) return
    setMonth(value)

    setTimeout(() => {
      if(value.length === 2 && yearRef.current) yearRef.current.focus()
    }, 200)
    
  }, [setMonth])

  const onYearChange = useCallback(value => {
    if (!value) {
      setYear('')
      return
    }

    const thisYear = dayjs().year()

    if (value.length < 4) {
      const minValue = value.padEnd(4, '99')
      const min = parseInt(minValue)
      if (isNaN(min) || min < thisYear - 100) return

      const maxValue = value.padEnd(4, '00')
      const max = parseInt(maxValue)
      if (isNaN(max) || max > thisYear) return

      setYear(value)
      return
    }

    const year = parseInt(value)
    if (isNaN(year) || year < thisYear - 100 || year > thisYear) return
    setYear(value)
  }, [setYear])

  return (
    <div className="date-input">
      <FormControl className="day" type="text" placeholder="DD" value={day} inputRef={el => dayRef.current = el}
        onChange={e => onDayChange(e.target.value)} 
        onBlur={handleChange} 
        onFocus={e => e.target.select()} 
        maxLength={2} />
      <FormControl className="month" type="text" placeholder="MM" value={month} inputRef={el => monthRef.current = el}
        onChange={e => onMonthChange(e.target.value)} 
        onBlur={handleChange} 
        onFocus={e => e.target.select()} 
        maxLength={2} />
      <FormControl className="year" type="text" placeholder="YYYY" value={year} inputRef={el => yearRef.current = el}
        onChange={e => onYearChange(e.target.value)} 
        onBlur={handleChange} 
        onFocus={e => e.target.select()} 
        maxLength={4} />
    </div>
  )
}