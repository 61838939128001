import { Promise } from "core-js"
import types from "./types"
import { memberService } from "./memberService"

export default {
  memberLogin,
  memberRegister,
  getMyBookings,
  forgotPassword,
  getProfile,
  updateProfile,
  getEmployeeInfo,
  retrieveBooking,
  checkStaffTicketAllowance,
  activate
}

function memberLogin(userName, password) {
  return dispatch => {
    dispatch(initState());
    return memberService.login(userName, password);
  }
}

function memberRegister(data) {
  return dispatch => {
    dispatch(initState());
    return memberService.register(data);
  }
}

function getMyBookings(forceRefresh) {
  return (dispatch, getState) => {

    if(!forceRefresh) {
      const state = getState()
      const bookings = state.member.myBookings
      if(bookings && bookings.length > 0) {
        return Promise.resolve(bookings)
      }
    }

    return memberService.getMyBookings().then(response => {
      dispatch(getMyBookingsResponse(response));
      return response;
    });
  }
}

function forgotPassword(email) {
  return dispatch => {
    dispatch(initState());
    return memberService.forgotPassword(email);
  }
}

function getProfile() {
  return () => memberService.getProfile();
}

function retrieveBooking(pnr) {
  return () => memberService.retrieveBooking(pnr)
}

function activate(token, errorHandler) {
  return () => memberService.activate(token, errorHandler)
}

function getEmployeeInfo() {
  return (dispatch) => {
    return memberService.getEmployeeInfo().then(response => {
      dispatch(getEmployeeInfoResponse(response));
      return response;
    });
  }
}

function updateProfile(data) {
  return () => memberService.updateProfile(data)
}

function checkStaffTicketAllowance() {
  return () => memberService.checkStaffTicketAllowance()
}

function initState() {
  return {
    type: types.INIT_STATE
  }
}

function getMyBookingsResponse(payload) {
  return {
    type: types.GET_MY_BOOKINGS_RESPONSE,
    payload,
  }
}

function getEmployeeInfoResponse(payload) {
  return {
    type: types.GET_EMPLOYEE_INFO_RESPONSE,
    payload,
  }
}
