import { api } from "../api";
import { sessionService } from "../session/sessionService";

export const resourceService = {
  getStations,
  getCountries,
  getLocale,
};

function getStations() {
  let culture = sessionService.getCulture();
  if(!culture) culture = 'en-US'

  const url = `api/resources/routes?culture=${culture}`
  return api.getFetch(url)
}

function getCountries() {
  let culture = sessionService.getCulture();
  if(!culture) culture = 'en-US'

  const url = `api/resources/countries?culture=${culture}`
  return api.getFetch(url)
}

function getLocale() {
  let culture = sessionService.getCulture();
  if(!culture) culture = 'en-US'

  const url = `api/resources/locale?culture=${culture}`
  return api.getFetch(url)
}
