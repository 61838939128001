import React from "react";
import { Button, Table } from "react-bootstrap";
import Icon from "react-icons-kit/Icon";
import { edit } from 'react-icons-kit/fa/edit';
import dayjs from "dayjs";

import "./index.scss"

function Activity({ onManageBooking, bookings }) {

  const manageBooking = booking => {
    if (typeof (onManageBooking) === 'function') onManageBooking(booking);
  }

  if (!Array.isArray(bookings)) bookings = []

  return (
    <React.Fragment>
      <h1>Recent Activity</h1>
      <hr />
      <div className="activity-wrapper">
        <Button type="submit" className="btn btn-primary flip btn-submit btn-effect">
          <span>Bookings</span>
        </Button>
        <div className="table-wrapper">
          <Table>
            <thead>
              <tr>
                <th>Ref. No</th>
                <th>Book Date</th>
                <th>Depart Date</th>
                <th>City Pair</th>
                <th>Pax</th>
                <th><span className="hidden-in-mobile">Action</span></th>
              </tr>
            </thead>
            <tbody>
              <ActivityList bookings={bookings} manageBooking={manageBooking} />
            </tbody>
          </Table>
        </div>
      </div>
    </React.Fragment>
  )
}

function ActivityList({bookings, manageBooking}) {
  return bookings.map((booking, index) => (
    <React.Fragment key={booking.pnr + '-' + index}>
      <tr>
        <td>{booking.pnr}</td>
        <td>{dayjs(booking.bookingDate).format('DD MMM YYYY')}</td>
        <td>{dayjs(booking.departureDate).format('DD MMM YYYY')}</td>
        <td>{booking.cityPair}</td>
        <td>{booking.passengerCount}</td>
        <td>
          {booking.status !== 'Cancelled' &&
            <Icon title="Manage booking" icon={edit} className="action-icon" onClick={() => manageBooking(booking)} />}
        </td>
      </tr>
      <tr className="blank" />
    </React.Fragment>
  ))
}


export default Activity;
