import React from "react";
import { Redirect } from "react-router-dom";
import { ls } from "../../locale";

export default function () {
  return (
    <div className={ls.t("rootClass") + " main-container"} dir={ls.t("direction")}>
      <Redirect to={`/${ls.lang}/search`} />
    </div>
  );
}
