import { commonHelpers } from './commonHelpers'

export function createTdsHandler(processedCallback, validatedCallback) {

  const state = {
    cardNumber: null
  }

  function setup(cardNumber) {

    state.cardNumber = cardNumber;

    return new Promise((resolve) => {
      if (window.Cardinal) {
        setupPaymentsSetupCompleteHandler(processedCallback);
        setupPaymentsValidatedHandler(validatedCallback);

        resolve(true);
      }
      else {
        const songBirdLib = commonHelpers.createScriptTag(
          process.env.REACT_APP_SONGBIRD
        );
  
        songBirdLib.onload = () => {
          window.Cardinal.configure({
            logging: {
              debug: process.env.NODE_ENV === "production" ? "Off" : "verbose",
              level: "on"
            }
          });
  
          setupPaymentsSetupCompleteHandler(processedCallback);
          setupPaymentsValidatedHandler(validatedCallback);

          resolve(true);
        };
      }
    })
  }

  function setupPaymentsSetupCompleteHandler(processedCallback) {
    window.Cardinal.off("payments.setupComplete");

    window.Cardinal.on("payments.setupComplete", () => {
        window.Cardinal.trigger("bin.process", state.cardNumber)
        .then((results) => processedCallback(results));
    });
  }

  function setupPaymentsValidatedHandler(validatedCallback) {
    window.Cardinal.off("payments.validated");

    window.Cardinal.on("payments.validated", (data, jwt) => validatedCallback(data, jwt));
  }

  function init3DS(token) {
    window.Cardinal.setup("init", {
      jwt: token
    });
  }

  function require3DS(request) {
    window.Cardinal.continue(
      "cca",
      {
        AcsUrl: request.acsUrl,
        Payload: request.paReq
      },
      {
        OrderDetails: {
          TransactionId: request.transactionID
        }
      }
    );
  }

  return {
    setup,
    init3DS,
    require3DS,
  }
}