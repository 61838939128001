export function getSelectedOptionFromCountries(countryCode, countries) {
  if (!countryCode || !Array.isArray(countries)) return null;

  const data = countries.find(x => x.countryCode === countryCode);
  if (!data) return null;

  return {
    value: data.countryCode,
    label: data.countryName
  }
}

export function getDialingOptionByDialingCode(dialingCode, countries) {

  if (!dialingCode || !Array.isArray(countries)) return null;

  const data = countries.find(x => x.dialingCode === dialingCode);
  if (!data) return null;

  return {
    value: data.dialingCode,
    label: data.dialingCode
  }
}


export function getSelectedOptionFromDocumentType(docType, types) {
  if (!docType || !Array.isArray(types)) return null;

  return types.find(x => x.value === docType);
}

// split the phone number into two parts: dialing code and number
export function splitPhoneNumber(number) {
  if(!number || !number.trim()) return null;
  number = number.trim();
  const index = number.indexOf(' ');
  if(index > 0) {
    return {
      code: number.substring(0, index),
      number: number.substring(index + 1)
    }
  }
  else {
    return {
      code: '',
      number: number
    }
  }
}

export function getLanguageFromCulture(culture) {
  let lang = 'en';
  if(typeof(culture) === 'string') {
    const values = culture.split('-');
    if(values.length > 0 && values[0].length === 2) lang = values[0];
  }
  return lang;
}