import React from 'react';
import { Row, Col, FormGroup, FormControl, Checkbox } from "react-bootstrap";

import CountrySelect from '../../../../components/Common/CountrySelect';
import { getSelectedOptionFromCountries } from "../../../../helpers";
import FormErrorBlock from '../../../../components/FormErrorBlock';

export default function AgentDetails(props) {

  const { values, errors, setInputState, countries } = props;
  const country = getSelectedOptionFromCountries(values.countryCode, countries) || ' ';

  return (
    <>
      <Row className="padt20">
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="First Name *" 
              value={values.firstName} 
              onChange={e => setInputState('firstName', e.target.value)} 
              data-toggle="tooltip" title="First Name (required)" />
            <FormErrorBlock error={errors.firstName} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Last Name *" 
              value={values.lastName} 
              onChange={e => setInputState('lastName', e.target.value)} 
              data-toggle="tooltip" title="Last Name (required)" />
            <FormErrorBlock error={errors.lastName} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Address *" 
              value={values.address} 
              onChange={e => setInputState('address', e.target.value)} 
              data-toggle="tooltip" title="Address (required)" />
            <FormErrorBlock error={errors.address} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="City *" 
              value={values.city} 
              onChange={e => setInputState('city', e.target.value)} 
              data-toggle="tooltip" title="City (required)" />
            <FormErrorBlock error={errors.city} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="State" 
              value={values.state} 
              onChange={e => setInputState('state', e.target.value)} 
              data-toggle="tooltip" title="State" />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Post Code *" 
              value={values.postalCode} 
              onChange={e => setInputState('postalCode', e.target.value)} 
              data-toggle="tooltip" title="Postal Code (required)" />
            <FormErrorBlock error={errors.postalCode} />
          </FormGroup>
        </Col>
      </Row>
      <Row className="padt20">
        <Col md={4}>
          <div className="country-select">
            <CountrySelect className="country-control"
              options={countries}
              selected={country}
              onSelected={selected => { setInputState('countryCode', selected.value); setInputState('country', selected.label); }}
              placeholder="Country"
            />
          </div>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Email Address *" 
              value={values.email} 
              onChange={e => setInputState('email', e.target.value)} 
              data-toggle="tooltip" title="Email Address (required)" />
            <FormErrorBlock error={errors.email} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Phone *" 
              value={values.phone} 
              onChange={e => setInputState('phone', e.target.value)} 
              data-toggle="tooltip" title="Phone Number (required)" />
            <FormErrorBlock error={errors.phone} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Checkbox checked={values.isActive} onChange={e => setInputState('isActive', e.target.checked)}>
              <label></label>
            </Checkbox>
            <span>Active</span>
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}