import React from 'react'
import { ls } from "../../../locale";

export function VoucherBanner() {

  const rTravelVoucher = ls.t('TravelVoucher')

  return (
    <div className="voucher-banner" id="voucher-banner">
      {rTravelVoucher.DealOnAir}
    </div>
  )
}