export const agentValidator = {
  validateIataNumber,
  validateUserName,
  validateAgencyName,
  validateAddress,
  validateCity,
  validatePostalCode,
  validateContactName,
}


function validateIataNumber(value) {
  if(!value) 
    return 'Travel Agent Number is required';
  
  if(value.length > 8) 
    return 'Travel Agent Number has maximum 8 characters';
  
  return null;
}


function validateUserName(value) {
  if(!value) return 'User Name is required';
  
  if(value.length > 15) 
    return 'User Name has maximum 15 characters';

  const regex = /^[a-zA-Z0-9_.-]*$/
  if(!regex.test(value))
    return 'User ID can only contains letters, numbers, dash(-), underscore(_) and period(.)'  

  return null;
}

function validateAgencyName(value) {
  if(!value) return 'Agnency Name is required';
  
  return null;
}

function validateAddress(value) {
  if(!value) return 'Address is required';
  if(value.length > 100) return 'Address has maximum 100 characters';
  
  return null;
}

function validateCity(value) {
  if(!value) return 'City is required';
  
  return null;
}

function validatePostalCode(value) {
  if(!value) return 'Postal Code is required';
  
  return null;
}

function validateContactName(value) {
  if(!value) return 'Contact Name is required';
  
  return null;
}

