import {customerValidator, profileValidator as validator } from "../../../helpers";


export function validateRegistrationDetails(values) {
  const errors = {};
  if (values.newPassword) {
    const currentPassword = values.currentPassword && values.currentPassword.trim();
    errors.currentPassword = currentPassword ? null : customerValidator.requiredField('CurrentPassword');
    errors.newPassword = customerValidator.validatePassword(values.newPassword);
    if (!errors.newPassword && values.newPassword !== values.confirmPassword) errors.confirmPassword = "Confirm password doesn't match";
  }

  return errors;
}

export function validateCustomerDetails(values) {
  const errors = {
    //documentType: validator.validateDocumentType(values.documentType),
    //documentNumber: validator.validateDocumentNumber(values.documentNumber),
    firstName: validator.validateNames(values.firstName),
    lastName: validator.validateNames(values.lastName),
    //dateOfBirth: validator.validateDateOfBirth(values.dateOfBirth),
    //nationality: values.nationality ? null : "Nationality can't be empty",
    //expiryDate: validator.validateExpiryDate(values.expiryDate),
    //issuingCountry: values.issuingCountry ? null : "Issuing Country can't be empty",
    //countryOfBirth: values.countryOfBirth ? null : "Country Of Birth can't be empty",
  }

  return errors;
}

export function validateContactDetails(values) {
  const errors = {};
  errors.phone = validator.validatePhone(values.phone);
  errors.mobile = validator.validateMobile(values.mobile);
  errors.email = validator.validateEmail(values.email);
  return errors;
}