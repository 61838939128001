import React, { useState } from "react";
import {Panel} from "react-bootstrap";
import dayjs from "dayjs";
import Icon from "react-icons-kit/Icon";
import {angleRight} from "react-icons-kit/fa/angleRight";

import { ls } from "../../../locale";

import {numberFormatter, stringFormatter, getOperatingDisclosureMessage } from "../../../helpers";

import { FareList } from './FareList'
import { FareLabel } from './FareLabel'
import { MetroGroupTips } from './MetroGroupTips'
import { FlightDetailsWindow } from "../../../components/FlightDetailsWindow";

export default function FlightCard({bookIndex, flight, flightIndex, selectFlight, selectedFlight, currencyCode, onExpendFlight}) {

  const [opened, setOpened] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const expendFlight = () => {
    if(typeof(onExpendFlight) === 'function') onExpendFlight();
  }

  const toggleArrow = () => setOpened(prevState =>  !prevState); 

  const showDetailBox = e => {
    e.stopPropagation();
    setShowDetail(true);
  }

  const hideDetailBox = () => setShowDetail(false);

  // render()
  const rFlight = ls.t('Flight');
  const rFlightPage = ls.t('FlightPage');
  const rFlightDetails = ls.t('FlightDetails');

  const segment = flight.segments[0];
  const {destinationCode, originCode, carrierCode, flightNumber, legs, flightTime, destinationSearchCode, originSearchCode} = segment;

  const stops = Array.isArray(legs) && legs.length > 0 ? legs.length : 1;

  let fareFrom = 0;
  let isShowExtra = false;
  if (Array.isArray(flight.fares) && flight.fares.length > 0) {
    fareFrom = Number.MAX_SAFE_INTEGER;
    flight.fares.forEach(fare => {
        const baseFare = fare.fareInfos[0].fareWithTaxes;
        if (baseFare < fareFrom) fareFrom = baseFare;

      if(fare.fareTypeName === 'Benefit' || fare.fareTypeName === 'Luban' || fare.fareTypeName === 'Friendly Smart') isShowExtra = true;
    });
  }

  // if there is no fare available, then hide this card
  if(!fareFrom) return null;

  const isDivertedOrigin = originCode !== originSearchCode;
  const isDivertedDestination = destinationCode !== destinationSearchCode;
  let metroGroupMessage = ''
  const rMetroGroupMessage = rFlightPage.MetroGroupMessage
  if(isDivertedOrigin) {
    metroGroupMessage =  stringFormatter.formatString(rMetroGroupMessage, segment.originCity, segment.originSearchCity)
  }
  else if(isDivertedDestination) {
    metroGroupMessage = stringFormatter.formatString(rMetroGroupMessage, segment.destinationCity, segment.destinationSearchCity)
  }

  const durationText = numberFormatter.formatFlightTime(flightTime, rFlight.Duration);
  const seatBarrier = 5;

  segment.operatingDisclosure = getOperatingDisclosureMessage(segment);

  return (
    <React.Fragment>
      <Panel className={"flightPanel Panel"+flightIndex } id={"flight" + flightIndex} eventKey={`${flightIndex}-${bookIndex}`}>
        <div className="flightCard">
          {/* mobile view */}
          <div className="flightCardChild" onClick={expendFlight}>
            <div className={"timeCon" + (isDivertedOrigin ? " highlighted" : "")}>
              <div className="time">{dayjs(flight.departureDate).format("HH:mm")}</div>
              <div className="text">{originCode} - {rFlight.Departure}</div>
            </div>
            <div>
              {
                stops > 1 ? <i className="picon picon-flights"/> : <i className="picon picon-flight"/>
              }
            </div>
            <div className={"timeCon" + (isDivertedDestination ? " highlighted" : "")}>
              <div className="time">{dayjs(flight.arrivalDate).format("HH:mm")}</div>
              <div className="text">{destinationCode} - {rFlight.Arrival}</div>
            </div>
          </div>
          {/* desktop view */}
          <div className="flightCardChild flightCardChild2" onClick={expendFlight}>
            <div>
              <div className="flightCard_flight-details">
                {carrierCode}{flightNumber} | {stops > 1 ? ((stops - 1) + " " + rFlight.Stop) : rFlight.Direct} | <span
                  className="hidden-xs">{rFlight.Duration.Total}
                </span>
                &nbsp;{durationText}
              </div>
              <span className="link" onClick={showDetailBox}>{rFlightDetails.Label} <Icon icon={angleRight}/></span>
            </div>
            { 0 < flight.seatsAvailable && flight.seatsAvailable < seatBarrier && <div className="remaining-seats">
              {stringFormatter.formatString(rFlight.SeatLeft, flight.seatsAvailable)}
            </div> }
          </div>
          <Panel.Toggle
            className={(opened && flight.seatsAvailable > 0 ? "opened " : "") + " flightCardButtonCon"}>
            {/* detailsCon is for mobile */}
            <div className="pad20 detailsCon">
              <div>
                {carrierCode}{flightNumber} | {stops > 1 ? ((stops - 1) + + " " + rFlight.Stop) : rFlight.Direct} | 
                <span className="hidden-xs">{rFlight.Duration.Total}</span> 
                &nbsp;{durationText}
              </div>                
            </div>
            { 0 < flight.seatsAvailable && flight.seatsAvailable < seatBarrier && <div className="remaining-seats">
              {stringFormatter.formatString(rFlight.SeatLeft, flight.seatsAvailable)}
            </div> }
            { flight.seatsAvailable > 0
            ? <><div className="text-center priceOutCon">
                <div className="labelCon">{rFlightPage.PriceFrom}</div>
                <div className="priceCon">{currencyCode}
                  <span>{numberFormatter.decimalSplitter(fareFrom).amount}
                    <span className={`${currencyCode} decimal-point`}>
                      {numberFormatter.oneDecimalPoint(numberFormatter.decimalSplitter(fareFrom).decimal, currencyCode)}
                    </span>
                  </span>
                </div>
              </div>
              <i className={"picon" + (!opened ? " picon-arrow-down-g " : " picon-arrow-up-w")}></i>
              { opened && <span className="link" onClick={showDetailBox}>{rFlightDetails.Label} <Icon icon={angleRight}/></span> }
              </>
            : <div className="panel-soldout">{rFlightPage.SoldOut}</div>
            }
          </Panel.Toggle>
        </div>
        { flight.seatsAvailable > 0 && <Panel.Collapse onEnter={toggleArrow} onExit={toggleArrow}>
          <div className="flightFaresCon">
            <FareLabel isShowExtra={isShowExtra} />
            <FareList flight={flight} 
              selectedFlight={selectedFlight} 
              isShowExtra={isShowExtra} 
              currencyCode={currencyCode} 
              bookIndex={bookIndex} 
              selectFlight={selectFlight} />
          </div>
        </Panel.Collapse> }
      </Panel>
      {segment.operatingDisclosure && <div className="operating-message">{segment.operatingDisclosure}</div>}

      {metroGroupMessage && !opened && <MetroGroupTips message={metroGroupMessage} 
        className={ls.t("rootClass")} target={isDivertedDestination ? 'destination' : 'origin'} /> }

      {showDetail && <FlightDetailsWindow flight={segment} onClose={() => hideDetailBox()}/>}
    </React.Fragment>
  );
}
