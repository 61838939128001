// the component to show the profile button and popup menu

import React, { useState, useEffect } from 'react'
import { ProfileMenu } from '../../pages/Member/Menu'

import './profile.scss'

export function MemberProfile({profile, onLogout, onLink}) {

  const [showMenu, setShowMenu] = useState(false)

  if(profile?.displayName) {
    profile.displayName = getFirstName(profile.displayName)
  }

  const toggleShowMenu = e => {
    e.stopPropagation()
    setShowMenu(!showMenu)
  }

  useEffect(() => {
    const handleClick = () => {
      setShowMenu(false)
    }
    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  },[])

  return (
    <div className="profile-button">
      <div className="profile-link">
        <i className="profile-icon icon-profile"></i>
        <div className="profile-name" onClick={toggleShowMenu}>{profile.displayName}</div>
      </div>
      {showMenu && <div className="profile-menu-modal" onClick={e => e.stopPropagation()}>
        <ProfileMenu profile={profile} onLogout={onLogout} onLink={onLink} />
      </div>}
    </div>
  )
}

function getFirstName(displayName) {
  if(!displayName) return '';

  const names = displayName.split(' ')
  return names[0].toLowerCase()
}
