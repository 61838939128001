import { statusCodes } from "../../../constants";
import { api } from "../api";

import { UserType } from "../../../constants/settings";

export const sessionService = {
  createSession,
  sessionFromStore,
  setSessionToStore,
  getSession,
  clearSession,
  getSearchParams,
  setSearchParams,
  getBookingMode,
  setBookingMode,
  getUserData,
  setUserData,
  getPassengerData,
  setPassengerData,
  logout,
  getUserStatus,
  // updateUserData,
  setCulture,
  getCulture,
  setCurrency,
  getCurrency,
  logging,
};

function sessionFromStore() {
  return sessionStorage.sid ? sessionStorage.sid : undefined;
}

function setSessionToStore(token) {
  sessionStorage.sid = token;
  return token;
}

async function getSession() {
  const token = sessionFromStore();
  if (!token) {
    return await createSession()
  }

  setSessionToStore(token);
  try {
    return await validateSession();
  }
  catch (err) {
    return await createSession()
  }
}

function setSearchParams(params) {
  sessionStorage.setItem("searchParams", JSON.stringify(params));
}

function getSearchParams() {
  const json = sessionStorage.getItem("searchParams");
  if(!json) return {};

  return JSON.parse(json);
}

function setBookingMode(mode) {
  const modeData = mode ? JSON.stringify(mode) : null;
  sessionStorage.setItem("bookingMode", modeData);
}

function getBookingMode() {
  let mode = sessionStorage.getItem("bookingMode");
  if(!mode) 
    mode = { mode: "Booking"};
  else
    mode = JSON.parse(mode);

  return mode;
}

function setUserData(data) {
  if(data) {
    data.lastModified = new Date();
  }
  const userData = data ? JSON.stringify(data) : null;
  sessionStorage.setItem("userData", userData)
}


function getUserData() {
  let data = sessionStorage.getItem("userData");
  if(!data) 
    data = { userType: UserType.guest }
  else
    data = JSON.parse(data)
  
  return data
}

function setPassengerData(data) {
  const paxData = data ? JSON.stringify(data) : null;
  sessionStorage.setItem('passengerData', paxData);
}

function getPassengerData() {
  const data = sessionStorage.getItem('passengerData');
  if(!data) return null;

  return JSON.parse(data);
}

function setCulture(culture) {
  if(culture) sessionStorage.setItem("culture", culture);
}

function getCulture() {
  let culture = sessionStorage.getItem("culture");
  if(!culture) culture = "en-US";
  return culture;
}

function setCurrency(currency) {
  if(!currency) currency = ''
  sessionStorage.setItem("currency", currency);
}

function getCurrency() {
  return sessionStorage.getItem("currency");
}

async function validateSession() {
  const result = await api.getFetch("api/session/validate", undefined, async response => {
    if(response.status === statusCodes.BAD_REQUEST || response.status === statusCodes.FORBIDDEN) {
      const createResult = await createSession();
      return createResult;
    }
  });
  return result;
}

async function createSession() {
  sessionStorage.removeItem("sid");
  try {
    const { token } = await  api.getSession("api/session");
    setSessionToStore(token);
    // if(version === '1.4.35.0') {
    //   window.location.reload();
    // }
    return token;
  }
  catch(err) {
    console.error('Error creating session: ', err);
    return Promise.reject(err);
  }
}

function clearSession() {
  return api.postFetch(`api/session/clear`)
}

function logout() {
  return api.postFetch(`api/session/logout`, undefined)
    .then(() => {
      let userData = getUserData();
      if(userData) {
        userData = {...userData, loggedIn: false, permissions: [] };
        setUserData(userData);
      }
      return userData;
    })
}

function getUserStatus() {
  return api.getFetch(`api/session/userStatus`)
}

function logging(data) {
  return api.post('api/session/logging', data)
}