import { api } from '../api'
import { getClientWindowSize } from '../helper'

export const agentTopupService = {
  getFingerprint,
  getPaymentMethods,
  getTopupInfo,
  authorizeCS,
  tdsEnrol,
  tdsValidate,
  makeOmanDebitPayment,
  getSmartPayParams
}

function getFingerprint() {
  return api.getFetch(`api/agentTopup/getFingerprint`)
}


function getPaymentMethods() {
  return api.getFetch(`api/agentTopup/PaymentMethods`)
}

function getTopupInfo() {
  return api.getFetch(`api/agentTopup/topupInfo`)
}

// process cybersource payment
function authorizeCS(paymentDetail, amount = {}) {

  const data = {
      ...amount,
      paymentDetail,
      clientWindowSize: getClientWindowSize()
    };

  return api.postFetch(`api/agentTopup/authorizeCS`, data)
}

function tdsEnrol(paymentDetail, amount = {}, cardinalEventData) {

  const data = {
      ...amount,
      paymentDetail,
      clientWindowSize: getClientWindowSize(),
      cardinalEventData
    };

    return api.postFetch(`api/agentTopup/EnrollmentCheck`, data)
}

function tdsValidate(paymentDetail, amount = {}) {
  const data = {
      ...amount,
      paymentDetail,
      clientWindowSize: getClientWindowSize()
    };

  return api.postFetch(`api/agentTopup/TDSValidation`, data)
}

// process OmanNet payment

function makeOmanDebitPayment(amount) {
  return api.getFetch(`api/agentTopup/onpay`, amount)
}

function getSmartPayParams(amount) {
  return api.getFetch(`api/agentTopup/SmartPay`, amount)
}

