import React, { useState } from "react";
import Icon from "react-icons-kit/Icon";
import { plus } from "react-icons-kit/fa/plus";
import { minus } from "react-icons-kit/fa/minus";
import { numberFormatter } from "../../helpers";

import "./index.scss";

export default function ServiceSection({ title, currency, services, hideCount }) {

  const [show, setShow] = useState(false);

  if (!Array.isArray(services) || services.length === 0) return null;

  const total = services.reduce((r, s) => r + s.amountWithoutTaxes + s.taxes, 0)
  const splitted = numberFormatter.decimalSplitter(numberFormatter.formatCurrency(total))

  return (
    <React.Fragment>
      <div className="collapsible" onClick={() => setShow(s => !s)}>
        <span>{title}</span>
        <span className="value"><span className="currency">{currency}</span>&nbsp;
          {splitted.amount}
          <span className={`${currency} decimal-point`}>
            {numberFormatter.oneDecimalPoint(splitted.decimal, currency)}
          </span>
          <Icon icon={show ? minus : plus} /></span>
      </div>

      <div className={show ? "tax-info" : "tax-info hidden"}>
        {
          services.map((service, index) => {
            const splittedAmount = numberFormatter.decimalSplitter(numberFormatter.formatCurrency(service.amountWithoutTaxes))
            const splittedTaxes = numberFormatter.decimalSplitter(numberFormatter.formatCurrency(service.taxes))
            return (<React.Fragment key={index}>
              <div>
                {hideCount ? <span>{service.label}</span> : <span>{service.label} x {service.serviceCount}</span>}
                <span className="value">
                  <span className="currency">{currency}</span>&nbsp;
                  {splittedAmount.amount}
                  <span className={`${currency} decimal-point`}>
                    {numberFormatter.oneDecimalPoint(splittedAmount.decimal, currency)}
                  </span>
                </span>
              </div>
              {service.taxes > 0 &&
                <div>
                  <span>VAT</span>
                  <span className="value">
                    <span className="currency">{currency}</span>&nbsp;
                    {splittedTaxes.amount}
                    <span className={`${currency} decimal-point`}>
                      {numberFormatter.oneDecimalPoint(splittedTaxes.decimal, currency)}
                    </span>
                  </span>
                </div>}
            </React.Fragment>)
          })
        }
      </div>
    </React.Fragment>
  );
}
