import types from "./types";
import { Promise } from "core-js";
import { resourceService } from "./resourceService";

export default {
  getStations,
  getCountries,
  getLocale,
};

function getStations() {
  return (dispatch, getState) => {
    const state = getState();
    const stations = state.resources.stations;
    if(stations && stations.length > 0) {
      return Promise.resolve(stations);
    }

    dispatch(stationRequest());
    return resourceService.getStations().then(response => {
      dispatch(stationResponse(response));
      return response;
    }).catch(err => { console.dir(err); });
  };
}

function getCountries() {
  return (dispatch, getState) => {

    const state = getState();
    const countries = state.resources.countries;
    if(countries && countries.length > 0) {
      return Promise.resolve(countries);
    }

    dispatch(countryRequest());
    return resourceService.getCountries().then(response => {
      dispatch(countryResponse(response));
      return response;
    }).catch(err => { console.dir(err); });
  };
}

function getLocale() {
  return () => {
    return resourceService.getLocale().then(response => {
      return response;
    })
    .catch(err => { console.dir(err); });
  };
}


function stationRequest() {
  return { type: types.STATION_REQUEST};
}

function stationResponse(payload) {
  return { type: types.STATION_RESPONSE,payload };
}



function countryRequest() {
  return { type: types.COUNTRY_REQUEST};
}

function countryResponse(payload) {
  return { type: types.COUNTRY_RESPONSE,payload };
}

