import { useState } from 'react'

export function useDialog(defaultTitle?: string, defaultMessage?: string) {
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState<string>(defaultTitle ?? '')
  const [message, setMessage] = useState<string>(defaultMessage ?? '')

  const [trigger, setTrigger] = useState<{
    reject: (v: any) => void;
    resolve: (v: any) => void;
  }>();

  function show(m?: string, t?: string): Promise<void> {
    setOpen(true)
    if(m) setMessage(m)
    if(t) setTitle(t)

    return new Promise((resolve, reject) => {
      setTrigger({ resolve, reject })
    })
  }

  // confirmed: a boolean to indicate if the user has confirmed the message
  function close(confirmed: boolean = false) {
    setOpen(false)
    if(trigger) {
      confirmed ? trigger.resolve(true) : trigger.reject(false)
      setTrigger(undefined)
    }
  }

  return {
    open,
    title,
    message,
    show,
    close
  }

}