import { api } from '../api'

export const voucherService = {
  getVoucher,
  saveVoucher,
  resendVoucher,
  getPaymentMethods,
  getPaymentTotal,
  makeInvoicePay,
  authorizeCS,
  tdsEnrol,
  tdsValidate,
  makeOmanDebitPayment
}

function getVoucher() {
  return api.getFetch(`api/voucher`)
}

function saveVoucher(request) {
  return api.postFetch(`api/voucher/save`, request)
}

function resendVoucher(request) {
  return api.postFetch(`api/voucher/resend`, request)
}


function getPaymentMethods() {
  return api.getFetch(`api/voucher/paymentMethods`)
}

function getPaymentTotal(methodCode) {
  const data = { methodCode }
  return api.getFetch(`api/voucher/paymentTotal`, data)
}

function makeInvoicePay() {
  return api.postFetch(`api/voucher/agpay`)
}

function authorizeCS(paymentDetail) {
  const data = { paymentDetail }
  return api.postFetch(`api/voucher/authorizeCS`, data)
}

function tdsEnrol(paymentDetail, cardinalEventData) {
  const data = { paymentDetail, cardinalEventData }
  return api.postFetch(`api/voucher/EnrollmentCheck`, data)
}

function tdsValidate(paymentDetail) {
  const data = { paymentDetail }
  return api.postFetch(`api/voucher/TDSValidation`, data)
}

function makeOmanDebitPayment() {
  return api.getFetch(`api/voucher/onpay`, undefined)
}