import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { jsonHelper } from "../../../../helpers";
import {Panel, Button, Checkbox, Row, Col} from "react-bootstrap";
import Icon from "react-icons-kit/Icon";
import {user} from "react-icons-kit/fa/user";
import {chevronRight} from "react-icons-kit/fa/chevronRight";

import { ReactSVG } from "react-svg";
import dayjs from "dayjs";

import { numberFormatter, stringFormatter } from "../../../../helpers";
import { PassengerStatus } from "../../../../constants/settings";
import SeatSVG from "../../../../images/seat.svg";

import { FlightDetailsWindow } from "../../../../components/FlightDetailsWindow";

import "./index.scss";
import { ls } from "../../../../locale";

export default forwardRef(function FlightPanel({segment, passengers, canCheckin, onCheckin}, ref) {

  const [showDetail, setShowDetail] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState(0);
  const [selectedPassengers, setSelectedPassengers] = useState([]);
  const [passengersInState, setPassengersInState] = useState(null);

  useEffect(() => {
    const state = getState(segment, passengers);
    setSelectedFlight(state.selectedFlight);
    setSelectedPassengers(state.selectedPassengers);
    setPassengersInState(state.passengers);
  }, [segment, passengers])


  useImperativeHandle(ref, () => ({
    getValues: () => selectedFlight ? { logicalFlightId: selectedFlight, passengers: selectedPassengers } : null
  }), [selectedFlight, selectedPassengers])

  const checkin = () => {
    if (!selectedFlight || !selectedPassengers.length) return;
    if (typeof(onCheckin) !== 'function') return;
    
    onCheckin({
      logicalFlightId: selectedFlight,
      passengers: selectedPassengers
    })
  }

  // render() {
    if(!passengersInState) return null;

    const fare = segment.fares[0];
    const stops = segment.legs.length ? segment.legs.length - 1 : 0;
    
    const rFlight = ls.t('Flight');
    const rFlightDetails = ls.t('FlightDetails');
    const rFlightPage = ls.t('WCI.FlightPage');
    const rFareType = ls.t('FareType');
    const rButton = ls.t('Button')

    const durationText = segment.flightTime > 0 ? numberFormatter.formatFlightTime(segment.flightTime, rFlight.Duration) : '';

    return (
      <React.Fragment>
      <Panel className="single-flight">
        <Row>
          <Col md={3}>
            <div className={"trip-details " + ls.t('rootClass')}>
              <i className="picon picon-plane"/>
              <span>{segment.originCity} {rFlight.To} {segment.destinationCity}</span>
            </div>
          </Col>
          <Col md={5}>
            <p className="flight-date">{dayjs(segment.departureDate).format('ddd D MMM YYYY')}</p>
            <div className="flight-times row">
              <Col md={4} xs={4}>
                <p className="time">{dayjs(segment.departureDate).format('HH:mm')}</p>
                <p>{segment.originCode} - {rFlight.Departure}</p>
              </Col>
              <Col md={4} xs={4}>
                <i className={stops > 0 ? "picon picon-flights" : "picon picon-flight"} />
              </Col>
              <Col md={4} xs={4}>
                <p className="time">{dayjs(segment.arrivalDate).format('HH:mm')}</p>
                <p>{segment.destinationCode} - {rFlight.Arrival}</p>
              </Col>
            </div>
          </Col>
          <Col md={4} className="fare-data">
            <h4 className="fare-name">{fare.fareTypeName ? rFareType[stringFormatter.removeSpace(fare.fareTypeName)].FullName : null}</h4>
            <div className="fare-info">
              <span>{segment.carrierCode}{segment.flightNumber}</span>
              <span>{stops > 0 ? (stops + " " + rFlight.Stop) : rFlight.Direct }</span><span>{durationText}</span>
              <span className="details-span"><button className="link-button" onClick={() => setShowDetail(true)}>
                {rFlightDetails.Label}</button> <Icon icon={chevronRight}/>
              </span>
            </div>
          </Col>
        </Row>
        <div className="passenger-heading">
          <Icon icon={user} className="passenger-icon"/>
          <span>{rFlightPage.PaxToCheckIn}</span>
        </div>
        {
          passengersInState.map((passenger, index) => {
            if(!passenger.isInSegment) return null;

            const selected = selectedPassengers.find(pax => {return pax === passenger.id}) || false;
            
            return (
              <div key={index} className="single-check-in-passenger">
                { passenger.isCheckedIn
                ? <div className="check-box"></div>
                : <Checkbox className="check-box" checked={selected} onChange={() => {}}>
                  <label></label>
                </Checkbox>
                }
                <div className="pax-details">
                    <span className="passenger-name">
                      {passenger.firstName} {passenger.lastName} {passenger.isCheckedIn ? "(Checked in)": ""}
                    </span>
                    <span>
                      {
                        passenger.seat &&
                        <React.Fragment>
                          <ReactSVG src={SeatSVG} className="inline-icon"/>&nbsp;SEAT {passenger.seat}
                        </React.Fragment>
                      }
                    </span>
                  </div>
              </div>
            );
          })
        }        
      </Panel>
      { canCheckin && selectedFlight > 0 && <div className="clearfix btnCon">
          <Button type="submit" className="btn btn-primary pull-right flip btn-submit btn-effect" onClick={checkin}>
            <span>{rButton.Checkin || "Check in"}&nbsp;<i className="picon picon-btn-arrow-right-w"/></span>
          </Button></div> }
      { showDetail && <FlightDetailsWindow flight={segment} onClose={() => setShowDetail(false) }  /> }
      </React.Fragment>
    );
})

function getState(segment, passengers) {

  if(Array.isArray(passengers)) passengers = jsonHelper.cloneDeep(passengers);
  else passengers = [];

  let selectedFlight = 0;
  const selectedPassengers = [];    

  if(segment && Array.isArray(segment.legs)) {

    passengers.forEach(passenger => {
      passenger.isCheckedIn = false;
      passenger.isInSegment = false;
      segment.legs.forEach(leg => {
        leg.customers.forEach(cust => {
          if(cust.passengerId !== passenger.id) return;

          passenger.isInSegment = true;
          if(cust.status === PassengerStatus.CheckedIn) 
            passenger.isCheckedIn = true;
          else {
            selectedFlight = segment.logicalFlightId;
            if(!selectedPassengers.find(p => p.id === passenger.id)) selectedPassengers.push(passenger.id);
          }              
        })
      })
    })
  }

  return {
    selectedFlight,
    selectedPassengers,
    passengers
  }
}

