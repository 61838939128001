import { Promise } from "core-js"
import types from "./types"
import { agentService } from "./agentService"

export default {
  agentLogin,
  getBookingHistory,
  resetBookingHistory,
  retrieveBooking,
  searchBooking,
  resetLastSearchedBooking,
  retrieveDashboardReport,
  forgotPassword,
  changePassword,
  createAgency,
  modifyAgency,
  getChildAgencies,
  getAgencyDetails,
  createAgent,
  modifyAgent,
  modifyAgentStatus,
  getChildAgents,
  transferFunds,
  retrieveReportList,
  generateReport
}

function agentLogin(iataNumber, userName, password) {
  return dispatch => {
    dispatch(initState());
    return agentService.agentLogin(iataNumber, userName, password);
  }
}

function getBookingHistory(forceRefresh) {
  return (dispatch, getState) => {

    if(!forceRefresh) {
      const state = getState()
      const bookingHistory = state.agent.bookingHistory
      if(bookingHistory && bookingHistory.length > 0) {
        return Promise.resolve(bookingHistory)
      }
    }

    return agentService.getBookingHistory()
    .then(response => {
      dispatch(bookingHistoryResponse(response));
      return response;
    });
  }
}

function resetBookingHistory() {
  return dispatch => {
    dispatch(bookingHistoryResponse([]));
  }
}

function searchBooking(pnr) {
  return (dispatch, getState) => {
    const state = getState();
    const searchHistory = state.agent.searchHistory;
    const booking = searchHistory.find(x => x.pnr === pnr);
    if(booking) {
      dispatch(lastSearchedBooking(booking))
      return Promise.resolve(booking);
    }

    return agentService.retrieveBooking(pnr)
    .then(response => {
      dispatch(retrieveBookingResponse(response));
      dispatch(lastSearchedBooking(response))
      return response;
    });
  }
}

function resetLastSearchedBooking(booking) {
  return (dispatch) => {
    dispatch(lastSearchedBooking(booking))
  }
}


function retrieveBooking(pnr) {
  return (dispatch, getState) => {
    const state = getState();
    const searchHistory = state.agent.searchHistory;
    const booking = searchHistory.find(x => x.pnr === pnr);
    if(booking) {
      return Promise.resolve(booking);
    }

    return agentService.retrieveBooking(pnr)
    .then(response => {
      dispatch(retrieveBookingResponse(response));
      return response;
    });
  }
}

function retrieveDashboardReport(data) {
  return () => {
    return agentService.retrieveDashboardReport(data)
    .then(response => {
      return response;
    });
  }
}

function forgotPassword(data) {
  return () => {
    return agentService.forgotPassword(data)
    .then(response => {
      return response;
    });
  }
}

function changePassword(data) {
  return () => {
    return agentService.changePassword(data)
    .then(response => {
      return response;
    });
  }
}

function createAgency(data) {
  return () => {
    return agentService.createAgency(data)
    .then(response => {
      return response;
    });
  }
}

function modifyAgency(data) {
  return () => {
    return agentService.modifyAgency(data)
    .then(response => {
      return response;
    });
  }
}

function createAgent(data) {
  return () => {
    return agentService.createAgent(data)
    .then(response => {
      return response;
    });
  }
}

function modifyAgent(data) {
  return () => {
    return agentService.modifyAgent(data)
    .then(response => {
      return response;
    });
  }
}

function modifyAgentStatus(data) {
  return () => {
    return agentService.modifyAgentStatus(data)
    .then(response => {
      return response;
    });
  }
}

function getChildAgencies(forceRefresh) {
  return (dispatch, getState) => {

    if(!forceRefresh) {
      const state = getState();
      const childAgencies = state.agent.childAgencies;
      if(childAgencies && childAgencies.length > 0) {
        return Promise.resolve(childAgencies);
      }
    }
    return agentService.getChildAgencies()
    .then(response => {
      dispatch(getChildAgenciesResponse(response));
      return response;
    });
  }
}

function getChildAgents(iataNumber) {
  return () => {
    return agentService.getChildAgents(iataNumber)
    .then(response => {
      return response;
    });
  }
}


function getAgencyDetails(iataNumber) {
  return () => {
    return agentService.getAgencyDetails(iataNumber)
    .then(response => {
      return response;
    });
  }
}


function transferFunds(data) {
  return () => {
    return agentService.transferFunds(data)
    .then(response => {
      return response;
    });
  }
}

function retrieveReportList(forceRefresh) {
  return (dispatch, getState) => {

    if(!forceRefresh) {
      const state = getState();
      const reportList = state.agent.reportList;
      if(reportList && reportList.length > 0) {
        return Promise.resolve(reportList);
      }
    }
    return agentService.retrieveReportList()
    .then(response => {
      dispatch(retrieveReportListResponse(response));
      return response;
    });
  }
}

function generateReport(data) {
  return () => {
    return agentService.generateReport(data)
    .then(response => {
      return response;
    });
  }
}

function bookingHistoryResponse(payload) {
  return {
    type: types.BOOKING_HISTORY_RESPONSE, 
    payload
  }
}

function retrieveBookingResponse(payload) {
  return {
    type: types.RETRIEVE_BOOKING_RESPONSE,
    payload
  }
}

function lastSearchedBooking(payload) {
  return {
    type: types.LAST_SEARCHED_BOOKING,
    payload
  }
}

function initState() {
  return {
    type: types.INIT_STATE
  }
}

function getChildAgenciesResponse(payload) {
  return {
    type: types.CHILD_AGENCIES_RESPONSE,
    payload
  }
}

function retrieveReportListResponse(payload) {
  return {
    type: types.REPORT_LIST_RESPONSE,
    payload
  }
}