import React from 'react'
import { Modal, Button } from 'react-bootstrap'

import { ls } from '../../locale'

import './index.scss'


/**
 * @deprecated We will stop using Bootstrap Modal. Use DialogBox instead
 */
export function PopupMessage({show, title, message, closeButtonText, onHide, hideCancelButton = false}
  : {show: boolean, title?: string, message?: string|string[], closeButtonText?: string, onHide: (confirmed: boolean) => void, hideCancelButton?: boolean}
) {

  if(!show || !message) return false;

  const messages = Array.isArray(message) ? message : [message]
  if(messages.length === 0) return false;

  return (
    <Modal className={`${ls.t("rootClass")} popup-message-box`} show={show} onHide={() => onHide(false)} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{ title || ls.t('MessageBox.Title.Notification')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { mapMessages(messages)}        
      </Modal.Body>
      <Modal.Footer>
        {!hideCancelButton && <button className='btn' onClick={() => onHide(false)}>{ls.t('Button.Cancel')}</button> }
        <Button onClick={() =>onHide(true)}>{closeButtonText || ls.t('Button.Close')}</Button>
      </Modal.Footer>
    </Modal>
  )
}


function mapMessages(messages: string | string[]) {
  const result = groupMesssages(messages);

  return result.map((m, i) => {
    if(Array.isArray(m)) {
      return (
        <ul key={i}>
          {m.map((m1, i1) => <li key={i1} dangerouslySetInnerHTML={{__html: m1}}/>)}
        </ul>
      )
    }
    else {
      return <p key={i} dangerouslySetInnerHTML={{__html: m}} />
    }
  })
}

// group the messages into paragraphs and ul
// in the result, a string is a paragraph, an array is a ul
function groupMesssages(messages: string | string[]) {
  
    if(!Array.isArray(messages)) return [messages];
    const result: (string[] | string)[] = []
  
    let startUl = false
    for(let i=0; i<messages.length; i++) {
      const m = messages[i]
      if(m.startsWith('- ')) {
        if(!startUl) {
          startUl = true
          result.push([])
        }
        const item = result[result.length-1]
        if(Array.isArray(item))item.push(m.substring(2))
      }
      else {
        if(startUl) {
          startUl = false
        }
        result.push(m)
      }
    }
  
    return result
}

