import types from "./types";

const INITIAL_STATE = {
  booking: { }
}


export default function (state = INITIAL_STATE, { type: actionType, payload}) {
  switch(actionType) {
    case types.BOOKING_RESPONSE:
      return {
        ...state,
        booking: payload
      };
      
    default:
      return state;
  }
}