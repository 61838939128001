import dayjs from "dayjs";
import { DocumentTypeCode } from "../../../../constants/settings";
import { customerValidator } from "../../../../helpers";

export const initialState = {
  values: {
    id: 0,
    title: 'MR',
    firstName: '',
    lastName: '',
    paxType: 1,
    apisInfo: {
      documentType: {
        value: 'P',
        label: 'Passport',
      },
      documentNumber: '',
      expiryDate: null,
      birthDate: null,
      citizenship: 'OM',
    }
  },
  errors: {}
}

export function reducer(state, action) {
  switch (action.type) {
    case 'SET_VALUES_STATE':
      return {
        values: action.values,
        errors: {}
      }
    case 'SET_APISINFO_STATE':
      return {
        values: { ...state.values, apisInfo: { ...state.values.apisInfo, [action.key]: action.value } },
        errors: { ...state.errors, [action.key]: null }
      }
    case 'SET_ERRORS_STATE':
      return {
        values: state.values,
        errors: action.errors
      }
    default:
      return state
  }
}

export function getCountryOptions(countryCode, countries) {
  const country = {value: 'OM', label: 'Oman'};

  if(!Array.isArray(countries)) return country;

  let result = countries.find(c => {return c.countryCode === countryCode });
  if(result) {
    result = { value: result.countryCode, label: result.countryName };
  }
  else {
    result = country;
  }

  return result;
}

export function validateApis(state, flightDate, countries) {
  const apisInfo= state.apisInfo;
  const dob = apisInfo.birthDate;
  const paxType = state.paxType;

  flightDate = dayjs(flightDate).toDate();

  const errors = {};

  errors.documentNumber = customerValidator.validateDocumentNumber(apisInfo.documentNumber) 
  errors.expiryDate = customerValidator.validateExpiryDate(apisInfo.expiryDate, flightDate)
  
  const dobValidation = customerValidator.validateBirthDate(dob, paxType, flightDate)
  if (!dobValidation.valid) {
    errors.birthDate = dobValidation.message; 
  }    

  if(apisInfo.documentType.value === DocumentTypeCode.NationalId) {
    errors.documentType = customerValidator.validateNationalId(apisInfo.citizenship, countries)
  }

  return errors;
}

export function getValuesFromState(state) {
  const apisInfo = state.apisInfo;
  const birthDate = dayjs(apisInfo.birthDate).format("YYYY-MM-DD");
  const expiryDate = dayjs(apisInfo.expiryDate).format("YYYY-MM-DD");
  
  return {
    id: state.id,
    title: state.title,
    firstName: state.firstName,
    lastName: state.lastName,
    apisInfo: {
      documentType: apisInfo.documentType.value,
      documentNumber: apisInfo.documentNumber,
      expiryDate,
      birthDate,
      citizenship: apisInfo.citizenship,
    }
  }
}