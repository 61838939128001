import { agentTopupService } from './agentTopupService';

export default {
  getFingerprint,
  getPaymentMethods,
  getTopupInfo,
  authorizeCS,
  tdsEnrol,
  tdsValidate,
  makeOmanDebitPayment,
  getSmartPayParams
}

function getFingerprint() {
  return () => {
    return agentTopupService.getFingerprint();
  };
}

function getPaymentMethods() {
  return () => {
    return agentTopupService.getPaymentMethods();
  }
}

function getTopupInfo() {
  return () => {
    return agentTopupService.getTopupInfo();
  }
}

function authorizeCS(details, amount) {
  return () => {
    return agentTopupService.authorizeCS(details, amount);
  };
}

function tdsEnrol(details, amount, cardinalEventData) {
  return () => {
    return agentTopupService.tdsEnrol(details, amount, cardinalEventData);
  };
}
function tdsValidate(details, amount) {
  return () => {
    return agentTopupService.tdsValidate(details, amount);
  };
}

function makeOmanDebitPayment(amount) {
  return () => {
    return agentTopupService.makeOmanDebitPayment(amount);
  };
}

function getSmartPayParams(amount) {
  return () => {
    return agentTopupService.getSmartPayParams(amount);
  }
}