import React from 'react';
import { Row, Col, FormGroup, FormControl, Checkbox, Radio } from "react-bootstrap";

import FormErrorBlock from '../../../../components/FormErrorBlock';

export default function AgencyDetails(props) {

  const { values, errors, setInputState } = props;

  function setFundTransferType(target) {
    if (target.checked) setInputState('fundTransferType', target.value);
  }

  return (
    <>
      <Row className="padt20">
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Agency Name *" 
              value={values.agencyName} 
              onChange={e => setInputState('agencyName', e.target.value)} 
              data-toggle="tooltip" title="Agency Name (required)" />
            <FormErrorBlock error={errors.agencyName} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Address *" 
              value={values.address} 
              onChange={e => setInputState('address', e.target.value)} 
              data-toggle="tooltip" title="Address (required)" />
            <FormErrorBlock error={errors.address} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="City *" 
              value={values.city} 
              onChange={e => setInputState('city', e.target.value)} 
              data-toggle="tooltip" title="City (required)" />
            <FormErrorBlock error={errors.city} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="State" 
              value={values.state} 
              onChange={e => setInputState('state', e.target.value)} 
              data-toggle="tooltip" title="State" />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Post Code *" 
              value={values.postalCode} 
              onChange={e => setInputState('postalCode', e.target.value)} 
              data-toggle="tooltip" title="Postal Code (required)" />
            <FormErrorBlock error={errors.postalCode} />
          </FormGroup>
        </Col>
        <Col md={4}>
        </Col>
      </Row>
      <Row className="padt20">
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Contact Name *" 
              value={values.contactName} 
              onChange={e => setInputState('contactName', e.target.value)} 
              data-toggle="tooltip" title="Contact Name (required)" />
            <FormErrorBlock error={errors.contactName} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Email Address *" 
              value={values.email} 
              onChange={e => setInputState('email', e.target.value)} 
              data-toggle="tooltip" title="Email Address (required)" />
            <FormErrorBlock error={errors.email} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormControl type="text" placeholder="Phone *" 
              value={values.phone} 
              onChange={e => setInputState('phone', e.target.value)} 
              data-toggle="tooltip" title="Phone Number (required)" />
            <FormErrorBlock error={errors.phone} />
          </FormGroup>
        </Col>
      </Row>

      {props.canTransferFund && <Row>
        <Col md={4} className="text-center">
          Assign Credit Limit
        </Col>
        <Col md={4}>
          <FormGroup>
            <Radio name="transferType" value="increase" inline onChange={e => setFundTransferType(e.target)}>Increase</Radio>
            <Radio name="transferType" value="decrease" inline onChange={e => setFundTransferType(e.target)}>Decrease</Radio>
            <FormControl type="number" placeholder="" 
              readOnly={!values.isActive} 
              data-toggle="tooltip" 
              title="Amount to transfer" 
              value={values.fundTransferAmount || ''} 
              onChange={e => setInputState('fundTransferAmount', e.target.value)} />
            <FormErrorBlock error={errors.fundTransferAmount} />
          </FormGroup>
        </Col>
      </Row>}

      <Row>
        <Col md={4}>
          <FormGroup>
            <Checkbox checked={values.isActive} onChange={e => setInputState('isActive', e.target.checked)}>
              <label></label>
            </Checkbox>
            <span>Active</span>
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}