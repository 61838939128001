import { createBrowserHistory } from "history";

import { sessionService } from "../state/ducks/session/sessionService";
import { ls } from "../locale";
import { UserType } from "../constants/settings";

export const history = createBrowserHistory();

export const jumpTo = (next, state) => {
  if(!next) return;
  
  if(!next.startsWith("/")) {
    next = "/" + next;
  }

  if(state) {
    history.push("/" + ls.lang + next, state);
  }
  else {
    history.push("/" + ls.lang + next);
  }
}

export const getAgentPath = () => {
  const user = sessionService.getUserData();
  return user?.loggedIn && user.userType === UserType.agent ? "/agent" : "";
}