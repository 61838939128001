import dot from "dot-object";

class LocaleService {
  constructor() {
    this._locale = {};
    this._lang = 'en';
  }

  setLocale(locale, lang = 'en') {
    this._locale = dot.object(locale);
    this._lang = lang;
  }

  // get locale value by key
  t(key) {
    return dot.pick(key, this._locale);
  }

  get lang() {
    return this._lang;
  }

}

export const ls = new LocaleService();
