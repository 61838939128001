export const defaultState = {
  values: {},
  errors: {}
}

export function reducer(state, action) {
  switch(action.type) {
    case 'value': return {
      values: { ...state.values, [action.payload.key]: action.payload.value },
      errors: { ...state.errors, [action.payload.key]: null }
    }
    case 'error': return {
      values: state.values,
      errors: { ...state.errors, [action.payload.key]: action.payload.value}
    }
    case 'values': return {
      values: action.payload,
      errors: state.errors
    }
    case 'errors': return {
      values: state.values,
      errors: action.payload
    }
    default:
      return state
  }
}

export function isEmptyErrors(errors) {
  return Object.values(errors).some(value => !!value)
}