import React, {useEffect } from "react";

import { jsonHelper } from "../../../helpers";

import "./index.scss";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ls } from "../../../locale";
import { numberFormatter } from "../../../helpers";

export default function MealMenuBox({ onSelectMeal, passenger, physicalFlightId, currencyCode, onUpdateSize }) {

  useEffect(() => {
    if(typeof(onUpdateSize) === 'function') {
      onUpdateSize()
    }
  })


  const selectMeal = meal => {
    if (typeof (onSelectMeal) === 'function') {
      onSelectMeal(meal);
    }
  }

  if (!Array.isArray(passenger.meals) || passenger.meals.length === 0) return null;

  const meals = passenger.meals.filter(m => m.physicalFlightId === physicalFlightId)

  const rCommon = ls.t('Common');
  const rMeal = ls.t('ExtraPage.Meal');

  let maxDecimal = 0;
  if(Array.isArray(meals)) {
    const prices = meals.map(m => m.amount)
    maxDecimal =  numberFormatter.getMaxDecimalFromPrices(prices);
  } 

  return (
    <div className="meal-grid">
    {
      meals && meals.map((meal, index) => {

        const mealImage = require('../../../images/meals/' + meal.ssrCode + '.png')
        let rDiscount = ls.t(`SSR.${meal.ssrCode}.Discount`);
        if (rDiscount === 'Discount') rDiscount = null;

        return (
            <div key={"meal" + index} className={meal.selected ? "meal-box active" : "meal-box"} 
              onClick={meal.selected ? undefined : () => { if (meal.quantity > 0) selectMeal(meal) }}>
              <img src={mealImage} alt="" />
              <div className="meal-title">{meal.label}</div>
              <div className="meal-data">
                <div className="meal-desc">
                  <div className="title">{meal.label}</div>
                  { meal.selected && meal.removable && <button className="link-button" 
                    onClick={() => {selectMeal({ physicalFlightId}) }} >{rMeal.Remove}<span>X</span></button> }
                  { meal.quantity <= 0 && <div className="unavailable">{rCommon.NotAvailable}</div> }
                </div>
                <div className="meal-price">
                  { meal.quantity > 0 && <>                  
                    <span className="currency">{currencyCode}</span>
                    <span className="amount">{jsonHelper.isNumber(meal.amount) ? meal.amount.toFixed(maxDecimal) : meal.amount}</span>
                  </>}
                </div>
              </div>
            </div>
        )
      })
    }
    </div>
  )
}

