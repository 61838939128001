import { voucherService } from './voucherService';

export default {
  getVoucher,
  saveVoucher,
  resendVoucher,
  getPaymentMethods,
  getPaymentTotal,
  makeInvoicePay,
  authorizeCS,
  tdsEnrol,
  tdsValidate,
  makeOmanDebitPayment,
}

function getVoucher() {
  return () => {
    return voucherService.getVoucher()
  }
}

function saveVoucher(request) {
  return () => {
    return voucherService.saveVoucher(request)
  }
}

function resendVoucher(request) {
  return () => {
    return voucherService.resendVoucher(request)
  }
}

function getPaymentMethods() {
  return () => {
    return voucherService.getPaymentMethods()
  };
}

function getPaymentTotal(methodCode) {
  return () => {
    return voucherService.getPaymentTotal(methodCode)
  };
}

function makeInvoicePay() {
  return () => {
    return voucherService.makeInvoicePay()
  }
}

function authorizeCS(payment) {
  return () => {
    return voucherService.authorizeCS(payment)
  };
}

function tdsEnrol(payment, cardinalEventData) {
  return () => {
    return voucherService.tdsEnrol(payment, cardinalEventData)
  };
}
function tdsValidate(payment) {
  return () => {
    return voucherService.tdsValidate(payment)
  };
}

function makeOmanDebitPayment(request) {
  return () => {
    return voucherService.makeOmanDebitPayment(request)
  }
}