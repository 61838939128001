import React, { Component } from "react";
import { connect } from "react-redux";
import { ls } from "../../../locale";
import { ListGroup, Button , Row , Col, Modal  } from "react-bootstrap";

import { history, numberFormatter , isInAppBrowser, buildSearchParams, webkitHelper, commonHelpers, stringFormatter, getAgentPath } from "../../../helpers";
import { bookingActions } from "../../../state/ducks/booking";
import { mmbActions } from "../../../state/ducks/mmb";
import { wciActions } from "../../../state/ducks/wci";
import { sessionActions } from "../../../state/ducks/session";
import { flightActions } from "../../../state/ducks/flights";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import StatusBar from "../../../components/StatusBar";
import FlightDetail from "./FlightDetail";
import SelectFlightWindow from "./SelectFlightWindow";
import AlertBox from "../../../components/Common/AlertBox";
import Vouchers from './Vouchers';

import { EmailBox } from './EmailBox'
import { ExpressCheckinBar } from "../../../components/ExpressCheckin";

import "./index.scss";
import { agentActions } from "../../../state/ducks/agent";
import { UserType } from "../../../constants/settings";
import Backdrop from "../../../components/Backdrop";
import { isAllowPrintItinerary } from "../../../helpers/userHelper";
import { isFlightDisrupted } from "../../DisruptionPage/helpers";
import { getRefundCharges } from '../helper'

const agent = commonHelpers.checkBrowser();

class SummaryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowSelectFlightWindow: false,
      selectFlightAction: '',
      selectFlightWindowTitle: '',
      selectFlightWindowMessage: '',
      selectFlightWindowNotes: '',
      selectFlightSegments: [],
      autoSelectAllFlight: false,
      message: null,
      isShowMessageBox: false,
      freeServices: null,
      userData: null,

      isShowEmailBox: false,
      defaultEmailAddress: '',
      expressCheckinSegments: [],
    };
  }

  componentDidMount() {

    this.unblockHistory = history.block(() => {

      if(!this.props.booking.modified) return true;

      //console.log('blocking: ', location);
      if(!this.props.loadingStatus.loading) {
        this.showMessageBox("To modify the selection, please click the 'RESET BOOKING' option on the page.", 'Warning');
      }
      return false;
    });

    this.props.getBooking().then(booking => {
      if(isFlightDisrupted(booking)) {
        this.jumpTo('disruption');
        return;
      }
    
      // reset searched bookings
      if(this.props.agentLastSearchedBooking) {
        let lastBooking = this.props.agentLastSearchedBooking;
        if(lastBooking.pnr === booking.pnr) {
          lastBooking = {...lastBooking, bookingStatus: booking.bookingStatus};
          this.props.resetAgentLastSearchedBooking(lastBooking);
        }
      }

      if(!booking.modified) {
        webkitHelper.sendBookingConfirmation(booking);

        // check express check-in
        this.props.checkExpressCheckin().then(response => {
          if(response && Array.isArray(response.segments) && response.segments.length > 0) {
            this.setState({
              expressCheckinSegments: response.segments
            })
          }
        })
      }
    });
    this.props.getBookingSummary();

    const userData = this.props.getUserData();
    this.setState({
      userData
    })
  }

  componentWillUnmount() {
    this.unblockHistory();
  }



  showChangeFlightWindow = () => {

    const rSummaryPage = ls.t('MMB.SummaryPage');
    const rButton = ls.t('Button');

    const booking = this.props.booking;
    const selectFlightSegments = (Array.isArray(booking.segments) && 
      booking.segments.filter(x => x && x.permissions && x.permissions.canChangeFlight)) || [];

    this.setState({
      isShowSelectFlightWindow: true,
      selectFlightAction: 'change',
      selectFlightSegments,
      selectFlightWindowTitle: rButton.ChangeFlight, // 'Change Flight',
      selectFlightWindowMessage: rSummaryPage.Message.ChangeFlight, // 'Please select flights to change.'
      selectFlightWindowNotes: rSummaryPage.Message.ChangeFlightNotes,
    })
  }

  showCancelFlightWindow = () => {

    this.props.checkCancelFlight().then(() => {

      const rSummaryPage = ls.t('MMB.SummaryPage');
      const rButton = ls.t('Button');

      const booking = this.props.booking;
      const selectFlightSegments = (Array.isArray(booking.segments) && 
        booking.segments.filter(x => x && x.permissions && x.permissions.canCancelFlight)) || [];

      const autoSelectAllFlight =  !Array.isArray(booking.payments) || !booking.payments.some(p => p.status === 'approved')

      this.setState({
        isShowSelectFlightWindow: true,
        selectFlightAction: 'cancel',
        selectFlightSegments,
        selectFlightWindowTitle: rButton.CancelFlight, // 'Cancel Flight',
        selectFlightWindowMessage: rSummaryPage.Message.CancelFlight, // 'Please select flights to cancel.'
        selectFlightWindowNotes: '',
        autoSelectAllFlight,
      })
    });
  }

  onSelectFlightWindowClose = selectedFlights => {
    const selectFlightAction = this.state.selectFlightAction;

    this.setState({
      isShowSelectFlightWindow: false,
      selectFlightAction: '',
      selectFlightSegments: [],
      selectFlightWindowTitle: '',
      selectFlightWindowMessage: '',
      selectFlightWindowNotes: '',
      autoSelectAllFlight: false,
    })

    if(Array.isArray(selectedFlights) && selectedFlights.length > 0) {

      const bookingMode = this.props.getBookingMode();
      if(bookingMode.mode !== 'MMB') return;

      const {booking } = this.props
      bookingMode.action = selectFlightAction;
      bookingMode.flights = selectedFlights.map(x => {
        // find correct segment index for multi city PNRs because the segment index in multi-city PNRs is always 0
        let segmentIndex = x.segmentIndex
        if(booking.tripType === 3) {
          const index = booking.segments.findIndex(s => s.originCode === x.originCode && s.destinationCode === x.destinationCode)
          if(index > 0) segmentIndex = index
        }

        return {
          logicalFlightId: x.logicalFlightId, 
          origin: x.originCode,
          destination: x.destinationCode,
          segmentIndex: segmentIndex,
        };
      });

      this.props.setBookingMode(bookingMode);

      if(selectFlightAction === 'cancel') {
        const flights = selectedFlights.map(x => x.logicalFlightId);
        this.props.cancelFlight(flights);
      }
      else if (selectFlightAction === 'change') {
        // build search request
        this.props.getSearchRequest().then(response => {
          const params = buildSearchParams(response);
          if (params) {
            params.lng = ls.lang;
            this.props.setSearchParams(params);

            this.jumpTo("flights");
          }
        });
      }
    }
  }

  continue = () => {
    const { booking } = this.props;

    if (booking.reservationBalance > 0) {
      this.jumpTo("checkout");
      return;
    }
    else if (booking.modified) {
      // confirm changes
      this.props.confirmChanges().then(() => {
        this.props.getBooking();
        this.props.getBookingSummary();
      });
    }
  };

  resetBooking = (nextPage) => {
    this.props.resetBooking().then(() => {
      if(nextPage) {
        this.jumpTo(nextPage);
      }
    });
  };

  resendItinerary = () => {
    const userData = this.state.userData;
    const isAgent = userData && userData.loggedIn && userData.userType === UserType.agent
    if(isAgent) {
      this.showEmailBox()
      return
    }

    this.props.resendItinerary()
    .then(() => {
      const rSummaryPage = ls.t('MMB.SummaryPage');
      this.showMessageBox(rSummaryPage.Message.SendItinerary);
    })
  }

  printItinerary = () => {
    this.props.printItinerary()
    .then(response => {
      commonHelpers.createDownloadTag(response, 'ticket.pdf');
    })
  }

  printItineraryWithoutPrice = () => {
    this.props.printItineraryWithoutPrice()
    .then(response => {
      commonHelpers.createDownloadTag(response, 'ticket-without-price.pdf');
    })
  }

  getPKPassFile = () => {
    this.props.getPKPassFile()
    .then(blob => {
      //console.log('ticket loaded.');
      commonHelpers.createDownloadTag(blob, 'salamair.pkpass', 'application/vnd.apple.pkpass');
    })
  }

  jumpTo = page => {
    if (!page) return;

    this.unblockHistory();

    const nextPage = "/" + page;
    history.push("/" + ls.lang + getAgentPath() + nextPage);
  };

  closeMessageBox = () => {
    this.setState({
      isShowMessageBox: false,
      message: null,
    });
  };

  showMessageBox = (message) => {
    this.setState({
      isShowMessageBox: true,
      message: message,
    });
  };

  showEmailBox = () => {

    const booking = this.props.booking
    if(!booking || !booking.contact || !booking.contact.email) return

    this.setState({
      isShowEmailBox: true,
      defaultEmailAddress: booking.contact.email
    })
  }

  hideEmailBox = (email) => {
    this.setState({
      isShowEmailBox: false,
      defaultEmailAddress: ''
    })

    if(!email) return

    this.props.resendItinerary(email)
    .then(() => {
      const rSummaryPage = ls.t('MMB.SummaryPage');
      this.showMessageBox(rSummaryPage.Message.SendItinerary);
    })

  }

  expressCheckin = logicalFlightId => {
    const booking = this.props.booking
    if(!Array.isArray(booking.segments) || !booking.segments.some(x => x.logicalFlightId === logicalFlightId)) return;
    if(!Array.isArray(booking.passengers) || !booking.passengers.length) return;

    const segment = {
      logicalFlightId,
    }

    this.props.expressCheckin([segment]).then(() => {
      this.props.setBookingMode({mode: "WCI", expressCheckin: 'MMB', segments: [segment]})
      this.jumpTo('wci/passenger')
    })

  }

  render() {

    const { booking, bookingSummary } = this.props;

    const rRetrievePage = ls.t('RetrievePage');
    if(!rRetrievePage.MMB) return null;

    const rCommon = ls.t('Common');
    const rSummaryPage = ls.t('MMB.SummaryPage');
    const rButton = ls.t('Button');
    const rMessageBox = ls.t('MessageBox');
    const rBookingStatus = ls.t('BookingStatus');
    const rConfirmationPage = ls.t('ConfirmationPage');
    
    const inAppBrowser = isInAppBrowser();

    let bookingStatus = booking.modified ? "Pending" : booking.bookingStatus; 
    if(rBookingStatus[bookingStatus]) bookingStatus = rBookingStatus[bookingStatus];

    const holdBooking = stringFormatter.formatHoldTime(booking, rConfirmationPage.HoldTime);
    const freeServices = checkFreeServices(booking);
    const additionalMessages = booking.additionalMessages || [];

    const bookingCancelled = booking.paymentAmount < 0

    const buttonText = booking.reservationBalance > 0 ? rButton.PayNow : rButton.ConfirmChanges;
    const amountText = bookingCancelled ? rSummaryPage.TotalRefund : rSummaryPage.TotalAmount;
    
    const paymentAmount = booking.paymentAmount < 0 ? booking.paymentAmount : booking.reservationBalance;
    const amount = numberFormatter.decimalSplitter(numberFormatter.formatCurrency(paymentAmount));
    const amountWhole = amount.amount;
    const amountDecimal = numberFormatter.oneDecimalPoint(amount.decimal, booking.currencyCode);

    const passengers = booking.passengers || [];

    const canCancelFlight = Array.isArray(booking.segments) && booking.segments.some(x => x && x.permissions && x.permissions.canCancelFlight);
    const canChangeFlight = Array.isArray(booking.segments) && booking.segments.some(x => x && x.permissions && x.permissions.canChangeFlight);
    const canChangeExtras = Array.isArray(booking.segments) && booking.segments.some(x => x && x.permissions && x.permissions.canChangeExtras);
    const canChangeSeats  = Array.isArray(booking.segments) && booking.segments.some(x => x && x.permissions && x.permissions.canChangeSeats);
    const canChangeContact = Array.isArray(booking.segments) && booking.segments.some(x => x && x.permissions && x.permissions.canChangeContact);

    const userData = this.state.userData;
    const isCorpCustomer = userData && userData.loggedIn && userData.isCorpCustomer;
    const isAgent = userData && userData.loggedIn && userData.userType === UserType.agent;

    const isAllowItinerary = isAllowPrintItinerary(userData);

    let refundMessage = null;
    let refundCharge = {};
    // refund info only show on the customer and member portal, not on the agent portal and staff portal
    if(booking.reservationBalance < 0) {
      refundCharge = getRefundCharges(booking, bookingSummary, rSummaryPage);
      if(refundCharge.totalCharges) {
        refundMessage = Array.isArray(booking.additionalMessages) && booking.additionalMessages[0];
      }
    }

    return (
      <div
        className={ls.t("rootClass") + " main-container"}
        dir={ls.t("direction")}
      >
        <div className="header-con">
          <Header hasSummary={true} beforeChangeLocation={() => this.unblockHistory()} />
        </div>
        <Backdrop />
        { !inAppBrowser && <StatusBar path={this.props.match} /> }
        <div className="container confirmation padt20 padb40">
          <div className="min-height">
            <div className="booking-reference">
              <p>{rRetrievePage.BookingReference}</p>
              <h2>{booking.pnr}</h2>
            </div>
            <h1>{rRetrievePage.MMB.Label}</h1>           

            <p className="booking-status">
              {rCommon.BookingStatus} : <span>{bookingStatus}</span>
            </p>
            <Vouchers vouchers={booking.vouchers} />
            <AlertBox key={booking.pnr + '-pending'} show={additionalMessages.length > 0 && !booking.cancelledSegments?.length > 0}>
                {additionalMessages.map((line, index) => <p key={index}>{line}</p>)}
            </AlertBox>
            <AlertBox key={booking.pnr + '-hold'} show={!!holdBooking} closeButton>
                {holdBooking}
            </AlertBox>
            <AlertBox key={booking.pnr + '-free-service'} show={!!freeServices} closeButton>
              {freeServices}
            </AlertBox>
            <ListGroup>
              {booking.segments && booking.segments.map((segment, index) => segment && (
                <React.Fragment key={segment.logicalFlightId}>
                  <FlightDetail
                    key={index}
                    passengers={passengers}
                    segment={segment}
                  />
                  {this.state.expressCheckinSegments.some(x => x.logicalFlightId === segment.logicalFlightId) && 
                    <ExpressCheckinBar onClick={() => this.expressCheckin(segment.logicalFlightId)} />
                  }
                </React.Fragment>
              ))}
            </ListGroup>

            {(booking.reservationBalance !== 0 || booking.modified) && <div className="payment-section">
              {refundCharge?.totalCharges && <Row>
                <Col md={8} xs={12}>
                  <ChargeDetails key="total-charges" {...refundCharge.totalCharges} currencyCode={booking.currencyCode} />
                  <ChargeDetails key="cancellation-fee" {...refundCharge.cancellationFee} currencyCode={booking.currencyCode} />
                </Col>
              </Row>}
              <Row>
                <Col md={8} xs={12}>
                  <ChargeDetails key="total-price" text={amountText} amount={amountWhole} decimal={amountDecimal} currencyCode={booking.currencyCode} />
                {/* <p className="details">{amountText} 
                  <span className={ls.t('rootClass')}>
                    <label>{booking.currencyCode}</label>
                    { amountWhole }{amountDecimal && amountDecimal !== "0" && <label>{amountDecimal}</label>}
                  </span>
                </p> */}
                </Col>
                <Col md={4} xs={12}>
                  <Button type="submit" className="btn btn-primary pull-right flip btn-submit btn-effect btn btn-default" onClick={() => this.continue()}>
                    <span>{buttonText}<i className="picon picon-btn-arrow-right-w"/></span>
                  </Button>
                </Col>
              </Row>
            </div>}
            {refundMessage && <div className="refund-message">{refundMessage}</div> }

            <div className="clearfix summary-btns">
              { canChangeFlight && !booking.modified && <Button className="btn btn-primary btn-effect" onClick={() => this.showChangeFlightWindow()}>
                <span>{rButton.ChangeFlight}</span>
              </Button> }
              { canCancelFlight && !booking.modified && <Button className="btn btn-primary btn-effect" onClick={() => this.showCancelFlightWindow()}>
                <span>{rButton.CancelFlight}</span>
              </Button> }
              { canChangeExtras && !booking.flightChanged && <Button className="btn btn-primary btn-effect" onClick={() => this.jumpTo("extra")}>
                <span>{rButton.ModifyExtra}</span>
              </Button>}
              { canChangeSeats && !booking.flightChanged && <Button className="btn btn-primary btn-effect" onClick={() => this.jumpTo("seats")}>
                <span>{rButton.SelectSeat}</span>
              </Button>}
              { !isCorpCustomer && canChangeContact && !booking.flightChanged && <Button className="btn btn-primary btn-effect" 
                  onClick={() => this.jumpTo("mmb/contact")}>
                  <span>{rButton.ChangeContact}</span>
              </Button>}
              { booking.modified && <Button className="btn btn-primary btn-effect" onClick={() => this.resetBooking()} >
                  <span>{rButton.ResetBooking}</span>
                </Button>
              }
              { !booking.modified && booking.bookingStatus !== 'Cancelled' && <Button className="btn btn-primary btn-effect" onClick={() => this.resendItinerary()}>
                <span>{rButton.ResendItinerary}</span>
              </Button> }
              { !booking.modified && booking.bookingStatus !== 'Cancelled' && <Button className="btn btn-primary btn-effect" onClick={() => this.printItinerary()}>
                <span>{rButton.PrintItinerary}</span>
              </Button> }
              { isAgent && isAllowItinerary && !booking.modified && booking.bookingStatus !== 'Cancelled' && <Button 
                className="btn btn-primary btn-effect" onClick={() => this.printItineraryWithoutPrice()}>
                <span>{rButton.PrintItineraryWithoutPrice}</span>
              </Button> }
            </div>
            <div className="clearfix text-right">
            { agent.safariAgent && !booking.modified && booking.bookingStatus !== 'Cancelled' && <div 
              className="clickable btn-apple-wallet" onClick={this.getPKPassFile}>
                <img width="150" src="/images/email/apple-wallet-2x.png" alt="Add to Apple Wallet" />
              </div> }
            </div>
          </div>
        </div>
        { !inAppBrowser && <Footer /> }
        <SelectFlightWindow
          segments={this.state.selectFlightSegments}
          isShow={this.state.isShowSelectFlightWindow}
          title={this.state.selectFlightWindowTitle}
          message={this.state.selectFlightWindowMessage}
          notes={this.state.selectFlightWindowNotes}
          onClose={selectedFlights => this.onSelectFlightWindowClose(selectedFlights)}
          autoSelectAllFlight={this.state.autoSelectAllFlight}
        />

        <Modal className={ls.t("rootClass")} show={this.state.isShowMessageBox} onHide={this.closeMessageBox}>
          <Modal.Header closeButton>
            <Modal.Title>{rMessageBox.Title.Notification}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{this.state.message}</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeMessageBox}>{rButton.Close}</Button>
          </Modal.Footer>
        </Modal>

        <EmailBox key={booking.pnr} show={this.state.isShowEmailBox} defaultEmail={this.state.defaultEmailAddress} onHide={this.hideEmailBox} />

      </div>
    );
  }
}

function ChargeDetails({text, amount, decimal, currencyCode}) {
  return (
    <p className="details">{text} 
      <span className={ls.t('rootClass')}>
        <label>{currencyCode}</label>
        { amount }{decimal && decimal !== "0" && <label>{decimal}</label>}
      </span>
    </p>
  )
}




function checkFreeServices(booking) {
  if(!booking || booking.flightChanged || !Array.isArray(booking.segments) || booking.segments.length === 0 ) return null;

  // 'You are eligible for free meals/seats on Benefit fare for {0}. Please click the buttons below to choose.'
  const rFreeServices = ls.t('MMB.SummaryPage.FreeServices'); 
  const flights = [];
  booking.segments.forEach(segment => {
    if(segment.hasFreeMeal || segment.hasFreeSeat) {
      flights.push(`${segment.originCity} - ${segment.destinationCity}`);
      return;
    }
  });

  if(flights.length > 0) {
    return stringFormatter.formatString(rFreeServices, flights.join(' and '));
  }
  return null;
}

function mapStateToProps(state) {
  return {
    booking: state.booking.details,
    bookingSummary: state.booking.summary,
    loadingStatus: state.redux.loadingStatus,
    agentLastSearchedBooking: state.agent.lastSearchedBooking,
  };
}


const mapDispatchToProps = {
  getBooking: bookingActions.getBooking,
  getBookingSummary: bookingActions.getBookingSummary,
  printItinerary: bookingActions.printItinerary,
  printItineraryWithoutPrice: bookingActions.printItineraryWithoutPrice,
  getPKPassFile: bookingActions.getPKPassFile,
  getSearchRequest: flightActions.getSearchRequest,
  resetAgentLastSearchedBooking: agentActions.resetLastSearchedBooking,
  ...mmbActions,
  ...sessionActions,
  checkExpressCheckin: wciActions.checkExpressCheckin,
  expressCheckin: wciActions.expressCheckin,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryPage);
