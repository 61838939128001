import React from 'react'
import { ls } from "../../../locale";

import { FareDetails } from './FareDetails'
import { FarePrice, BaseFarePrice } from './FarePrice'

export function FareItem({fare, logicalFlightId, departureDate, selectedFlight, bookIndex, isShowExtra, hasLounge, currencyCode, selectFlight}) {
  
  const { fareWithTaxes, fareSellKey, baseFareWithTaxes } = fare.fareInfos[0];
  const fareSelected = checkSelected(selectedFlight, fare.fareTypeName, logicalFlightId, departureDate);
  const selectedFare = {
    logicalFlightId: logicalFlightId,
    departureDate: departureDate,
    fare: fare.fareTypeName
  };

  let className = "faresCon";
  if (fareSelected) className += " selected";
  else if (fare.available) className += " faresConHover"

  const rCommon = ls.t('Common')
  const hasFreeLounge = hasLounge && fare.fareTypeName === 'Luban'

  const handleFareItemClick = e => {
    e.stopPropagation(); 
    if (fare.available && !fareSelected) selectFlight(selectedFare, fareSellKey, bookIndex) 
  }

  return (
    <div className={className}
      key={fare.fareTypeName} onClick={handleFareItemClick}>
      <FareDetails fareTypeName={fare.fareTypeName} 
        isShowExtra={isShowExtra} 
        hasFreeLounge={hasFreeLounge} 
        logicalFlightId={logicalFlightId} 
        promotions={fare.promotions} />
      <div className='tw-px-1'>
        <FarePrice isFree={fare.isFree} currencyCode={currencyCode} amount={fareWithTaxes} />
        { baseFareWithTaxes > fareWithTaxes && <BaseFarePrice currencyCode={currencyCode} amount={baseFareWithTaxes} /> }
      </div>
      { fareSelected || fare.available 
        ? <div className="radioCon"><span className="radio" /></div> 
        : <div className="textCon">{rCommon.NotAvailable}</div>
      }
    </div>
  );
}

function checkSelected (selectedFlight, fare, logicalFlightId, departureDate) {
  return selectedFlight
      && selectedFlight.fare === fare
      && selectedFlight.logicalFlightId === logicalFlightId
      && selectedFlight.departureDate === departureDate;
}
