import { Promise } from "core-js";
import { logEvent, store } from "../../helpers";
import { alertActions } from "../../actions";
import { loadingActions } from "../../actions";
import { sessionService } from "./session/sessionService";

export const api = {
  getFetch,
  postFetch,
  post,
  queryParams,
  getSession,
};

function getFetch(link, params, errorHandler) {

  const token = sessionService.sessionFromStore();
  const culture = sessionService.getCulture();

  if (!token) return Promise.reject('No token');

  let url = "";
  let buildParams = "";
  if (typeof params !== "undefined") {
    buildParams = queryParams(params);
    url = process.env.REACT_APP_API_HOST + link + "?" + buildParams;
  } else {
    url = process.env.REACT_APP_API_HOST + link;
  }

  store.dispatch(loadingActions.startloading());

  return fetch(url, {
    method: "GET",
    headers: getFetchHeaders(token, culture),
    cache: "no-store"
  })
    .then(response => {

      store.dispatch(loadingActions.completeLoading());

      if (response.ok) {
        let data = null;
        const contentType = response.headers.get('Content-Type');

        if (contentType && contentType.indexOf('application/json') > -1) {
          data = response.json();
        }
        else if (contentType && contentType.indexOf('application/pdf') > -1) {
          data = response.blob()
        }
        else {
          data = response.text();
        }
        return data;
      }

      if(response.status === 503) {
        const errors = [{
          errorCode: 503,
          message: "Site is under maintenance. Please try again later."
        }]
        return Promise.reject(errors)
      }
      
      if (typeof (errorHandler) === "function")
        return errorHandler(response);

      if (response.status === 403 || response.status === 401) {
        const errors = [{
          errorCode: 403,
          message: 'Your session has expired.'
        }]          
        return Promise.reject(errors)
      } 
      else if(response.status === 404) {
        const errors = [{
          errorCode: 404,
          message: "The requested resource does not exist."
        }]

        logEvent({
          trackId: 'notracking',
          eventName: 'ajax error',
          details: {
            request: `GET ${link}`,
            params,
            errors
          }
        })
        return Promise.reject(errors)
      }

      return response.json().then(errors => {
        if (!Array.isArray(errors)) {
          errors = getResponseStatusError(response);
        }
        return Promise.reject(errors);
      })
      .catch(error => {
        const errors = Array.isArray(error) ? error : [{
          errorCode: 500,
          message: error.message || error.TypeError
        }];
        return Promise.reject(errors);
      })
    })
    .catch(error => {
      if (Array.isArray(error)) {
        store.dispatch(alertActions.error(error))
        return Promise.reject(error);
      }
      store.dispatch(loadingActions.completeLoading());
      if (error) {
        const errors = [{
          errorCode: 500,
          message: error.TypeError || 'Internal Server Error'
        }];
        store.dispatch(alertActions.error(errors));

        logEvent({
          trackId: 'notracking',
          eventName: 'ajax error',
          details: {
            request: `GET ${link}`,
            params,
            errors
          }
        })
      }
      return Promise.reject();
    });
}

function postFetch(link, params, errorHandler) {

  const token = sessionService.sessionFromStore();
  const culture = sessionService.getCulture();

  if (!token) return Promise.reject('No token');

  store.dispatch(loadingActions.startloading());

  return fetch(process.env.REACT_APP_API_HOST + link, {
    method: "POST",
    headers: getFetchHeaders(token, culture),
    body: JSON.stringify(params)
  })
    .then(response => {
      store.dispatch(loadingActions.completeLoading());
      if (response.ok) {

        let data = null;
        const contentType = response.headers.get('Content-Type');

        if (contentType && contentType.indexOf('application/json') > -1) {
          data = response.json();
        }
        else if (contentType && contentType.indexOf('application/pdf') > -1) {
          data = response.blob()
        }
        else if (contentType && contentType.indexOf('application/vnd.apple.pkpass') > -1) {
          data = response.blob()
        }
        else {
          data = response.text();
        }
        return data;
      }
    
      if(response.status === 503) {
        const errors = [{
          errorCode: 503,
          message: "Site is under maintenance. Please try again later."
        }]
        return Promise.reject(errors)
      }

      // error happened
      if (typeof (errorHandler) === "function")
        return errorHandler(response);

      if (response.status === 403 || response.status === 401) {
        const errors = [{
          errorCode: 403,
          message: 'Your session has expired.'
        }]
        return Promise.reject(errors)
      }
      else if(response.status === 404) {
        const errors = [{
          errorCode: 404,
          message: "The requested resource does not exist."
        }]

        logEvent({
          trackId: 'notracking',
          eventName: 'ajax error',
          details: {
            request: `POST ${link}`,
            params,
            errors
          }
        })
        return Promise.reject(errors)
      }

      return response.json().then(errors => {
        if (!Array.isArray(errors)) {
          errors = getResponseStatusError(response);
        }
        return Promise.reject(errors);
      })
      .catch(error => {
        const errors = Array.isArray(error) ? error :  [{
          errorCode: 500,
          message: error.TypeError
        }];
        return Promise.reject(errors);
      })
      
    })
    .catch(error => {
      if (Array.isArray(error)) {
        store.dispatch(alertActions.error(error));
        return Promise.reject(error);
      }

      store.dispatch(loadingActions.completeLoading());
      if (error) {
        const errors = [{
          errorCode: 500,
          message: error.message || error.TypeError 
        }];
        store.dispatch(alertActions.error(errors));

        logEvent({
          trackId: 'notracking',
          eventName: 'ajax error',
          details: {
            request: `POST ${link}`,
            params,
            errors
          }
        })
      }
      return Promise.reject();
    });
}

// post without loading status
function post(url, params) {

  const token = sessionService.sessionFromStore();
  const culture = sessionService.getCulture();

  if (!token) return Promise.reject('No token');

  return fetch(process.env.REACT_APP_API_HOST + url, {
    method: "POST",
    headers: getFetchHeaders(token, culture),
    body: JSON.stringify(params)
  })
    .then(response => {
      if (response.ok) {

        let data = null;
        const contentType = response.headers.get('Content-Type');

        if (contentType && contentType.indexOf('application/json') > -1) {
          data = response.json();
        }
        else if (contentType && contentType.indexOf('application/pdf') > -1) {
          data = response.blob()
        }
        else if (contentType && contentType.indexOf('application/vnd.apple.pkpass') > -1) {
          data = response.blob()
        }
        else {
          data = response.text();
        }
        return data;
      }
      else {
        if(response.status === 503) {
          const errors = [{
            errorCode: 503,
            message: "Site is under maintenance. Please try again later."
          }]
          return Promise.reject(errors)
        }

        if (response.status === 403 || response.status === 401) {
          const errors = [{
            errorCode: 403,
            message: 'Your session has expired.'
          }]
          return Promise.reject(errors)
        } 

        return response.json().then(errors => {
          if (!Array.isArray(errors)) {
            errors = getResponseStatusError(response);
          }
          return Promise.reject(errors);
        })
      }
    })
    .catch(error => {
      if (Array.isArray(error)) return Promise.reject(error);
      if (error) {
        error = [{
          errorCode: 500,
          message: error.TypeError
        }];
      }
      return Promise.reject(error);
    });
}

async function getSession(link, errorMsg) {

  store.dispatch(loadingActions.startloading());
  
  const url = process.env.REACT_APP_API_HOST + link;
  const response = await  fetch(url, { method: "POST" });

  store.dispatch(loadingActions.completeLoading());

  if (response.ok) {
    const token = response.headers.get("X-Session-Token");
    const version = response.headers.get("X-Api-Version");
    return { token, version };
  }

  //if fetch error, error modal pop up.
  response.errorMsg = errorMsg ? errorMsg : null;
  store.dispatch(alertActions.error(response));

  return {};
  // stop promise if error
  //return Promise.reject("failed to get session");      
}

function queryParams(params) {
  return Object.keys(params)
    .map(k => {
      if (Array.isArray(params[k])) {
        return params[k]
          .map(val => encodeURIComponent(k) + "=" + encodeURIComponent(val))
          .join("&");
      }
      return encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
    })
    .join("&");
}

function getResponseStatusError(response) {
  if (!response || !response.status) {
    return [{
      errorCode: "unknow",
      message: "Unknow error"
    }];
  }
  else {
    return [{
      errorCode: response.status,
      message: response.statusCode
    }];
  }
}

function getFetchHeaders(token, culture) {
  return {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "X-Session-Token": token,
    "Culture": culture,
    "cache-control": "no-store"
  }
}