import React from 'react'

import { Route, Switch } from "react-router-dom";
import { withAuthentication } from "../../helpers";

// Booking
import SearchPage from "../SearchPage";
import FlightsPage from "../FlightsPage";
import ExtraPage from "../ExtraPage";
import SeatsPage from "../SeatsPage";
import CheckoutPage from "../CheckoutPage";
import PaymentPage from "../PaymentPage";
import TdsPaymentPage from "../PaymentPage/tdsPayment";
import ConfirmationPage from "../ConfirmationPage";
import NotFoundPage from "../NotFoundPage";
import DeepLinkRedirectPage from "../DeepLinkRedirectPage";


// WebCheckIn

import CheckInPage from "../WCI";
import WciFlightPage from "../WCI/FlightPage";
import WciPassengerPage from "../WCI/PassengerPage";
import WciSeatsPage from "../WCI/SeatsPage";
import WciConfirmtionPage from "../WCI/ConfirmationPage";

// MMB
import ManagePage from "../MMB";
import MmbSummaryPage from "../MMB/SummaryPage";
import MmbContactPage from "../MMB/ContactPage";

// Flight Disruption
import DisruptionPage from "../DisruptionPage";

// Agent
import AgentLoginPage from "../Agent/Login";
import AgentPortalPage from "../Agent/Portal";
import ReportPage from "../Agent/Report";
import AgentManagementPage from "../Agent/Management";
import AgentTopupPage from '../Agent/Topup';
import AgentPassword from "../Agent/Password";
import HelpPage from "../Agent/Help";

// Member
import MemberPortalPage from "../Member/Portal";
import MemberProfilePage from "../Member/Profile";
import MemberActivatePage from "../Member/Activate";
import SigninPage from '../Member/Signin'

// Others
import FeedbackPage from "../FeedbackPage"

import PaymentErrorPage from "../ErrorPage/PaymentError";
import ServiceErrorPage from "../ErrorPage/ServiceError";

import RedirectComponent from "../RedirectComponent";


import PkPassPage from '../PkPassPage'
import ItineraryPkPassPage from '../ItineraryPkPassPage'

// voucher
import PurchasePage from '../Voucher/PurchasePage'
import VoucherConfirmationPage from '../Voucher/ConfirmationPage'
import VoucherRetrievePage from '../Voucher/RetrievePage'


export const routes = (
  <Switch>
    <Route exact path="/:lng(en|ar)?" component={RedirectComponent} />
    <Route path="/:lng(en|ar)?/search" component={SearchPage} />
    <Route path="/:lng(en|ar)?/flights" component={FlightsPage} />
    <Route path="/:lng(en|ar)?/extra" component={ExtraPage} />
    <Route path="/:lng(en|ar)?/seats" component={SeatsPage}/>
    <Route path="/:lng(en|ar)?/checkout" component={CheckoutPage}/>
    <Route path="/:lng(en|ar)?/payment" component={PaymentPage} />
    <Route path="/:lng(en|ar)?/tdsPayment" component={TdsPaymentPage} />
    <Route path="/:lng(en|ar)?/confirmation" component={ConfirmationPage}/>

    {/* Web Check In */}
    <Route exact path="/:lng(en|ar)?/wci" component={CheckInPage} />
    <Route path="/:lng(en|ar)?/wci/flight" component={WciFlightPage}/>
    <Route path="/:lng(en|ar)?/wci/passenger" component={WciPassengerPage}/>
    <Route path="/:lng(en|ar)?/wci/seats" component={WciSeatsPage}/>
    <Route path="/:lng(en|ar)?/wci/confirmation" component={WciConfirmtionPage}/>

    {/* Manage My Booking  */}
    <Route exact path="/:lng(en|ar)?/mmb" component={ManagePage} />
    <Route path="/:lng(en|ar)?/mmb/summary" component={MmbSummaryPage} />
    <Route path="/:lng(en|ar)?/mmb/contact" component={MmbContactPage} />
    

    {/* Flight Disruption */}
    <Route path="/:lng(en|ar)?/disruption" component={DisruptionPage} />

    {/* Travel Agent */}
    <Route exact path="/:lng(en)?/agent" component={AgentLoginPage} />
    <Route path="/:lng(en)?/agent/login" component={AgentLoginPage} />
    <Route path="/:lng(en)?/agent/portal" component={withAuthentication(AgentPortalPage, 'agent')} />
    <Route path="/:lng(en)?/agent/report" component={ withAuthentication(ReportPage, 'agent')} />
    <Route path="/:lng(en)?/agent/manage" component={withAuthentication(AgentManagementPage, 'agent')} />
    <Route path="/:lng(en)?/agent/topup" component={withAuthentication(AgentTopupPage, 'agent')} />
    <Route path="/:lng(en)?/agent/password" component={withAuthentication(AgentPassword, 'agent')} />
    <Route path="/:lng(en)?/agent/help" component={withAuthentication(HelpPage, 'agent')} />

    {/* booking process for agent */}
    <Route path="/:lng(en)?/agent/search" component={SearchPage} />
    <Route path="/:lng(en)?/agent/flights" component={FlightsPage} />
    <Route path="/:lng(en)?/agent/extra" component={ExtraPage} />
    <Route path="/:lng(en)?/agent/seats" component={SeatsPage}/>
    <Route path="/:lng(en)?/agent/checkout" component={CheckoutPage}/>
    <Route path="/:lng(en)?/agent/confirmation" component={ConfirmationPage}/>

    {/* wci for agent */}
    <Route exact path="/:lng(en)?/agent/wci" component={CheckInPage} />
    <Route path="/:lng(en)?/agent/wci/flight" component={WciFlightPage}/>
    <Route path="/:lng(en)?/agent/wci/passenger" component={WciPassengerPage}/>
    <Route path="/:lng(en)?/agent/wci/seats" component={WciSeatsPage}/>
    <Route path="/:lng(en)?/agent/wci/confirmation" component={WciConfirmtionPage}/>

    {/* mmb for agent */}
    <Route exact path="/:lng(en)?/agent/mmb" component={ManagePage} />
    <Route path="/:lng(en)?/agent/mmb/summary" component={MmbSummaryPage} />
    <Route path="/:lng(en)?/agent/mmb/contact" component={MmbContactPage} />

    {/* flight disruption for agent */}
    <Route path="/:lng(en)?/agent/disruption" component={DisruptionPage} />

    {/* My Portal*/}
    <Route exact path="/:lng(en)?/member" component={SigninPage}/>
    <Route path="/:lng(en)?/member/login" component={SigninPage}/>
    <Route path="/:lng(en)?/member/portal" component={withAuthentication(MemberPortalPage, 'member')}/>
    <Route path="/:lng(en)?/member/profile" component={withAuthentication(MemberProfilePage, 'member')}/>
    <Route path="/:lng(en)?/member/activate" component={MemberActivatePage} />

    <Route exact path="/:lng(en|ar)?/voucher" component={PurchasePage} />
    <Route path="/:lng(en|ar)?/voucher/confirmation" component={VoucherConfirmationPage} />
    <Route path="/:lng(en|ar)?/voucher/retrieve" component={VoucherRetrievePage} />


    <Route path="/feedback" component={FeedbackPage} />
    <Route path="/pkpass" component={PkPassPage} />
    <Route path="/itinerary" component={ItineraryPkPassPage} />

    <Route path="/:lng(en|ar)?/payment-error" component={PaymentErrorPage} />
    <Route path="/:lng(en|ar)?/service-error" component={ServiceErrorPage} />
    <Route path="/index.aspx" component={DeepLinkRedirectPage} />
    <Route component={NotFoundPage} />
  </Switch>
);