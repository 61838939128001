import React, { forwardRef, useEffect, useReducer, useCallback, useImperativeHandle } from 'react'
import {
  Col,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";

import CountrySelect from "../../components/Common/CountrySelect";
import DialingCodeSelect from "../../components/Common/DialingCodeSelect";

import { customerValidator, splitPhoneNumber, getDialingOptionByDialingCode } from "../../helpers";
import { ls } from "../../locale";
import FormErrorBlock from '../../components/FormErrorBlock';

import * as form from '../../reducers/formReducer';

export default forwardRef(function Contact(props, ref) {

  const [state, dispatch] = useReducer(form.reducer, form.initialState);

  // set init state
  useEffect(() => {
    const contact = getContactFromProps(props);
    dispatch(form.setValues(contact));
  }, [props.contact])

  const validateStateValues = useCallback(contact => {
    const errors = getErrors(contact, props.hideCountry);
    dispatch(form.setErrors(errors));
    return !form.hasError(errors);
  }, [props.hideCountry]);

  useImperativeHandle(ref, () => {
    return {
      validateForm: () => validateStateValues(state.values),
      getFormValues: () => getValues(state.values)
    }
  }, [state.values])

  const setContactState = useCallback((field, value) => {
    dispatch(form.setInput(field, value));

    if (field === 'email') {
      dispatch(form.setInput('confirmEmail', ''));
    }
    if (typeof (props.onStateChanged) === 'function') props.onStateChanged(state.values);
  }, [state.values, props.onStateChanged]);

  const setContactErrorState = useCallback((field, value) => {
    dispatch(form.setError(field, value));
  }, []);

  // render() 

  if (!state.values) return null;

  const rCheckoutPage = ls.t('CheckoutPage');
  if (!rCheckoutPage.BookingContact) return null;
  const rPax = ls.t('Pax');

  const contact = state.values;
  const errors = state.errors;
  const { countries, hideCountry } = props;

  let states = [];
  let selectedState = contact.state || '';
  const selectedCountry = countries.find(x => x.countryCode === contact.country.value);
  if (selectedCountry && Array.isArray(selectedCountry.states)) {
    states = selectedCountry.states;

    if (selectedState) {
      selectedState = states.find(x => x.toUpperCase() === selectedState.toUpperCase()) || '';
    }
  }

  const autoCompleteSeed = new Date().getMilliseconds();

  const defaultCountryOption = {
    value: '',
    label: rPax.Residence
  }

  return (
    <React.Fragment>
      <h1>{rCheckoutPage.BookingContact}</h1>

      <div className="booking-contact">
        <Row>
          <Col md={4}>
            <FormGroup className={errors.email ? "has-error" : null}>
              <FormControl disabled={contact.contactLocked}
                type="email"
                placeholder={rPax.Email + ' *'}
                value={contact.email || ""}
                autoComplete={autoCompleteSeed}
                onChange={e => setContactState("email", e.target.value)}
                onFocus={e => e.target.select()}
                readOnly={props.readOnly}
              />
              {/*<span className="mandatory">*</span>*/}
              <FormErrorBlock error={errors.email} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className={errors.confirmEmail ? "has-error" : null}>
              <FormControl disabled={contact.contactLocked}
                type="email"
                placeholder={rPax.ConfirmEmail + ' *'}
                value={contact.confirmEmail || ""}
                autoComplete={autoCompleteSeed}
                onChange={e => setContactState("confirmEmail", e.target.value)}
                onPaste={e => e.preventDefault()}
                onFocus={e => e.target.select()}
                readOnly={props.readOnly}
              />
              {/*<span className="mandatory">*</span>*/}
              <FormErrorBlock error={errors.confirmEmail} />
            </FormGroup>
          </Col>

          <Col md={4}>
            <Row className="phone-dropdown mobile-number">
              <Col md={4} xs={4}>
                <DialingCodeSelect
                  className="dialingcode-select"
                  selected={contact.mobileCountry}
                  options={countries}
                  onSelected={selected => setContactState("mobileCountry", selected)}
                  readOnly={props.readOnly} />
              </Col>
              <Col md={8} xs={8}>
                <FormGroup className={errors.mobile ? "has-error no-margin-bottom" : "no-margin-bottom"}>
                  <FormControl
                    type="telephone"
                    placeholder={rPax.Mobile + ' *'}
                    value={contact.mobile || ""}
                    autoComplete={autoCompleteSeed}
                    onChange={e => setContactState("mobile", e.target.value)}
                    onFocus={e => e.target.select()}
                    readOnly={props.readOnly}
                  />
                  {/*<span className="mandatory">*</span>*/}
                </FormGroup>
              </Col>
            </Row>
            <FormErrorBlock error={errors.mobile} />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Row className="phone-dropdown">
              <Col md={4} xs={4}>
                <DialingCodeSelect
                  className="dialingcode-select"
                  selected={contact.phoneCountry}
                  options={countries}
                  onSelected={selected => setContactState("phoneCountry", selected)}
                  readOnly={props.readOnly} />
              </Col>
              <Col md={8} xs={8}>
                <FormGroup className={errors.phone ? "has-error no-marging-bottom" : "no-marging-bottom"}>
                  <FormControl
                    type="telephone"
                    placeholder={rPax.Phone}
                    value={contact.phone || ""}
                    autoComplete={autoCompleteSeed}
                    onChange={event => setContactState("phone", event.target.value)}
                    readOnly={props.readOnly}
                    onFocus={e => e.target.select()}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormErrorBlock error={errors.phone} />
          </Col>
          {!hideCountry && <Col md={4}>
            <div className="country-select">
              <CountrySelect
                className="country-control"
                options={countries}
                selected={contact.country}
                onSelected={(selected) => setContactState("country", selected)}
                readOnly={props.readOnly}
                defaultCountryOption={defaultCountryOption}
                onMenuOpen={() => setContactErrorState('country', null)} />
            </div>
            <FormErrorBlock error={errors.country} />
          </Col>}
        </Row>
      </div>

    </React.Fragment>
  )
})


function getContactFromProps(props) {
  const contact = props.contact ? { ...props.contact } : {};
  const employeeInfo = props.employeeInfo;
  const employee = Array.isArray(employeeInfo) && employeeInfo.length > 0 ? employeeInfo[0] : {};

  const address = contact.address || {};
  if (address && address.country) {
    contact.country = {
      value: address.countryCode,
      label: address.country
    }
  }
  else {
    const rPax = ls.t('Pax');
    contact.country = { value: "", label: rPax.Residence };
  }

  contact.street = address.address1 || "";
  contact.city = address.city || "";
  contact.postalCode = address.postalCode || "";
  contact.poBox = address.poBox || "";
  contact.state = address.state || "";

  let email = contact.email;
  if (employee.email) email = employee.email;
  if (email) {
    contact.email = email.trim();
    contact.confirmEmail = contact.email;
  }

  const phoneCountry = {
    label: "968",
    value: "Oman"
  };

  let mobile = contact.mobile;
  if (employee.mobile) mobile = employee.mobile;
  if (mobile) {
    const splitted = splitPhoneNumber(mobile);
    contact.mobile = splitted.number;
    contact.mobileCountry = getDialingOptionByDialingCode(splitted.code, props.countries) || phoneCountry;
  }
  else {
    contact.mobileCountry = phoneCountry;
  }

  if (contact.phone) {
    const splitted = splitPhoneNumber(contact.phone);
    contact.phone = splitted.number;
    contact.phoneCountry = getDialingOptionByDialingCode(splitted.code, props.countries) || phoneCountry;
  }
  else {
    contact.phoneCountry = phoneCountry;
  }

  return contact;
}

function getValues(contact) {
  const result = {
    email: contact.email,
    mobile: `${contact.mobileCountry.label} ${contact.mobile}`,
    phone: null,
    address: {
      address1: contact.street,
      city: contact.city,
      postalCode: contact.postalCode,
      poBox: contact.poBox,
      countryCode: contact.country.value,
      country: contact.country.label,
    }
  };

  if (contact.phone) {
    result.phone = `${contact.phoneCountry.label} ${contact.phone}`
  }

  if (result.address.countryCode === 'US' || result.address.countryCode === 'CA') {
    result.address.state = contact.state;
  }
  return result;
}

function getErrors(contact, hideCountry) {
  const errors = {};

  errors.email = customerValidator.validateEmail(contact.email);
  errors.confirmEmail = customerValidator.validateConfirmEmail(contact.email, contact.confirmEmail);
  errors.mobile = customerValidator.validateMobile(contact.mobile);
  errors.phone = customerValidator.validatePhone(contact.phone);
  if (!hideCountry)
    errors.country = customerValidator.validateCountryCode(contact.country.value);

  return errors;
}
