import React, {useState} from 'react'
import { Col, Row, Table } from "react-bootstrap"
import BasePage from "../../../components/BasePage"
import SideMenu from "../SideMenu"
import { Section } from './Section'

import "./index.scss";

function HelpPage({ location }) {

  const [expandedSection, setExpandedSection] = useState('')

  return (
    <BasePage>
      <Row className="min-height padt40">
        <Col md={9} xs={12}>
          <h1>Agent Portal</h1>
          <hr />
          <Section title="Agent Account Top up" expanded={expandedSection === 'topup'} onTitleClick={() => setExpandedSection('topup')}>
            <ul>
              <li>Please send your requests to
                <a href="mailto:revenue.accounting@salamair.com" className="email">revenue.accounting@salamair.com</a>
                with the transaction details of the credit to SalamAir account and your TA Number.
              </li>
              <li>Optionally, you can also top up your account using your bank cards through our TA portal</li>
            </ul>
          </Section>

          <Section title="Group Booking" expanded={expandedSection === 'group'} onTitleClick={() => setExpandedSection('group')}>
            <p>A “group” is defined as 10 or more guests occupying seats, booked together, 
              all travelling on the same route, date and flight on the same Booking.</p>
            <p className="sub-title">Eligibility:</p>
            <ul>
              <li>10 or more passengers occupying seats.</li>
            </ul>
            <p className="sub-title">Group Booking Request:</p>
            <p>Pls send below details on email to <a href="mailto:sales@salamair.com" className="email">sales@salamair.com</a></p>
            <ul>
              <li>Travel Agent Account number</li>
              <li>Travel Date</li>
              <li>Flight Number</li>
              <li>Sector</li>
              <li>Fare Type</li>
              <li>Number of Passengers</li>
            </ul>
            <p className="sub-title">Terms and Condition:</p>
            <ul>
              <li>Fares quoted are valid for 24hrs and net payable to SalamAir Airline</li>
              <li>Seats are subject to availability</li>
              <li>In case of Round-trip fare, the fare is valid only for round-trip travel</li>
              <li>100 % payment must be applied for ticket issuance</li>
              <li>Names must be updated 24 hrs prior to the flight departure</li>
              <li>Wheelchair must be requested at the respective airport prior to flight departure</li>
              <li>
                Any loss resulting due to failure to provide this information will not be the responsibility of the airline
              </li>
            </ul>
          </Section>

          <Section title="Modification and cancelation policy"
            expanded={expandedSection==='policy'} 
            onTitleClick={() => setExpandedSection('policy')}>
            <ul>
              <li>10% from group size are allowed to cancel with charge of 25 OMR 
                per PAX/Sector with a notice of 48 hours prior the departure time.</li>
              <li>Date Change: 10% of the group size up to 48 hours before departure 
                with a charge of 20 OMR plus fare difference (if any) per PAX/Sector.
              </li>
              <li>Payment: 25% of the total payment for the flights more than 21 days, 
                50% of the total payment for the flights within 21 days and 100% of the total payment
                for the flights in less than 14 days.
              </li>
            </ul>
          </Section>

          <Section title="Lost Baggage Inquiries" expanded={expandedSection==='baggage'} onTitleClick={() => setExpandedSection('baggage')}>
            <ul>
              <li>For any baggage related queries, pls email to 
                <a href="mailto:baggage.services@salamair.com" className="email">baggage.services@salamair.com</a>
              </li>
            </ul>
          </Section>

          <Section title="Wheelchair"  expanded={expandedSection==='wheelchair'} onTitleClick={() => setExpandedSection('wheelchair')}>
            <ul>
              <li>Wheelchair must be requested at the respective airport prior to flight departure</li>
            </ul>
          </Section>

          <Section title="Payment Failures" expanded={expandedSection==='payment'} onTitleClick={() => setExpandedSection('payment')}>
            <p>Incase if you have experienced an error while applying payment or your account is debited 
              without getting a confirmation of your ticket. Pls send below details to 
              <a href="mailto:sales@salamair.com" className="email">sales@salamair.com</a>
            </p>
            <Table bordered>
              <thead>
              <tr>
                <th colSpan={2} style={{textAlign: "center", backgroundColor: "yellow"}}>Online Error</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td style={{width: "220px"}}>Passenger Name</td>
                <td></td>
              </tr>
              <tr>
                <td style={{width: "220px"}}>Transaction Date</td>
                <td></td>
              </tr>
              <tr>
                <td style={{width: "220px"}}>Hold Amount</td>
                <td></td>
              </tr>
              <tr>
                <td style={{width: "220px"}}>Travel Date and Flight Details</td>
                <td></td>
              </tr>
              <tr>
                <td style={{width: "220px"}}>Bank Name</td>
                <td></td>
              </tr>
              <tr>
                <td style={{width: "220px"}}>Bank Card Last 4 Digits</td>
                <td></td>
              </tr>
              <tr>
                <td style={{width: "220px"}}>Contact No.</td>
                <td></td>
              </tr>
              <tr>
                <td style={{width: "220px"}}>Email Address</td>
                <td></td>
              </tr>
              </tbody>
            </Table>
          </Section>

        </Col>
        <Col md={3} xs={12}>
          <SideMenu activeItem="help" path={location.pathname} />
        </Col>
      </Row>
    </BasePage>
  )
}


export default HelpPage;