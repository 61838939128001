import React from 'react';

import { Checkbox as HeadlessCheckbox } from '@headlessui/react';

export function Checkbox({checked, disabled, onChange, children}
  : {checked: boolean, disabled?: boolean, onChange: (checked: boolean) => void, children: React.ReactNode}) {
  return (
    <fieldset className="tw-flex tw-items-center tw-gap-2">
    <HeadlessCheckbox checked={checked} onChange={onChange} disabled={disabled}
              className="tw-group tw-block tw-size-5 tw-border-solid tw-border-2 sa-border-lightblue data-[disabled]:tw-border-gray-400">
        <svg className="sa-stroke-lightblue tw-opacity-0 group-data-[checked]:tw-opacity-100 group-data-[disabled]:tw-stroke-gray-400" viewBox="0 0 14 14" fill="none">
          <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </HeadlessCheckbox>
    <label className="tw-m-0" onClick={() => !disabled && onChange(!checked)}>{children}</label>
  </fieldset>
  )
}