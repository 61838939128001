import React from 'react'

import MastercardPNG from "../../../images/mastercard-logo.png";
import VisaLogoPNG from "../../../images/visa-logo.png";
import MadaLogoPNG from "../../../images/mada-logo.png";

export function CardLogo({cardType}) {

  let image, title = ''
  
  switch (cardType) {
    case 'mastercard':
      image = MastercardPNG
      title = 'Mastercard'
      break
    case 'visa':
      image = VisaLogoPNG
      title = 'Visa'
      break
    case 'mada':
      image = MadaLogoPNG
      title = 'MADA'
      break

    default:
      break;
  }

  if(!image) return null

  return (
    <div className="card-logo">
      <img height="18" src={image} alt={title} />
    </div>
  )
}