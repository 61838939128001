import React, { Component } from "react";
import { ls } from "../../locale";

import SeatSelection from "../../components/SeatSelection/index";

import { connect } from "react-redux";
import { bookingActions } from "../../state/ducks/booking";
import { Button, Modal } from "react-bootstrap";
import { getAgentPath, isInAppBrowser, jumpTo, stringFormatter, tripTypeHelper } from "../../helpers";
import { numberFormatter } from "../../helpers";
import { PassengerType } from "../../constants/settings";

import "./index.scss";
import BasePageWithStatusBar from "../../components/BasePage/BasePageWithStatusBar";

class SeatsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message: null,
      isShowMessageBox: false,
      canContinue: true,
      seatMaps: [],
    };
  }

  componentDidMount() {
    this.props.getAncillaries().then(() => {
      this.props.getSeatMap().then(response => {
        this.setState({
          seatMaps: response
        })

        this.props.getBookingWithSummary().then(response => {
          if (response.booking?.paymentStarted) {
            jumpTo(`${getAgentPath()}/checkout`);
          }
        })
      })
    })
  }

  onAssignSeat = (data) => {
    return this.props.assignSeat(data)
      .then(() => {
        this.props.getBookingWithSummary();
      });
  }

  onClearSeat = (data) => {
    return this.props.clearSeat(data)
      .then(() => {
        this.props.getBookingWithSummary();
      })
  }

  onClearAllSeat = () => {
    return this.props.clearAllSeat()
      .then(() => {
        this.props.getBookingWithSummary();
      })
  }

  continue = (checked) => {

    if (checked) {
      this.goNext();
      return;
    }

    const rError = ls.t('SeatPage.Error')
    const rCommon = ls.t('Common');

    const { booking } = this.props;
    if (!booking) return;

    const selection = this.getSelection(booking);
    if (selection.totalSelected >= selection.totalCount && 
      selection.extraSelected >= selection.extraCount && 
      selection.freeSelected >= selection.freeCount) {
      this.goNext();
      return;
    }
    else if (selection.extraSelected < selection.extraCount) {
      this.showMessageBox(rError.SelectAll, false);
      return;
    }
    else if (selection.freeSelected < selection.freeCount) {
      let flights = [];
      selection.segments.forEach(segment => {
        if (segment.freeSelected >= segment.freeCount) return;
        flights.push(`${segment.originCity} - ${segment.destinationCity}`);
      })
      flights = flights.join(` ${rCommon.And} `);
      const message = stringFormatter.formatString(rError.FreeSeat, flights);

      this.showMessageBox(message, false);
    }
    else {
      const tripType = tripTypeHelper.findValueByCode(booking.tripType);
      const message = tripType === 'oneway' ?  rError.SeatNotSelected : rError.SeatNotSelectedReturn;

      this.showMessageBox(message, true);
      return;
    }
  }

  goNext = () => {
    const { booking } = this.props;
    if (booking && booking.bookingMode === "MMB") {
      jumpTo(`${getAgentPath()}/mmb/summary`);
    }
    else {
      jumpTo(`${getAgentPath()}/checkout`);
    }
  }

  goBack = () => {
    const { booking } = this.props;
    if (booking && booking.bookingMode === 'MMB')
      jumpTo(`${getAgentPath()}/mmb/summary`);
    else
      jumpTo(`${getAgentPath()}/extra`);
  }

  getSelection = (booking) => {
    const { passengers, segments } = booking;
    if (!Array.isArray(passengers) || !Array.isArray(segments)) return false;

    const selections = segments.map(segment => {
      let extraCount = 0, extraSelected = 0, totalCount = 0, totalSelected = 0, freeCount = 0, freeSelected = 0;

      if (Array.isArray(segment.fares) && segment.fares.length > 0) {
        const fare = segment.fares[0];
        if (fare.fareTypeName === 'ID90') return false;
      }
      if (!Array.isArray(segment.legs)) return false;

      //const isShowExtraFare = segment.fares.find(x => x.fareTypeName === 'Benefit');

      const segmentInSeatMap = Array.isArray(this.state.seatMaps) && 
        this.state.seatMaps.find(s => s.logicalFlightId === segment.logicalFlightId);

      segment.legs.forEach(leg => {
        if (leg.carrierCode !== leg.operatingCarrierCode) {
          return;
        }
        const legInSeatMap = segmentInSeatMap && Array.isArray(segmentInSeatMap.legs) && 
          segmentInSeatMap.legs.find(l => l.physicalFlightId === leg.physicalFlightId);
        if(!legInSeatMap || !legInSeatMap.hasAvailableSeat) return;

        passengers.forEach(pax => {
          if (pax.paxType === PassengerType.Infant) return;
          if (segment.hasFreeSeat)
            freeCount++;
          else if (pax.paxType === PassengerType.ExtraSeat)
            extraCount++;
          else
            totalCount++;

          const seat = leg.customers.find(s => { return (s.passengerId === pax.id) && s.seat });
          if (seat) {
            if (segment.hasFreeSeat)
              freeSelected++;
            else if (pax.paxType === PassengerType.ExtraSeat)
              extraSelected++;
            else
              totalSelected++;
          }
        })
      })

      
      return {
        extraCount,
        extraSelected,
        totalCount,
        totalSelected,
        freeCount,
        freeSelected,
        originCity: segment.originCity,
        destinationCity: segment.destinationCity,
      };
    })

    const validSelections = selections.filter(x => x);

    const selection = {
      extraCount: validSelections.map(x => x.extraCount).reduce((a, b) => a + b, 0),
      extraSelected: validSelections.map(x => x.extraSelected).reduce((a, b) => a + b, 0),
      totalCount: validSelections.map(x => x.totalCount).reduce((a, b) => a + b, 0),
      totalSelected: validSelections.map(x => x.totalSelected).reduce((a, b) => a + b, 0),
      freeCount: validSelections.map(x => x.freeCount).reduce((a, b) => a + b, 0),
      freeSelected: validSelections.map(x => x.freeSelected).reduce((a, b) => a + b, 0),
      segments: selections
    }

    return selection;
  }

  closeMessageBox = () => {
    this.setState({
      isShowMessageBox: false,
      message: null,
    })
  };

  showMessageBox = (message, canContinue) => {
    this.setState({
      isShowMessageBox: true,
      message: message,
      canContinue
    })
  }

  render() {

    const { booking, bookingSummary } = this.props;

    const currencyCode = booking.currencyCode;
    const total = (booking.bookingMode === 'Booking' ? bookingSummary.bookingTotal : booking.reservationBalance) || 0;
    const amountSplit = numberFormatter.decimalSplitter(numberFormatter.formatCurrency(total));

    const inAppBrowser = isInAppBrowser();

    const rSeatPage = ls.t('SeatPage');
    if (!rSeatPage.Title) return null;

    const rButton = ls.t('Button');
    const rCommon = ls.t('Common');
    const rMessageBox = ls.t('MessageBox');

    // console.log(this.state.seatMaps);

    return (
      <>
        <BasePageWithStatusBar hasSummary statusLabel="seats" onItemClick={item => jumpTo(getAgentPath() + `/${item}`)}>
          <div className="min-height seat-page">
            <h1>{rSeatPage.Title}</h1>
            <SeatSelection
              key={`${booking.pnr}-${this.state.seatMaps.length}`}
              segments={booking.segments}
              passengers={booking.passengers}
              seatMaps={this.state.seatMaps}
              currencyCode={booking.currencyCode}
              onAssignSeat={this.onAssignSeat}
              onClearSeat={this.onClearSeat}
              onClearAllSeat={this.onClearAllSeat} />

            <div className="clearfix btnCon">
              {!inAppBrowser && <Button type="submit" 
                className="btn btn-primary pull-left flip btn-cancel btn-effect" 
                onClick={() => this.goBack()}>
                <span><i className="picon picon-btn-arrow-left-w" />{rButton.Back}</span>
              </Button>}
              <Button type="submit" 
                className="btn btn-primary pull-right flip btn-submit btn-effect" 
                onClick={() => this.continue()}><span>{rButton.Continue}
                <i className="picon picon-btn-arrow-right-w" /></span>
              </Button>
              <div className="bottom-total pull-right">
                <label>{rCommon.BookingTotal}</label>
                <p>
                  <span className="currency">{currencyCode}</span>{amountSplit.amount}
                  <span className={`${currencyCode} decimal-point`}>{numberFormatter.oneDecimalPoint(amountSplit.decimal, currencyCode)}</span>
                </p>
              </div>
            </div>
          </div>
        </BasePageWithStatusBar>
        <Modal className={ls.t("rootClass")} show={this.state.isShowMessageBox} onHide={this.closeMessageBox}>
          <Modal.Header closeButton>
            <Modal.Title>{rMessageBox.Title.Warning}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="">{this.state.message}</p>
            {this.state.canContinue && <p>{rSeatPage.Continue}</p>}
          </Modal.Body>
          <Modal.Footer>
            {this.state.canContinue && <Button className="btn btn-primary" onClick={() => this.continue(true)}>{rButton.Yes}</Button>}
            <Button className="btn btn-default" 
              onClick={() => this.closeMessageBox()}> {this.state.canContinue ? rButton.Cancel : rButton.OK}</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {

  return {
    booking: state.booking.details,
    bookingSummary: state.booking.summary,
  };
}

const mapDispatchToProps = {
  ...bookingActions
};

export default connect(mapStateToProps, mapDispatchToProps)(SeatsPage);

