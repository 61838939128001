import { Booking } from "../../state/models/booking";

export function isFlightDisrupted(booking: Booking) {
  if(!booking) return false;

  if (Array.isArray(booking.segments) && booking.segments.length) {
    return booking.segments.some((segment) => !!segment.flightDisruption?.code);
  }
  else if(Array.isArray(booking.cancelledSegments) && booking.cancelledSegments.length) {
    return booking.cancelledSegments.some((segment) => !!segment.flightDisruption?.code);
  }
  return false;
}

export function getDisruptedFlights(booking: Booking) {
  if (!Array.isArray(booking?.segments)) return null;
  return booking.segments.filter((segment) => !!segment.flightDisruption?.code);
}

export function getActionsForDisruption(booking: Booking) {
  const flights = getDisruptedFlights(booking);
  if (!Array.isArray(flights)) return [];

  // pick actions from all disrupted flights, flatten the array and remove duplicate
  return Array.from(new Set(flights.flatMap((flight) => flight.flightDisruption?.actions || [])));
}
