import { useState } from 'react'

/**
 * @deprecated use useDialog instead
 */

export function usePopupMessage() {
  const [show, setShow] = useState(false)
  const [title, setTitle] = useState<string>()
  const [message, setMessage] = useState<string>()

  const [trigger, setTrigger] = useState<{
    reject: (v: any) => void;
    resolve: (v: any) => void;
  }>();

  function showMessage(m: string, t?: string): Promise<void> {
    setShow(true)
    if(m) setMessage(m)
    if(t) setTitle(t)

    return new Promise((resolve, reject) => {
      setTrigger({ resolve, reject })
    })
  }

  // confirmed: a boolean to indicate if the user has confirmed the message
  function hideMessage(confirmed: boolean = false) {
    setShow(false)
    if(trigger) {
      confirmed ? trigger.resolve(true) : trigger.reject(false)
      setTrigger(undefined)
    }
  }

  return {
    show,
    title,
    message,
    showMessage,
    hideMessage
  }

}