import React, { useEffect } from "react"
import { connect } from "react-redux"

import { Button } from "@headlessui/react";

import { bookingActions } from "../../state/ducks/booking";
import { mmbActions } from "../../state/ducks/mmb";
import { flightActions } from "../../state/ducks/flights";
import { sessionActions } from "../../state/ducks/session";

import { jumpTo, isInAppBrowser, getAgentPath, buildSearchParams } from "../../helpers";
import { ls } from "../../locale";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { DialogBox, mapDialogMessage, useDialog } from "../../comps/dialog";

import { AlertBox } from "./AlertBox";
import { getActionsForDisruption, getDisruptedFlights, isFlightDisrupted } from "./helpers";
import { FlightDetail } from "./FlightDetail";
import { Booking, Segment, Summary, BookingWithSummaryResponse } from "../../state/models";

import { FlightSelect, useFlightSelect } from "./FlightSelect";

import { Payment } from "./Payment";
import { ButtonInActions } from "./ButtonInActions";

import "../MMB/SummaryPage/index.scss";

function DisruptionPage({ booking, bookingSummary, getBookingWithSummary, processDisruption, cancelFlight, resetBooking, confirmChanges, getBookingMode, setBookingMode, getSearchRequest, setSearchParams }
  : { booking: Booking, 
      bookingSummary: Summary, 
      getBookingWithSummary: () => Promise<BookingWithSummaryResponse>, 
      processDisruption: (action: string) => Promise<void>, 
      cancelFlight: (flights: number[]) => Promise<void>,
      resetBooking: () => Promise<void>,
      confirmChanges: () => Promise<void>,
      getBookingMode: () => any,
      setBookingMode: (mode: any) => void,
      getSearchRequest: () => Promise<any>,
      setSearchParams: (params: any) => void
    }) {

  const dialog = useDialog('Confirmation');
  const flightSelect = useFlightSelect();

  useEffect(() => {
    getBookingWithSummary().then((response: BookingWithSummaryResponse) => {
      if(!isFlightDisrupted(response.booking)) {
        jumpTo(getAgentPath() + "/mmb/summary");
      }
    }) 

  }, [])

  const handleConfirm = () => {
    const rDisruptionPage = ls.t('DisruptionPage');

    dialog
    .show(rDisruptionPage.Confirm.Message)
    .then(() => {
      // console.log("confirmed")
       processDisruption('confirm').then(() => {
         jumpTo(getAgentPath() + "/mmb/summary");
       })
    })
    .catch(() => {})
  }

  const handleChange = () => {

    const selected = booking.segments.filter(s => s.flightDisruption?.code).map(s => s.logicalFlightId);
    const segments = booking.segments.filter(s => s.permissions?.canChangeFlight === true);

    const rDisruptionPage = ls.t('DisruptionPage');

    flightSelect
    .show(segments, selected, 'Change Flight', rDisruptionPage.Change.SelectFlight, rDisruptionPage.Change.Notes)
    .then(selected => {
      // console.log('selected: ', selected)
      const bookingMode = getBookingMode();
      if(bookingMode?.mode !== 'MMB') return;

      bookingMode.action = 'change';
      bookingMode.flights = selected.map(x => {
        // find correct segment index for multi city PNRs because the segment index in multi-city PNRs is always 0
        const segment = booking.segments.find(s => s.logicalFlightId === x);
        if(!segment) return null;

        const segmentIndex = booking.segments.findIndex(s => s.logicalFlightId === x);

        return {
          logicalFlightId: segment.logicalFlightId, 
          origin: segment.originCode,
          destination: segment.destinationCode,
          segmentIndex: segmentIndex,
        };
      });
      bookingMode.flights = bookingMode.flights.filter((x:any) => !!x);

      setBookingMode(bookingMode);

      getSearchRequest().then(response => {
        const params = buildSearchParams(response);
        if (params) {
          setSearchParams(params);
          jumpTo(getAgentPath() + "/flights");
        }
      });

    })
    .catch(() => {});
  }

  const handleCancel = () => {

    const selected = booking.segments.filter(s => s.flightDisruption?.code).map(s => s.logicalFlightId);
    const segments = booking.segments.filter(s => s.permissions?.canCancelFlight);

    const rDisruptionPage = ls.t('DisruptionPage');

    flightSelect
    .show(segments, selected, 'Cancel Flight', rDisruptionPage.Cancel.SelectFlight)
    .then(selected => {
      // console.log('selected: ', selected)
      if(Array.isArray(selected) && selected.length) {
        cancelFlight(selected).then(response => {
          console.log(response)
        })
      }
    })
    .catch(() => {});
  }

  const handleReset = () => {
    resetBooking();
  }

  const handleConfirmChanges = () => {
    confirmChanges().then(() => {
      jumpTo(getAgentPath() + "/mmb/summary");
    })
  }

  // render

  if(!booking?.pnr) return null;

  const inAppBrowser = isInAppBrowser();

  const rDisruptionPage = ls.t('DisruptionPage');
  if(!rDisruptionPage.Label) return null;

  const rRetrievePage = ls.t('RetrievePage');
  const rCommon = ls.t('Common');
  const rBookingStatus = ls.t('BookingStatus');
  const rButton = ls.t('Button');

  let bookingStatus = booking.modified ? "Pending" : booking.bookingStatus; 
  if(rBookingStatus[bookingStatus]) bookingStatus = rBookingStatus[bookingStatus];

  const disruptedFlights = getDisruptedFlights(booking);

  let disruptionMessage = '';
  if(disruptedFlights && disruptedFlights.length > 0)
  {
    const disruptionCode = disruptedFlights[0].flightDisruption?.code;
    const messages = booking.isGroupBooking ? rDisruptionPage.GroupBooking : rDisruptionPage;
    disruptionMessage = messages[`Message${disruptionCode}`] || messages.Message;
  } 
  const changeTips = rDisruptionPage.Change?.Tips ?? 'You have one free change';

  const disruptionActions = getActionsForDisruption(booking);

  return (
    <>
      <div className={ls.t("rootClass") + " main-container"} dir={ls.t("direction")}>
        <div className="header-con"><Header /></div>
        {!inAppBrowser && <div className="header-bar" />}
        <div className="container confirmation padt40 padb40">
          <div className="min-height">
            <div className="booking-reference">
              <p>{rRetrievePage.BookingReference}</p>
              <h2>{booking.pnr}</h2>
            </div>
            <h1>{rDisruptionPage.Label}</h1>   
            <p className="booking-status">
              {rCommon.BookingStatus} : <span>{bookingStatus}</span>
            </p>

            {disruptionMessage && <AlertBox className="tw-mt-20 md:tw-mt-0">{ disruptionMessage }</AlertBox> }

            { disruptedFlights?.map((segment: Segment) => <FlightDetail key={segment.logicalFlightId} segment={segment} /> )}

            {(booking.reservationBalance !== 0 || booking.modified) && <Payment booking={booking} bookingSummary={bookingSummary} onConfirm={handleConfirmChanges} />}
            
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-end tw-items-end tw-gap-3 tw-mt-5">
              { !booking.modified && disruptionActions.includes('confirm') && <ButtonInActions text={rButton.Accept} onClick={handleConfirm}></ButtonInActions> }
              { !booking.modified && disruptionActions.includes('change') && <ButtonInActions text={rButton.ChangeFlight} hint={changeTips} onClick={handleChange}></ButtonInActions>}
              { !booking.modified && disruptionActions.includes('cancel') && <ButtonInActions text={rButton.CancelFlight} onClick={handleCancel}></ButtonInActions> }
              { booking.modified && <ButtonInActions text={rButton.ResetBooking} onClick={handleReset}></ButtonInActions> }
            </div>
          </div>
        </div>
        {!inAppBrowser && <Footer />}
      </div>
      <DialogBox open={dialog.open} title={dialog.title} closeButtonText={rButton.Confirm} onClose={dialog.close}>
        { mapDialogMessage(dialog.message) }
      </DialogBox>
      <FlightSelect {...flightSelect} />
    </>
  )
}

function mapStateToProps(state: any) {
  return {
    booking: state.booking.details,
    bookingSummary: state.booking.summary
  };
}

const mapDispatchToProps = {
  getBookingWithSummary: bookingActions.getBookingWithSummary,
  processDisruption: mmbActions.processDisruption,
  cancelFlight: mmbActions.cancelFlight,
  resetBooking: mmbActions.resetBooking,
  confirmChanges: mmbActions.confirmChanges,
  getSearchRequest: flightActions.getSearchRequest,
  getBookingMode: sessionActions.getBookingMode,
  setBookingMode: sessionActions.setBookingMode,
  setSearchParams: sessionActions.setSearchParams,
}


export default connect(mapStateToProps, mapDispatchToProps)(DisruptionPage)