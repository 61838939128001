import React from 'react';

export function mapDialogMessage(message?: string | string[]) {

  if(!message) return false;
  const messages = Array.isArray(message) ? message : [message]

  const result = groupMesssages(messages);

  return result.map((m, i) => {
    if(Array.isArray(m)) {
      return (
        <ul key={i}>
          {m.map((m1, i1) => <li key={i1} dangerouslySetInnerHTML={{__html: m1}}/>)}
        </ul>
      )
    }
    else {
      return <p key={i} dangerouslySetInnerHTML={{__html: m}} />
    }
  })
}

// group the messages into paragraphs and ul
// in the result, a string is a paragraph, an array is a ul
function groupMesssages(messages: string | string[]) {
  
    if(!Array.isArray(messages)) return [messages];
    const result: (string[] | string)[] = []
  
    let startUl = false
    for(let i=0; i<messages.length; i++) {
      const m = messages[i]
      if(m.startsWith('- ')) {
        if(!startUl) {
          startUl = true
          result.push([])
        }
        const item = result[result.length-1]
        if(Array.isArray(item))item.push(m.substring(2))
      }
      else {
        if(startUl) {
          startUl = false
        }
        result.push(m)
      }
    }
  
    return result
}