import React, { forwardRef, useReducer, useImperativeHandle, useCallback } from "react";
import { Button, Modal, FormGroup, FormControl } from "react-bootstrap";


import { ls } from "../../locale";
import FormErrorBlock from "../../components/FormErrorBlock";

import { reducer, initialState, isValidUsername } from "./loginReducer";

export default forwardRef(function LoginWindow(props, ref) {

  const [state, dispatch] = useReducer(reducer, initialState);

  const show = useCallback(() => {
    dispatch({type: 'SHOW_LOGIN_FORM'})
  }, [])

  const hide = useCallback(() => {
    dispatch({type: 'HIDE_LOGIN_FORM'})
  },[])

  useImperativeHandle(ref, () => {
    return {
      show,
      hide,
    }
  }, [show, hide])


  const setUsername = useCallback(username => {
    dispatch({type: 'SET_INPUT_STATE', key: 'username', value: username.trim()})
  }, [])

  const setPassword = useCallback(password => {
    dispatch({type: 'SET_INPUT_STATE', key: 'password', value: password})
  }, [])

  // return value is true if the username is valid
  const validateUsername = useCallback(username => {

    const error = isValidUsername(username);
    
    if(error) {
      dispatch({ type: 'SET_ERROR_STATE', key: 'username', error })
      return false
    }

    return true
  }, [isValidUsername])

  
  const login = () => {

    const { username, password } = state;
    if(!validateUsername(username)) return;

    if(!password) {
      dispatch({ type: 'SET_ERROR_STATE', key: 'password', error: "Password is empty"})
      return;
    }

    if(typeof(props.onLogin) === 'function')
      props.onLogin(username, password);
  }


  const forgotPassword = () => {
    const { username } = state;
    if(!validateUsername(username)) return;

    dispatch({ type: 'SHOW_PASSWORD_BOX'})
  }

  const closePasswordBox = () => {
    dispatch({ type: 'HIDE_PASSWORD_BOX'})
  };

  const resetPassword = () => {
    if(typeof(props.onResetPassword) === 'function') {
      props.onResetPassword(state.username);
    }
  }

  return (
    <React.Fragment>
      <Modal show={state.isShowLoginForm} onHide={hide} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Your Login Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormGroup>
              <FormControl type="text" placeholder="User ID" 
                value={state.username} 
                onChange={event => setUsername(event.target.value)} />
              <FormErrorBlock error={state.errors.username} />
            </FormGroup>
          </div>
          <div>
            <FormGroup>
              <FormControl type="password" placeholder="Password" 
                value={state.password} 
                onChange={event => setPassword(event.target.value)} />
              <FormErrorBlock error={state.errors.password} />
              <p><button className="link-button" onClick={forgotPassword}>Forgot Password</button></p>
            </FormGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={login}>Login</Button>
        </Modal.Footer>
      </Modal>

      <Modal className={ls.t("rootClass")} show={state.isShowPasswordBox} onHide={closePasswordBox}>
        <Modal.Header closeButton>
          <Modal.Title>{'Warning'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Your password will be sent to your contact email address. Do you want to process?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={resetPassword}>Yes</Button>
          <Button onClick={closePasswordBox}>No</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
  
})
