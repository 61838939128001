import React from "react";
import { Col , Row } from "react-bootstrap";


const RowHeading = () => {
  return(
    <div className="seat-row-container heading">
      <Row className="seat-heading">
        <Col md={5}  xs={5} className="pd0">
          <Row>
            <Col md={4}   xs={4} className="text-center">
              A
            </Col>
            <Col md={4} xs={4} className="text-center">
              B
            </Col>
            <Col md={4} xs={4} className="text-center">
              C
            </Col>
          </Row>
        </Col>
        <Col md={2} xs={2}>
        </Col>
        <Col md={5}  xs={5} className="pd0">
          <Row>
            <Col md={4} xs={4} className="text-center">
              D
            </Col>
            <Col md={4} xs={4} className="text-center">
              E
            </Col>
            <Col md={4} xs={4} className="text-center">
              F
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
};

export default RowHeading;
