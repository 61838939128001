import scrollToElement from "scroll-to-element";


export function filterOutLegsWithSeatMap(segment, seatMaps) {
  if(!segment || !Array.isArray(segment.legs)) return [];
  if(!Array.isArray(seatMaps)) return segment.legs;

  // for through flight
  if(segment.isThroughFlight) return [segment.legs[0]];

  const seatSegment = seatMaps.find(x => x.logicalFlightId === segment.logicalFlightId);
  if(!seatSegment) return segment.legs;

  return segment.legs.filter(leg => {
    const seatLeg = seatSegment.legs.find(x => x.physicalFlightId === leg.physicalFlightId);
    return seatLeg && Array.isArray(seatLeg.carbins);
  })
}

export function findLeg(logicalFlightId, physicalFlightId, segments){
  if (!Array.isArray(segments)) return null;

  const segment = segments.find(s => s && s.logicalFlightId === logicalFlightId);
  if (!segment || !Array.isArray(segment.legs)) return null;

  return segment.legs.find(l => { return l.physicalFlightId === physicalFlightId; });
}

export function findPassenger(passengerId, passengers) {
  if (!Array.isArray(passengers)) return null;
  return passengers.find(p => { return p.id === passengerId });
}

export function scrollToSegment(segments, logicalFlightId) {
  // scroll to segment
  if(segments && segments.length > 1) {
    if( segments[1] && logicalFlightId === segments[1].logicalFlightId) {
      if( window.innerWidth < 480 ){
        scrollToElement('#trip-id1',{
          offset:-150
        });
      } else{
        scrollToElement('#trip-id1' , {
          offset: -100
        });
      }
    }
  }
}