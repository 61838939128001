import { profileValidator as validator, customerValidator } from "../../../helpers";

export const initialState = {
  values: {
    email: '',
    password: '',
  },
  errors: {}
}
export function validateValues(values) {
  
  const errors = {}
  
  errors.email = validator.validateUserName(values.email)
  errors.password = customerValidator.validateSigninPassword(values.password)

  return errors
}
