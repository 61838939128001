import React from 'react';
import { HelpBlock } from "react-bootstrap";

export default function FormErrorBlock(props) {
  if (!props.error) return null;

  return (
    <HelpBlock className="error-con">
      <div className="message">{props.error}</div>
    </HelpBlock>)
}