import React from 'react'

import { Button, Clearfix } from 'react-bootstrap'
import { ls } from "../../../locale"

export function Actions({onPayNow, onBack}) {

  const rButton = ls.t('Button')

  return (
    <>
      <Clearfix />

      <div className="btnCon form-actions">
        <Button className="btn-cancel" onClick={onBack}>{rButton.Back}</Button>
        <Button className="btn-submit" onClick={onPayNow}>{rButton.PayNow}</Button>
      </div>
    </>
  )
}