import React from "react";
import { Modal, Button } from "react-bootstrap";
import { ls } from "../../locale";

import { FlightDetails } from "./FlightDetails";

import "./index.scss";

export function FlightDetailsWindow({flight, onClose}) {

  const rFlightDetails = ls.t('FlightDetails')
  const rButton = ls.t('Button')

  return (
    <Modal className={ls.t("rootClass")} show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{rFlightDetails.Title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FlightDetails flight={flight} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>{rButton.Close}</Button>
      </Modal.Footer>
    </Modal>
  )
}

