import React from 'react';
import { DateFormatter } from '../../helpers/dateFormatter';

type DateType = Date | string;

export function FlightDate({date, prevDate, className}: {date: DateType, prevDate?: DateType, className?: string}) {

  const curr = new DateFormatter(date);
  const prev = prevDate ? new DateFormatter(prevDate) : null;

  return (
    <div className={`tw-flex tw-flex-col`}>
      <div className="tw-px-2 tw-text-sm tw-line-through tw-min-h-5">{ prev?.toTime24()}</div>
      <div className={className}>
        <div className="tw-text-3xl tw-font-bold">{ curr.toTime24() }</div>
        <div className="tw-text-base">{ curr.toWeekDayMonthShort() }</div>
      </div>
      <div className="tw-px-2 tw-text-sm tw-line-through tw-min-h-5">{ prev?.toWeekDayMonthShort() }</div>
    </div>
  )
}