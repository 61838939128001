import { paymentService } from "./paymentService";


export default {
  getFingerprint,
  authorizeCS,
  tdsEnrol,
  tdsValidate,
  makeVoucherPayment,
  makeOmanDebitPayment,
  makeAGPayment,
  makePLPayment,
  makeFTPayment,
  makeCashPayment,
  checkOmanDebitPayment,
  getPaymentTotal,
  convertCurrency,
  getPaymentMethods,
  getMadaPaymentParams,
  getKnetPaymentParams,
  getSmartPayParams
};

function getFingerprint(eppSelected) {
  return () => {
    return paymentService.getFingerprint(eppSelected);
  };
}

function authorizeCS(details) {
  return () => {
    return paymentService.authorizeCS(details);
  };
}

function tdsEnrol(details, cardinalEventData) {
  return () => {
    return paymentService.check3DSEntrollment(details, cardinalEventData);
  };
}
function tdsValidate(details) {
  return () => {
    return paymentService.check3DSValidation(details);
  };
}

function makeVoucherPayment(details) {
  return () => {
    return paymentService.makeVoucherPayment(details);
  };
}

function makeOmanDebitPayment() {
  return () => {
    return paymentService.makeOmanDebitPayment();
  };
}

function makeAGPayment() {
  return () => {
    return paymentService.makeAGPayment();
  };
}

function makePLPayment() {
  return () => {
    return paymentService.makePLPayment();
  };
}

function makeFTPayment() {
  return () => {
    return paymentService.makeFTPayment();
  };
}

function makeCashPayment(request) {
  return () => {
    return paymentService.makeCashPayment(request);
  };
}

function checkOmanDebitPayment(reference) {
  return () => {
    return paymentService.checkOmanDebitPayment(reference);
  };
}

function getPaymentTotal(methodCode) {
  return () => {
    return paymentService.getPaymentTotal(methodCode);
  };
}

function convertCurrency(data) {
  return () => {
    return paymentService.convertCurrency(data);
  };
}

function getPaymentMethods() {
  return () => {
    return paymentService.getPaymentMethods();
  };
}

function getMadaPaymentParams() {
  return () => {
    return paymentService.getMadaPaymentParams();
  }
}

function getKnetPaymentParams() {
  return () => {
    return paymentService.getKnetPaymentParams();
  }
}

function getSmartPayParams() {
  return () => {
    return paymentService.getSmartPayParams();
  }
}


