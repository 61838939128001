import dayjs from 'dayjs';
import React from 'react'
import { ls } from "../../../locale";

export function VoucherDetails({voucher}) {

  const rTravelVoucher = ls.t('TravelVoucher')

  if(!voucher || !voucher.voucherNumber) return (
    <div>No Voucher Found</div>
  );

  return (
    <div className="padt20 voucher-confirmation">
      <div className="voucher-status">{rTravelVoucher.VoucherStatus}: <span>Confirmed</span></div>

      <div className="voucher-details">
        <p className="padb20">{rTravelVoucher.Notification}</p>
        <p>{rTravelVoucher.Confirmation}</p>
        <p>{rTravelVoucher.IssueTo}: <strong>{voucher.passenger.firstName} {voucher.passenger.lastName}</strong></p>
        <p>{rTravelVoucher.VoucherNumber}: <strong>{ voucher.voucherNumber }</strong></p>
        <p>{rTravelVoucher.VoucherPin}: <strong>{ voucher.voucherPin }</strong></p>
        <p>{rTravelVoucher.VoucherAmount}: <strong>{voucher.currency} {voucher.amount}</strong></p>
        <p>{rTravelVoucher.IssueDate}: <strong>{dayjs(voucher.issuedDate).format('D MMM YYYY')}</strong></p>
      </div>
    </div>
  )
}