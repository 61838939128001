import React from "react"
import { Row, Col, FormGroup, FormControl } from "react-bootstrap";
import { ls } from "../../../locale";
import FormErrorBlock from "../../../components/FormErrorBlock";

export default function (props) {

  const { values, errors, setInputState } = props;

  const rProfilePage = ls.t('Member.ProfilePage');
  const rInput = ls.t('Input');

  return (
    <div className="single-passenger">
      <h1>{rProfilePage.RegistrationDetails}</h1>
      <Row>
        <Col md={3} xs={12}>
          <FormGroup >
            <FormControl type="text" placeholder={rInput.UserId} value={values.userName || ''} disabled />
            <label className="text-muted"> {rProfilePage.ChangeUserId} </label>
          </FormGroup>
        </Col>
        <Col md={3} xs={12}>
          <FormGroup>
            <FormControl type="password" placeholder={rInput.CurrentPassword} 
              value={values.currentPassword || ''} 
              onChange={event => setInputState('currentPassword', event.target.value)} />
            <FormErrorBlock error={errors.currentPassword}/>
          </FormGroup>
        </Col>
        <Col md={3} xs={12}>
          <FormGroup>
            <FormControl type="password" placeholder={rInput.NewPassword} 
              value={values.newPassword || ''} 
              onChange={event => setInputState('newPassword', event.target.value)} />
            <FormErrorBlock error={errors.newPassword} />
          </FormGroup>
        </Col>
        <Col md={3} xs={12}>
          <FormGroup>
            <FormControl type="password" placeholder={rInput.ConfirmPassword} 
              value={values.confirmPassword || ''} 
              onChange={event => setInputState('confirmPassword', event.target.value)} />
            <FormErrorBlock error={errors.confirmPassword} />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}
