import React from "react";
import dayjs from "dayjs";

import { ls } from "../../../locale";
import { Toolbar } from './Toolbar.js'

const DirectionHeader = ( props ) => {
  const rFlightPage = ls.t('FlightPage');
  const rFlight = ls.t('Flight');
  //const locale = ls.t('rootClass');

  return(
    <React.Fragment>
      <div className="flight-heading">
        <h1>{props.bIndex === 0 ? rFlightPage.DepartingFlight : rFlightPage.ReturningFlight}</h1>
        <Toolbar 
              selectedCurrency={props.selectedCurrency} 
              onModifySearch={props.onModifySearch} 
              onSelectCurrency={props.onSelectCurrency} 
              userData={props.userData} 
              bookingMode={props.bookingMode}
              currencies={props.currencies}
              isShowCurrencySelect={props.bIndex === 0}
              onPriceAlert={props.onPriceAlert} />
      </div>

      <div className="topDetailsCon clearfix" id={ props.bIndex === 0 ? "depature-flight" : "return-flight"}>
        <i className="picon picon-plane">
        </i>&nbsp;
        {props.origin} {rFlight.To} {props.destination}&nbsp;
        <span className="dateCon">{dayjs(props.date).format("dddd DD MMMM YYYY")}</span>
      </div>
      <hr />
    </React.Fragment>
  );
}


export default DirectionHeader;
