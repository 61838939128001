import { combineReducers } from "redux";

import { alert } from "./alert";
import { loadingStatus } from "./loadingStatus";

const appReducer = combineReducers({
  alert,
  loadingStatus,
});

const reduxReducer = (state, action) => {
  return appReducer(state, action);
};

export default reduxReducer;
