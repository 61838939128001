import React from "react";
import dayjs from "dayjs";
import Slider from "react-slick";

import { numberFormatter } from "../../../helpers";
import { ls } from "../../../locale";

export default function CalendarStrip({ tripData, bIndex, onSelectDate, onMoveSlide }) {

  const selectDate = (date, index) => {
    if (typeof (onSelectDate) === 'function')
      onSelectDate(date, index);
  }

  const nextSlide = (index, days) => {
    if (typeof (onMoveSlide) === 'function')
      onMoveSlide(index, days);
  };

  const prevSlide = (index, days) => {
    if (typeof (onMoveSlide) === 'function')
      onMoveSlide(index, -days);
  };

  const days = tripData.markets.length ? tripData.markets.length : settings.slidesToScroll;

  //const locale = ls.t('rootClass');

  return (
    <React.Fragment>
      <div className="slider-con">
        <div className="slider-incon">
          <Slider ref={function () { return "slider" + bIndex; }} {...settings}>
            {
              tripData.markets.map(day => (
                <div className={"intslider " + checkCurrent(day.date, tripData.date)} key={day.date} onClick={() => selectDate(day.date, bIndex)}>
                  <div className="date">{dayjs(day.date).format("ddd DD MMM")}</div>
                  <CheckFare day={day} currency={tripData.currencyCode} />
                </div>
              ))
            }
          </Slider>
          <div className="slider-control">
            <button className='btn btn-next' onClick={() => nextSlide(bIndex, days)}>
              <i className="picon picon-arrow-right-g" />
            </button>
            <button className='btn btn-prev' onClick={() => prevSlide(bIndex, days)}>
              <i className="picon picon-arrow-left-g" />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function CheckFare({ day, currency }) {

  const rFlightPage = ls.t('FlightPage');

  const today = dayjs();
  const theDay = dayjs(day.date);
  if (today.diff(theDay, 'day') > 0) {
    return <span className="currency">{rFlightPage.NotAvailable}</span>
  }

  if (!Array.isArray(day.flights))
    return <span className="currency">{rFlightPage.NoFlights}</span>;
  if (day.flights.fares === null || day.lowestFare === 0)
    return <span className="currency">{rFlightPage.SoldOut}</span>;

  const splitted = numberFormatter.decimalSplitter(day.lowestFare);
  return (
    <React.Fragment>
      <span className="currency">{currency}</span>
      {splitted.amount}
      <span className={`${currency} decimal-point`}>{numberFormatter.oneDecimalPoint(splitted.decimal, currency)}</span>
    </React.Fragment>
  );
}

const checkCurrent = (date, bookingDate) => {
  if (dayjs(date).isSame(dayjs(bookingDate), 'day')) {
    return "current";
  } else {
    return "";
  }
}

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 7,
  slidesToScroll: 7,
  responsive: [
    { breakpoint: 768, settings: { slidesToShow: 3, slidesToScroll: 3, dots: true, initialSlide: 3 } },
    { breakpoint: 600, settings: { slidesToShow: 3, slidesToScroll: 3, dots: true, initialSlide: 3 } },
    { breakpoint: 480, settings: { slidesToShow: 3, slidesToScroll: 1, dots: true, initialSlide: 2 } }
  ],
}
